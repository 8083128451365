import { useSelector, useDispatch } from 'react-redux';
import { FetchHandler } from '../Handlers/FetchHandler';
import { CHANGE_USERS } from '../rdx/dataReducer';
import { UsersInfoStoreType, UsersInfoType } from '../rdx/Redux.DataReducerType';
import { RdxStore } from '../rdx/ReduxTypes';



export const useUsers = () => {
    const users: UsersInfoStoreType = useSelector((state: RdxStore) => state.data.users);
    const dispatch = useDispatch();

    // const getUserData = async (id) => {
    //     return await FetchHandler.getJson("/api/user/" + id + "/info");
    // }

    return {
        getUser: (userId: string): UsersInfoType => {
            return users?.[userId];
        },
        getUsersName: (userId: string): string => {
            if (userId) {
                return users?.[userId] ? users?.[userId]?.name : "N/A";
            } else {
                return "";
            }
        },
        getUsersEmail: (userId: string): string => {
            if (userId) {
                return users?.[userId] ? users?.[userId]?.email : "N/A";
            } else {
                return "";
            }
        },

        //
        ensureUserInfo: async (userIds: string[]) => {
            // console.log("userIds: ", userIds);
            // console.log("userIds: ", userIds?.map(m => m?.length));

            const uids = Array.from(new Set(userIds ?? []));
            const userIds_clean = uids
                .filter(m => m?.length === 36)
                .filter(userId => { return !users?.[userId] });
            const t = await FetchHandler.postJson("/api/user/info", { ids: userIds_clean });

            // const w = userIds
            //     .filter(m => m?.length === 36)
            //     .filter(userId => { return !users?.[userId] })
            //     .map(async userId => { return await FetchHandler.getJson("/api/user/" + userId + "/info") });
            // const t = await Promise.all(w);
            let u_new = { ...users };
            t.forEach((u: UsersInfoType) => u_new[u.userId] = u);
            dispatch({ type: CHANGE_USERS, payload: u_new })
        },
    }
}

