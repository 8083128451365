import { combineReducers } from 'redux';
import { adReducer } from './adReducer';
import {
  appReducer,
  CHANGE_CURRENT_VIEW, CHANGE_ONLINE_STATUS, CHANGE_APP_URI,
  CHANGE_LOGGED_IN, CHANGE_ACTIVE_ROLES, CHANGE_TOASTS, CHANGE_UI_SETTINGS
} from './appReducer';

import {
  dataReducer,
  CHANGE_COLOR_LIST, CHANGE_PLANTFIELD_LIST, CHANGE_PLANTFIELD_OBJECT,
  CHANGE_PLANTTYPES_LIST, CHANGE_PLANTTYPES_OBJECT, CHANGE_PLANTTAGS_LIST,
  CHANGE_PLANTTAGS_OBJECT, CHANGE_PLANTLISTS, CHANGE_PLANTLISTS_LINKED_TO_OBJECT,
  CHANGE_CURRENT_PLANTLIST, CHANGE_SNIPPETS, CHANGE_PLANTIMAGES, CHANGE_USERS
} from "./dataReducer";

import {
  searchReducer,
  CHANGE_SEARCH_TEXT, CHANGE_SEARCH_TYPE, CHANGE_SEARCH_PUBLISHED, CHANGE_SEARCH_SEARCHING,
  CHANGE_SEARCH_LIST, CHANGE_SEARCH_VISIBLEFIELDS
} from "./searchReducer";
import { signalrReducer } from './signalrReducer';


import {
  toolsReducer,
  CHANGE_TOOLS_USERPLANTS
} from "./toolsreducer";

const reducers = combineReducers({
  app: appReducer,
  data: dataReducer,
  search: searchReducer,
  tools: toolsReducer,
  ads: adReducer,
  signalr: signalrReducer
});

export {
  reducers,

  /* appReducer */
  CHANGE_CURRENT_VIEW, CHANGE_ONLINE_STATUS, CHANGE_UI_SETTINGS,
  CHANGE_APP_URI, CHANGE_LOGGED_IN, CHANGE_ACTIVE_ROLES, CHANGE_TOASTS,

  /* dataReducer */
  CHANGE_COLOR_LIST,
  CHANGE_PLANTFIELD_LIST, CHANGE_PLANTFIELD_OBJECT,
  CHANGE_PLANTTYPES_LIST, CHANGE_PLANTTYPES_OBJECT,
  CHANGE_PLANTTAGS_LIST, CHANGE_PLANTTAGS_OBJECT,
  CHANGE_PLANTLISTS, CHANGE_PLANTLISTS_LINKED_TO_OBJECT,
  CHANGE_SNIPPETS, CHANGE_CURRENT_PLANTLIST, CHANGE_PLANTIMAGES,
  CHANGE_USERS,

  /* searchReducer */
  CHANGE_SEARCH_TEXT, CHANGE_SEARCH_TYPE,
  CHANGE_SEARCH_PUBLISHED,
  CHANGE_SEARCH_SEARCHING, CHANGE_SEARCH_LIST,
  CHANGE_SEARCH_VISIBLEFIELDS,

  /* searchReducer */
  CHANGE_TOOLS_USERPLANTS

}