import React, { Component, ReactNode } from 'react';
import { Helmet } from 'react-helmet';
import { LeftMenu } from "./UI/LeftMenu";
import { TopMenu } from "./UI/TopMenu";
import { StoreFillerHandler } from "../rdx/StoreFillerHandler";
import { ClipBoardElement } from './ClipBoard/ClipBoard';
import { useClipBoard } from './ClipBoard/useClipBoard';

interface IProps {
  children: any
}
interface IState { }

export class Layout extends Component<IProps, IState> {
  static displayName = Layout.name;

  constructor(props) {
    super(props);
    StoreFillerHandler.ensureCache(true);
  }


  render() {
    return (
      <div className='ui-outer'>
        <Header />
        <TopMenu />
        <LeftMenu />
        <div id="ui-content">
          <UIContent>
            {this.props.children}
          </UIContent>
        </div>
      </div>
    );
  }
}

const UIContent = ({ children }: { children: ReactNode }) => {
  const ui = useClipBoard();
  return (<div id="ui-content" className={ui.clipBoardShow == false ? "clipboard-hidden" : ""}>
    <div id='ui-content-children'>
      {children}
    </div>
    {!!ui.clipBoardShow && (
      <ClipBoardElement />
    )}
  </div>
  )
}


const Header = () => {
  return (
    <Helmet>
      <title>Oh! Garden administration</title>
      <link rel="preconnect" href="https://cdnjs.cloudflare.com" />
      <meta name="description" content="Oh Garden Admin." />
      <link
        rel="stylesheet"
        href="https://cdnjs.cloudflare.com/ajax/libs/font-awesome/5.15.2/css/all.min.css"
        integrity="sha512-HK5fgLBL+xu6dm/Ii3z4xhlSUyZgTT9tuc/hSrtw6uzJOvgRr2a9jyxxT1ely+B+xFAmJKVSTbpM/CuL7qxO8w=="
        crossOrigin="anonymous" />

      <style>
        @import url('https://fonts.googleapis.com/css2?family=Quicksand:wght@300;400;487;500;600;700&amp;display=swap');
      </style>
      <link href="https://fonts.googleapis.com/css2?family=Abhaya+Libre:wght@400;500;600;700;800&amp;family=Quicksand:wght@300;400;487;500;600;700" rel="stylesheet" />
      <script src="https://cdnjs.cloudflare.com/ajax/libs/microsoft-signalr/3.1.7/signalr.js"></script>
    </Helmet>
  );
}