
import React, { Component, useEffect, useLayoutEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import Select from 'react-select';
import { FetchHandler } from '../Handlers/FetchHandler';
import { MessageBoxes } from '../Handlers/MessageBoxes';
import { useUsers } from './useUsers';

export type FeedbackStateType = "none" | "inprogress" | "processed" | "hide";

export type FeedbackType = {
    id: string,
    label: string,
    message: string,
    response: string,
    name: string,
    partition: string,
    screenname: string,
    sectionname: string,
    senderuserid: string,
    plantid: string,
    state: FeedbackStateType,
    sys_datecreated_utc_epoch: number
}

export const useFeedback = () => {


    const users = useUsers();
    const dispatch = useDispatch();
    const [selected, setSelected] = useState<string>(null);
    const [selectedState, setSelectedState] = useState<FeedbackStateType>("none");

    const states: FeedbackStateType[] = ["none", "inprogress", "processed", "hide"];
    const options = states.map(k => { return { value: k, label: k } });

    const [items, setItems] = useState<FeedbackType[]>([])


    const onChangeStateInSelected = (item: FeedbackType) => {
        console.log("item: ", item);
        // state
        const backup = [...items];
        let n = [...items];
        const idx = n.findIndex(obj => obj.id == item.id);
        n[idx] = item;
        setItems(n);

        FetchHandler.postJson("api/system/feedback/" + item.id + "/setstate", { state: item.state }).then(success => {
            if (!success) {
                setItems(backup);
                MessageBoxes.warning("Operation failed!")
            } else {
                MessageBoxes.info("status uppdaterades")
            }
        });

    }

    const onDeleteClicked = async (id: string) => {
        // state
        const backup = [...items];
        let n = [...items];
        const idx = n.findIndex(obj => obj?.id == id);
        if (n[idx]) {
            delete n[idx];
            setItems(n);
        }

        const success = await FetchHandler.postJson("api/system/feedback/" + id + "/delete", { action: "delete" });
        if (!success) {
            setItems(backup);
            MessageBoxes.warning("Operation failed!")
        } else {
            MessageBoxes.info("Meddelandet raderades")
        }
    }


    useEffect(() => {
        console.log("selectedState : ", selectedState)
        load(selectedState);
    }, [selectedState])



    const load = async (state?: string) => {
        setSelected(null);
        const f: FeedbackType[] = await FetchHandler.getJson("/api/system/feedback/" + state);
        const u: string[] = Array.from(new Set(f.map(obj => obj?.senderuserid).filter(m => !!m)));
        setItems(f);
        users.ensureUserInfo(u);
    }


    // useLayoutEffect(() => {
    //     load();
    // }, []);

    return {
        reLoad: load,
        onChangeStateInSelected: onChangeStateInSelected,
        selectedState: selectedState, setSelectedState: setSelectedState,
        selected: selected, setSelected: setSelected,
        stateOptions: options,
        items: items,
        onDeleteClicked: onDeleteClicked,

        sendResponse: async (item: FeedbackType, message: string) => {

            // state
            const backup = [...items];
            let n = [...items];
            const idx = n.findIndex(obj => obj.id == item.id);
            n[idx] = { ...item, state: "processed", response: message };
            setItems(n);

            const url = "/api/system/feedback/" + item.id + "/sendresponse";
            const postObj = {
                ...item,
                response: message,
            };
            const success = await FetchHandler.postJson(url, postObj);

            if (!success) {
                setItems(backup);
                MessageBoxes.warning("Operation failed!")
            } else {
                MessageBoxes.info("status uppdaterades")
            }
            return success;
        }


    }
}