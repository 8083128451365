import { useEffect, useState } from 'react';
import axios from 'axios';
import { RootStateOrAny, useSelector } from 'react-redux';


export const useFetch = (url: string) => {
    const [data, setData] = useState(null);
    const [loading, setLoading] = useState(null);
    const [error, setError] = useState(null);
    const [duration, setDuration] = useState(null);

    useEffect(() => {
        const startTime = new Date();
        setLoading('loading...')
        setData(null);
        setError(null);
        const source = axios.CancelToken.source();
        axios.get(url, { cancelToken: source.token })
            .then(res => {
                setLoading(false);
                setData(res.data);
                const endTime = new Date();
                const timeDiff = endTime.getTime() - startTime.getTime();
                setDuration(timeDiff);
            })
            .catch(err => {
                setLoading(false)
                setError('An error occurred. Awkward..')
            })
        return () => {
            source.cancel();
        }
    }, [url]);

    return { data, loading, error, duration }

}

export type UserRolesType = "ADMINISTRATOR" |
    "SYSADMIN" |
    "PLANTDATAADMIN" |
    "PARTNER" |
    "MODERATOR" |
    "SUPPORT" |
    "TESTER" |
    "DEVELOPER" |
    "CONTENTADMINISTRATOR" |
    "BLOGAUTHOR" |
    "ADMANAGER";


export const useUserRoles = () => {
    const roles = useSelector((state: RootStateOrAny) => state.app.roles);
    return {
        roles: roles,
        hasRole: (role: UserRolesType) => roles.indexOf(role) >= 0,
        hasAnyOfRoles: (roles: UserRolesType[]) => {
            return roles
                .filter(role => roles.indexOf(role) >= 0)
                .length > 0;
        },
    };
}
