import { useSelector, useDispatch } from 'react-redux';
import { FetchHandler } from '../../Handlers/FetchHandler';
import { UsersInfoType } from '../../rdx/Redux.DataReducerType';
import { RdxStore } from '../../rdx/ReduxTypes';
import { CHANGE_TOOLS_POSTS_STORE } from '../../rdx/toolsreducer';
import { ForumPostType } from '../Forum/Forum';

export const usePostStore = () => {
    const postStore = useSelector((state: RdxStore) => state.tools.postStore);
    const dispatch = useDispatch();

    const ensurePosts = async (ids: string[]) => {
        let change = { ...postStore };

        const d: ForumPostType[] = await FetchHandler.postJson("/api/post/get", { ids: ids });
        const uids = Array.from(new Set(d.map(m => m?.userid)))
        const users: UsersInfoType[] = await FetchHandler.postJson("/api/user/info", { ids: uids });

        d.forEach(e => {
            change[e?.id] = {
                ...e, alias: users.find(m => m?.userId === e?.userid)?.name
            };
        });
        dispatch({ type: CHANGE_TOOLS_POSTS_STORE, payload: change });
    }


    return {
        ensurePosts: ensurePosts,
        db: postStore
    }
}