import React, { useEffect, useRef, useState } from 'react';
import Select from 'react-select';
import { useSelector } from 'react-redux';
import { Dialog } from '../Shared/Dialog';
import { GardenActivityType, PlantLinksTypes, useGardenActivities, zonePeriodType } from './useGardenActivities';
import { RdxStore } from '../../rdx/ReduxTypes';
import { Data } from '../../Handlers/Data';
import { usePlantTypes, useSnippetHelper } from '../../Handlers/Hooks';
import { SelectOptionStringType } from '../UI/UITYpes';
import { NotVisibleIcon, VisibleIcon } from '../Shared/Icons';
import { PlantFieldType } from '../../rdx/Redux.DataReducerType';
import { FormatterEditor } from './FormatterEditor';
import { AdminIcons } from '../Shared/AdminIcon';
import { ScreenHeader } from '../UI/Elements';
import { HasAnyRole } from '../UI/RoleElements';
import { MessageBoxes } from '../../Handlers/MessageBoxes';

export const GardenActivities = () => {

    const handler = useGardenActivities(true);
    let renderItem = null;


    return (
        <div className='gardenactivities'>
            <ScreenHeader text="Aktiviteter" icon={AdminIcons.calendarCheck} />

            <p>
                Antal: {handler.data?.length}
            </p>
            <div>
                <button className='btn' onClick={() => { handler.addClicked() }} >Lägg till</button>
            </div>
            <hr />
            <table className='tbl'>
                <thead>
                    {/* <tr>
                        <th>Namn</th>
                        <th>Beskrivning</th>
                        <th>Typ</th>
                        <th>Info</th>
                    </tr> */}
                </thead>
                <tbody>
                    {handler.data && handler.data.map(item => {
                        const itemPeriod = handler.getFirstPeriodFromActivity(item);
                        let firstMonthRow = false;
                        if (itemPeriod !== renderItem) {
                            renderItem = itemPeriod;
                            firstMonthRow = true;
                        }

                        return (
                            <ActivityRow
                                key={item.id}
                                item={item}
                                open={item?.id === handler.selectedActivityId}
                                onRowClicked={() => { handler.onRowClicked(item?.id) }}
                                deleteClicked={() => { handler.onRowDeleteClicked(item?.id) }}
                                onSave={e => {
                                    handler.updateActivity(e)
                                }}
                                firstMonthRow={firstMonthRow}
                            />
                        )
                    })}
                </tbody>

            </table>

            {handler.addDialogVisible && (
                <Dialog
                    title="Lägg till aktivitet"
                    onOk={handler.addOnOk}
                    onCancel={handler.addOnCancel}
                    okDisabled={!handler.addIsValid()}
                    disabled={handler.addDialogDisabled}
                    width="800px"
                    height='600px'
                // width="70%" height="700px" top="1vh"
                >
                    <div className="form-container" style={{ height: "300px" }}>
                        <div className="form-item w50">
                            <label>Namn</label>
                            <input type="text" value={handler.addModel?.name} onChange={e => handler.setAddModel({ name: e.target.value })} />
                        </div>

                        <div className="form-item w50">
                            <label>Typ</label>
                            <Select
                                defaultValue={{ value: "-", label: "Inget filter" }}
                                options={handler.scheduleTypes}
                                onChange={(e) => { handler.setAddModel({ scheduleType: e?.value }) }}
                            />

                        </div>

                        <div className="form-item w100">
                            <label>Beskrivning</label>
                            <textarea className="w100" style={{ height: "300px" }} value={handler.addModel?.description} onChange={e => handler.setAddModel({ description: e.target.value })} />
                        </div>

                    </div>

                </Dialog>

            )}

        </div>
    )

}


const ActivityRow = ({ item, open, onRowClicked, deleteClicked, onSave, firstMonthRow }: { item: GardenActivityType, open: boolean, onRowClicked: any, deleteClicked: any, onSave(e: GardenActivityType): void, firstMonthRow: boolean }) => {

    const plantfields: PlantFieldType[] = (useSelector((store: RdxStore) => store.data.plantfields) as PlantFieldType[])?.filter(obj => obj?.type === 4 || obj?.type === 5);

    const [changed, setChanged] = useState(false);
    const [editModel, setEditModel] = useState<GardenActivityType>({ ...item });
    const [deletedPressed, setDeletedPressed] = useState<boolean>(false);
    const snippetOptions = useSnippetHelper().array?.map(s => { return { value: s?.id, label: s?.name } });
    const plantTypeOptions = usePlantTypes()?.map(obj => { return { value: obj.id, label: obj.name } });

    const gardenActivities = useGardenActivities(false);
    const scheduleTypes = gardenActivities.scheduleTypes;
    const plantLinkOptions = gardenActivities.plantLinkOptions;

    const updateModel = (changes: GardenActivityType) => {

        const model = { ...editModel, ...changes };
        setEditModel(model);
        setChanged(true);
        console.log(model);
    }

    useEffect(() => {
        setEditModel({ ...item });
        setChanged(false);
    }, [item]);

    const singleperiodOptions = [{ value: "-", label: "Ingen vald" }].concat(Data.timePeriods.map(obj => { return { value: obj.key, label: obj.name } }));


    const selectInputRefPlantType = useRef();

    const periods = Array.from(new Set(item?.zonePeriods?.map(p => Data.timePeriods.find(obj => obj.key === p?.period)?.name)))?.join(',');

    const numColumns = 7;

    let fieldValueString;
    if (item?.scheduleType === "zone") {
        const field = plantfields.find(m => m.id === item?.zoneFieldOptions?.fieldId);
        if (field) {
            fieldValueString = field?.name + " = ";
            fieldValueString += field.selectOptions.find(m => m.key === item?.zoneFieldOptions.value)?.name;
        }
    }

    const rowBackgroundColor = editModel?.cardColor ?? "#fff";

    return (
        <>
            {firstMonthRow && (
                <tr>
                    <td colSpan={numColumns} className="month-header">
                        {Data.timePeriods.find(obj => obj.key === gardenActivities.getFirstPeriodFromActivity(item))?.name}
                    </td>
                </tr>
            )}
            <tr onClick={onRowClicked} className="tbl-row" style={{ backgroundColor: rowBackgroundColor }}>
                <td>{item.name}</td>
                <td>{(item.scheduleType === "zone" && item.isSingleActivity === false) ? "Per växt" : "Samlingsaktivitet"}</td>
                <td>
                    {item.publicActivity && (<VisibleIcon />)}
                    {!item.publicActivity && (<NotVisibleIcon />)}
                </td>
                <td>{periods?.length > 0 ? periods : Data.timePeriods.find(obj => obj.key === item.singleperiodSelection)?.name}</td>
                <td>{scheduleTypes.find(obj => obj.value === item.scheduleType)?.label}</td>
                <td>
                    {item?.scheduleType === "singleperiodwithsnippet" && snippetOptions.find(m => m.value === item?.snippetId)?.label}
                    {item?.scheduleType === "singleperiodwithplanttype" && plantTypeOptions.find(m => m.value === item?.plantTypeId)?.label}
                    {item?.scheduleType === "zone" && fieldValueString}
                </td>
                <td className='td-limit'>{item.description}</td>
            </tr>
            {open === true && (
                <>
                    <tr>
                        <td colSpan={numColumns}>
                            <div className='row-container'>
                                {/* Type */}
                                <div className='detail-pane'>
                                    <div >
                                        <div className='filterbox'>
                                            <label>Typ</label>
                                            <Select
                                                value={scheduleTypes.find(obj => obj.value === editModel.scheduleType)}
                                                options={scheduleTypes}
                                                onChange={(e) => { updateModel({ scheduleType: e.value }) }}
                                            />
                                        </div>
                                        {/* Public */}
                                        <div className='filterbox'>
                                            <label>Public</label>
                                            <div>
                                                <input type="checkbox" checked={editModel?.publicActivity} onChange={(e) => { updateModel({ publicActivity: e.target.checked }) }} />
                                            </div>
                                        </div>
                                        <div className='filterbox'>
                                            <label>Länk till växt</label>
                                            <Select
                                                value={plantLinkOptions.find(obj => obj.value === editModel.plantLink)}
                                                options={plantLinkOptions}
                                                onChange={e => { updateModel({ plantLink: (e.value as PlantLinksTypes) }) }}
                                            />
                                        </div>
                                        <div className='filterbox'>
                                            <label>Samlingsaktivitet</label>
                                            <div>
                                                <input type="checkbox" checked={editModel?.isSingleActivity} onChange={(e) => { updateModel({ isSingleActivity: e.target.checked }) }} />
                                            </div>
                                        </div>
                                        {/* <div className='filterbox'>
                                            <label>Kortfärg</label>
                                            <div>
                                                <ActivityColorSelector value={editModel?.cardColor} onChange={(e) => { updateModel({ cardColor: e.value }) }} />
                                            </div>
                                        </div> */}
                                        <HasAnyRole roles={["DEVELOPER"]}>
                                            <div className='filterbox' onClick={() => {
                                                navigator.clipboard.writeText(editModel?.id);
                                                MessageBoxes.info("ID kopierad: " + editModel?.id)
                                            }}>
                                                {editModel?.id}
                                            </div>
                                        </HasAnyRole>
                                    </div>
                                </div>

                                {/* Basic data */}
                                <div className='detail-pane'>
                                    <div>
                                        <label>Namn</label>
                                        <div className='name'>
                                            <input className="w100" type="text" value={editModel?.name} onChange={e => updateModel({ name: e.target.value })} />
                                        </div>
                                    </div>

                                    {editModel.scheduleType === "zone" && (
                                        <div>
                                            <FormatterEditor
                                                value={editModel?.nameFormatter}
                                                onChange={value => updateModel({ nameFormatter: value })}
                                            />
                                        </div>
                                    )}

                                    <div>
                                        <label>Beskrivning</label>
                                        <div className='description'>
                                            <textarea className="w100" value={editModel?.description} onChange={e => updateModel({ description: e.target.value })} />
                                        </div>
                                        <div className='code-font'>
                                            AktivitetsId: {editModel?.id}
                                        </div>
                                    </div>
                                </div>

                                {/* Settings */}
                                <div className='detail-pane'>
                                    <div className='twocolumns'>

                                        {/* singleperiod - select period */}
                                        {(editModel.scheduleType === "singleperiod" || editModel.scheduleType === "singleperiodwithsnippet" || editModel.scheduleType === "singleperiodwithplanttype") && (
                                            <div className='filterbox'>
                                                <label>Välj period</label>
                                                <Select
                                                    value={singleperiodOptions.find(obj => obj.value === editModel.singleperiodSelection)}
                                                    options={singleperiodOptions}
                                                    onChange={(e: SelectOptionStringType) => { updateModel({ singleperiodSelection: e.value }) }}
                                                />
                                            </div>
                                        )}

                                        {/* Snippet */}
                                        {(editModel.scheduleType === "singleperiodwithsnippet") && (
                                            <div className='filterbox'>
                                                <label>Välj snippet</label>
                                                <Select
                                                    value={snippetOptions.find(obj => obj.value === editModel.snippetId)}
                                                    options={snippetOptions}
                                                    onChange={(e: SelectOptionStringType) => { updateModel({ snippetId: e.value }) }}
                                                />
                                            </div>
                                        )}

                                        {/* plantType */}
                                        {(editModel.scheduleType === "singleperiodwithplanttype") && (
                                            <div className='filterbox'>
                                                <label>Välj Växttyp</label>
                                                <Select
                                                    value={plantTypeOptions.find(obj => obj.value === editModel.plantTypeId)}
                                                    options={plantTypeOptions}
                                                    onChange={(e: SelectOptionStringType) => { updateModel({ plantTypeId: e.value }) }}
                                                />
                                            </div>
                                        )}

                                        {(editModel.scheduleType === "zone") && (
                                            <div>
                                                <FieldValueSelector
                                                    fieldId={editModel?.zoneFieldOptions?.fieldId}
                                                    value={editModel?.zoneFieldOptions?.value}
                                                    onChange={(field, value) => {
                                                        updateModel({
                                                            zoneFieldOptions: { fieldId: field, value: value }
                                                        })
                                                    }}
                                                />
                                                <div className='filterbox'>
                                                    <label>Välj Växttyp</label>
                                                    <ResetButton onClick={() => {
                                                        if ((selectInputRefPlantType as any)?.current?.select) {
                                                            (selectInputRefPlantType as any).current.select.clearValue();
                                                        }
                                                    }} />
                                                    <Select
                                                        ref={selectInputRefPlantType}
                                                        value={plantTypeOptions.find(obj => obj.value === editModel.plantTypeId)}
                                                        options={plantTypeOptions}
                                                        onChange={(e: SelectOptionStringType) => { updateModel({ plantTypeId: e?.value ?? null }) }}
                                                    />
                                                </div>
                                            </div>
                                        )}

                                        {(editModel.scheduleType === "zone") && (
                                            <div className='filterbox'>
                                                <ZoneEditor value={editModel?.zonePeriods} onChange={e => { updateModel({ zonePeriods: e }) }} />
                                            </div>
                                        )}

                                    </div>
                                </div>
                                {/* Right tools                  */}
                                <div className='detail-tools'>
                                    <button className='btn' disabled={!changed} onClick={() => {
                                        onSave(editModel);
                                        setChanged(false);
                                    }}>Spara</button>
                                    {deletedPressed === false && (<button className='btn' onClick={() => { setDeletedPressed(true) }}>Radera</button>)}
                                    {deletedPressed === true && (
                                        <div>
                                            <button className='btn' onClick={deleteClicked}>Radera!</button>
                                            <button className='btn' onClick={() => { setDeletedPressed(false) }}>Ångra</button>
                                        </div>
                                    )}
                                </div>
                            </div>
                        </td>
                    </tr>
                    <tr />
                </>
            )}
        </>
    )
}


const ResetButton = ({ onClick }: { onClick: any }) => {

    return (
        <div className='resetbutton'>
            <button onClick={onClick}>Rensa</button>
        </div>
    )

}
const ZoneEditor = ({ value, onChange }: { value: zonePeriodType[], onChange(e: zonePeriodType[]): void }) => {
    const zones: number[] = [1, 2, 3, 4, 5, 6, 7, 8];
    const [selected, setSelected] = useState<zonePeriodType[]>(value ?? [])
    const singleperiodOptions = [{ value: "-", label: "Ingen vald" }].concat(Data.timePeriods.map(obj => { return { value: obj.key, label: obj.name } }));

    const _onChange = (zone: number, e: SelectOptionStringType) => {
        let n = [...selected];
        let idx = n.findIndex(e => e.zone === zone);
        if (idx < 0) {
            if (e.value?.length > 1) {
                n.push({ zone: zone, period: e.value });
            }
        } else {
            if (e.value?.length > 1) {
                n[idx].period = e.value;
            } else {
                n.splice(idx, 1);
            }
        }
        setSelected(n);
        if (onChange) {
            onChange(n);
        }
    }
    useEffect(() => {
        if (value) { setSelected(value) }
    }, []);


    return (
        <div>
            <label>Välj visningsperiod</label>
            <div className='zone-editor'>
                {zones.map(z => {
                    const setting = selected?.find(obj => obj.zone === z);
                    return (

                        <div key={z} className='zone-editor-container'>
                            <div className='zone-editor-header'>
                                ZON: {z}
                            </div>
                            <div className='zone-editor-input'>
                                <Select
                                    value={singleperiodOptions.find(obj => obj.value === setting?.period)}
                                    options={singleperiodOptions}
                                    onChange={(e: SelectOptionStringType) => { _onChange(z, e); }}
                                />
                            </div>
                        </div>

                    )
                })}
            </div>
        </div>

    )
}


const FieldValueSelector = ({ fieldId, value, onChange }: { fieldId: string, value: string, onChange(fieldId: string, value: string): void }) => {
    const plantfields: PlantFieldType[] = (useSelector((store: RdxStore) => store.data.plantfields) as PlantFieldType[])?.filter(obj => obj?.type === 4 || obj?.type === 5);
    const fieldOptions = plantfields.map(obj => { return { value: obj.id, label: obj.name } });
    const [selectedField, setSelectedField] = useState<PlantFieldType>(null);

    const [valueOptions, setValueOptions] = useState<SelectOptionStringType[]>([]);
    const [selectedValue, setSelectedValue] = useState<string>(null);

    const _onChange = (field: string, value: string) => {
        if (onChange) {
            onChange(field, value);
        }
    }

    useEffect(() => {
        if (value) {
            setSelectedValue(value)
        }
        if (fieldId) {
            setSelectedField(plantfields.find(obj => obj.id === fieldId))
        }
    }, [, fieldId, value])


    useEffect(() => {
        if (selectedField?.selectOptions) {
            setValueOptions(
                selectedField?.selectOptions.map(obj => { return { value: obj.key, label: obj.name } })
            );
        } else {
            setValueOptions([]);
        }
        _onChange(selectedField?.id, selectedValue)
    }, [selectedField])

    useEffect(() => {
        _onChange(selectedField?.id, selectedValue)
    }, [selectedValue])

    const selectInputRefField = useRef();
    const selectInputRefFieldValue = useRef();

    return (
        <div className='filterbox'>
            <label>Fält</label>
            <ResetButton onClick={() => {
                if ((selectInputRefField as any)?.current?.select) {
                    (selectInputRefField as any).current.select.clearValue();
                }
                if ((selectInputRefFieldValue as any)?.current?.select) {
                    (selectInputRefFieldValue as any).current.select.clearValue();
                }
            }} />
            <Select
                ref={selectInputRefField}
                value={fieldOptions.find(obj => obj.value === selectedField?.id)}
                options={fieldOptions}
                onChange={(e: SelectOptionStringType) => {
                    setSelectedField(plantfields.find(obj => obj.id === e?.value) ?? null)
                }}
            />
            <label>Värde</label>
            <Select
                ref={selectInputRefFieldValue}
                value={valueOptions.find(obj => obj.value === selectedValue)}
                options={valueOptions}
                onChange={(e: SelectOptionStringType) => {
                    setSelectedValue(e?.value ?? null);
                }}
                isMulti={false}
            />
            {/* selectedField: {JSON.stringify(selectedField?.id)} <br />
            selectedValue: {JSON.stringify(selectedValue)} */}
        </div>
    )
}

