import React, { Component, useState } from 'react';
import Select from 'react-select';
import { useSelector } from 'react-redux';

import store from "../../rdx/store";
import { Validator } from "../../Handlers/Validator";
import { FetchHandler } from "../../Handlers/FetchHandler";

import { CheckboxCheckedIcon, CheckboxUnCheckedIcon, FieldIcon } from '../Shared/Icons';
import { ThreeColumnContainer, FourColumnContainer } from '../Shared/Formelements';
import { FieldInput } from "./FieldInputs/FieldInputs";
import { MessageBoxes } from '../../Handlers/MessageBoxes';
import { delayKeyUp } from '../../Handlers/Helpers';
import { Operations } from '../../rdx/Operations';
import { isPropertySignature } from 'typescript';

interface IProps { }

interface IState {
    newmodel?: any,

    planttypesoptions?: any,
    plantfieldsobject?: any,
    plantypeid?: string, name?: string, namelatin?: string, specifictype?: string, description?: string, alternativenames?: string, // basefields
    editfields?: any[],

    isvalid?: boolean,
    isCheckingSpecificType?: boolean,
    specificTypeExists?: boolean

}

export class AddPlant extends Component<IProps, IState>  {
    static displayName = AddPlant.name;

    constructor(props) {
        super(props);


        this.selectTypeChanged = this.selectTypeChanged.bind(this);
        this.modelChanged = this.modelChanged.bind(this);
        this.resetForm = this.resetForm.bind(this);


        this.saveClicked = this.saveClicked.bind(this);
        this.specifictypeChanged = this.specifictypeChanged.bind(this);


        const planttypes = store.getState().data.planttypes;
        const plantfieldsobject = store.getState().data.plantfieldsobject;
        let _state = {
            planttypesoptions: [{ value: "", label: "-" }].concat(planttypes.map(t => { return { value: t.id, label: t.name, tag: t } })),
            plantfieldsobject: plantfieldsobject,
            plantypeid: null, name: "", namelatin: "", specifictype: "", description: "", alternativenames: "", // basefields
            editfields: [],

            isvalid: false,
            isCheckingSpecificType: false,
            specificTypeExists: false
        };
        this.state = _state;
    }

    selectTypeChanged(type, tag) {
        let newmodel = {};
        if (tag && tag.fields) {
            tag.fields.map(f => f.toLowerCase()).forEach(f => { newmodel[f] = null; });
            this.setState({ plantypeid: type, editfields: tag.fields, newmodel: newmodel });
        } else {
            this.resetForm();
        }
    }

    modelChanged(field, value) {
        let m = this.state.newmodel;
        m[field.toLowerCase()] = value;

        const model_isvalid = this.state.plantfieldsobject !== null ?
            Validator.newPlantModelIsValid(this.state.plantfieldsobject, m) :
            false;

        const fields_isvalid = !(
            Validator.stringIsNullOrEmpty(this.state.plantypeid) ||
            Validator.stringIsNullOrEmpty(this.state.name) ||
            Validator.stringIsNullOrEmpty(this.state.namelatin) ||
            Validator.stringIsNullOrEmpty(this.state.specifictype) ||
            Validator.stringIsNullOrEmpty(this.state.description));

        this.setState({ newmodel: m, isvalid: model_isvalid && fields_isvalid });
    }
    resetForm() {
        this.setState({
            newmodel: {},
            plantypeid: "",
            name: "",
            namelatin: "",
            specifictype: "",
            description: ""
        });
    }

    saveClicked() {
        // Collect data
        let postObj = this.state.newmodel;
        postObj.plantypeid = this.state.plantypeid;
        postObj.name = this.state.name;
        postObj.namelatin = this.state.namelatin;
        postObj.specifictype = this.state.specifictype;
        postObj.description = this.state.description;
        postObj.alternativenames = this.state.alternativenames;

        FetchHandler.postJson("/api/plants/add", postObj).then(response => {
            if (response.success === true) {
                this.resetForm();

                MessageBoxes.info("Saved!")
            } else {
                MessageBoxes.warning("Fel!")
            }
        });
    }

    componentDidMount() {
        // this.unsubscribe = store.subscribe(() => {
        //     this.setState(_state);
        // });
    }

    componentWillUnmount() {
        // this.unsubscribe();
    }

    specifictypeChanged(e) {

        const val = e.target.value;
        this.setState({ specifictype: val });
        if (val && val.length > 5) {
            delayKeyUp(() => {
                this.setState({ isCheckingSpecificType: true })

                Operations.checkIfSpecificTypeExists(val).then(exists => {
                    this.setState({
                        isCheckingSpecificType: false,
                        specificTypeExists: exists
                    });
                })

                console.log(val);
                this.setState({ isCheckingSpecificType: false })
            });
        }
    }


    render() {
        return (
            <div className="form-large">
                <h2>Add</h2>
                <div>
                    <ThreeColumnContainer>
                        <div>
                            <label>Växttyp</label>
                            <Select
                                options={this.state.planttypesoptions}
                                value={this.state.planttypesoptions.filter(o => o.value == this.state.plantypeid)[0]}
                                onChange={e => { this.selectTypeChanged(e.value, e.tag) }}
                            />
                        </div>
                        <div></div>
                        <div>
                            <label>&nbsp;</label>
                            <button className="btn float-right" disabled={!(this.state.isvalid === true)} onClick={this.saveClicked}>Spara</button>
                        </div>
                    </ThreeColumnContainer>
                    <hr />

                    <ThreeColumnContainer>
                        <div>
                            <label>
                                Namn
                                {Validator.stringIsNullOrEmpty(this.state.name) && (<span className="invalid">*</span>)}
                            </label>
                            <input name="name" type="text" className="w100" autoComplete="off"
                                value={this.state.name} onChange={(e) => { this.setState({ name: e.target.value }) }}
                            />
                        </div>
                        <div>
                            <label>
                                Latinskt namn
                                {Validator.stringIsNullOrEmpty(this.state.namelatin) && (<span className="invalid">*</span>)}
                            </label>
                            <input name="namelatin" type="text" className="w100" autoComplete="off"
                                value={this.state.namelatin}
                                onChange={(e) => { this.setState({ namelatin: e.target.value }) }}
                            />
                        </div>
                        <div>
                            <label>
                                Sort
                                {Validator.stringIsNullOrEmpty(this.state.specifictype) && (<span className="invalid">*</span>)}
                            </label>
                            <input name="specifictype" type="text" className={
                                "w100"
                                + (this.state.specificTypeExists ? " input-invalid" : " input-ok")
                                + (this.state.isCheckingSpecificType ? " input-working" : "")
                            } autoComplete="off"
                                value={this.state.specifictype}
                                onChange={this.specifictypeChanged}
                            />
                        </div>
                        <div>
                            <label>
                                Alternativa namn
                            </label>
                            <input name="alternativenames" type="text" className="w100" autoComplete="off"
                                value={this.state.alternativenames}
                                onChange={(e) => { this.setState({ alternativenames: e.target.value }) }}
                            />
                        </div>


                    </ThreeColumnContainer>

                    <div>
                        <label>
                            Beskrivning
                            {Validator.stringIsNullOrEmpty(this.state.description) && (<span className="invalid">*</span>)}
                        </label>
                        <textarea name="description" className="w100" autoComplete="off"
                            value={this.state.description}
                            onChange={(e) => { this.setState({ description: e.target.value }) }}
                        ></textarea>
                    </div>
                    <hr />
                </div>
                {this.state.plantypeid && (
                    <FourColumnContainer>
                        {this.state.editfields.map((f: string) => (
                            <PlantField
                                key={f}
                                fieldKey={f}
                                onChange={(field, value) => { this.modelChanged(field, value) }}
                                value={this.state.newmodel[f.toLowerCase()]}
                            />
                        ))}
                    </FourColumnContainer>
                )}
                <hr />
                <div>
                    {JSON.stringify(this.state.newmodel)}
                </div>
            </div>
        )
    }
}

const PlantField = (props) => {
    const field_key = props.fieldKey;
    const field = useSelector(state => state.data.plantfieldsobject[field_key.toLowerCase()]);

    const [hasValue, setHasValue] = useState(false);

    const onChange = (e) => {
        setHasValue(true);
        props.onChange(field_key, e);
    };


    if (field_key) {
        let control = (null);

        switch (field.type) {
            case 1: { // Text
                control = (<FieldInput.Text value={props.value} onChange={onChange} multiLine={null} />);
                break;
            }
            case 2: { // Integer
                control = (<FieldInput.Integer value={props.value} onChange={onChange} />);
                break;
            }
            case 3: { // Decimal
                control = (<FieldInput.Decimal value={props.value} onChange={onChange} />);
                break;
            }
            case 4: { // SingleSelect
                control = (<FieldInput.SingleSelect fieldType={field_key} value={props.value} onChange={onChange} />);
                break;
            }
            case 5: { // MultiSelect
                control = (<FieldInput.MultiSelect fieldType={field_key} value={props.value} onChange={onChange} />);
                break;
            }
            case 6: { // Color
                control = (<FieldInput.Color value={props.value} onChange={onChange} />);
                break;
            }
            case 7: { // TrueFalse
                control = (<FieldInput.TrueFalse value={props.value} onChange={onChange} />);
                break;
            }
            case 8: { // Tags
                control = (<FieldInput.Tag value={props.value} onChange={onChange} />);
                break;
            }
        }

        return (
            <div>
                <label>
                    {field.name}
                    {!hasValue && (<span className="invalid">*</span>)}
                </label>
                {control}
            </div>
        );

    }
    return (<div>N/A</div>);
}
