import React from "react"
import { Label } from "./Primitives"

export const EditStringInput = ({ title, defaultValue, onChange, placeholder }: { title: string, defaultValue: string, onChange(e: string): void, placeholder?: string }) => {
    return (
        <div className="form-item w100">
            <Label text={title} />
            <input className="frm" type="text" defaultValue={defaultValue ?? ""} onChange={e => onChange(e.target.value)} placeholder={placeholder} />
        </div>
    )
}

export const EditTextArea = ({ title, defaultValue, onChange }: { title: string, defaultValue: string, onChange(e: string): void }) => {
    return (
        <div className="form-item w100">
            <Label text={title} />
            <textarea className="frm" defaultValue={defaultValue ?? ""} onChange={e => onChange(e.target.value)} />
        </div>
    )
}

export const EditCheckbox = ({ title, defaultValue, onChange, trueText = "Ja", falseText = "Nej" }: { title: string, defaultValue: boolean, onChange(e: boolean): void, trueText?: string, falseText?: string }) => {
    return (
        <EditSelect
            title={title}
            defaultValue={defaultValue}
            onChange={e => onChange(e === "true")}
            options={[
                { value: "true", label: trueText },
                { value: "false", label: falseText },
            ]} />
    )
}

export type EditSelectOptionType = { label: string, value: string }
export const EditSelect = ({ title, defaultValue, onChange, options }: { title: string, defaultValue: boolean, onChange(e: string): void, options: EditSelectOptionType[] }) => {
    return (
        <div className="form-item w100">
            <Label text={title} htmlFor={title} />
            <select className="frm" defaultValue={defaultValue ? "true" : "false"} onChange={e => onChange(e.target.value)}>
                {options?.map(op => (
                    <option value={op?.value}>{op.label}</option>
                ))}
            </select>
        </div>
    )
}
