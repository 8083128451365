import { DateTime } from 'luxon';
import React, { Component, useEffect, useLayoutEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import { useSelector, useDispatch } from 'react-redux';
import { FetchHandler } from '../../Handlers/FetchHandler';
import { StringHelper } from '../../Handlers/Helpers';
import { useDateHelper } from '../../hooks/useHelper';
import { AdminIcons } from '../Shared/AdminIcon';
import { ScreenHeader } from '../UI/Elements';
import { RdxStore, reportSettingsType } from '../../rdx/ReduxTypes';
import { CHANGE_REPORT_SETTINGS } from '../../rdx/dataReducer';

export const UserStatus = () => {
    const h = useDateHelper();

    const ui = useUserStatus();

    return (
        <div className='report statusreport'>
            <ScreenHeader text="Status på användarkonton" icon={AdminIcons.users} />
            <p>
                Kriteriet för att hamna på den här listan är att man åtminstone efterfråga en loginlänk, <br />
                men att inget alias angivits på kontot, vilket krävs för att onboardingen ska avslutas.
            </p>
            <button className='btn' onClick={() => { ui.setOnlyShowStartedOnboardings(!ui.onlyShowStartedOnboardings) }} >
                {!ui.onlyShowStartedOnboardings ? "Visa endast påbörjade" : "Visa alla"}
            </button>
            <hr />
            <div>
                <table>
                    <thead>
                        <tr>
                            <th>Testkonto</th>
                            <th>Email</th>
                            <th>Alias</th>
                            <th>OAuth</th>
                            <th>Bio</th>
                            <th>Skapat</th>
                            <th>Kommun</th>
                            <th>Onboarding avslutad</th>
                            <th>Paketversion</th>
                            <th>Telefon</th>
                        </tr>
                    </thead>
                    <tbody>
                        {ui.userData?.userAccounts?.filter(m => !m?.oAuthAccount)?.map(m => {
                            const acc = m;
                            const accInfo = ui.userData.accountInfo.find(n => n.id == m.id);

                            if (ui.onlyShowStartedOnboardings) {
                                if (!accInfo) { return (null); }
                            }

                            return (
                                <tr key={acc.id}>
                                    <td>{acc.isTestAccount ? "Testkonto" : ""}</td>
                                    <td>
                                        {!!accInfo && (
                                            <Link to={"/user/" + acc.id} >
                                                {acc.email}
                                            </Link>
                                        )}
                                        {!accInfo && (
                                            <span>
                                                {acc.email}
                                            </span>
                                        )}
                                    </td>
                                    <td>{acc.alias}</td>
                                    <td>{acc.oAuthAccount ? "Ja" : ""}</td>
                                    <td>{acc.biography}</td>
                                    <td>
                                        {DateTime.fromISO(acc.userCreatedUtc).toFormat("yyyy-LL-dd")}
                                        {DateTime.fromISO(acc.userCreatedUtc).toFormat("HH:mm:ss")}
                                    </td>
                                    <td>{acc.city}</td>
                                    <td>{accInfo?.onboardingfinished ? "Avslutad" : "Ej avslutad"}</td>
                                    <td>{accInfo?.packageversion}</td>
                                    <td>{accInfo?.devicebrand}</td>
                                    {/* <td>{JSON.stringify(accInfo)}</td> */}
                                </tr>
                            )
                        })}
                    </tbody>
                </table>
            </div>
        </div>
    )



}




export type AccountInfo = {
    id: string;
    label: string;
    partition: string;
    name: string;
    publicprofile: boolean;
    follows: number;
    followers: number;
    posts: number;
    sys_datecreated_utc_epoch: number;
    onboardingfinished?: boolean;
    onboardingdata: string;
    email: string;
    username: string;
    firstname: string;
    lastname: string;
    frost: string;
    municipality: string;
    zone: string;
    appversion: string;
    devicebrand: string;
    devicemodel: string;
    packageversion: string;
    devicesize: string;
    flag_hide_under_construction?: boolean;
    onboardingstarted?: boolean;
}

export type UserAccount = {
    id: string,
    email: string,
    city?: any,
    alias?: any,
    biography?: any,
    userCreatedUtc?: string,
    isTestAccount?: boolean,
    oAuthAccount?: boolean
}

export type UserDataType = {
    accountInfo: AccountInfo[];
    userAccounts: UserAccount[];
}

const useUserStatus = () => {

    const reportSettings: reportSettingsType = useSelector((state: RdxStore) => state.data.reportSettings);
    const dispatch = useDispatch();

    const [userData, setUserData] = useState<UserDataType>(null);
    const load = async () => {
        var data = await FetchHandler.getJson("/api/userdata/userstatus");
        setUserData(data);
    }

    useEffect(() => {
        load();
    }, [])


    return {
        onlyShowStartedOnboardings: !!reportSettings?.userStatus_onlyShowStartedOnboardings,
        setOnlyShowStartedOnboardings: (val: boolean) => {
            dispatch({ type: CHANGE_REPORT_SETTINGS, payload: { ...reportSettings, userStatus_onlyShowStartedOnboardings: val } })
        },
        userData: userData,
    }
}