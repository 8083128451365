import React, { useEffect, useRef, useState } from "react"
import { Link } from 'react-router-dom';
import { GoogleMap, useJsApiLoader, Marker, Polyline } from '@react-google-maps/api';
import Select from 'react-select'
import { FetchHandler } from "../../Handlers/FetchHandler";
import { MessageBoxes } from "../../Handlers/MessageBoxes";
import { AdminIcon, AdminIcons } from "../Shared/AdminIcon"
import { ThreeColumnContainer } from "../Shared/Formelements";
import { ToolBar } from "../ToolBar/ToolBar";
import { AdminMultiSelect, ScreenHeader } from "../UI/Elements"
import { LatLng, MapBounds } from "./Data/MapTypes";
import { PoiDataItem, useLargeMap } from "./Data/useLargeMap";
import { Config } from "../../Config";
import { MapHelper, mapPins } from "./Data/MapHelper";
import { useDateHelper } from "../../hooks/useHelper";


const containerStyle = { width: '100%', height: 'calc(100vh - 160px)' };
const center = { lat: 59.433636, lng: 18.325516 };

export const LargeMap = () => {

    // ------------------------------------- Init Map -------------------------------------
    const { isLoaded } = useJsApiLoader({ id: 'google-map-script', googleMapsApiKey: Config.googleMapsApiKey })
    const [map, setMap] = React.useState<google.maps.Map>(null);
    const dh = useDateHelper();

    const onLoad = React.useCallback((map: google.maps.Map) => {
        map.panTo({ lat: center?.lat, lng: center?.lng });
        map.setZoom(11);
        setMap(map)
    }, [])
    const onUnmount = React.useCallback((map) => { setMap(null) }, []);
    // ------------------------------------- /Init Map -------------------------------------

    // Hook
    const ui = useLargeMap();
    useEffect(() => {
        Object.keys(mapPins).map(key => mapPins[key]).forEach((picture) => {
            const img = new Image();
            img.src = picture;
        });

        ui.loadPois();
    }, [])

    // Helpers
    const setCenter = (poi: LatLng) => {
        map.panTo({ lat: poi?.latitude, lng: poi?.longitude });
    }

    // Edit
    const [editMarkerPosition, setEditMarkerPosition] = useState<google.maps.LatLng>(undefined);
    useEffect(() => {
        if (ui.isEditing) {
            if (ui.selectedPoi) {
                const r: google.maps.LatLng = new google.maps.LatLng(ui.selectedPoi?.latitude, ui.selectedPoi?.longitude);
                setEditMarkerPosition(r);
            } else {
                const c = map.getCenter();
                setEditMarkerPosition(c);
            }
        } else if (!ui.isEditing && !!ui.selectedPoi?.id) {

            if (MessageBoxes.confirm("Move " + (ui.selectedPoi?.garden?.gardenName ?? ui.selectedPoi?.poi?.title) + "?")) {
                ui.setPoiPosition(ui.selectedPoi?.id, { latitude: editMarkerPosition?.lat(), longitude: editMarkerPosition?.lng() })
            }
        }
    }, [ui.isEditing]);

    return (
        <div>
            <ScreenHeader text="Karta" icon={AdminIcons.globe} />
            <ThreeColumnContainer gridTemplateColumns="600px auto 20px">
                <div>
                    <div>
                        <button className={"btn" + (ui.viewState === "pois" ? " btn-toggle-down" : "")} onClick={() => ui.setViewState(ui.viewState === "pois" ? "none" : "pois")} >POI</button>
                        <button className={"btn" + (ui.viewState === "gardens" ? " btn-toggle-down" : "")} onClick={() => ui.setViewState(ui.viewState === "gardens" ? "none" : "gardens")}>Trädgårdar</button>
                        <button className={"btn"} onClick={() => ui.loadPois()}>
                            <AdminIcon icon={ui.isLoading ? AdminIcons.refreshspin : AdminIcons.refresh} />
                        </button>

                        <button className={"pull-right btn" + (ui.isEditing ? " btn-toggle-down" : "")} onClick={() => ui.setIsEditing(!ui.isEditing)}>Edit</button>
                    </div>
                    <hr />
                    <div id="scrollable-tbl" className="tbl-scrollable">
                        <table className="tbl tbl-small">
                            <thead>
                                <tr>
                                    <th></th>
                                    <th></th>
                                    <th>Namn</th>
                                    <th>Adress</th>
                                </tr>
                            </thead>
                            <tbody>
                                {ui.allPoui?.map((poi, idx) => (
                                    <tr id={poi?.id} key={poi?.id} onClick={() => {
                                        if (ui.selectedPoi?.id == poi?.id) {
                                            ui.selectPoi(poi?.id);
                                        } else {
                                            ui.selectPoi(poi?.id);
                                            setCenter(poi);
                                        }
                                        //map.setZoom(12);
                                    }} className={"hoverable" + (poi?.id == ui.selectedPoi?.id ? " selected" : "")}>
                                        <td>
                                            {idx + 1}
                                        </td>
                                        <td>
                                            <img src={MapHelper.getIcon(poi)} style={{ width: 24, aspectRatio: "1" }} loading="lazy" />
                                        </td>
                                        <td>
                                            {!!poi?.garden?.name && (
                                                <Link to={"/user/" + poi?.garden?.id} title={dh.epochToDateTime(poi?.garden?.locationset_epoch)} >
                                                    {poi?.garden?.name ?? poi?.poi?.title}
                                                </Link>
                                            )}
                                            {!poi?.garden?.name && (
                                                <span>
                                                    {poi?.garden?.name ?? poi?.poi?.title}
                                                </span>
                                            )}
                                        </td>
                                        <td>
                                            {poi?.garden?.municipality ?? poi?.poi?.addressline1}
                                        </td>
                                    </tr>
                                ))}
                            </tbody>
                        </table>
                    </div>
                </div>
                <div>
                    {isLoaded && (

                        <GoogleMap mapContainerStyle={containerStyle}
                            center={center}
                            zoom={20}
                            onLoad={onLoad}
                            onUnmount={onUnmount}
                            options={{
                                streetViewControl: false,
                                rotateControl: false,
                            }}
                            onBoundsChanged={() => {
                                const b = map?.getBounds();
                                // MapHelper.convertBounds(b);
                                // setLastCenter(map.getCenter())
                            }}
                        >


                            {!ui.isEditing && ui.allPoui.map((poi, index) => (
                                <Marker
                                    key={poi?.id}
                                    title={poi?.poi?.title ?? poi?.garden?.name}
                                    position={{ lat: poi?.latitude, lng: poi?.longitude }}
                                    onClick={e => {
                                        const p = ui.allPoui.find(m => m.latitude == e.latLng?.lat() && m.longitude == e.latLng?.lng())
                                        ui.selectPoi(p?.id);
                                        var myElement = document.getElementById(p?.id);
                                        var topPos = myElement.offsetTop;
                                        console.log("topPos: ", topPos)
                                        document.getElementById('scrollable-tbl').scrollTop = topPos;
                                    }}
                                    icon={MapHelper.getIcon(poi)}
                                />
                            ))}

                            {ui.isEditing && (
                                <Marker
                                    position={editMarkerPosition}
                                    title="Edit"
                                    draggable={true}
                                    onDragEnd={e => {
                                        setCenter({ latitude: e?.latLng?.lat(), longitude: e?.latLng?.lng() });
                                        setEditMarkerPosition(e?.latLng)
                                    }}

                                />
                            )}

                            {/* <Polyline 
                                path={[ new google.maps.LatLng(0,0) ]}
                                options={{ strokeColor: "#ffa" }}
                            /> */}



                        </GoogleMap>
                    )}

                </div>
            </ThreeColumnContainer>
        </div>
    )
}
