import { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { Data } from '../../Handlers/Data';
import { RdxStore } from '../../rdx/ReduxTypes';
import { PlantListType } from '../../rdx/Redux.DataReducerType';
import { FetchHandler } from '../../Handlers/FetchHandler';

type selectedOnboardingQuestion = "frienemies" | "colors" | "types" | "style";
type addDropPlantListToAlternativeArgs = {
    question: selectedOnboardingQuestion,
    alternative: string,
    listId: string,
    action: "add" | "drop"
}

export const useOnboardingLists = () => {
    const listsObject = useSelector((state: RdxStore) => state.data.plantlists);

    const questionOptions: { value: selectedOnboardingQuestion, label: string }[] = [
        { value: "colors", label: "Färg" },
        { value: "frienemies", label: "Frienemies" },
        { value: "types", label: "Typ" },
        { value: "style", label: "Stil" },
    ]
    const [selectedQuestion, setSelectedQuestion] = useState<selectedOnboardingQuestion>("colors");
    const [selectedQuestionOptions, setSelectedQuestionOptions] = useState<{ value: string, label: string }[]>([]);

    useEffect(() => {
        if (selectedQuestion == "colors") {
            setSelectedQuestionOptions(Data.onBoardingQuestionsAnswers.colors.map(c => { return { value: c, label: c } }));
        } else if (selectedQuestion == "style") {
            setSelectedQuestionOptions(Data.onBoardingQuestionsAnswers.style.map(c => { return { value: c, label: c } }));
        } else if (selectedQuestion == "types") {
            setSelectedQuestionOptions(Data.onBoardingQuestionsAnswers.types.map(c => { return { value: c, label: c } }));
        } else if (selectedQuestion == "frienemies") {
            setSelectedQuestionOptions(Data.onBoardingQuestionsAnswers.frienemies.map(c => { return { value: c.value, label: c.label } }));
        } else {
            setSelectedQuestionOptions([]);
        }
    }, [selectedQuestion]);


    const [data, setData] = useState<{ [key: string]: PlantListType[] }>({});


    const [droppedListIds, setDroppedListIds] = useState<string[]>([])
    const array: PlantListType[] = Object.keys(listsObject ?? {})
        .filter(key => key != "loaded")
        //.filter(key => droppedListIds.indexOf(key) < 0)
        .map(key => {
            return listsObject?.[key]
        });

    useEffect(() => {
        console.log("data: ", data);
    }, [data])


    useEffect(() => {
        apiOnboardingLists.get().then(d => {
            console.log(d);
            setData(d)
        });
    }, [])





    return {
        questionOptions: questionOptions,
        selectedQuestion: selectedQuestion,
        selectedQuestionOptions: selectedQuestionOptions,
        onSelectedQuestionChange: (value: selectedOnboardingQuestion) => {
            setSelectedQuestion(value);
        },
        alternativesAssociated: (plist: string) => {
            let ret = []
            Object.keys(data).map(key => {
                if (data[key].findIndex(m => m.id == plist) >= 0) {
                    ret.push(key.split(':')[1]);
                }
            });
            return ret;
        },

        store: listsObject,
        array: array,
        listDroppedOnAlternative: (alternative: string, item: PlantListType) => {
            const key = selectedQuestion + ":" + alternative;
            setDroppedListIds([...droppedListIds, item.id]);
            const bak = { ...data };
            let f = { ...data };
            if (f[key]) {
                f[key].push(item)
            } else {
                f[key] = [item];
            }
            setData(f);

            // api
            const args: addDropPlantListToAlternativeArgs = {
                action: "add",
                alternative: alternative,
                listId: item.id,
                question: selectedQuestion
            };

            apiOnboardingLists.addOrRemove(args).then(success => {
                if (!success) {
                    setData(bak)
                }
            });
        },
        listRemovedFromAlternative: (alternative: string, listId: string) => {
            const key = selectedQuestion + ":" + alternative;
            setDroppedListIds([...droppedListIds.filter(obj => obj != listId)]);
            const bak = { ...data };
            let f = { ...data };
            if (f[key]) {
                f[key] = [...f[key].filter(obj => obj.id != listId)];
            }
            setData(f);

            // Api
            const args: addDropPlantListToAlternativeArgs = {
                action: "drop",
                alternative: alternative,
                listId: listId,
                question: selectedQuestion
            };
            apiOnboardingLists.addOrRemove(args).then(success => {
                if (!success) {
                    setData(bak)
                }
            });


        },
        getAlternativeLists: (alternative: string) => {
            const key = selectedQuestion + ":" + alternative;
            let ret = [...(data[key] ?? [])];
            ret.forEach(l => {
                l.numberOfPlants = listsObject?.[l.id]?.numberOfPlants;
            });
            return ret;
        },
    }
}


const apiOnboardingLists = {
    addOrRemove: async (args: addDropPlantListToAlternativeArgs): Promise<boolean> => {
        const url = "/api/onboardinglists/edit";
        return await FetchHandler.postJson(url, args);
    },
    get: async (): Promise<{ [key: string]: PlantListType[] }> => {
        const url = "/api/onboardinglists/get";
        return await FetchHandler.getJson(url);
    }
}