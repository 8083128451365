import store from '../rdx/store';
import { CHANGE_SEARCH_SEARCHING, CHANGE_SEARCH_LIST, CHANGE_SEARCH_VISIBLEFIELDS } from '../rdx/reducers';
import { FetchHandler } from "./FetchHandler";
import { CHANGE_SEARCH_ALLSEARCHHITS } from '../rdx/searchReducer';
import { StringHelper } from './Helpers';

function searchHandler() {
    const self = this;


    self.addToAllSearchHits = (plants) => {
        let allHits = { ...store.getState().search.allSearchHits };
        plants.forEach(plant => { allHits[plant.id] = plant });
        store.dispatch({ type: CHANGE_SEARCH_ALLSEARCHHITS, payload: allHits });
    };
    self.execSearchWithQuery = async (qstring) => {
        var url = "/api/plants?";
        store.dispatch({ type: CHANGE_SEARCH_SEARCHING, payload: true });
        //this.props.history.push({ pathname: Routes.plants, search: qstring});
        const data = await FetchHandler.getJson(url + qstring);
        //if(this.mounted) {
        data.forEach(p => { p.selected = false; });
        //const tags = this.extractTags(data);
        store.dispatch({ type: CHANGE_SEARCH_SEARCHING, payload: false });
        store.dispatch({ type: CHANGE_SEARCH_LIST, payload: data });
        self.addToAllSearchHits(data);
    };
    self.searchActionPlantsWithImagesWithoutPrimary = async () => {
        const qstring = "action=plantswithimageswithoutprimary";
        return await self.execSearchWithQuery(qstring);
    };
    self.search = async (qOptions?: { onlyWithImages: boolean }) => {
        const options = store.getState().search;
        var qstring = "?";
        var hasQuery = false;
        if (StringHelper.isGuid(options.searchText)) { qstring += "&id=" + options.searchText; hasQuery = true; }
        if (options.searchText) { qstring += "&search=" + options.searchText; hasQuery = true; }
        if (options.typeId) { qstring += "&type=" + options.typeId; hasQuery = true; }
        if (options.published === true || options.published === false) { qstring += "&published=" + options.published; hasQuery = true; }
        if (options.tags && options.tags.length > 0) { qstring += "&tags=" + encodeURIComponent(JSON.stringify(options.tags)); hasQuery = true; }
        if (qOptions && qOptions?.onlyWithImages) { qstring += "&onlywithimages=true" }

        if (hasQuery) {
            await self.execSearchWithQuery(qstring);
            return true;
        }
        return false;
    }

    self.SearchPlant = async (id) => {
        var url = "/api/plants?id=" + id;
        const data = await FetchHandler.getJson(url);
        self.addToAllSearchHits(data);
    }

    self.toggleVisibleColumns = (fieldid) => {
        let fields = [...store.getState().search.visibleFields];
        const idx = fields.indexOf(fieldid);
        if (idx < 0) {
            fields.push(fieldid);
            store.dispatch({ type: CHANGE_SEARCH_VISIBLEFIELDS, payload: fields });
        } else {
            fields.splice(idx, 1);
            store.dispatch({ type: CHANGE_SEARCH_VISIBLEFIELDS, payload: fields });
        }
    };
    self.setVisibleColumns = (fieldIds) => {
        store.dispatch({ type: CHANGE_SEARCH_VISIBLEFIELDS, payload: fieldIds });
    }

    self.latestSort = "";
    self.sortDescending = false;
    self.sortOnField = (fieldid) => {
        self.sortDescending = self.latestSort === fieldid && self.sortDescending === false;
        self.latestSort = fieldid;

        let list = [...store.getState().search.searchList];
        const sortField = fieldid.toLowerCase();
        list.sort((a, b) => {
            if (a.fields[sortField] > b.fields[sortField]) { return self.sortDescending ? 1 : -1; }
            else if (b.fields[sortField] > a.fields[sortField]) { return self.sortDescending ? -1 : 1; }
            else { return 0; }
        });
        store.dispatch({ type: CHANGE_SEARCH_LIST, payload: list });
    }

    self.lastidSelected = null;
    self.toggleSelectedItem = (id, shiftPressed, ctrlPressed) => {
        let list = [...store.getState().search.searchList];

        // shiftPressed, ctrlPressed
        // false, false => select single, deselect old
        // false, true => select single, keep old
        // true, false =>
        // true, true =>

        if (!shiftPressed && !ctrlPressed) {
            list.filter(obj => obj.id !== id).forEach(obj => obj.selected = false);
            list.filter(obj => obj.id === id).forEach(obj => obj.selected = !obj.selected);
            self.lastidSelected = id;
        } else if (!shiftPressed && ctrlPressed) {
            list.filter(obj => obj.id === id).forEach(obj => obj.selected = !obj.selected);
            self.lastidSelected = id;
        } else if (shiftPressed && !ctrlPressed && self.lastidSelected) {
            let _state = 0;
            for (var i = 0; i < list.length; i++) {
                if (_state === 0 && (list[i].id === id || list[i].id === self.lastidSelected)) {
                    _state = 1;
                    list[i].selected = true;
                } else if (_state === 1 && (list[i].id !== id && list[i].id !== self.lastidSelected)) {
                    list[i].selected = true;
                } else if (_state === 1 && (list[i].id === id || list[i].id === self.lastidSelected)) {
                    list[i].selected = true;
                    break;
                }
            }
        }
        store.dispatch({ type: CHANGE_SEARCH_LIST, payload: list });
        return;
    }
}


const SearchHandler = new searchHandler();

export { SearchHandler };