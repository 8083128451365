import { useLayoutEffect, useRef, useState } from "react";
import { useSelector } from "react-redux";
import { createNoSubstitutionTemplateLiteral } from "typescript";
import { CHANGE_UI_SETTINGS } from "../rdx/appReducer";
import { PlantFieldType, PlantTypeType, SnippetObjectType } from "../rdx/Redux.DataReducerType";
import { PlantImageType, RdxStore } from "../rdx/ReduxTypes";
import { CHANGE_SEARCH_ALLSEARCHHITS } from "../rdx/searchReducer";
import store from "../rdx/store";

// returns userObject
export const usePlantImages = (id?: string) => {
    const data: { [key: string]: PlantImageType } = useSelector((state: RdxStore) => state.data.plantimages);
    if (id) {
        return data[id];
    } else {
        return data;
    }
}

export const usePlantSearchHit = (id: string) => {
    return useSelector((store: RdxStore) => store.search.allSearchHits[id]);
}

export const useSnippet = (id?: string) => {
    var obj = useSelector((store: RdxStore) => store.data.snippets);
    if (id) {
        return obj?.[id]
    } else {
        return obj;
    }
}
export const useSnippetData = () => {
    var obj = useSelector((store: RdxStore) => store.data.snippets);
    return {
        object: obj,
        getOptionsWithKey: (key: string) => {
            console.log("useSnippetData.getOptionsWithKey(key): ", key);
            return Object.keys(obj)
                .map(k => obj[k])
                .filter(s => s.snippetKey === key);
        }
    }
}

export const usePlantField = (id?: string) => {
    var obj: { [key: string]: PlantFieldType } = useSelector((store: RdxStore) => store.data.plantfieldsobject);
    if (id) {
        return obj?.[id];
    } else {
        return obj;
    }
}
export const usePlantFieldData = () => {
    var item = useSelector((store: RdxStore) => store.data.plantfieldsobject);
    return {
        object: item,
        item: (id: string) => {
            return item?.[id?.toLowerCase()];
        }
    }
}

export const usePlantTypes = (): PlantTypeType[] => {
    return useSelector((store: RdxStore) => store.data.planttypes);
}
export const usePlantTypesData = () => {
    const types_arr = useSelector((store: RdxStore) => store.data.planttypes);
    const types_obj = useSelector((store: RdxStore) => store.data.planttypesobject);
    return {
        item: (id) => {
            return types_obj?.[id];
        }
    }
}
export const useColors = () => {
    let colors = useSelector((store: RdxStore) => store.data.colors).filter(obj => obj.selectable);
    colors.sort((a, b) => {
        if (a.hexNumber < b.hexNumber) { return 1 }
        if (a.hexNumber > b.hexNumber) { return -1 }
        return 0;
    })
    return colors;
}
export const useSnippets = (id?: string) => {
    var obj = useSelector(store => store.data.snippets);
    if (id) {
        return obj[id]
    } else {
        return obj;
    }
}

export const useSnippetHelper = () => {
    var snippets: SnippetObjectType = useSelector((store: RdxStore) => store.data.snippets);
    var types: PlantTypeType[] = useSelector((store: RdxStore) => store.data.planttypes);

    const [tags, setTags] = useState<string[]>([]);

    const handleTags = () => {
        let alltags = types.map(obj => obj.name);
        if (snippets) {
            Object.keys(snippets).forEach(key => {
                if (snippets[key]?.snippetTags) {
                    alltags = alltags.concat(snippets[key]?.snippetTags)
                }
            });
        }
        alltags = Array.from(new Set(alltags));
        setTags(alltags);
    }

    useLayoutEffect(() => {
        if (snippets) {
            handleTags();
        }
    }, [, snippets]);


    return {
        tags: tags,
        array: Object.keys(snippets).map(key => snippets?.[key]),
    }
}

export const useUISettings = () => {
    const uISettings = useSelector((store: RdxStore) => store.app.uisettings);
    return {
        get: (key) => {
            if (key) {
                return uISettings?.[key];
            } else {
                return uISettings;
            }
        },
        set: (keyPair) => {
            let ui = { ...store.getState().app.uisettings, ...keyPair };
            store.dispatch({ type: CHANGE_UI_SETTINGS, payload: ui });
        }
    }
}

export const useQueryParams = (): any => {
    const params = new URLSearchParams(
        window ? window.location.search : {}
    );

    return new Proxy(
        params, {
        get(target, prop: string) { return target.get(prop) }
    });
}


export const useFocus = () => {
    const htmlElRef = useRef(null)
    const setFocus = () => { htmlElRef.current && htmlElRef.current.focus() }
    return [htmlElRef, setFocus]
}