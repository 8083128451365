import { useEffect, useState } from "react";
import { FetchHandler } from "../../Handlers/FetchHandler";
import { MessageBoxes } from "../../Handlers/MessageBoxes";
import { FeaturedPostType } from "../PostsManagement/usePostsManagement";

export const useBlog = () => {

    const api = useBlogApi();
    const [blogPosts, setBblogPosts] = useState<BlogPostType[]>([]);
    const [authors, setAuthors] = useState<BlogPostAuthorType[]>([]);
    const [selectedId, setSelectedId] = useState<string>(null);
    const [isWaiting, setIsWaiting] = useState<boolean>(false)

    const load = async () => {
        const d = await api.getAll();
        setBblogPosts(d);

        const da = await api.getAuthors();
        setAuthors(da);
    }
    useEffect(() => {
        load();
    }, []);

    return {
        blogPosts: blogPosts,
        authors: authors,
        isWaiting: isWaiting,
        setSelected: (id: string) => {
            if (selectedId == id) {
                setSelectedId(null);
            } else {
                setSelectedId(id);
            }
        },
        selectedItem: blogPosts?.find(m => m?.id == selectedId),
        makeFeatured: async (id: number) => {
            setIsWaiting(true);
            const success = await api.makeFeatured(id);
            if (success) {
                let d = [...blogPosts];
                d.find(m => m.idNumber == id).featured = true;
                setBblogPosts(d);
            }
            setIsWaiting(false);
        },
        removeFeatured: async (id: number) => {
            setIsWaiting(true);
            const success = await api.removeFeatured(id);
            if (success) {
                let d = [...blogPosts];
                d.find(m => m.idNumber == id).featured = false;
                setBblogPosts(d);
            }
            setIsWaiting(false);
        },
        setVisibleOnWeb: async (id: number, visible: boolean) => {
            setIsWaiting(true);
            const success = await api.setVisibleOnWeb(id, visible);
            if (success) {
                let d = [...blogPosts];
                d.find(m => m.idNumber == id).visibleOnWeb = visible;
                setBblogPosts(d);
            }
            setIsWaiting(false);
        },
        updateDb: async () => {
            setIsWaiting(true);

            const success: boolean = await FetchHandler.patchJson("/api/blog/update");
            if (success === true) {
                MessageBoxes.info("Uppdaterad")
            } else {
                MessageBoxes.warning("Failed")
            }
            await load();
            setIsWaiting(false);
        },
        updateAuthors: async (authorId: string, e: BlogPostAuthorType) => {
            await api.updateAuthors(authorId, e);
            load();
        },
        updateIsAuthor: async (authorId: string, isauthor: boolean) => {
            await api.updateIsAuthor(authorId, isauthor);
            load();
        },
        refreshDatabase: async () => {
            await api.refreshDatabase();
            const da = await api.getAuthors();
            setAuthors(da);
        },
    }
}


export type BlogPostType = {
    label: string,
    name: string,
    id: string,
    idNumber: number,
    html: string,
    title: string,
    titleUrlEncoded: string,
    titleHtmlDecoded: string,
    author: string,
    published: string,
    publishedDateTime: string,
    publishedFormatted: string,
    image: string,
    htmlExcerpt: string,
    htmlExcerptDecoded: string,
    modifiedUTC: string,
    permalink: string,
    authorimage: string,
    imageid: string,
    sys_datecreated_utc_epoch: number,
    featured: boolean,
    visibleOnWeb: boolean,
    featuredtype: FeaturedPostType,
    featuredimageid: string
}

export type BlogPostAuthorType = {
    name?: string
    isauthor?: boolean
    id?: string
    imageid?: string
    sys_datecreated_utc_epoch?: number,

    twitterhandle?: string
    instagramhandle?: string
    facebookhandle?: string
    tiktokhandle?: string
    email?: string
    ohgardenid?: string
    ohgardennamn?: string
    website?: string;

    publicheader?: string
    publicdescription?: string
    publicname?: string
}

const useBlogApi = () => {

    return {
        getAll: async (): Promise<BlogPostType[]> => {
            return await FetchHandler.getJson("/api/blog");
        },
        makeFeatured: async (id: number): Promise<boolean> => {
            return await FetchHandler.patchJson("/api/blog/" + id + "/featured", { featured: true });
        },
        removeFeatured: async (id: number): Promise<boolean> => {
            return await FetchHandler.patchJson("/api/blog/" + id + "/featured", { featured: false });
        },
        setVisibleOnWeb: async (id: number, visible: boolean): Promise<boolean> => {
            return await FetchHandler.patchJson("/api/blog/" + id + "/visible", { visible: visible });
        },
        getAuthors: async (): Promise<BlogPostAuthorType[]> => {
            return await FetchHandler.getJson("/api/blog/authors");
        },
        updateAuthors: async (authorId: string, e: BlogPostAuthorType): Promise<boolean> => {
            return await FetchHandler.patchJson("/api/blog/authors/" + authorId, e);
        },
        updateIsAuthor: async (authorId: string, isauthor: boolean): Promise<boolean> => {
            return await FetchHandler.patchJson("/api/blog/authors/" + authorId + "/setisauthor", { isauthor: isauthor });
        },
        refreshDatabase: async (): Promise<boolean> => {
            return await FetchHandler.patchJson("/api/blog/authors/refresh", {});
        },
    }
}