export const fn = {
    groups: (list: any[], propName?: string) => {
        if (propName) {
            return list.map(item => item[propName]).filter((value, index, self) => self.indexOf(value) === index);
        } else {
            return list.filter((value, index, self) => self.indexOf(value) === index);
        }
    },
    orderAsc: (list: any[], propName?: string) => {
        if (propName) {
            return list.sort((a, b) => {
                if (a[propName] > b[propName]) { return 1; }
                else if (a[propName] < b[propName]) { return -1; }
                else { return 0; }
            });
        } else {
            return list.sort((a, b) => {
                if (a > b) { return 1; }
                else if (a < b) { return -1; }
                else { return 0; }
            });
        }
    },
    orderDesc: (list: any[], propName?: string) => {
        if (propName) {
            return list.sort((a, b) => {
                if (a[propName] < b[propName]) { return 1; }
                else if (a[propName] > b[propName]) { return -1; }
                else { return 0; }
            });
        } else {
            return list.sort((a, b) => {
                if (a < b) { return 1; }
                else if (a > b) { return -1; }
                else { return 0; }
            });
        }
    },
    move: function (arr: any[], from: number, to: number) {
        arr.splice(to, 0, arr.splice(from, 1)[0]);
    }
}
