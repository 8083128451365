import React, { Component, useEffect, useLayoutEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import { FetchHandler } from '../../Handlers/FetchHandler';
import { SortHelper } from '../../Handlers/Helpers';
import { AdminIcon, AdminIcons } from '../Shared/AdminIcon';
import { ThreeColumnContainer } from '../Shared/Formelements';
import { ScreenHeader } from '../UI/Elements';
import { useFetch } from "./../../hooks/MiscHooks"

type FaqType = {
    id?: string,
    question?: string,
    answer?: string,
    sorting?: number,
    public?: boolean
}
type FaqTypeEdit = FaqType & {
    changed?: boolean,
    create?: boolean,
}

export const Faqs = () => {


    const dispatch = useDispatch();
    const [selected, setSelected] = useState<string>(null);
    const [faqItems, setFaqItems] = useState<FaqType[]>([]);

    const [selectedFaqItem, setSelectedFaqItem] = useState<FaqTypeEdit>(null);

    const onLoad = async () => {
        let f: FaqType[] = await FetchHandler.getJson("/api/system/faq");
        f.sort((a, b) => SortHelper.sortAscending(a, b, "sorting"));
        setFaqItems(f);
    }

    useLayoutEffect(() => { dispatch(onLoad); }, []);

    useEffect(() => {
        if (selected) {
            const f = faqItems?.find(m => m?.id == selected);
            if (f) {
                setSelectedFaqItem(f);
            }

        } else {
            setSelectedFaqItem(null)
        }
    }, [selected])




    const onCreate = () => {
        setSelectedFaqItem({
            question: "Ny Fråga",
            answer: "Nytt svar",
            public: false,
            create: true,
            changed: true,
            sorting: 0,
        })
    }

    const itemPress = (e: string) => {
        setSelected(selected === e ? null : e);
    }

    const onSaveSelected = async () => {
        const url = selectedFaqItem?.create ? "/api/system/faq/create" : "/api/system/faq/update";
        const success: boolean = await FetchHandler.postJson(url, selectedFaqItem);
        await onLoad();
    }

    return (
        <div className="view-faq">
            <ScreenHeader text="FAQ" icon={AdminIcons.questionCircle} />
            <ThreeColumnContainer gridTemplateColumns="700px 20px auto">
                <div>
                    <table className='tbl'>
                        <thead>
                            <tr>
                                <th> Fråga</th>
                                <th> Sortering</th>
                                <th> Privat/Publik</th>
                            </tr>
                        </thead>
                        <tbody>
                            {faqItems && faqItems.map(item => (
                                <ItemRow
                                    key={item.id}
                                    item={item}
                                    selected={selected === item?.id}
                                    onPress={itemPress} />
                            ))}
                            {/* <tr>
                                <td>
                                    <textarea style={{ width: "100%", height: "200px" }}></textarea>
                                </td>
                                <td>
                                    <textarea style={{ width: "100%", height: "200px" }}></textarea>
                                </td>
                                <td>
                                    <button className='btn' onClick={onCreate}>öaslkdösa</button>
                                </td>
                            </tr> */}
                        </tbody>
                    </table>

                    <button className='btn' onClick={onCreate}>
                        Skapa ny...
                    </button>
                </div>
                <div></div>
                <div className='edit-box'>
                    {!!selectedFaqItem && (
                        <div>
                            <label>Fråga</label>
                            <div className='edit-question'>

                                <input type="text"
                                    value={selectedFaqItem?.question}
                                    onChange={e => setSelectedFaqItem({ ...selectedFaqItem, question: e.target.value, changed: true })}
                                />
                            </div>
                            <label>Svar</label>
                            <div className='edit-answer'>
                                <textarea
                                    value={selectedFaqItem?.answer}
                                    onChange={e => setSelectedFaqItem({ ...selectedFaqItem, answer: e.target.value, changed: true })}
                                />
                            </div>
                            <label>Publik</label>
                            <div className='edit-public'>
                                <button onClick={() => {
                                    setSelectedFaqItem({ ...selectedFaqItem, public: !selectedFaqItem.public, changed: true })
                                }}>
                                    {(!!selectedFaqItem && selectedFaqItem?.public) && (<AdminIcon icon={AdminIcons.eye} title="Publik" />)}
                                    {(!!selectedFaqItem && !selectedFaqItem?.public) && (<AdminIcon icon={AdminIcons.eyeclosed} title="Privat" />)}
                                </button>
                            </div>
                            <label>Sortering</label>
                            <div className='edit-sorting'>
                                <input type={"number"}
                                    value={selectedFaqItem?.sorting}
                                    onChange={e => setSelectedFaqItem({ ...selectedFaqItem, sorting: parseInt(e.target.value), changed: true })}
                                />
                            </div>

                            <button className='btn' disabled={!selectedFaqItem?.changed} onClick={onSaveSelected}>
                                Spara
                            </button>
                        </div>
                    )}
                </div>
            </ThreeColumnContainer>
        </div>
    )

}

const ItemRow = ({ item, selected, onPress }: { item: FaqType, selected: boolean, onPress(e: string): void }) => {
    return (
        <tr
            key={item?.id}
            onClick={() => { onPress(item.id) }}
            className={"selectable " + (selected ? "selected" : "")}
        >
            <td>{item?.question}</td>
            <td>{item?.sorting}</td>
            <td>
                {item?.public && (<AdminIcon icon={AdminIcons.eye} title="Publik" />)}
                {!item?.public && (<AdminIcon icon={AdminIcons.eyeclosed} title="Privat" />)}
            </td>
        </tr>
    )
}