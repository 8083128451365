import React, { useEffect, useState } from "react"
import { Link } from 'react-router-dom';
import { DragDropContainer, DropTarget } from 'react-drag-drop-container';
import { FetchHandler } from "../../Handlers/FetchHandler";
import { MessageBoxes } from "../../Handlers/MessageBoxes";
import { useDateHelper } from "../../hooks/useHelper";
import { AdminIcons } from "../Shared/AdminIcon";
import { ThreeColumnContainer } from "../Shared/Formelements";
import { ScreenHeader, ShareLinkElement } from "../UI/Elements";
import { JwtImage } from "../UI/ImageElements";
import { ForumPostType } from "./Forum";
import { ToolBar } from "../ToolBar/ToolBar";


// ################################ ForumPostCard ################################
export const ForumPostCard = ({ post, hideLink, selected, overrrideLink, openImageOnClick }: { post: ForumPostType, hideLink?: boolean, selected?: boolean, overrrideLink?: boolean, openImageOnClick?: boolean }) => {

    const [showComments, setShowComments] = useState<boolean>(false);
    const onCommentsClick = () => {
        setShowComments(!showComments);
    }
    // api/comments/post/{postId}
    return (
        <div key={post?.id} className={"forum-post" + (selected ? " selected" : "")} data-id={post?.id}>
            <PostHeader post={post} overrrideLink={overrrideLink} />
            <PostImages post={post} openOnClick={openImageOnClick} />
            <PostFooter numberofcomments={post?.numberofcomments} numberoflikes={post?.numberoflikes} numberofplants={post?.plantids?.length} onCommentsClick={onCommentsClick} showComments={showComments} />
            <PostText text={post?.text} />
            {!(hideLink === true) && (
                <>
                    <hr />
                    <ShareLinkElement id={post?.id} type="post" />
                </>
            )}
        </div>
    )
}
// --------------------------------------------------------------------------------
const PostHeader = ({ post, overrrideLink }: { post: ForumPostType, overrrideLink: boolean }) => {

    const helper = useDateHelper();

    const Content = () => {
        return (
            <>
                <div className="post-top-image">
                    <JwtImage imageId={post?.profileimage} format="thumb" />
                </div>

                <div className="post-top-text">
                    <div className="post-alias">
                        {post?.alias}
                        {/* {post?.userid} */}
                    </div>
                    <div className="post-epochposted">
                        {helper.timeAgo(post?.epochposted)}
                    </div>
                </div>
            </>
        )
    }

    if (overrrideLink) {
        return (
            <div className="post-top">
                <Content />
            </div>
        )
    } else {
        return (
            <Link className="post-top" to={"/user/" + post?.userid}  >
                <Content />
            </Link>
        )
    }

}

const PostImages = ({ post, openOnClick }: { post: ForumPostType, openOnClick: boolean }) => {
    return (
        <div>
            {post?.imageids?.filter((i, idx) => idx < 1)?.map(i => (
                <div key={i} className="post-image">
                    <JwtImage imageId={i} format="hd" openOnClick={openOnClick} />
                </div>
            ))}

        </div>
    )
}

const PostFooter = ({ numberofcomments, numberoflikes, numberofplants, onCommentsClick, showComments }: { numberofcomments: number, numberoflikes: number, numberofplants: number, onCommentsClick(): void, showComments: boolean }) => {
    return (
        <div className="post-footer">
            <div className="post-numberofcomments cursor-pointer" onClick={onCommentsClick}>
                Kommentarer: {numberofcomments}
            </div>
            <div className="post-numberofplants cursor-pointer" onClick={onCommentsClick}>
                Växter: {numberofplants}
            </div>
            <div className="post-numberoflikes">
                Gillar:{numberoflikes}
            </div>
        </div>
    )
}

const PostText = ({ text }: { text: string }) => {
    return (
        <div className="post-text">
            {text}
        </div>
    )
}

// ################################ ForumPostRow ################################
