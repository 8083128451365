import React, { useEffect, useState } from "react"
import { AdminIcons } from "../Shared/AdminIcon";
import { ThreeColumnContainer } from "../Shared/Formelements";
import { ScreenHeader } from "../UI/Elements"
import { Overlay } from "../UI/Overlay"
import { useAdAdmin, useAdClients } from "./useAdAdmin";

export const AdClients = () => {

    const uiClients = useAdClients();

    useEffect(() => {
        uiClients.load();
    }, [])




    return (
        <div>
            <Overlay visible={false} />
            <ScreenHeader text="Annonsörer" icon={AdminIcons.users} />
            <div>
                {/* Klienter */}
                <table className="tbl">
                    <thead>
                        <tr>
                            <th>Namn</th>
                        </tr>
                    </thead>
                    <tbody>
                        {uiClients.all?.map(a => (
                            <tr key={a}>
                                <td>{uiClients.get(a)?.name}</td>
                            </tr>
                        ))}
                    </tbody>
                </table>

                <div>

                    <hr />
                    <div>
                        <div>
                            <input type={"text"} value={uiClients.newName} onChange={e => {
                                uiClients.newClientUpdateName(e.target.value)
                            }} placeholder="Lägg till ny klient" />
                        </div>
                        <button onClick={() => { uiClients.addNewClient() }}>
                            Lägg till
                        </button>
                    </div>
                </div>

            </div>
        </div>
    )
}

