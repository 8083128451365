import React, { useState, useEffect } from 'react';
import Select from 'react-select';
import { useSelector } from 'react-redux';
import store from "../../../rdx/store";
import { CHANGE_SEARCH_TEXT, CHANGE_SEARCH_TYPE, CHANGE_SEARCH_VISIBLEFIELDS, CHANGE_SEARCH_PUBLISHED } from "../../../rdx/reducers";
import { SearchHandler } from '../../../Handlers/SearchHandler';
import { RdxStore } from '../../../rdx/ReduxTypes';
import { useSignalRMessaging } from '../../../rdx/useSignalR';

const DoSearch = () => {
    SearchHandler.search();
}

export const SearchTextBox = () => {
    const initText = useSelector((state: RdxStore) => state.search.searchText);
    const isSearching = useSelector((state: RdxStore) => state.search.isSearching);
    const [text, setText] = useState<string>(initText);
    const signalR = useSignalRMessaging();

    useEffect(() => { setText(initText); }, [initText]);

    const onChange = (e) => {
        const val = e.target.value;
        setText(val);
    }

    const onEnter = () => {
        store.dispatch({ type: CHANGE_SEARCH_TEXT, payload: text });
        DoSearch();
        signalR.action("plantsearch: '" + text + "'")
    }
    const onLeave = () => {
        store.dispatch({ type: CHANGE_SEARCH_TEXT, payload: text });
    }
    return (
        <input type="text" value={text}
            onChange={onChange}
            onKeyUp={(e) => { if (e.key === 'Enter') { onEnter(); }; }}
            onBlur={onLeave}
            disabled={isSearching}
        />
    )
}

export const SearchTypeDropDown = () => {
    const initValue = useSelector((state: RdxStore) => state.search.typeId);

    const types = useSelector((state: RdxStore) => state.data.planttypes.map(o => { return { value: o.id, label: o.name } }));
    const options = [{ value: "-", label: "Inget filter" }].concat(types);
    const isSearching = useSelector(state => state.search.isSearching);
    const signalR = useSignalRMessaging();

    const [value, setValue] = useState({ value: "-", label: "Inget filter" });

    useEffect(() => {
        const val = options.filter(obj => obj.value == initValue)[0];
        setValue(val);
    }, [initValue]);

    const typeChanged = (e) => {
        store.dispatch({ type: CHANGE_SEARCH_TYPE, payload: e.value });
        signalR.action("plantsearch filter => " + e?.label)
        DoSearch();
    }

    return (
        <Select
            value={value}
            options={options}
            onChange={typeChanged}
            isDisabled={isSearching}
        />
    );

}

export const SearchPublishFilter = () => {
    const initValue = useSelector((state: RdxStore) => state.search.published);
    const options = [
        { value: null, label: "Båda" },
        { value: true, label: "Publicerade" },
        { value: false, label: "Ej publicerade" },
    ];
    const isSearching = useSelector((state: RdxStore) => state.search.isSearching);
    const [value, setValue] = useState(options[0]);


    useEffect(() => {
        const val = options.filter(obj => obj.value == initValue)[0];
        setValue(val);
    }, [initValue]);


    const typeChanged = (e) => {
        store.dispatch({ type: CHANGE_SEARCH_PUBLISHED, payload: e.value });
        DoSearch();
    }

    return (
        <Select
            value={value}
            options={options}
            onChange={typeChanged}
            isDisabled={isSearching}
        />
    )
}

export const SearchOptionsViewer = () => {
    const options = useSelector((state: RdxStore) => state.search);
    const isSearching = useSelector((state: RdxStore) => state.search.isSearching);
    return (
        <div>
            <div>Text: {isSearching ? "...." : options.searchText}</div>
            <div>typeId: {isSearching ? "...." : options.typeId}</div>
        </div>
    )
}
