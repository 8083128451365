import React, { useState } from 'react';
import { TwoColumnContainer } from '../Shared/Formelements';
import { FieldInput } from "./FieldInputs/FieldInputs";
import { MessageBoxes } from '../../Handlers/MessageBoxes';
import { PlantOperations } from '../../rdx/Operations';
import { useHistory } from 'react-router-dom';
import { usePlantField, usePlantSearchHit, useQueryParams, useSnippet, useSnippets } from '../../Handlers/Hooks';
import { Routes } from '../../Handlers/ViewHandler';


export const CreatePlant = ({ }) => {
    const { from } = useQueryParams();
    const hit = usePlantSearchHit(from);
    const fields = usePlantField();
    var nav = useHistory();

    const template = hit ? { ...hit, id: null, fields: { ...hit.fields, specifictype: "", imageid: "" } } : { fields: {} };
    const [model, setModel] = useState(template);

    let fieldKeys = hit ? Object.keys(template?.fields) : [];
    if (fieldKeys.indexOf("public_description") < 0) {
        fieldKeys.splice(4, 0, "public_description");
    }


    const onValueChanged = (key, value) => {
        let m = { ...model };
        m.fields[key] = value;
        setModel(m);
    }

    const onSaveClicked = () => {

        let m = { ...model };
        delete model.fields.imageid;

        fieldKeys.forEach(key => {
            if (fields?.[key]?.public === false && m.fields[key]) {
                delete m.fields[key];
                console.log(key);
            }
        })

        m.inheritFrom = from;
        PlantOperations.createPlant(model).then(response => {
            if (response.success) {
                const id = response.id;
                MessageBoxes.info("Växten skapades");
                nav.push({ pathname: Routes.start });
                console.log(response);
            } else {
                MessageBoxes.warning("Växten skapades inte!");
            }
        });
        console.log("model: ", model)
    }

    return (
        <div className='createplant-view'>

            <h1>
                Kopiera egenskaper från '{model?.fields?.name} {hit?.fields?.specifictype}'
            </h1>

            <TwoColumnContainer>

                <div>
                    <table>
                        <thead></thead>
                        <tbody>
                            {fieldKeys
                                .filter(key => { return fields?.[key] || key === "public_description" ? true : false; })
                                .filter(key => { return fields?.[key]?.public; })
                                .map(key => (
                                    <tr key={key} className={'type-' + fields?.[key]?.type}>
                                        <td className='col-title' >
                                            <div className='name' title={key}>{fields?.[key]?.name}</div>
                                            <div className='type'>{fields?.[key]?.typeString}</div>
                                        </td>
                                        <td className='col-input'>
                                            <PlantField fieldKey={key} onValueChanged={onValueChanged} value={model?.fields?.[key]} hideLabel={true} multiLine={key.includes("description")} />
                                        </td>
                                        <td className='col-value'>{JSON.stringify(model?.fields?.[key])}</td>
                                    </tr>
                                ))}
                        </tbody>
                    </table>
                </div>
                <div>
                    <button className='btn' onClick={onSaveClicked}>
                        Skapa
                    </button>
                </div>

            </TwoColumnContainer>
        </div>
    )
}


export const PlantField = ({ fieldKey, onValueChanged, value, hideLabel, multiLine }) => {
    const field_key = fieldKey;
    const field = usePlantField(fieldKey);

    const [hasValue, setHasValue] = useState(false);

    const onChange = (e) => {
        setHasValue(true);
        onValueChanged(field_key, e);
    };

    if (!field) {
        return (null);
    }

    if (field_key) {
        let control = (null);

        switch (field.type) {
            case 1: { // Text
                control = (<FieldInput.Text value={value} onChange={onChange} multiLine={multiLine} />);
                break;
            }
            case 2: { // Integer
                control = (<FieldInput.Integer value={value} onChange={onChange} />);
                break;
            }
            case 3: { // Decimal
                control = (<FieldInput.Decimal value={value} onChange={onChange} />);
                break;
            }
            case 4: { // SingleSelect
                control = (<FieldInput.SingleSelect fieldType={field_key} value={value} onChange={onChange} />);
                break;
            }
            case 5: { // MultiSelect
                control = (<FieldInput.MultiSelect fieldType={field_key} value={value} onChange={onChange} />);
                break;
            }
            case 6: { // Color
                control = (<FieldInput.Color value={value} onChange={onChange} />);
                break;
            }
            case 7: { // TrueFalse
                control = (<FieldInput.TrueFalse value={value} onChange={onChange} />);
                break;
            }
            case 8: { // Tags
                control = (<FieldInput.Tag value={value} onChange={onChange} />);
                break;
            }

            case 10: { // Snippet
                control = (<SnippetViewer id={value} onChange={onChange} />);
                break;
            }
        }

        if (hideLabel === true) {
            return (
                <>
                    {control}
                </>
            )
        }

        return (
            <div>
                <label>
                    {field.name}
                    {!hasValue && (<span className="invalid">*</span>)}
                </label>
                {control}
            </div>
        );

    }
    return (<div>N/A</div>);
}


const SnippetViewer = ({ id, onChange }) => {
    const snippets = useSnippet();
    const keys = Object.keys(snippets);
    const snippet = useSnippet(id);
    return (
        <div>
            {/* {snippet?.snippetKey} */}
            <div className='snippet-name'>
                {snippet?.snippetName}
            </div>
            <div className='snippet-description'>
                {snippet?.snippetDescription}
            </div>
            {/* {keys.join(':')} */}
        </div>
    )
}