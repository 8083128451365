export const stringIsNullOrEmpty = (str) => {
    if(str === null || str === undefined) {
        return true;
    } 
    if(str.length < 1) {
        return true;
    } 
    return false;
}
export const valueIsNullOrUndefined = (item) => {
    if(item === null || item === undefined) {
        return true;
    } 
    return false;
}

export const Validator = {
    stringIsNullOrEmpty: stringIsNullOrEmpty,

    /*
        { type: 0, typeString: "NotSet" },
        { type: 1, typeString: "String" },
        { type: 2, typeString: "Integer" },
        { type: 3, typeString: "Decimal" },
        { type: 4, typeString: "SingleSelect" },
        { type: 5, typeString: "MultiSelect" },
        { type: 6, typeString: "Color" },
        { type: 7, typeString: "TrueFalse" }
     */
    newPlantModelIsValid: (plantfieldsobject, model) => {
        const fields = Object.getOwnPropertyNames(model);

        // console.log("fieldSettings: ", fieldSettings);
        // console.log("fields: ", fields);
        // console.log("model: ", model);
        for(let i = 0; i < fields.length; i++){
            const field  = fields[i];
            const fieldValue = model[field];
            const fieldSetting = plantfieldsobject[field];

            if(!fieldSetting) {
                if(!fieldValue) { return false; }
            } else {
                switch(fieldSetting.type) {
                    case 1 : {
                        if(stringIsNullOrEmpty(fieldValue)) { return false; }
                        break;
                    }
                    case 2 :
                    case 3 :
                    case 4 :
                    case 5 :
                    case 6 :
                    case 7 : {
                        if(valueIsNullOrUndefined(fieldValue)) { 
                            console.log("invalid field: ", field)
                            return false; 
                        }
                        break;
                    }
                    default: {
                        return false;
                    }
                }
            }
        }

        console.log("model is valid: ");
        return true;
    }
};