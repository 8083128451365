import React, { useState } from 'react';
import Select from 'react-select';
import { useSelector } from 'react-redux';
import { Dialog } from '../Shared/Dialog';
import { PlantHandler } from '../../Handlers/PlantHandler';

export const RemovePlantFieldDialog = (props) => {

  const ids = useSelector(state => state.search.searchList.filter(obj => obj.selected).map(obj => obj.id))
  const options = useSelector(state => state.data.plantfields.map(o => { return { value: o.id, label: o.name } }));
  const [value, setValue] = useState(null);
  const [dialogdisabled, setDialogdisabled] = useState(false);
  const onOkClicked = () => {
    setDialogdisabled(true);
    PlantHandler.dropPlantsField(ids, value.value).then(success => {
      if (success && props.okHandler) {
        props.okHandler();
      }
    })

  };

  return (<Dialog
    onCancel={props.cancelHandler}
    onOk={onOkClicked}
    width="600px"
    // okDisabled={value === null }
    disabled={dialogdisabled}
  >
    <h4>Ta bort fält</h4>
    <div>
      <Select
        value={value}
        options={options}
        onChange={e => { setValue(e); }}
        menuPortalTarget={document.body}
        styles={{ menuPortal: base => ({ ...base, zIndex: 9999 }) }}
      />
    </div>
  </Dialog>)
}