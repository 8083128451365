import { useState } from "react";

export type PagingOptionsType = {
    pageSize?: number,
    page?: number
}

export function usePaging<T>(options?: PagingOptionsType) {
    const [pageSize, setPageSize] = useState<number>(options?.pageSize ?? 20)
    const [currentPage, setCurrentPage] = useState<number>(0);

    const [rawData, setRawData] = useState<T[]>([]);
    const [pagedData, setPagedRawData] = useState<T[]>([]);

    const reCalc = (newPage: number, rData?: T[]) => {
        setCurrentPage(newPage);
        setPagedRawData((rData ?? rawData)?.filter((e, idx) => {
            const u = idx < (pageSize + newPage * pageSize);
            const o = idx >= (newPage * pageSize);
            return u && o;
        }));
        if (!!rData) {
            setRawData(rData);
        }
    };

    return {
        // Data
        datasource: pagedData,

        // Props
        totalCount: rawData?.length,
        pageIndex: currentPage,
        pageSize: pageSize,
        pageCount: Math.ceil(rawData?.length / pageSize),

        // Sets
        setPageSize: setPageSize,
        setCurrentPageIndex: setCurrentPage,

        // Methods Basic
        setData: (data: T[]) => {
            reCalc(0, data);
        },
        next: () => {
            const current_index = currentPage;
            const max_index = Math.floor((rawData?.length - 1) / pageSize);
            console.log("current_index:", current_index, ", max_index:", max_index);
            if (current_index >= max_index) {
                return false;
            } else {
                reCalc(currentPage + 1)
                return true;
            }
        },
        prev: () => {
            if (currentPage <= 0) {
                return false;
            } else {
                reCalc(currentPage - 1)
                return true;
            }
        },

        // Methods Operations
        deleteItem: (lambda: (value: T, index: number, obj: T[]) => unknown) => {
            const idx = rawData?.findIndex(lambda);
            reCalc(currentPage, rawData.filter((e, i) => i !== idx));
        },
        updateItem: (lambda: (value: T, index: number, obj: T[]) => unknown, item: T) => {
            const idx = rawData?.findIndex(lambda);
            let newData = [...rawData];
            newData[idx] = item;
            reCalc(currentPage, newData);
        },
        addItem: (item: T) => {
            reCalc(currentPage, [...rawData, item]);
        },
    }
}