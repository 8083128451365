
import React, { Component, useEffect, useLayoutEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import Select from 'react-select';
import { FetchHandler } from '../../Handlers/FetchHandler';
import { StringHelper } from '../../Handlers/Helpers';
import { MessageBoxes } from '../../Handlers/MessageBoxes';
import { FeedbackStateType, FeedbackType, useFeedback } from '../../hooks/useFeedback';
import { usePlant } from '../../hooks/usePlant';
import { useUsers } from '../../hooks/useUsers';
import { RdxStore } from '../../rdx/ReduxTypes';
import { AdminIcon, AdminIcons } from '../Shared/AdminIcon';
import { ThreeColumnContainer, TwoColumnContainer } from '../Shared/Formelements';
import { AddIcon, CancelIcon, EditIcon, FaIcon, RemoveIcon, SaveIcon } from '../Shared/Icons';
import { ScreenHeader } from '../UI/Elements';
import { useFetch } from "./../../hooks/MiscHooks"
import { useSystemSettings } from './useSystemSettings';

export const SystemSettings = () => {

    const ui = useSystemSettings();


    return (
        <div className='system-settings'>
            <ScreenHeader text="Inställningar" icon={AdminIcons.cogs} />
            <div className='setting-cards'>
                <div className='setting-card'>
                    <div>
                        <div>
                            <h3>Minsta version</h3>
                            <p>
                                Minsta version på appens Package.js.
                            </p>
                        </div>
                        <div>
                            <label>
                                Sparad minimumVersion
                            </label>
                            <div>
                                {ui.savedMinimumVersionString}
                            </div>
                        </div>
                        <div>
                            <label>
                                Ny minimumVersion
                            </label>
                            <div>
                                {ui.minimumVersionString}
                            </div>
                        </div>
                        <div className='versionList'>
                            {ui.packageVersions?.map(v => (
                                <span key={v.packageVersion} className='version'>{v.packageVersion} : {v.count}</span>
                            ))}
                        </div>
                        <div className='number-input'>
                            <input type={"number"} value={ui.minimumVersionMajor} onChange={e => { ui.setMinimumVersionMajor(e.target.value) }} />
                            .
                            <input type={"number"} value={ui.minimumVersionMinor} onChange={e => { ui.setMinimumVersionMinor(e.target.value) }} />
                            .
                            <input type={"number"} value={ui.minimumVersionBuild} onChange={e => { ui.setMinimumVersionBuild(e.target.value) }} />
                        </div>
                        <SaveOrDiscardSetting onSave={ui.onSaveMinimumVersion} onCancel={ui.loadData} />
                    </div>
                </div>
            </div>
        </div>
    )
}


const SaveOrDiscardSetting = ({ onSave, onCancel }) => {
    return (
        <div className='saveordiscardsetting'>
            <div>
                <button className='btn cancel' onClick={onCancel}>
                    Avbryt
                </button>
            </div>
            <div></div>
            <div>
                <button className='btn save' onClick={onSave}>
                    Spara
                </button>
            </div>
        </div>
    )
}
