import React, { useState } from 'react';
import { Dialog } from "../Shared/Dialog"
import { useSelector } from 'react-redux';
import { TwoColumnContainer } from '../Shared/Formelements';

export const PlantFieldsMergeOptionsButton = (props) => {
    const [dialogopen, setDialogopen] = useState(false)
    return (
        <>
            <button className="btn pull-right" onClick={() => { setDialogopen(true); }}>
                ....</button>

            {dialogopen && (
                <PlantFieldsMergeOptions id={props.id} close={() => { setDialogopen(false); }} />
            )}
        </>
    )
}

export const PlantFieldsMergeOptions = (props) => {

    const field = useSelector(state => state.data.plantfieldsobject[props.id.toLowerCase()]);
    let options = [...field.selectOptions];
    options.forEach(obj => obj.selected = false);
    options.sort((a, b) => {
        if (a.name > b.name) return 1;
        if (a.name < b.name) return -1;
        else return 0;
    });
    const [selected, setSelected] = useState([]);

    const addToSelected = (key) => {
        let a = [...selected];
        a.push(key);
        setSelected(a);
    }
    const removeFromSelected = (key) => {
        let a = [...selected];
        a.splice(a.indexOf(key));
        setSelected(a);
    }

    return (
        <Dialog
            disabled={false}
            onCancel={props.close}
            onOk={props.close}
            width="900px"
        >
            <div>{field.id}</div>
            <div>{field.name}</div>
            <TwoColumnContainer>
                <div>
                    <table className="tbl tbl-small">
                        <tbody>

                            {options.filter(obj => selected.indexOf(obj.key) < 0).map(option => (
                                <tr key={option.key}>
                                    <td>
                                        {option.name}
                                    </td>
                                    <td>
                                        <button className="btn-link" onClick={() => { addToSelected(option.key); }}>
                                            &gt;&gt;&gt;
                                        </button>
                                    </td>
                                </tr>
                            ))}
                        </tbody>
                    </table>
                </div>
                <div>
                    <table className="tbl tbl-small">
                        <tbody>

                            {options.filter(obj => selected.indexOf(obj.key) >= 0).map(option => (
                                <tr key={option.key}>
                                    <td>
                                        <button className="btn-link" onClick={() => { removeFromSelected(option.key); }}>
                                            &lt;&lt;&lt;
                                        </button>
                                    </td>
                                    <td>
                                        {option.name}
                                    </td>
                                </tr>
                            ))}
                        </tbody>
                    </table>
                </div>

            </TwoColumnContainer>

        </Dialog>
    )
}