import { useEffect, useState } from "react"
import { useSelector, useDispatch } from 'react-redux'; import { FetchHandler } from "../../Handlers/FetchHandler";
import { MessageBoxes } from "../../Handlers/MessageBoxes";
import { SearchHandler } from "../../Handlers/SearchHandler";
import { PlantListType } from "../../rdx/Redux.DataReducerType";
import { SearchListPlantType } from "../../rdx/Redux.SearchReducerType";
import { RdxStore } from "../../rdx/ReduxTypes";
import { CHANGE_SEARCH_TEXT } from "../../rdx/searchReducer";
import { CHANGE_TOOLS_DISCOVERYUI, CHANGE_TOOLS_POSTS_STORE, ForumPostStore } from "../../rdx/toolsreducer";
import { ClipBoardDataType } from "../ClipBoard/useClipBoard";
import { ForumPostType } from "../Forum/Forum";
import { SettingsStore, SystemSettingsKeys } from "./../SystemObjects/useSystemSettings";


export type DiscoveryUIType = {
    list01: string,
    list02: string,
    list03: string,
    list04: string,
    plantOfTheWeek: string,
    imageOfTheWeek: string,
    plantImageOfTheWeek: string,
    postImageOfTheWeek: string,
    featured01: string,
    featured02: string,
    featured03: string,
}

export type SystemSettingsRevision = SettingsStore & {
    id: string,
    publishepoch: number
}
export type ObjectWithId = { id: string }

export type DiscoverViewState = "plants" | "images" | "lists" | "featured";
export interface DiscoveryUI {
    viewState: DiscoverViewState
}

export const useDiscover = () => {

    const discoveryUI: DiscoveryUI = useSelector((state: RdxStore) => state.tools.discoveryUI);
    const clipBoardData: ClipBoardDataType = useSelector((state: RdxStore) => state.tools.clipBoardData);

    const listsObject = useSelector((state: RdxStore) => state.data.plantlists);
    const [viewState, setViewState] = useState<DiscoverViewState>(discoveryUI?.viewState ?? "plants");

    const [loading, setLoading] = useState(false);
    const [uiConfig, setUiConfig] = useState<DiscoveryUIType>(null)

    const [revisions, setRevisions] = useState<SystemSettingsRevision[]>([]);
    const [selectedRevision, setSelectedRevision] = useState(null);

    const transformConfig = (data: { [key: string]: string }) => {
        var list01Key: SystemSettingsKeys = "DISCOVERY_LIST_01";
        var list02Key: SystemSettingsKeys = "DISCOVERY_LIST_02";
        var list03Key: SystemSettingsKeys = "DISCOVERY_LIST_03";
        var list04Key: SystemSettingsKeys = "DISCOVERY_LIST_04";
        var plantOfTheWeek: SystemSettingsKeys = "PLANT_OF_THE_WEEK";
        var imageOfTheWeek: SystemSettingsKeys = "IMAGE_OF_THE_WEEK";

        var plantImageOfTheWeek: SystemSettingsKeys = "PLANT_IMAGE_OF_THE_WEEK";
        var postImageOfTheWeek: SystemSettingsKeys = "POST_IMAGE_OF_THE_WEEK";

        var featured01: SystemSettingsKeys = "DISCOVERY_USER_POST_FEATURED_CARD_01";
        var featured02: SystemSettingsKeys = "DISCOVERY_USER_POST_FEATURED_CARD_02";
        var featured03: SystemSettingsKeys = "DISCOVERY_USER_POST_FEATURED_CARD_03";


        return {
            list01: data?.[list01Key],
            list02: data?.[list02Key],
            list03: data?.[list03Key],
            list04: data?.[list04Key],
            plantOfTheWeek: data?.[plantOfTheWeek],
            imageOfTheWeek: data?.[imageOfTheWeek],

            plantImageOfTheWeek: data?.[plantImageOfTheWeek],
            postImageOfTheWeek: data?.[postImageOfTheWeek],

            featured01: data?.[featured01],
            featured02: data?.[featured02],
            featured03: data?.[featured03],
        };
    }

    const setSortedRevisions = (e: SystemSettingsRevision[]) => {
        let list: SystemSettingsRevision[] = [...e];
        list.sort((a, b) => {
            if (a.publishepoch > b.publishepoch) { return -1; }
            else if (a.publishepoch < b.publishepoch) { return 1; }
            else { return 0; }
        });
        setRevisions(list);
    }

    const loadData = async () => {
        // setLoading(true);
        // const data: { [key: string]: string } = await FetchHandler.getJson("/api/system/systemsettings");
        // setUiConfig(transformConfig(data));
        // setLoading(false);

        const revs = await FetchHandler.getJson("/api/system/systemsettings/revisions");
        let list: SystemSettingsRevision[] = revs.list;
        setSortedRevisions(revs.list)
    }

    const postStore: ForumPostStore = useSelector((state: RdxStore) => state.tools.postStore);

    const loadPosts = async () => {
        let change = { ...postStore };
        const d: ForumPostType[] = await FetchHandler.getJson("/api/content/featuredposts");
        d.forEach(e => { change[e?.id] = e });
        dispatch({ type: CHANGE_TOOLS_POSTS_STORE, payload: change });
    }


    useEffect(() => {
        loadData();
        loadPosts();
    }, []);

    useEffect(() => {
        if (selectedRevision && revisions) {
            const current = revisions.find(m => m.id == selectedRevision)
            setUiConfig(transformConfig(current as any));
        }

    }, [selectedRevision, revisions])


    const dispatch = useDispatch();

    const isSearching = useSelector((state: RdxStore) => state.search.isSearching);
    const list: SearchListPlantType[] = useSelector((state: RdxStore) => state.search.searchList);

    const [sendNotificationForForumPostType, setSendNotificationForForumPostType] = useState<ForumPostType>(undefined)

    return {
        viewState: viewState, setViewState: (e: DiscoverViewState) => {
            dispatch({ type: CHANGE_TOOLS_DISCOVERYUI, payload: { ...discoveryUI, viewState: e } });
            setViewState(e)
        },
        loading: loading || isSearching,
        plantSearchList: list,
        uiConfig: uiConfig,
        searchPlant: (search: string) => {
            dispatch({ type: CHANGE_SEARCH_TEXT, payload: search });
            SearchHandler.search({ onlyWithImages: true });
        },
        plantLists: Object.keys(listsObject ?? {}).filter(m => m?.length > 10).map(key => listsObject?.[key] as PlantListType),
        // Revisions
        revisions: revisions,
        addRevision: async (epoch: number) => {
            setLoading(true);
            const url = "/api/system/systemsettings/revision/create";
            const postObj = {
                publishepoch: epoch
            };

            const success = await FetchHandler.postJson(url, postObj);

            if (success) {
                const revs = await FetchHandler.getJson("/api/system/systemsettings/revisions");
                setSortedRevisions(revs?.list)
            }
            setLoading(false);
        },
        deleteRevision: async (id: string) => {
            setLoading(true);
            const url = "/api/system/systemsettings/revision/" + id + "/delete";
            const success = await FetchHandler.delete(url);
            if (success) {
                const revs = await FetchHandler.getJson("/api/system/systemsettings/revisions");
                setSortedRevisions(revs?.list)
            }
            setLoading(false);
        },
        selectedRevision: selectedRevision,
        selectRevision: async (id: string) => {
            const newRev = selectedRevision === id ? null : id;
            setSelectedRevision(newRev);
        },
        keyValueChange: async (key: SystemSettingsKeys, data: { id: string }, droppedObject: any) => {
            if (data?.id) {
                //console.log("keyValueChange.pl: ", selectedRevision, key, data);
                const url = "/api/system/systemsettings/revision/" + selectedRevision + "/update";
                const response: boolean = await FetchHandler.patchJson(url, { [key]: data?.id });
                const revs = await FetchHandler.getJson("/api/system/systemsettings/revisions");
                setSortedRevisions(revs?.list);

                if (response === true && (key === "DISCOVERY_USER_POST_FEATURED_CARD_01" || key === "DISCOVERY_USER_POST_FEATURED_CARD_02" || key === "DISCOVERY_USER_POST_FEATURED_CARD_03")) {
                    setSendNotificationForForumPostType(droppedObject)
                }
            } else {
                console.log("keyValueChange.p: ", selectedRevision, key, data);
            }
        },
        selectedUserImages: clipBoardData,
        featuredPosts: () => {
            let d = Object.keys(postStore ?? {}).map(key => postStore?.[key]).filter(m => m?.featured && !!m?.featuredimageid && (m?.featuredtype == "flower_bed" || m?.featuredtype == "friend" || m?.featuredtype == "flower_recipe"));
            d.sort((a, b) => {
                if (a.epochposted < b.epochposted) {
                    return 1;
                } else if (a.epochposted > b.epochposted) {
                    return -1;
                } else {
                    return 0
                }
            });
            return d;
        },
        sendNotificationForForumPostTypeVisible: sendNotificationForForumPostType,
        sendNotificationForForumPostTypeHide: () => { setSendNotificationForForumPostType(undefined) },

    }
}