import React, { useEffect } from "react"
import { FetchHandler } from "../../Handlers/FetchHandler";
import Select from 'react-select'
import { MessageBoxes } from "../../Handlers/MessageBoxes";
import { AdminIcon, AdminIcons } from "../Shared/AdminIcon"
import { ThreeColumnContainer } from "../Shared/Formelements";
import { TabSelector, ToolBar, ToolBarClean } from "../ToolBar/ToolBar";
import { AdminMultiSelect, LoadingBar, ScreenHeader } from "../UI/Elements"
import { PoiType, PoiTypeType, usePointOfInterests } from "./Data/usePointOfInterests";
import { SelectOptionStringType, SelectOptionType } from "../UI/UITYpes";
import { PointOfInterestsMap } from "./PointOfInterestsMap";
import { UploadImageElement } from "../FormComponents/UploadImageElement";
import { JwtImage } from "../UI/ImageElements";

export const PointOfInterests = () => {

    const ui = usePointOfInterests();

    return (
        <div className="pointofinterests-screen">
            <ScreenHeader text="POIs" icon={AdminIcons.globe} />

            <div>
                <LoadingBar loading={ui.saving} />
            </div>

            <ThreeColumnContainer gridTemplateColumns="50% auto">

                <div>
                    <div className="filters">
                        <div>
                            <label>Filtrera på namn</label>
                            <input className="frm" placeholder="Namn..." type={"search"} onChange={e => ui.onSetFilter(e.target.value)} />

                        </div>
                        <div>
                            <label>&nbsp;</label>
                            <button
                                className={"btn btn-toggle " + (ui.filterOnlyPublished ? "pressed" : "")}
                                onClick={() => { ui.setfilterOnlyPublished(!ui.filterOnlyPublished) }}>
                                Enbart publicerade
                            </button>
                        </div>
                    </div>

                    <hr />
                    <table className="tbl">
                        <thead>
                            <PoiHeader />
                        </thead>
                        <tbody>
                            {ui?.pois?.map(poi => (
                                <PoiRow
                                    key={poi?.id}
                                    poi={poi}
                                    selected={poi?.id === ui.itemSelected}
                                    onItemSelected={(id: string) => ui.onItemSelected(id)} />
                            ))}
                        </tbody>

                    </table>

                </div>

                <div>
                    <div className="poi-form">
                        {ui.editState === "add" && (<h2>Lägg till POI</h2>)}
                        {ui.editState === "edit" && (<h2>Ändra POI</h2>)}
                        {(ui.editState === "edit" || ui.editState === "add") && (
                            <div>
                                <div>
                                    <TabSelector tabs={[
                                        { id: "data", label: "Info", onClick: () => ui.setEditItemState("data") },
                                        { id: "images", label: "Bilder", onClick: () => ui.setEditItemState("images") },
                                        { id: "map", label: "Karta", onClick: () => ui.setEditItemState("map") },
                                    ]} selectedId={ui.editItemState} />
                                </div>
                                <div className="tabselector-tabs">

                                    {ui.editItemState === "data" && (
                                        <div className="poi-form-data">
                                            <table className="w100">
                                                <tbody>

                                                    <tr>
                                                        <td colSpan={2}>
                                                            <label>Namn</label>
                                                            <input type={"text"} className="frm" onChange={e => ui.setItemModel({ title: e.target.value })} placeholder="Namn" value={ui.itemModel?.title ?? ""} />
                                                        </td>
                                                        <td>
                                                            <label>Typ</label>
                                                            <select className="frm" onChange={e => ui.setItemModel({ type: e.target.value as PoiTypeType })} value={ui.itemModel?.type}>
                                                                <option value={"store"}>store</option>
                                                                <option value={"garden"}>garden</option>
                                                            </select>
                                                        </td>
                                                        <td>
                                                            <label>Latitud</label>
                                                            <input type={"number"} className="frm" placeholder="latitude" value={ui.itemModel.latitude ?? 0}
                                                                onChange={e => ui.setItemModel({ latitude: Math.round(parseFloat(e.target.value) * 1000000) / 1000000 })} max={90} min={-90} />
                                                        </td>
                                                        <td>
                                                            <label>Longitud</label>
                                                            <input type={"number"} className="frm" placeholder="longitude" value={ui.itemModel.longitude ?? 0}
                                                                onChange={e => ui.setItemModel({ longitude: Math.round(parseFloat(e.target.value) * 1000000) / 1000000 })} max={180} min={-180} />
                                                        </td>
                                                    </tr>
                                                    <tr>
                                                        <td>
                                                            <label>Adress</label>
                                                            <input type={"text"} className="frm" onChange={e => ui.setItemModel({ addressline1: e.target.value })} placeholder="Adressrad 1" value={ui.itemModel?.addressline1 ?? ""} />
                                                        </td>
                                                        <td colSpan={4}>
                                                            <label>Webbadress</label>
                                                            <input type={"text"} className="frm" onChange={e => ui.setItemModel({ url: e.target.value })} placeholder="https://..." value={ui.itemModel?.url ?? ""} />
                                                        </td>
                                                    </tr>
                                                    <tr>
                                                        <td>
                                                            <input type={"text"} className="frm" onChange={e => ui.setItemModel({ addressline2: e.target.value })} placeholder="Adressrad 2" value={ui.itemModel?.addressline2 ?? ""} />
                                                        </td>
                                                        <td colSpan={4}>
                                                        </td>
                                                    </tr>
                                                    <tr>
                                                        <td colSpan={5}>
                                                            <label>Beskrivning</label>
                                                            <textarea className="frm h200" placeholder="Beskrivning" onChange={e => ui.setItemModel({ description: e.target.value })} value={ui.itemModel?.description ?? ""}></textarea>
                                                        </td>
                                                    </tr>
                                                    <tr>
                                                        <td colSpan={5}>
                                                            <label>Vår kommentar (intern)</label>
                                                            <textarea className="frm h100" placeholder="Vår kommentar" onChange={e => ui.setItemModel({ ourcomment: e.target.value })}
                                                                value={ui.itemModel?.ourcomment ?? ""}></textarea>
                                                        </td>
                                                    </tr>
                                                </tbody>
                                            </table>
                                        </div>
                                    )}

                                    {ui.editItemState === "images" && (
                                        <div className="poi-form-image">

                                            {ui.editState === "edit" && (
                                                <div>
                                                    <div className="current-image">
                                                        <div className="current-image-preview">
                                                            <label>Primärbild</label>
                                                            {!!ui.itemModel?.imageid && (
                                                                <JwtImage
                                                                    imageId={ui.itemModel?.imageid} format={"thumb"}
                                                                    openOnClick={true}
                                                                    previewImageClassName="current-image-preview-image"
                                                                />
                                                            )}
                                                        </div>
                                                        <div className="current-image-tools">
                                                            <ToolBarClean tools={[
                                                                {
                                                                    id: "1", label: "Ta bort primärbild", onClick: async () => {
                                                                        await ui.removePrimary();
                                                                    }
                                                                },
                                                            ]} />
                                                        </div>
                                                    </div>
                                                    <hr />
                                                    <h4>Alla bilder</h4>
                                                    <div className="poi-form-images-container">
                                                        {ui.loadingImages && (
                                                            <AdminIcon icon={AdminIcons.refreshspin} />
                                                        )}
                                                        {ui?.itemSelectedImages?.map(img => (
                                                            <span className={"poi-image-card" + (img?.id === ui.selectedImageId ? " selected" : "")} onClick={() => {
                                                                ui.setSelectedImageId(img?.id === ui.selectedImageId ? undefined : img?.id)
                                                            }}>
                                                                <JwtImage imageId={img?.id} format={"thumb"} title={Math.round(img?.length / 1024) + " kb"} />
                                                            </span>
                                                        ))}
                                                    </div>
                                                    {!!ui.selectedImageId && (
                                                        <div className="pt10">
                                                            <ToolBarClean tools={[
                                                                {
                                                                    id: "1", label: "Gör primär", onClick: async () => {
                                                                        await ui.makePrimary(ui.selectedImageId);
                                                                    }
                                                                },
                                                                {
                                                                    id: "2", label: "Radera", onClick: async () => {
                                                                        if (MessageBoxes.confirm("Radera?")) {
                                                                            await ui.deleteImage(ui.selectedImageId);
                                                                            await ui.setSelectedImageId(undefined);
                                                                        }
                                                                    }
                                                                },
                                                            ]} />
                                                        </div>
                                                    )}
                                                    <hr />
                                                    <UploadImageElement
                                                        id={ui?.itemModel?.id}
                                                        onFileUploaded={() => { ui.reloadImages(); }}
                                                    />
                                                </div>
                                            )}
                                            {ui.editState !== "edit" && (
                                                <div>
                                                    <div>
                                                        <div>Spara platsen för att ladda upp bilder</div>
                                                    </div>
                                                </div>
                                            )}
                                        </div>
                                    )}

                                    {ui.editItemState === "map" && (
                                        <div className="poi-form-map">
                                            <div>
                                                Lat: {ui.itemModel?.latitude},&nbsp;Long : {ui.itemModel?.longitude}
                                            </div>

                                            <PointOfInterestsMap
                                                value={{
                                                    latitude: ui.itemModel?.latitude ?? 0,
                                                    longitude: ui.itemModel?.longitude ?? 0
                                                }}
                                                onChange={e => ui.setItemModel({ latitude: e.latitude, longitude: e.longitude })}
                                            />
                                        </div>
                                    )}

                                </div>

                                <ToolBar tools={[
                                    { id: "save", label: ui.editState === "add" ? "Lägg till" : "Uppdatera", onClick: ui.onAddClick, icon: AdminIcons.save, },
                                    { id: "cancel", label: "Ångra", onClick: ui.onCancelAddNewClicked, icon: AdminIcons.trashcan, hidden: ui.editState !== "add" },
                                    {
                                        id: "published", label: ui.itemModel?.published ? "Avpublicera" : "Publicera", onClick: () => ui.onSetSelectedPublished(!ui.itemModel?.published),
                                        icon: ui.itemModel?.published ? AdminIcons.eyeclosed : AdminIcons.eye, disabled: ui.editState === "add", hidden: ui.editState !== "edit"
                                    },
                                    {
                                        id: "open", label: "Google Maps", onClick: () => { window.open("https://maps.google.com/maps?q=loc:" + ui.itemModel?.latitude + "," + ui.itemModel?.longitude, "_blank") },
                                        icon: AdminIcons.globe, hidden: ui.editState !== "edit"
                                    },
                                    {
                                        id: "delete", label: "Radera", onClick: () => {
                                            if (MessageBoxes.confirm("Radera " + ui.itemModel?.title + "?")) {
                                                ui.onDeleteSelected();
                                            }
                                        }, icon: AdminIcons.trashcan, disabled: ui.editState === "add", hidden: ui.editState !== "edit"
                                    },

                                ]} />
                            </div>
                        )}

                        {ui.editState === "none" && (
                            <div>
                                <button className="btn" style={{ minWidth: "100px", }} onClick={ui.onAddNewClicked}>
                                    Lägg till ny POI
                                </button>
                            </div>
                        )}


                    </div>
                </div>

            </ThreeColumnContainer>

        </div>
    )
}

const PoiHeader = () => {
    return (
        <tr>
            <th><AdminIcon icon={AdminIcons.eye} title="Publik" /></th>
            <th>Namn</th>
            <th>Typ</th>
            <th>Adress</th>
            {/* <th>Long</th> */}
            <th><AdminIcon icon={AdminIcons.mapmarker} title="Position" /></th>
            <th><AdminIcon icon={AdminIcons.globe} title="Webbsida" /></th>
            <th><AdminIcon icon={AdminIcons.image} title="Bild" /></th>
        </tr>
    )
}
const PoiRow = ({ poi, selected, onItemSelected }: { poi: PoiType, selected: boolean, onItemSelected(e: string): void }) => {
    return (
        <tr key={poi?.id} onClick={() => onItemSelected(poi?.id)} className={"cursor-pointer tr-selectable" + (selected ? " tr-selected" : "")}>
            <td>{poi?.published ? (<AdminIcon icon={AdminIcons.eye} />) : (<AdminIcon icon={AdminIcons.eyeclosed} />)}</td>
            <td>{poi?.title}</td>
            <td>{poi?.type}</td>
            <td>{poi?.addressline1}</td>
            {/* <td>{poi?.description}</td> */}
            {/* <td>{Math.round(1000 * poi?.latitude) / 1000}</td>
            <td>{Math.round(1000 * poi?.longitude) / 1000}</td> */}
            <td>{!!poi?.latitude && (<AdminIcon icon={AdminIcons.mapmarker} />)}</td>
            <td>{!!poi?.url && (<AdminIcon icon={AdminIcons.globe} />)}</td>
            <td>{(!!poi?.imageid) && (<AdminIcon icon={AdminIcons.image} />)}</td>
        </tr>
    )
}


