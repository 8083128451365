import { useEffect, useState } from "react";
import { FetchHandler } from "../../../Handlers/FetchHandler";
import { MessageBoxes } from "../../../Handlers/MessageBoxes";
import { useSignalRMessaging } from "../../../rdx/useSignalR";

export type PoiTypeType = "store" | "garden";
const poiTypeTypes: PoiTypeType[] = ["store", "garden"];
export type PoiType = {
    id?: string
    title?: string
    description?: string
    type?: PoiTypeType
    addressline1?: string
    addressline2?: string
    url?: string
    ourcomment?: string

    latitude?: number
    longitude?: number
    published?: boolean
    imageid?: string
    imageurl?: string
    sys_datecreated_utc_epoch?: number
}

type UIEditState = "none" | "add" | "edit";
type UIItemEditState = "data" | "images" | "map";
const PoiTypeDefault: PoiType = {
    type: "store", title: "", description: "", latitude: 0, longitude: 0,
    addressline1: "", addressline2: "", url: "", published: false, ourcomment: "",
    imageid: "", imageurl: ""
};


export type PoiImageType = {
    id: string
    label: string
    partition: string
    filename: string
    length: number
    contenttype: string
    dateaddedutc: string
    name: string
    imageid: string
    sys_datemodified_utc_epoch: number
}

export const usePointOfInterests = () => {

    const sign = useSignalRMessaging();

    const [pois, setPois] = useState<PoiType[]>([]);
    const [itemModel, setItemModel] = useState<PoiType>({ ...PoiTypeDefault });
    const [itemSelected, setItemSelected] = useState<string>(undefined);
    const [itemSelectedImages, setItemSelectedImages] = useState<PoiImageType[]>(undefined);

    const [editState, setEditState] = useState<UIEditState>("none");
    const [editItemState, setEditItemState] = useState<UIItemEditState>("data");
    const [poiFilter, setPoiFilter] = useState<string>("");
    const [poiTypeFilter, setPoiTypeFilter] = useState<string[]>([]);
    const [filterOnlyPublished, setfilterOnlyPublished] = useState<boolean>(false);
    const [saving, setSaving] = useState<boolean>(false);
    const load = async (selectId?: string) => {
        let data: PoiType[] = await FetchHandler.getJson("/api/poi");
        data.sort((a, b) => {
            if (a?.title > b?.title) { return 1; }
            else if (a?.title < b?.title) { return -1; }
            else { return 0 }
        });
        setPois(data.map(m => { return { ...PoiTypeDefault, ...m }; }));
        if (selectId) {
            setItemSelected(selectId);
            setEditState("edit");
            setItemModel({ ...PoiTypeDefault, ...data.find(m => m?.id == selectId) });
        }
    }

    useEffect(() => {
        load();
    }, []);

    const setModel = (id?: string) => {
        if (!id) {
            setItemSelected(undefined);
            setEditState("none");
            setItemModel({ ...PoiTypeDefault })
        } else {
            setItemSelected(id);
            setEditState("edit");
            setItemModel({ ...PoiTypeDefault, ...pois.find(m => m?.id == id) });
        }
    }

    const onItemSelected = (id: string, override?: boolean) => {

        if (editState === "add" && !(override === true)) {
            const cancel = MessageBoxes.confirm("Avbryt?");
            if (!cancel) {
                return;
            }
        }

        if (id === itemSelected) {
            setModel();
            setItemSelectedImages([]);
        } else {
            setModel(id);
            loadImages(id);
            setSelectedImageId(undefined)
        }
    }

    const [loadingImages, setLoadingImages] = useState<boolean>(false)
    const loadImages = async (id?: string) => {
        setLoadingImages(true);
        if (id) {
            const data = await FetchHandler.getJson("/api/poi/" + id + "/images");
            setItemSelectedImages(data);
        } else {
            setItemSelectedImages([]);
        }
        setLoadingImages(false);
    }

    const [selectedImageId, setSelectedImageId] = useState<string>(undefined);

    return {
        pois: pois?.filter(m => {
            return (!poiFilter || (m?.title.toLowerCase().includes(poiFilter?.toLowerCase()))) &&
                (poiTypeFilter?.length == 0 || (poiTypeFilter.indexOf(m?.type) >= 0)) &&
                (filterOnlyPublished == false || (filterOnlyPublished === true && m?.published))
                ;
        }),
        editState: editState,
        editItemState: editItemState, setEditItemState: setEditItemState,
        itemModel: itemModel, setItemModel: (e: PoiType) => {
            setItemModel({ ...PoiTypeDefault, ...itemModel, ...e });
        },
        onItemSelected: onItemSelected,
        saving: saving,
        // Images
        itemSelectedImages: itemSelectedImages,
        reloadImages: () => loadImages(itemSelected),
        selectedImageId: selectedImageId,
        setSelectedImageId: setSelectedImageId,
        loadingImages: loadingImages,
        deleteImage: async (id: string) => {
            const url = "/api/poi/" + itemSelected + "/images/" + id
            await FetchHandler.delete(url);
            await loadImages(itemSelected);
            sign.action("poi - deleteImage");
        },
        makePrimary: async (id: string) => {
            const url = "/api/poi/" + itemSelected + "/makeprimary"
            await FetchHandler.patchJson(url, { imageid: id });
            setItemModel({ ...itemModel, imageid: id });


            let temp = [...pois];
            for (let i = 0; i < temp.length; i++) {
                if (temp[i].id == itemModel?.id) {
                    temp[i].imageid = id;
                    continue;
                }
            }
            setPois(temp);
            sign.action("poi - makePrimary");
        },
        removePrimary: async () => {
            const url = "/api/poi/" + itemSelected + "/removeprimary"
            await FetchHandler.patchJson(url, {});
            setItemModel({ ...itemModel, imageid: undefined });

            let temp = [...pois];
            for (let i = 0; i < temp.length; i++) {
                if (temp[i].id == itemModel?.id) {
                    temp[i].imageid = undefined;
                    continue;
                }
            }
            setPois(temp);
            sign.action("poi - removePrimary");
        },

        itemSelected: itemSelected,
        onDeleteSelected: async () => {
            const success = await FetchHandler.delete("/api/poi/" + itemSelected);
            if (success) {
                setModel();
                load();
                MessageBoxes.info("POI raderades!")
            }
        },
        onSetSelectedPublished: async (published: boolean) => {
            const success = await FetchHandler.patchJson("/api/poi/" + itemSelected + "/published", { published: published });
            if (success) {
                load().then(() => setModel(itemSelected));
                MessageBoxes.info("POI uppdaterades!")
            }
        },
        onAddClick: async () => {
            setSaving(true);
            let success: PoiType = undefined;
            if (editState === "add") {
                success = await FetchHandler.postJson("/api/poi", itemModel);

                if (success?.id) {
                    setModel();
                    load(success?.id);
                    MessageBoxes.info("POI lades till!")

                } else {
                    MessageBoxes.warning("failed")
                }

            } else {
                success = await FetchHandler.patchJson("/api/poi", itemModel);
                if (!success) {
                    MessageBoxes.warning("failed")
                } else {
                    load();
                }
            }
            setSaving(false);
        },
        onAddNewClicked: () => { setEditState("add") },
        onCancelAddNewClicked: () => {
            setEditState("none");
            setModel();
        },
        onSetFilter: (filter: string) => {
            setPoiFilter(filter);
        },
        filterOnlyPublished: filterOnlyPublished, setfilterOnlyPublished: setfilterOnlyPublished,
        poiTypeTypes: poiTypeTypes,
        poiTypeFilter: poiTypeFilter, setPoiTypeFilter: setPoiTypeFilter,
    }
}