import React from 'react';
import { UserRolesType, useUserRoles } from '../../hooks/MiscHooks';


type HasRoleprops = { role: UserRolesType, children: React.ReactNode }
export const HasRole = ({ role, children }: HasRoleprops) => {
  const userRoles = useUserRoles();
  if (!userRoles.hasRole(role)) {
    return (null);
  }

  return (
    <>
      {children}
    </>
  )
}

type HasAnyRoleprops = { roles: UserRolesType[], children: React.ReactNode }
export const HasAnyRole = ({ roles, children }: HasAnyRoleprops) => {
  const userRoles = useUserRoles();
  if (!userRoles.hasAnyOfRoles(roles)) {
    return (null);
  }

  return (
    <>
      {children}
    </>
  )
}