import { Routes } from "../../Handlers/ViewHandler";
// const menu = useElasticMenu();
// <ScreenMenu items={menu?.items} />
export const useElasticMenu = () => {

    const items = [
        { id: "a", label: "Sök Växt", link: Routes.elasticsearchplants },
        { id: "b", label: "Sök användare", link: Routes.elasticsearchusers },
        { id: "c", label: "Sök blog", link: Routes.elasticsearchblogs },
        { id: "d", label: "Sök POI", link: Routes.elasticsearchpois }, ,
        { id: "e", label: "Sök Inlägg", link: Routes.elasticsearchposts },
    ];

    return {
        items: items,
    }
}