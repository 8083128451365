import React, { Component } from 'react';
import { DateTime } from "luxon";
import { FetchHandler } from "../../Handlers/FetchHandler";
import { efn } from "../../Handlers/ElementFunctions";
import { LoadingIcon, LinkIcon, TrashIcon } from "../Shared/Icons";
import { LoadingElement } from "../Shared/LoadingElement";
import { MessageBoxes } from '../../Handlers/MessageBoxes';
import { AdminIcon, AdminIcons } from '../Shared/AdminIcon';
import { ScreenHeader } from '../UI/Elements';


interface IProps {
}

interface IState {
  images: any[],
  uploading: boolean,
  updating: boolean
}


export class Images extends Component<IProps, IState> {
  static displayName = Images.name;

  mounted: boolean;

  constructor(props) {
    super(props);
    this.mounted = false;
    this.state = {
      images: [],

      uploading: false,
      updating: false
    };


    this.filechanged = this.filechanged.bind(this);
    this.reload = this.reload.bind(this);
    this.onDeleteClick = this.onDeleteClick.bind(this);
  }
  onDeleteClick(e) {
    const del = MessageBoxes.confirm("Är du säker?");
    if (del) {
      const id = efn.getElementDataset(e.target, "id");
      this.setState({ updating: true });

      FetchHandler.postJson("/api/images/delete", { id: id }).then(response => {
        if (this.mounted) {
          if (response) {
            this.reload();
          } else {
            MessageBoxes.warning("Delete failed");
          }
        }
      });
    }
  }

  filechanged(e) {
    const files = Array.from(e.target.files)
    this.setState({ uploading: true })
    const formData = new FormData()

    files.forEach((file: Blob, i: number) => {
      formData.append("image", file)
    })

    fetch("/api/images/upload", {
      method: 'POST',
      body: formData
    })
      .then(res => res.json())
      .then(images => {
        this.setState({
          uploading: false
        });
        this.reload();
      })
  }

  reload() {
    this.setState({ updating: true });
    FetchHandler.getJson("/api/images").then(images => {
      if (this.mounted) {
        this.setState({ images: images, updating: false });
      }
    });
  }

  componentDidMount() {
    this.mounted = true;
    this.reload();
  }

  componentWillUnmount() {
    this.mounted = false;
  }



  render() {
    return (
      <div>
        <div>
          <ScreenHeader text="Bilder" icon={AdminIcons.images} />
          <div>
            <form>
              <input type="file" id="image" onChange={this.filechanged} />

              {this.state.uploading && (
                <LoadingIcon />
              )}

            </form>
          </div>

        </div>

        {this.state.updating && (
          <div className="center">
            <LoadingElement />
          </div>
        )}

        <div className={"image-container " + (this.state.updating ? "updating" : "")}>
          {this.state.images.map(i => (
            <ImageElement key={i.id} {...i} onDeleteClick={this.onDeleteClick} />
          ))}

        </div>

      </div>
    );
  }
}


const ImageElement = (props) => {
  return (
    <div className="image">
      <div className="img-container">
        <img src={props.url} alt={props.title} loading="lazy" />
      </div>
      <div className="toolbox">
        <div className="date" >{DateTime.fromISO(props.dateAdded).toRelative()}</div>
        <div className="size">{Math.round(props.length / 1024)} kb</div>

        <div className="buttons">
          <button className="btn-icon left" onClick={() => { MessageBoxes.info(props.url, true) }}>
            <LinkIcon />
          </button>
          <button className="btn-icon" data-id={props.id} onClick={props.onDeleteClick}>
            <TrashIcon />
          </button>
        </div>
      </div>
    </div>
  )
}