import React, { useEffect, useState } from "react"
import { FetchHandler } from "../Handlers/FetchHandler";
import { MessageBoxes } from "../Handlers/MessageBoxes";
import { AdminIcon, AdminIcons } from "./Shared/AdminIcon"
import { ThreeColumnContainer, TwoColumnContainer } from "./Shared/Formelements";
import { SettingsStore, SystemSettingsKeys, useSystemSettings } from "./SystemObjects/useSystemSettings";
import { ConfirmDelete, EditableText, ScreenHeader, SearchBox } from "./UI/Elements"
import { JwtImage } from "./UI/ImageElements";
import { SearchUserType, useUserLists } from "./UserLists.useUserLists";



export const UserLists = () => {

    const ui = useUserLists(true);

    return (
        <div className="view-userlists">
            <ScreenHeader text="Användarlistor" icon={AdminIcons.users} />
            <ThreeColumnContainer gridTemplateColumns="600px 500px auto">
                <div>
                    <table className="tbl">
                        <thead>
                            <tr>
                                <th></th>
                                <th>Namn</th>
                                <th></th>
                                <th></th>
                            </tr>
                        </thead>
                        <tbody>
                            {ui.data?.map(d => {
                                const selected = ui.selectedList == d?.id;
                                return (
                                    <tr key={d?.id} className={selected ? "selected-list" : ""}>
                                        <td>
                                            <button className="btn" onClick={() => ui.setSelectedList(d?.id)}>
                                                Välj
                                            </button>
                                        </td>
                                        <td>
                                            <EditableText text={d?.name} onChange={e => { ui.updateName(d?.id, e) }} />
                                        </td>
                                        <td>
                                            {ui.defaultFollowList === d?.id && (
                                                <span>
                                                    Följförslag
                                                </span>
                                            )}
                                            {(ui.defaultFollowList !== d?.id && selected) && (
                                                <button className="btn" onClick={() => ui.makeDefaultFollowList(d?.id)}>
                                                    Gör till följförslag
                                                </button>
                                            )}
                                        </td>
                                        <td className="w200px right">
                                            {(ui.defaultFollowList !== d?.id) && (
                                                <ConfirmDelete onDelete={() => { ui.onDelete(d?.id); }} />
                                            )}
                                        </td>
                                    </tr>
                                );
                            })}
                        </tbody>
                    </table>

                    <hr />

                    <div>
                        <input type="text" value={ui.inputValue} onChange={e => ui.inputChange(e.target.value)} />
                        <button className="btn" onClick={() => ui.add()}>
                            Lägg till
                        </button>

                    </div>

                </div>

                <div>


                    <table className="tbl">
                        <thead>
                            <tr>
                                <th>Profil</th>
                                <th>Trädgård</th>
                                <th>Namn</th>
                                <th>Trädgårdsnamn</th>
                            </tr>
                        </thead>
                        <tbody>
                            {ui.listUsers?.map(u => (
                                <ProfileRow
                                    key={u?.id}
                                    u={u}
                                    addUser={null}
                                    removeUser={() => { ui.removeUserFromList(u?.id) }}
                                />
                            ))}
                        </tbody>

                    </table>

                </div>

                <div>
                    <SearchBox onSearch={e => { ui.search(e); }} />
                    <hr />
                    <table className="tbl">
                        <thead>
                            <tr>
                                <th>Profil</th>
                                <th>Trädgård</th>
                                <th>Namn</th>
                                <th>Trädgårdsnamn</th>
                            </tr>
                        </thead>
                        <tbody>
                            {ui.searchHits?.map(u => (
                                <ProfileRow
                                    key={u?.id}
                                    u={u}
                                    addUser={() => { ui.addUserToList(u?.id) }}
                                    removeUser={null}
                                    disabled={!!ui.listUsers.find(m => m.id === u.id)}
                                />
                            ))}
                        </tbody>

                    </table>
                </div>

            </ThreeColumnContainer>


        </div>
    )
}

const ProfileRow = ({ u, addUser, removeUser, disabled }: { u: SearchUserType, addUser(): void, removeUser(): void, disabled?: boolean }) => {

    const [open, setOpen] = useState<boolean>(false);

    const toggleOpen = () => {
        setOpen(!open)
    };

    return (
        <>
            <tr key={u?.id} className="cursor-pointer">
                <td style={{ width: "50px" }} onClick={toggleOpen}>
                    <div style={{ width: "30px" }}>
                        <JwtImage imageId={u?.profileimage} format="thumb" />
                    </div>
                </td>
                <td style={{ width: "50px" }} onClick={toggleOpen}>
                    <div style={{ width: "30px" }}>
                        <JwtImage imageId={u?.gardenImageId} format="thumb" />
                    </div>
                </td>
                <td onClick={toggleOpen}>
                    {u?.name}
                </td>
                <td onClick={toggleOpen}>
                    {u?.gardenName}
                </td>
                <td>
                    {(!!removeUser && disabled !== true) && (
                        <div className="ico-button" onClick={removeUser}>
                            <AdminIcon icon={AdminIcons.trashcan} />
                        </div>
                    )}
                    {(!!addUser && disabled !== true) && (
                        <div className="ico-button" onClick={addUser}>
                            <AdminIcon icon={AdminIcons.plus} />
                        </div>
                    )}
                </td>
            </tr>
            {open && (
                <>
                    <tr>
                        <td colSpan={5}>
                            <div className="image-large-container">
                                <JwtImage imageId={u?.profileimage} format="thumb" />
                            </div>
                            <div className="image-large-container">
                                <JwtImage imageId={u?.gardenImageId} format="thumb" />
                            </div>
                        </td>
                    </tr>
                    <tr />
                </>
            )
            }

        </>
    )
}
