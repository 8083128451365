import { useLayoutEffect, useState } from "react";
import { useSelector, useDispatch } from 'react-redux';
import { convertCompilerOptionsFromJson, textChangeRangeIsUnchanged } from "typescript";
import { FetchHandler } from "../Handlers/FetchHandler";
import { MessageBoxes } from "../Handlers/MessageBoxes";
import { UsersInfoStoreType } from "../rdx/Redux.DataReducerType";
import { RdxStore } from "../rdx/ReduxTypes";
import { CHANGE_TOOLS_USERPLANTS, UserPlantStates, UserPlantType } from "../rdx/toolsreducer";
import { useFetch } from "./MiscHooks";
import { useUsers } from "./useUsers";

export const useAllUserPlants = () => {
    //const { data, loading, error, duration } = useFetch('/api/userplants');
    const userplants = useSelector((state: RdxStore) => state.tools.userplants);
    const users = useUsers();

    const states: UserPlantStates[] = ["none", "hide", "onhold", "replacementsent"];
    const [selectedState, setSelectedState] = useState<UserPlantStates>("none");

    const [loading, setLoading] = useState(null);

    const [selectedPlantId, setSelectedPlantId] = useState<string>(null);
    const dispatch = useDispatch();


    const loadItems = async (state: UserPlantStates) => {

        const data = await FetchHandler.getJson('/api/userplants/' + state);
        // Transform plantdata
        let d = { ...userplants };
        data.data.forEach((item: UserPlantType) => {
            d[item.id] = { ...item, state: item?.state ? item?.state : "none" };
        });
        dispatch({ type: CHANGE_TOOLS_USERPLANTS, payload: d });

        // Transform plantdata
        const u: string[] = Array.from(new Set(data?.data.map(obj => obj?.userid)));
        users.ensureUserInfo(u);

    }

    useLayoutEffect(() => {
        setLoading(true);
        loadItems(selectedState).then(() => {
            setLoading(false);
        })
    }, [selectedState]);

    const [data, setData] = useState<UserPlantType[]>([]);
    useLayoutEffect(() => {
        const alld: UserPlantType[] = userplants ? Object.keys(userplants).map(key => userplants[key]) : [];
        const d = alld.filter((obj: UserPlantType) => {
            if (!selectedState) {
                return true;
            } else {
                return obj.state === selectedState;
            }
        });
        setData(d);
    }, [selectedState, userplants])


    return {
        // Data
        data: data,
        numberOfItems: data?.length,
        loading: loading,
        error: "",
        duration: 0,
        selectedPlantId: selectedPlantId,
        setSelectedPlantId: setSelectedPlantId,
        // Actions
        itemClicked: (id: string) => {
            if (id === selectedPlantId) {
                setSelectedPlantId(null)
            } else {
                setSelectedPlantId(id)
            }
        },
        // States
        plantStates: states,
        selectedState: selectedState,
        setSelectedState: (state: UserPlantStates) => { console.log(state); setSelectedState(state); },
        //setSelectedState: setSelectedState,
    }
}

type UserImageType = {
    approved: boolean,
    contenttype: string,
    dateaddedutc: string,
    filename: string,
    id: string,
    label: string,
    length: number,
    name: string,
    partition: string,
    plantid: string,
    sys_datecreated_utc_epoch: number,
    taguser: boolean,
    userid: string
}

export const useUserPlants = (userid: string, gardenid: string, plantid: string) => {

    const userplants: { [key: string]: UserPlantType } = useSelector((state: RdxStore) => state.tools.userplants);
    const plant: UserPlantType = userplants?.[plantid];
    const dispatch = useDispatch();

    const [images, setImages] = useState<UserImageType[]>([]);
    const [loadingImages, setLoadingImages] = useState<boolean>(false);
    const [previewId, setPreviewId] = useState<string>(null);

    useLayoutEffect(() => {

        setLoadingImages(true);
        const url = "api/userplants/" + plant.userid + "/" + plant.partition + "/" + plant.id + "/images";
        FetchHandler.getJson(url).then(imgs => {
            setPreviewId(null);
            setImages(imgs);
            console.log(imgs);
            setLoadingImages(false);
        });


    }, []);






    const updateItem = (prop: { state: UserPlantStates }) => {
        const backup = { ...userplants };
        const d: UserPlantType = { ...userplants?.[plantid], ...prop };
        const n = { ...userplants, [plantid]: { ...d } };
        dispatch({ type: CHANGE_TOOLS_USERPLANTS, payload: n });

        if (!!prop.state) {
            const url = "/api/userplants/" + encodeURIComponent(plant.partition) + "/" + plant.id + "/setstate";
            FetchHandler.postJson(url, { state: prop.state }).then(success => {
                if (success !== true) {
                    dispatch({ type: CHANGE_TOOLS_USERPLANTS, payload: backup });
                    MessageBoxes.warning("failed")
                }
            })
        }
    }

    return {
        // Data
        data: userplants?.[plantid],
        plantState: userplants?.[plantid]?.state,
        loading: false,
        images: images,
        loadingImages: loadingImages,
        // SetState
        setOnhold: async (): Promise<boolean> => {
            updateItem({ state: "onhold" });
            return false;
        },
        setHide: async (): Promise<boolean> => {
            updateItem({ state: "hide" });
            return false;
        },
        setReplacementSent: async (): Promise<boolean> => {
            updateItem({ state: "replacementsent" });
            return false;
        },

        previewId: previewId,
        previewVisible: !!previewId,
        previewHide: () => {
            setPreviewId(null)
        },
        previewShow: (id: string) => {
            setPreviewId(id)
        },

    }
}