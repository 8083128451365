import { Routes } from "../../Handlers/ViewHandler";
import { useUserRoles } from "../../hooks/MiscHooks";
import { useUser } from "../../hooks/useUser";

export const useStatisticsMenu = () => {
    const userRoles = useUserRoles();

    const isDev = userRoles.hasRole("DEVELOPER");

    const items = isDev ? [
        { id: "a", label: "Prenumerationer statistik", link: Routes.statistics },
        { id: "b", label: "Senast inloggade användare", link: Routes.userstatistics },
        { id: "c", label: "Statistik per kommun", link: Routes.citystatistics },
        { id: "d", label: "Aktiva Admin användare", link: Routes.signalradmin },
        { id: "e", label: "Misc", link: Routes.miscstatistics },
    ] : [
        { id: "a", label: "Prenumerationer statistik", link: Routes.statistics },
        { id: "b", label: "Senast inloggade användare", link: Routes.userstatistics },
        { id: "c", label: "Misc", link: Routes.miscstatistics },
    ];

    return {
        items: items,
    }
}