import React, { Component, useState } from 'react';
import { useSelector } from 'react-redux';
import { FetchHandler } from '../../Handlers/FetchHandler';
import { TwoColumnContainer } from "../Shared/Formelements";
import { MessageBoxes } from "../../Handlers/MessageBoxes";
import { StoreFillerHandler } from "../../rdx/StoreFillerHandler";
import { ConfirmDelete } from '../UI/Elements';

interface IProps { }
interface IState {

}

export class PlantTags extends Component<IProps, IState> {
    static displayName = PlantTags.name;

    state: any;

    constructor(props) {
        super(props);
        this.state = {};

        this.addTag = this.addTag.bind(this);
        this.removeTag = this.removeTag.bind(this);

    }

    addTag(tag) {
        console.log("add:", tag);
        FetchHandler.postJson("/api/planttags/add", { tag: tag }).then(result => {
            if (result) {
                StoreFillerHandler.ensureCache(true);
            } else {
                MessageBoxes.warning("Failed!");
            }
        });
    }
    removeTag(tag: string) {
        console.log("Remove:", tag);

        FetchHandler.postJson("/api/planttags/delete", { tag: tag }).then(result => {
            if (result) {
                StoreFillerHandler.ensureCache(true);
            } else {
                MessageBoxes.warning("Failed!");
            }
        });
    }

    render() {
        return (
            <div>
                <TwoColumnContainer>
                    <Tags addTag={this.addTag} removeTag={this.removeTag} />
                </TwoColumnContainer>
            </div>
        )
    }
}

const Tags = (props) => {
    const tags = useSelector(state => state.data.planttags);
    const [input, setInput] = useState("");
    const [valid, setValid] = useState(true);

    const inputChanged = (e) => {
        var val = e.target.value;
        setInput(val);
        if (val && tags.findIndex(el => el.tag.toLowerCase().trim() === val.toLowerCase().trim()) >= 0) {
            setValid(false);
        } else {
            setValid(true);
        }
    }

    return (<div>

        <table className="tbl">
            <thead>
                <tr>
                    <th>Tag</th>
                    <th></th>
                </tr>
            </thead>
            <tbody>
                {tags.map(t => (
                    <tr key={t.id}>
                        <td>{t.tag}</td>
                        <td className="right">
                            <ConfirmDelete onDelete={() => { props.removeTag(t.id) }} />
                        </td>
                    </tr>))}
                <tr>
                    <td>
                        <input type="text" value={input} onChange={inputChanged} className={!valid ? "red-border" : ""} />
                    </td>
                    <td className="right">
                        <button onClick={() => { props.addTag(input); setInput(""); }} disabled={!valid}>
                            Lägg till tag
                        </button>

                    </td>
                </tr>
            </tbody>
        </table>
    </div>)
}
