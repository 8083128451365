import { AdReducerType } from "./ReduxTypes";


const CHANGE_AD_CLIENTS = "CHANGE_AD_CLIENTS";
const CHANGE_AD_ADS = "CHANGE_AD_ADS";

const appReducerStore: AdReducerType = {
    clients: { ohgarden: { id: "ohgarden", name: "Oh!Garden AB" } },
    ads: {}
};


const adReducer = (state = { ...appReducerStore }, action) => {
    switch (action.type) {
        case CHANGE_AD_CLIENTS: { state = { ...state, clients: action.payload }; break; }
        case CHANGE_AD_ADS: { state = { ...state, ads: action.payload }; break; }
        default: { return state; }
    }
    return state;
};

export {
    adReducer,
    CHANGE_AD_CLIENTS, CHANGE_AD_ADS,
}