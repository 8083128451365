import store from "./store";
import { FetchHandler } from "../Handlers/FetchHandler";
import {
    CHANGE_COLOR_LIST,
    CHANGE_PLANTFIELD_LIST, CHANGE_PLANTFIELD_OBJECT,
    CHANGE_PLANTTYPES_LIST, CHANGE_PLANTTYPES_OBJECT,
    CHANGE_PLANTTAGS_LIST, CHANGE_PLANTTAGS_OBJECT,
    CHANGE_PLANTLISTS, CHANGE_SNIPPETS, CHANGE_PLANTLISTS_LINKED_TO_OBJECT
} from "./dataReducer";

export const Reloads = {
    reloadColors: () => {
        FetchHandler.getJson("/api/plantinfo/colors").then(data => {
            const c = data.map(o => {
                return {
                    id: o.id,
                    name: o.name,
                    hexNumber: o.hexNumber,
                    selectable: o.selectable
                }
            });
            store.dispatch({ type: CHANGE_COLOR_LIST, payload: c });
        })
    },
    reloadPlantFields: () => {
        FetchHandler.getJson("/api/plantfields").then(plantfields => {
            store.dispatch({ type: CHANGE_PLANTFIELD_LIST, payload: plantfields });
            const fields_object = plantfields.reduce((acc, curr) => (acc[curr.id.toLowerCase()] = curr, acc), {});
            store.dispatch({ type: CHANGE_PLANTFIELD_OBJECT, payload: fields_object });
        })
    },
    reloadPlantTypes: () => {
        FetchHandler.getJson("/api/planttypes").then(data => {
            store.dispatch({ type: CHANGE_PLANTTYPES_LIST, payload: data });
            const planttypesobject = data
                .map(m => { return { id: m.id, name: m.name } })
                .reduce((acc, curr) => (acc[curr.id] = curr, acc), {});
            store.dispatch({ type: CHANGE_PLANTTYPES_OBJECT, payload: planttypesobject });
        })
    },
    reloadPlantTags: () => {
        FetchHandler.getJson("/api/planttags").then(data => {
            store.dispatch({ type: CHANGE_PLANTTAGS_LIST, payload: data });

            const planttagsobject = data.reduce((acc, curr) => (acc[curr.id] = curr.tag, acc), {});
            store.dispatch({ type: CHANGE_PLANTTAGS_OBJECT, payload: planttagsobject });
        })
    },
    reloadPlantlists: () => {
        FetchHandler.getJson("/api/plantlist").then(data => {
            let model = data.reduce((acc, curr) => (acc[curr.id] = curr, acc), {});
            model.loaded = true;
            store.dispatch({ type: CHANGE_PLANTLISTS, payload: model });
        })
    },
    reloadSnippets: async () => {
        const data = await FetchHandler.getJson("/api/snippet");
        let model = data.reduce((acc, curr) => (acc[curr.id] = curr, acc), {});
        store.dispatch({ type: CHANGE_SNIPPETS, payload: model });
        return true;
    },

    reloadObjectPlantlistLinks: async () => {
        const listOfLinks = await FetchHandler.getJson("/api/plantlist/linkedtobject");
        let data = {};
        listOfLinks.forEach(item => {
            if (data[item.objectId]) {
                data[item.objectId].push(item.listId);
            } else {
                data[item.objectId] = [item.listId];
            }
        });
        store.dispatch({ type: CHANGE_PLANTLISTS_LINKED_TO_OBJECT, payload: data });
        return true;
    },
    // api/plantlist/linkedtobject
}

export const reloadPlantlists = () => {
}


export const StoreFillerHandler = {
    ensureCache: (force?: boolean) => {
        const data = store.getState().data;

        if (data.colors.length === 0 || (force === true)) {
            Reloads.reloadColors();
        }

        if (data.plantfields.length === 0 || (force === true)) {
            Reloads.reloadPlantFields();
        }

        if (data.planttypes.length === 0 || (force === true)) {
            Reloads.reloadPlantTypes();
        }

        if (data.planttags.length === 0 || (force === true)) {
            Reloads.reloadPlantTags();
        }

        if (!data.plantlists?.loaded) {
            Reloads.reloadPlantlists();
        }

        if (Object.keys(data.snippets).length <= 0) {
            Reloads.reloadSnippets();
        }

        if (Object.keys(data.plantlistslinkedtobject).length <= 0) {
            Reloads.reloadObjectPlantlistLinks();
        }

    }
}