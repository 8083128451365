import { useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { FetchHandler } from '../../Handlers/FetchHandler';
import { MessageBoxes } from '../../Handlers/MessageBoxes';
import { RdxStore } from '../../rdx/ReduxTypes';
import { CHANGE_MONTHLY_ACTIVITIES_ADMIN_UI } from '../../rdx/toolsreducer';

// ----------------------------- CONST -----------------------------
const months = [1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12];

// ----------------------------- TYPE -----------------------------
export type MonthlyActivitiesAdminUIType = {
    monthlyActivities?: MonthlyActivityStoreType,
    currentMonthlyActivity?: MonthlyActivityType,
}

export type MonthlyActivityStoreType = { [key: string]: MonthlyActivityType }
export type MonthlyActivityType = {
    id?: string
    name?: string,
    periodNumber?: number // 1 -> 12
    header?: string
    text?: string
    imageid?: string
    internallink?: string
    externallink?: string
    lines?: MonthlyActivityLineType[],
    images?: GenericImageType[]
}

export type GenericImageType = {
    id: string
    filename: string
    length: number
    contenttype: string
    dateaddedutc: string
    name: string
    imageid: string
    sys_datemodified_utc_epoch: number
    internallink?: string
    externallink?: string
}
export type MonthlyActivityLineType = {
    linenumber: number
    header?: string
    text?: string
    imageid?: string
    internallink?: string
    externallink?: string
}


const getDefaultMonthlyActivity = (month: number) => {
    return {
        id: "-",
        name: "Nytt namn",
        periodNumber: month,
        header: "Ny rubrik",
        text: "Ny text",
        imageid: undefined,
        lines: [],
        internallink: undefined,
        externallink: undefined,
    }
}

// ----------------------------------------------------------------
// ----------------------------- HOOK -----------------------------
// ----------------------------------------------------------------
export const useMonthlyActivitiesAdmin = () => {

    // Rdx
    const dispatch = useDispatch();
    const ui: MonthlyActivitiesAdminUIType = useSelector((state: RdxStore) => state.tools.monthlyActivitiesAdminUI);
    const monthlyActivities = ui?.monthlyActivities;
    const api = useMonthlyActivitiesAdminApi();
    const currentMonthlyActivity = ui?.currentMonthlyActivity;

    const editUi = (e: MonthlyActivitiesAdminUIType) => {
        dispatch({ type: CHANGE_MONTHLY_ACTIVITIES_ADMIN_UI, payload: { ...ui, ...e } });
    }

    const editCurrent = (e: MonthlyActivityType) => {
        console.log("editCurrent...", e)
        const change: MonthlyActivitiesAdminUIType = { ...ui, currentMonthlyActivity: { ...currentMonthlyActivity, ...e } }
        dispatch({ type: CHANGE_MONTHLY_ACTIVITIES_ADMIN_UI, payload: change });
        setChanged(true);
    }
    // --- Rdx

    const [changed, setChanged] = useState<boolean>(false);
    const [lineEditing, setLineEditing] = useState<MonthlyActivityLineType>(undefined);

    const loadData = async () => {
        setIsLoading(true);
        const data: MonthlyActivityStoreType = await api.getAll();
        editUi({ monthlyActivities: data });
        setIsLoading(false);
    }

    const [isSaving, setIsSaving] = useState<boolean>(false);
    const [isLoading, setIsLoading] = useState<boolean>(false);

    const saveActivity = async (): Promise<boolean> => {
        setIsSaving(true);
        const postObj = { ...currentMonthlyActivity };
        const success: boolean = await api.save(postObj);
        await loadData();
        setIsSaving(false);
        setChanged(false);

        return success;
    }

    return {
        loadData: loadData,
        isSaving: isSaving,
        isLoading: isLoading,

        changed: changed,
        save: saveActivity,
        // ----------------- Lines -----------------
        addline: (e) => {
            let lines = [...currentMonthlyActivity?.lines];
            var id = (lines?.length ?? 0) + 1;

            const n: MonthlyActivityLineType = {
                linenumber: id,
                header: "Rubrik " + id,
                text: "Text " + id,
                imageid: undefined
            };

            editCurrent({ lines: [...lines, n] });
            setChanged(true);

        },
        // Select lines
        setSelectedLineNumber: (e: number) => {
            let lines = [...currentMonthlyActivity?.lines];
            setLineEditing(lines.find(m => m?.linenumber === e));
        },
        // line current editing
        lineEditing: lineEditing,

        // End editing.
        editLine: (e: MonthlyActivityLineType) => {
            console.log("editLine:", e);
            setLineEditing({ ...lineEditing, ...e });
        },
        saveLine: () => {
            let lines = [...currentMonthlyActivity?.lines];
            const idx = lines.findIndex(m => m?.linenumber === lineEditing?.linenumber);
            lines[idx] = { ...lineEditing };
            console.log(idx, lines)
            editCurrent({ lines: lines });
            setLineEditing(undefined);
            setChanged(true);
        },

        removeLine: () => {
            const linenumber = lineEditing?.linenumber;
            console.log("Remove linenumber:", linenumber);
            let lines = [...currentMonthlyActivity?.lines];
            editCurrent({ lines: [...lines.filter(m => m?.linenumber !== linenumber)] });
            setLineEditing(undefined);
            setChanged(true);
        },

        // Data
        months: months,
        monthlyActivities: monthlyActivities,

        onItemSelect: async (month: number) => {

            if (changed) {
                if (!MessageBoxes.confirm("Du har ändrat aktiviteten, vill du fortfarande byta?")) {
                    return;
                }
            }

            console.log("onItemSelect: ", month);
            const a = Object.keys(monthlyActivities ?? {}).map(key => monthlyActivities?.[key]);
            const m = a?.find(m => m?.periodNumber === month);
            if (!!m) {
                const imgs = await api.getImages(m?.id);
                editCurrent({ ...m, images: imgs });
            } else {
                editCurrent(getDefaultMonthlyActivity(month))
            }
            setChanged(false);
        },
        loadImages: async () => {
            const imgs = await api.getImages(currentMonthlyActivity?.id);
            editCurrent({ ...currentMonthlyActivity, images: imgs });
        },
        deleteImage: async (id: string): Promise<boolean> => {
            if (MessageBoxes.confirm("Radera?")) {
                const bak = { ...currentMonthlyActivity };
                let change = { ...currentMonthlyActivity };
                if (change.imageid === id) {
                    change.imageid = undefined;
                }
                change.lines.forEach(l => {
                    if (l?.imageid === id) {
                        l.imageid = undefined;
                    }
                });
                const idx = change.images.findIndex(m => m?.id === id);
                change.images.splice(idx, 1);
                editCurrent(change);
                setChanged(true);
                const success = await api.deleteImage(change?.id, id);
                if (success) {
                    return success;
                } else {
                    editCurrent(bak);
                    MessageBoxes.warning("Bilden togs inte bort")
                }
            }
        },
        selectedItem: currentMonthlyActivity,
        editSelected: editCurrent,
    }
}

const useMonthlyActivitiesAdminApi = () => {
    return {
        getAll: async (): Promise<MonthlyActivityStoreType> => {
            const data: MonthlyActivityStoreType = await FetchHandler.getJson("/api/monthlyactivities");
            return data;
        },
        getImages: async (id: string): Promise<GenericImageType[]> => {
            const images: GenericImageType[] = await FetchHandler.getJson("/api/poi/" + encodeURIComponent(id) + "/images");
            return images;
        },
        save: async (obj: MonthlyActivityType): Promise<boolean> => {
            return await FetchHandler.postJson("/api/monthlyactivities/save", obj);
        },
        deleteImage: async (objectId: string, imageId: string): Promise<boolean> => {
            const url = "api/poi/" + objectId + "/images/" + imageId;
            return await FetchHandler.delete(url);
        }
    }
}