import React, { useEffect, useState } from "react"
import { LoadingBar, Padder, ScreenHeader } from "../UI/Elements"
import { AdminIcon, AdminIcons } from "../Shared/AdminIcon"
import { FetchHandler } from "../../Handlers/FetchHandler"
import { IAllResult, IPlantNetCacheItem } from "./PlantNetTypes"
import { JwtImage } from "../UI/ImageElements"
import { useDateHelper } from "../../hooks/useHelper"
import { usePaging } from "../../hooks/usePaging"
import { useUsers } from "../../hooks/useUsers"

export const PlantNetList = () => {

    const ui = usePlantNetList();
    const dh = useDateHelper();
    const userOps = useUsers();

    const onDelete = (image: IPlantNetCacheItem) => {
        ui.DeleteImageCache(image);
    }

    return (
        <div className="screen-plantnet-list">
            <ScreenHeader text="PlantNet" icon={AdminIcons.spa} />
            <div>
                100 senaste sökningar
            </div>
            <hr />
            <div>
                <span>
                    <button className="btn" onClick={ui.prev}>prev</button>
                </span>
                <span className="info">
                    Total: {ui.totalCount}
                </span>
                <span className="info">
                    pageSize: {ui.pageSize}
                </span>
                <span className="info">
                    Sida: {ui.pageIndex + 1}/{ui.pageCount}
                </span>
                <span>
                    <button className="btn" onClick={ui.next}>next</button>
                </span>
                <span>
                    <button className="btn" onClick={ui.refresh}>Refresh</button>
                </span>
                <span>
                    <button className="btn" onClick={ui.loadMissing}>Refresh missing</button>
                </span>
            </div>
            <div>
                <hr />
                <LoadingBar loading={ui.isLoading} />
            </div>
            <div>
                <table>
                    <tbody>
                        {ui.data?.map(item => {
                            const hasFirstHit = !!item?.findResult?.allResults?.[0]?.plantId;
                            const u = userOps.getUser(item?.userId);
                            return (
                                <tr className="plantnet-tr-row">
                                    <td style={{ width: "140px" }}>
                                        <JwtImage imageId={item?.imageId} format={"thumb"} openOnClick={true} imageText={item?.firstPlantId} />
                                        <div className="timestamp" title={dh.epochToDateTime(item?.sys_datecreated_utc_epoch)}>{dh.timeAgo(item?.sys_datecreated_utc_epoch)}</div>
                                        <div className="timestamp" title={u?.email}>{u?.name}</div>

                                    </td>

                                    <td style={{ verticalAlign: "middle" }}>
                                        <div className="direction-indicator">
                                            <AdminIcon icon={AdminIcons.angle_double_right} />
                                        </div>
                                    </td>


                                    <td>
                                        <div className={"res-lst" + (hasFirstHit ? " has-first-hit" : "")}>
                                            {item?.findResult?.allResults?.map((n, idx) => (
                                                <AllResultElement
                                                    key={n?.name}
                                                    idx={idx}
                                                    item={n}
                                                />
                                            ))}
                                        </div>
                                    </td>
                                    <td>
                                        {/* {dh.timeAgo(item?.sys_datecreated_utc_epoch)} */}
                                        <button className="btn" onClick={() => onDelete(item)}>Hanterad</button>
                                    </td>
                                </tr>
                            )
                        })}
                    </tbody>
                </table>
            </div>
            <Padder.Horizontal size={"50px"} />
        </div>
    )
}

const AllResultElement = ({ idx, item }: { idx: number, item: IAllResult }) => {
    return (
        <div className="res">
            <div className="num">{idx + 1}.</div>
            <div className="name-l" title="Plantnet namn">{item?.name}</div>
            <div className="name-oh" title="Oh!Garden namn">{item?.plantJson?.name ?? "-"}</div>
            <div className="score" title="Score">{Math.round(item?.score * 100)}%</div>
            <div className="other-names" title="Plantnet andra namn">{item?.otherNames?.join(", ")}</div>
        </div>
    )
}

const usePlantNetList = () => {
    const api = usePlantNetListApi();
    const paging = usePaging<IPlantNetCacheItem>({ pageSize: 10 });
    const [isLoading, setIsLoading] = useState<boolean>(false);


    const userOps = useUsers();

    const load = async () => {
        setIsLoading(true);
        const d = await api.data();
        paging.setData(d);
        userOps.ensureUserInfo(d?.map(m => m.userId));


        setIsLoading(false);
    }

    const loadMissing = async () => {
        setIsLoading(true);
        const d = await api.data();
        paging.setData(d?.filter(m => !m?.firstPlantId));
        setIsLoading(false);
    }

    useEffect(() => {
        load();
    }, [])

    return {
        refresh: load,
        loadMissing: loadMissing,
        data: paging.datasource,
        isLoading: isLoading,
        DeleteImageCache: async (image: IPlantNetCacheItem) => {
            paging.deleteItem(e => e.imageId === image?.imageId);
            const s = await api.deleteImage(image?.imageId);
            if (!s) {
                alert("asda")
            }
        },
        prev: () => { return paging.prev() },
        next: () => { return paging.next() },
        pageIndex: paging.pageIndex,
        totalCount: paging.totalCount,
        pageSize: paging.pageSize,
        pageCount: paging.pageCount,


    }
}


const usePlantNetListApi = () => {
    return {
        data: async (): Promise<IPlantNetCacheItem[]> => {
            return await FetchHandler.getJson("/api/plantnet/all");
        },
        deleteImage: async (imageId: string): Promise<boolean> => {
            return await FetchHandler.delete("/api/plantnet/" + imageId);
        }
    }
}
