
let timeout = null;
export const delayKeyUp = (action: any, ms?: number) => {
    clearTimeout(timeout);
    timeout = setTimeout(() => {
        if (action) {
            action();
        }
    }, ms ? ms : 300);
}


export const DateFormatter = {
    epochToDateAndTime: (epoch: number) => {
        const d = new Date(epoch * 1000);
        const date = d.toISOString().substring(0, 10);
        const time = d.toTimeString();
        return date + " " + time.substring(0, 8);
    },
    epochToDateAndTimeObj: (epoch: number) => {
        const d = new Date(epoch * 1000);
        const date = d.toISOString().substring(0, 10);
        const time = d.toTimeString();
        return {
            date: date,
            time: time,
            str: date + " " + time.substring(0, 8)
        };
    },
}

export const StringHelper = {
    isGuid: (str: string): boolean => {
        if (!str) { return false; }

        var regex = /^[0-9a-f]{8}-([0-9a-f]{4}-){3}[0-9a-f]{12}$/i;
        const res = str.match(regex);
        // setIsValid(!!res && replacement.newplantpartition?.length > 3);
        return !!res;
    }
}


export const SortHelper = {
    sortAscending: (a: any, b: any, propName?: string): number => {

        const ak = Object.hasOwn(a, propName) ? a[propName] : a;
        const bk = Object.hasOwn(a, propName) ? b[propName] : b;

        if (ak > bk) { return 1; }
        else if (ak < bk) { return -1; }
        else { return 0; }
    },

    sortDescending: (a: any, b: any, propName?: string): number => {
        const ak = Object.hasOwn(a, propName) ? a[propName] : a;
        const bk = Object.hasOwn(a, propName) ? b[propName] : b;

        if (ak > bk) { return -1; }
        else if (ak < bk) { return 1; }
        else { return 0; }
    }
}