import React, { Component, useState } from 'react';
import { FetchHandler } from "../../Handlers/FetchHandler";
import { MessageBoxes } from '../../Handlers/MessageBoxes';
import { TrashIcon } from "../Shared/Icons";
import { LoadingElement } from '../Shared/LoadingElement';
import { Dialog } from '../Shared/Dialog';
import { Reloads, StoreFillerHandler } from "../../rdx/StoreFillerHandler";
import { useSelector } from 'react-redux';
import { Operations } from '../../rdx/Operations';
import { TwoColumnContainer } from '../Shared/Formelements';
import { AdminIcons } from '../Shared/AdminIcon';
import { ScreenHeader } from '../UI/Elements';


interface IProps { }
interface IState {
  planttypes?: any[],
  loading?: boolean,

  model?: any,
  id?: string,
  plantTypeName?: string,
  plantTypeDescription?: string,


  showAddDialog?: boolean,
  dialogHeader?: string
}


export class PlantTypes extends Component<IProps, IState> {
  static displayName = PlantTypes.name;

  mounted: boolean;

  constructor(props) {
    super(props);
    this.mounted = false;
    this.state = {
      planttypes: [],
      loading: false,

      model: null,
      id: "",
      plantTypeName: "",
      plantTypeDescription: "",


      showAddDialog: false,
      dialogHeader: "-",

    };

    this.reload = this.reload.bind(this);
    this.inputChanged = this.inputChanged.bind(this);
    this.addClicked = this.addClicked.bind(this);
    this.cancelClicked = this.cancelClicked.bind(this);
    this.okClicked = this.okClicked.bind(this);
    this.selectModel = this.selectModel.bind(this);
    this.deleteItemClicked = this.deleteItemClicked.bind(this);

  }

  componentDidMount() {
    this.mounted = true;
    this.reload();
    Reloads.reloadPlantlists();
  }
  componentWillUnmount() {
    this.mounted = false;
  }
  reload() {
    this.setState({ loading: true });
    FetchHandler.getJson("/api/planttypes").then(data => {
      if (this.mounted) {
        this.setState({ loading: false, planttypes: data });
      }
    });
  }
  inputChanged(e) {
    this.setState({ [e.target.name]: e.target.value });
  }


  selectModel(model) {
    this.setState({
      showAddDialog: true,
      dialogHeader: "Ändra",

      model: model,
      id: model.id,
      plantTypeName: model.plantTypeName,
      plantTypeDescription: model.plantTypeDescription,
    });
  }
  deleteItemClicked(id) {
    if (MessageBoxes.confirm("Är du säker?")) {
      FetchHandler.postJson("/api/planttypes/delete", { id: id }).then(success => {
        if (success) {
          this.reload();
          StoreFillerHandler.ensureCache(true);
        } else {
          MessageBoxes.warning("Failed");
        }
      });
    }
  }

  addClicked() {
    this.setState({ showAddDialog: true, dialogHeader: "Lägg till" });
  }

  cancelClicked() {
    this.setState({
      showAddDialog: false, plantTypeName: "", plantTypeDescription: "", id: "", model: null,
      dialogHeader: "-"
    });
  }
  okClicked() {
    // Add
    if (this.state.model === null) {
      const postObj = {
        plantTypeName: this.state.plantTypeName,
        plantTypeDescription: this.state.plantTypeDescription
      }
      FetchHandler.postJson("/api/planttypes/create", postObj).then(success => {
        if (success) {
          this.setState({ showAddDialog: false, plantTypeName: "", plantTypeDescription: "", id: "", model: null });
          this.reload();
          StoreFillerHandler.ensureCache(true);
        } else {
          MessageBoxes.warning("Failed!");
        }
      });
    } else {
      // update
      const postObj = {
        id: this.state.id,
        plantTypeName: this.state.plantTypeName,
        plantTypeDescription: this.state.plantTypeDescription
      }

      FetchHandler.postJson("/api/planttypes/update", postObj).then(success => {
        if (success) {
          this.setState({ showAddDialog: false, plantTypeName: "", plantTypeDescription: "", id: "", model: null });
          this.reload();
          StoreFillerHandler.ensureCache(true);
        } else {
          MessageBoxes.warning("Failed!");
        }
      });
    }

  }



  render() {
    return (
      <div>
        <ScreenHeader text="Växttyper" icon={AdminIcons.envira} />
        <p>
          Den övergripande klassificeringen av växter. Alla växter tillhör någon av nedanstående typer.
          Om det finns fält kopplade till typen kan typen inte raderas.
        </p>
        <div>
          <button className="btn" onClick={this.addClicked}>
            Lägg till ny
          </button>
        </div>
        <hr />

        {/* Loading */}
        <div>
          {this.state.loading && (
            <LoadingElement />
          )}
        </div>

        {/* Dialog */}
        <div>
          {this.state.showAddDialog && (
            <Dialog onCancel={this.cancelClicked} onOk={this.okClicked} okButtonText="Spara" >
              <div className="form-container">
                <h3>{this.state.dialogHeader}</h3>
                {!!this.state.id && (
                  <h5>
                    ID: {this.state.id}
                  </h5>
                )}
                <div className="form-item w100">
                  <label>Namn</label>
                  <input type="text" name="plantTypeName" value={this.state.plantTypeName} onChange={this.inputChanged} />
                </div>

                <div className="form-item w100">
                  <label>Beskrivning</label>
                  <input type="text" name="plantTypeDescription" value={this.state.plantTypeDescription} onChange={this.inputChanged} />
                </div>

              </div>
            </Dialog>
          )}
        </div>

        {/* Table */}
        <div>
          <PlantTypesTable planttypes={this.state.planttypes} selectModel={this.selectModel} deleteItemClicked={this.deleteItemClicked} />
        </div>

      </div>
    );
  }
}


const PlantTypesTable = (props) => {
  return (
    <table className="tbl">
      <thead>
        <tr>
          <th>Namn</th>
          <th>Beskrivning</th>
          <th>Kopplade fält</th>
          <th></th>
          <th></th>
          <th></th>
        </tr>
      </thead>
      <tbody>
        {props.planttypes.map(item => (
          <PlantTypesRow
            key={item.id}
            id={item.id}
            plantTypeName={item.plantTypeName}
            plantTypeDescription={item.plantTypeDescription}
            fields={item.fields}
            openItem={() => { props.selectModel({ ...item }) }}
            deleteItem={() => { props.deleteItemClicked(item.id); }}
          />
        ))}
      </tbody>
    </table>
  )
}

const PlantTypesRow = ({ id, openItem, plantTypeName, plantTypeDescription, fields, deleteItem }) => {

  const allListsObj = useSelector(state => state.data.plantlists);
  const allLists = Object.keys(allListsObj).filter(k => k !== "loaded").map(k => allListsObj[k]);
  const linked_lists = useSelector(state => state.data.plantlistslinkedtobject[id]);

  const defaultSelectValue = "-";

  const [listId, setListId] = useState(linked_lists ? linked_lists[0] : defaultSelectValue);
  const selectOnChange = (e) => {
    const val = e.target.value;
    setListId(val);
    if (val === defaultSelectValue) {
      return;
    }
    Operations.linkPlantListToObject(id, val).then(success => {
      if (success) {
        MessageBoxes.info("listan kopplades!")
      } else {
        MessageBoxes.warning("Operationen misslyckades")
      }
    });
  }

  return (
    <tr key={id}>
      <td>
        <button className="btn-link" onClick={openItem}>
          {plantTypeName}
        </button>
      </td>
      <td>{plantTypeDescription}</td>
      <td>{fields.length}</td>
      <td>
        {fields.length === 0 && (
          <button className="btn-icon" onClick={deleteItem}>
            <TrashIcon />
          </button>
        )}
      </td>
      <td className="form-large" style={{ minWidth: "250px" }}>
        <select value={listId} onChange={selectOnChange}>
          <option value={defaultSelectValue}>-</option>
          {allLists && allLists.map(option => (
            <option key={option.id} value={option.id}>{option.name}</option>
          ))}
        </select>
      </td>
      <td style={{ minWidth: "250px" }}>
        {linked_lists && linked_lists.map(listid => (
          <ObjectListItem key={listid} objectId={id} id={listid}
            name={allListsObj[listid]?.name}
            numberOfPlants={allListsObj[listid]?.numberOfPlants}
          />
        ))}
      </td>
    </tr>
  )
}


const ObjectListItem = ({ objectId, id, name, numberOfPlants }) => {

  const deleteClicked = () => {
    Operations.unLinkPlantListFromObject(objectId, id).then(success => {
      MessageBoxes.successAlert(success, "Listan kopplades!")
    })
  }

  return (
    <TwoColumnContainer>
      <div className="no-wrap" key={id}>{name} ({numberOfPlants})</div>
      <div className="right">
        <button className="btn-icon" onClick={deleteClicked}>
          <TrashIcon />
        </button>
      </div>
    </TwoColumnContainer>

  )
}