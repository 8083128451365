import React, { useState } from 'react';
import { useSelector } from 'react-redux';
import { Dialog } from "../Dialog";
import { SearchHandler } from '../../../Handlers/SearchHandler';
import { Data } from '../../../Handlers/Data';
import { Link } from 'react-router-dom';
import { HasImageIcon, HasntImageIcon, NotVisibleIcon, StarIcon, StartIcon, VisibleIcon } from "../Icons";
import { SearchListContextMenu } from "./SearchListContextMenu";
import { usePlantImages } from '../../../Handlers/Hooks';
import { JwtPlantImage } from '../../UI/ImageElements';
import { RdxStore } from '../../../rdx/ReduxTypes';
import { isTemplateExpression } from 'typescript';

const viewStyles = {
    table: "table",
    images: "images"
}

export const SearchHits = () => {
    const isSearching = useSelector((state: RdxStore) => state.search.isSearching);
    const [columnDialogVisible, setColumnDialogVisible] = useState(false);
    const [viewStyle, setViewStyle] = useState(viewStyles.table);



    return (
        <div>
            {isSearching && (<span>Söker...</span>)}

            <button className='btn' onClick={() => { setViewStyle(viewStyle === viewStyles.table ? viewStyles.images : viewStyles.table) }}>
                {viewStyle === viewStyles.table && (<span>Visa som bilder</span>)}
                {viewStyle === viewStyles.images && (<span>Visa som Tabell</span>)}
            </button>

            {viewStyles.table === viewStyle && (
                <>
                    <button className="btn" onClick={() => { setColumnDialogVisible(true); }}> Ändra kolumner </button>
                    {columnDialogVisible && (<DialogVisibleColumns close={() => { setColumnDialogVisible(false); }} />)}
                </>
            )}

            <hr />

            {viewStyles.table === viewStyle && (
                <div>
                    <SearchTable dialogVisible={columnDialogVisible} />
                </div>
            )}

            {viewStyles.images === viewStyle && (
                <div>
                    <SearchImageGrid />
                </div>
            )}
        </div>
    )
}

const SearchImageGrid = () => {
    const list = useSelector(state => state.search.searchList);
    const plantImages = usePlantImages();

    return (
        <div className='searchimagegrid'>
            {list.filter(item => item?.fields?.imageid).map(item => (
                <div
                    key={item.id}
                    className={'searchimagegrid-item ' + (item.selected ? "selected" : "")}
                    onClick={(e) => {
                        if ((e.target as HTMLElement).tagName !== "A") {
                            SearchHandler.toggleSelectedItem(item.id, false, e.ctrlKey);
                        }
                    }}
                >
                    <JwtPlantImage plantImageId={item?.fields?.imageid} />
                    <div>
                        <div className="name">{item.fields.name}</div>
                        <div className="specifictype">{item.fields.specifictype}</div>
                        <div className="plantTypeName">{item.plantTypeName}</div>
                        <div className="licensing-audience">
                            {plantImages[item.id]?.[item?.fields?.imageid]?.licensing?.licensingAudience}
                        </div>
                    </div>
                    {/* image: {item?.fields?.imageid} */}
                </div>
            ))}
        </div>
    )

}
const SearchTable = ({ dialogVisible }) => {
    const list = useSelector((state: RdxStore) => state.search.searchList);

    const plantImages = usePlantImages();
    const plantfieldsobject = useSelector(state => state.data.plantfieldsobject);
    const snippets = useSelector(state => state.data.snippets);
    const allFields = useSelector(state => state.data.plantfields);
    const defaultFieldIds = allFields.map(obj => obj.id);

    const colors = useSelector(state => state.data.colors);
    const colorObject = colors.reduce((acc, curr) => (acc[curr.id] = curr, acc), {});


    const visibleFields = useSelector(state => state.search.visibleFields);
    const fields = visibleFields.length > 0 ? visibleFields : defaultFieldIds;
    const isSearching = useSelector(state => state.search.isSearching);

    const sort = (fieldid) => {
        SearchHandler.sortOnField(fieldid);
    }



    const [contextMenuVisible, setContextMenuVisible] = useState(false);
    const [clickedFieldKey, setClickedFieldKey] = useState(null);
    const [contextMenuPosition, setContextMenuPosition] = useState({ x: 0, y: 0 });
    const onContextMenu = (e) => {
        e.preventDefault();
        setContextMenuPosition({ x: e.clientX, y: e.clientY });
        setContextMenuVisible(true);
        setClickedFieldKey(e.target.dataset?.fieldkey);
        return false;
    }
    const theadstyle: React.CSSProperties = dialogVisible ? null : { position: "sticky", top: "-22px" };

    const closeContextMenu = () => {
        setContextMenuVisible(false);
        setClickedFieldKey(null);
    }

    return (
        <>
            <SearchListContextMenu
                position={contextMenuPosition}
                close={closeContextMenu}
                menuVisible={contextMenuVisible}
                clickedFieldKey={clickedFieldKey}
            />
            <div>
                Antal: {list.length}
            </div>

            {/* disabled={isSearching} */}
            <table className="tbl plant-search-tbl" >
                <thead style={theadstyle}>
                    <tr>
                        <th className="td-sticky" onClick={() => { sort("name"); }}>{plantfieldsobject["name"]?.name}</th>
                        <th style={{ width: "30px" }}></th>
                        <th className="td-sticky-100" onClick={() => { sort("specifictype"); }}>{plantfieldsobject["specifictype"]?.name}</th>
                        <th>Alternativa namn</th>
                        <th onClick={() => { sort("__typename__"); }}>Typ</th>
                        {fields.map(field => (
                            <th key={field} onClick={() => { sort(field); }}>{plantfieldsobject[field.toLowerCase()]?.name}</th>
                        ))}
                    </tr>
                </thead>
                <tbody onContextMenu={onContextMenu}>
                    {list.map(item => (
                        <tr
                            key={item.id}
                            data-id={item.id}
                            className={"unselectable hover-highlight " + (item.selected ? "selected" : "")}
                            onClick={(e) => {
                                closeContextMenu();
                                if ((e.target as HTMLElement).tagName !== "A") {
                                    SearchHandler.toggleSelectedItem(item.id, e.shiftKey, e.ctrlKey);
                                }
                            }}
                        >
                            <td className="td-value td-sticky">
                                <Link to={"/plants/edit/" + item.id}>
                                    {item.fields.name}
                                </Link>

                            </td>
                            <td className="">
                                <div style={{ paddingRight: "5px" }}>
                                    {item.public && (<VisibleIcon />)}
                                    {!item.public && (<NotVisibleIcon />)}

                                    <Link to={"/plants/plantimages/" + item.id}>
                                        <span style={{ marginLeft: "15px", marginRight: "10px", color: plantImages[item.id] ? "#000" : "#aaa" }}>
                                            <HasImageIcon />
                                            {item?.fields?.imageid && (
                                                <StarIcon className="star-has-primaryimage" />
                                            )}
                                        </span>
                                    </Link>
                                    <span className='plant-licensingname'>
                                        {/* {plantImages[item.id]?.[item?.fields?.imageid]?.licensing?.licensingName} */}

                                        {plantImages[item.id]?.[item?.fields?.imageid]?.licensing?.licensingAudience}

                                    </span>
                                </div>
                            </td>
                            <td className="td-value td-sticky-100">{item.fields.specifictype}</td>
                            <td className="td-value">{item.fields.alternativenames}</td>
                            <td className="td-value">{item.plantTypeName}</td>

                            {fields.map(field => (
                                <ValueCell
                                    key={field}
                                    fieldkey={field}
                                    field={plantfieldsobject[field.toLowerCase()]}
                                    value={item.fields[field.toLowerCase()]}
                                    snippets={snippets}
                                    colorObject={colorObject}
                                />
                            ))}

                        </tr>
                    ))}
                </tbody>
            </table>

        </>
    )
}

const DialogVisibleColumns = (props) => {
    const planttypes = useSelector(state => state.data.planttypes);
    const allFields = useSelector(state => state.data.plantfields);
    const visibleFields = useSelector(state => state.search.visibleFields);

    const onOk = () => {
        if (props.close) { props.close(); }
    }
    const toggleColumn = (e, fieldid) => {
        SearchHandler.toggleVisibleColumns(fieldid);
    }

    const options = planttypes.map(obj => { return { key: obj.id, label: obj.name } });
    const [selectedType, setSelectedType] = useState("-");

    const typeChanged = (e) => {
        const type = e.target.value;
        setSelectedType(type);
        const selected = planttypes.filter(obj => obj.id === type)[0];
        console.log("selected:", selected?.fields);
        if (selected?.fields) {
            SearchHandler.setVisibleColumns(selected?.fields);
        }
    }

    return (
        <Dialog onOk={onOk} >
            <div className="form-large">
                <label>
                    Visa fält för
                </label>
                <select value={selectedType} onChange={typeChanged}>
                    <option value="-">-</option>
                    {options && options.map(option => (
                        <option value={option.key} key={option.key}>{option.label}</option>
                    ))}
                </select>
                <hr />
            </div>
            <table className="tbl">
                <thead></thead>
                <tbody>

                    {allFields.map(field => (
                        <tr key={field.id}>
                            <td>
                                <input type="checkbox" checked={visibleFields.indexOf(field.id) >= 0} onChange={(e) => { toggleColumn(e, field.id); }} />
                            </td>
                            <td>
                                {field.name}
                            </td>
                        </tr>
                    ))}
                </tbody>
            </table>


        </Dialog>

    )

}


const ValueCell = ({ value, field, snippets, fieldkey, colorObject }) => {

    const parseJson = (json) => {
        try {

            if (json === "True") { return true; }
            if (json === "False") { return false; }

            return JSON.parse(json);
        }
        catch {
            console.error("Could no parse Json in field '" + field?.id + "', value: '" + value + "'.")

        }
    }

    // 
    let title = field?.name;

    if (field?.type === Data.fieldTypes.multiSelect || field?.type === Data.fieldTypes.singleSelect) {
        value = value ? parseJson(value) : null;
        if (value) {
            value = field.selectOptions.filter(obj => value.indexOf(obj.key) >= 0).map(obj => obj.name).join(",");
        }
    } else if (field?.type === Data.fieldTypes.trueFalse) {
        value = value ? parseJson(value) : null;
        if (value) {
            value = "X";
        }
    } else if (field?.type === Data.fieldTypes.color) {
        // value = value ? JSON.parse(value) : null;
        try {
            const list = value ? parseJson(value) : null;
            if (list) {
                value = (
                    <>
                        {list.map(c => (
                            <Colordot key={c} name={colorObject[c]?.name} hex={colorObject[c]?.hexNumber} />
                        ))}
                    </>
                )
            }
        }
        catch {

        }
    } else if (field?.type === Data.fieldTypes.snippet) {
        //value = value ? "snippet: " + value : "";
        if (value && snippets) {
            value = snippets[value]?.snippetName;
            title = snippets[value]?.snippetDescription;
        }
    }

    return (
        <td
            className="td-value"
            title={title}
            data-fieldkey={fieldkey}
        >
            {value}
        </td>
    )
}

const Colordot = ({ name, hex }) => {
    const style = {
        backgroundColor: hex,
    }
    return (
        <span className="colordot" style={style} title={name}></span>
    )
}