import React, { Component } from 'react';
// import store from "./../../rdx/store";
import { FetchHandler } from "../../Handlers/FetchHandler";
import { Dialog } from "../Shared/Dialog";
import { efn } from "../../Handlers/ElementFunctions";


interface IProps {
}

interface IState {
    inspiration: any[],
    images: any[],
    selectimage: boolean,
    loadingimages: boolean,
    selectedimage: string
}

export class Inspiration extends Component<IProps, IState>  {
    static displayName = Inspiration.name;

    mounted: boolean;

    constructor(props) {
        super(props);
        this.mounted = false;
        this.state = {
            inspiration: [],
            images: [],

            selectimage: false,
            loadingimages: false,
            selectedimage: null
        };

        this.selectImageClicked = this.selectImageClicked.bind(this);
        this.imageClicked = this.imageClicked.bind(this);
        this.selectImageOkClicked = this.selectImageOkClicked.bind(this);

    }

    imageClicked(e) {
        const id = efn.getElementDataset(e.target, "id");
        let a = this.state.images;
        a.forEach(i => {
            if (i.id === id) {
                i.selected = true;
            } else {
                i.selected = false;
            }
        });
        this.setState({ images: a });
    }

    selectImageClicked() {
        this.setState({ selectimage: true, loadingimages: true });

        FetchHandler.getJson("/api/images").then(images => {
            if (this.mounted) {
                images.forEach(i => { i.selected = false; });
                this.setState({ images: images, loadingimages: false });
            }
        });

    }

    selectImageOkClicked() {
        const model = this.state.images.filter(obj => obj.selected)[0];
        this.setState({ selectimage: false });
        if (model !== null) {
            this.setState({ selectedimage: model.url });
        }
    }

    componentDidMount() {
        this.mounted = true;
        this.setState({
            inspiration: [
                { id: "1", title: "First", html: "<p>sdsd</p>" },
                { id: "2", title: "Last", html: "<p>sdsd</p>" },
                { id: "3", title: "Everything", html: "<p>sdsd</p>" },
            ]
        });
    }

    render() {
        return (
            <div>
                <h1>Inspiration!</h1>
                <button onClick={this.selectImageClicked} >
                    Välj bild
                </button>

                <hr />

                <img src={this.state.selectedimage} alt="" loading="lazy" />

                <div className="inspiration-list-container">
                    {this.state.inspiration.map(i => (
                        <div key={i.id} className="item">
                            <label>Titel:</label>
                            <div className="item-title">{i.title}</div>
                            <label>Text</label>
                            <div dangerouslySetInnerHTML={{ __html: i.html }} className="item-html" ></div>
                        </div>
                    ))}
                </div>


                {this.state.selectimage && (
                    <SelectImageDialog
                        onCancel={() => { this.setState({ selectimage: false }) }}
                        onOk={this.selectImageOkClicked}
                        imageClicked={this.imageClicked}
                        images={this.state.images}
                    />
                )}
            </div>
        );
    }
}

const SelectImageDialog = (props) => {
    return (
        <Dialog
            onCancel={props.onCancel}
            onOk={props.onOk}
            width="800px"
            height="500px">
            <div className="imagelist-container">
                <div className="imagelist-small">
                    {props.images.map(i => (
                        <div key={i.id} data-id={i.id} className={"imagelist-small-item " + (i.selected ? "selected" : "")} onClick={props.imageClicked}>
                            <img src={i.url} alt="" loading="lazy" />
                        </div>
                    ))}
                </div>
            </div>
        </Dialog>
    );
}
