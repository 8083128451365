import React, { useState, useEffect } from 'react';
import { useSelector } from 'react-redux';

export const TagSelector = (props) => {
    const tagsobject = useSelector(state => state.data.planttagsobject);
    const [tags, setTags] = useState(props.tags);
    useEffect(() => { setTags(props.tags); }, [props.tags]);
    const groupedTags = tags.reduce((a, c) => (a[c] = (a[c] || 0) + 1, a), Object.create(null));
    let a = [];
    Object.getOwnPropertyNames(groupedTags).forEach(t => a.push({tag: t, num: groupedTags[t]}));
    
    a.sort((a,b) => { if(a.num < b.num ) { return 1 } if(a.num > b.num ) { return -1 } return 0; });

    const clicked = (tag) => {
        if(props.onClick) {
            props.onClick(tag);
        }
    };

    return (
        <div className="taglist">
          {a.filter(tag => tagsobject[tag.tag]).map(tag => (
            <span className="tag" key={tag.tag} onClick={() => clicked(tag)}>
                <span className="name">{tagsobject[tag.tag]}</span>
                <span className="num">{tag.num}</span>
            </span>
          ))}
        </div>
      )
}