import React, { useEffect, useState } from 'react';
import { DateTime } from "luxon";
import { MessageBoxes } from '../../Handlers/MessageBoxes';
import { usePlant } from '../../hooks/usePlant';
import { JwtPlantImage } from '../UI/ImageElements';
import { SystemSettingsRevision, useDiscover } from './useDiscover';
import { DragDropContainer } from 'react-drag-drop-container';
import { usePlantLists } from '../../hooks/usePlantLists';
import { AdminIcons } from '../Shared/AdminIcon';
import { ScreenHeader } from '../UI/Elements';
import { UserPlantImageCard } from '../PlantImageOperations/UserPlantImageOperations';
import { PlantWithImage } from '../PlantImageOperations/PlantImageOperations';
import { PostImage } from '../PlantImageOperations/SocialImageOperations';
import { ClipBoardDropTargets } from '../ClipBoard/useClipBoard';
import { UserPostFeaturedCard } from '../PostsManagement/PostsManagement';
import { DiscoverScreen } from './DiscoverScreen';
import { Dialog } from '../Shared/Dialog';
import { ForumPostType } from '../Forum/Forum';
import { FetchHandler } from '../../Handlers/FetchHandler';

export const Discover = () => {
    const ui = useDiscover();



    return (
        <div className="discover-tools">
            <ScreenHeader text="Utforska" icon={AdminIcons.search} />
            <div className='workarea'>
                {/* App */}
                <div>
                    <div className='workarea-title'>
                        App
                    </div>
                    <RevisionsSelector
                        revisions={ui.revisions}
                        addClick={epoch => { ui.addRevision(epoch) }}
                        deleteClick={id => { ui.deleteRevision(id) }}
                        selectClick={id => { ui.selectRevision(id) }}
                        selectedRevision={ui.selectedRevision}
                    />
                    {ui.selectedRevision && (
                        <DiscoverScreen config={ui.uiConfig} onChange={ui.keyValueChange} />
                    )}
                </div>
                <div>
                    <div>
                        <button className='btn' onClick={() => ui.setViewState("plants")} disabled={ui.viewState == "plants"}>
                            Växter
                        </button>

                        <button className='btn' onClick={() => ui.setViewState("images")} disabled={ui.viewState == "images"} >
                            Bilder
                        </button>

                        <button className='btn' onClick={() => ui.setViewState("lists")} disabled={ui.viewState == "lists"}>
                            Listor
                        </button>

                        <button className='btn' onClick={() => ui.setViewState("featured")} disabled={ui.viewState == "featured"}>
                            Inlägg
                        </button>
                    </div>



                    {/* Växter */}
                    {ui.viewState === "plants" && (
                        <div>
                            <div className='workarea-title'>
                                Växter med bilder
                            </div>
                            <div className='workarea-tagline'>
                                Sök växter
                            </div>
                            <div>
                                <Search onSearch={e => { ui.searchPlant(e); }} disabled={ui.loading} />
                                <div className={'search-list ' + (ui.loading ? "loading" : "")}>
                                    {ui.plantSearchList?.map(p => (
                                        <SearchPlantCard key={p.id} plantId={p.id} />
                                    ))}
                                </div>
                            </div>
                        </div>
                    )}

                    {/* Images */}
                    {ui.viewState === "images" && (
                        <div>
                            <div className='workarea-title'>
                                Växtbilder
                            </div>
                            <div className='workarea-tagline'>
                                Från clipboard
                            </div>

                            <div>
                                <div className="userplantimagecard-grid">
                                    {ui?.selectedUserImages?.plantimages?.map(item => (
                                        <UserPlantImageCardDragItem key={item?.imageid} item={item} />
                                    ))}
                                </div>
                            </div>
                            <div>
                                <div className="postimages-grid">
                                    {ui?.selectedUserImages?.postimage?.map(item => (
                                        <PostImage
                                            key={item?.imageid}
                                            item={item}
                                            selected={false}
                                            onSelect={null}
                                            showSelectionToggle={false}
                                            openClipBoardOnDrag={false}
                                        />
                                    ))}
                                </div>
                            </div>

                        </div>
                    )}

                    {/* featured */}
                    {ui.viewState === "featured" && (
                        <div>
                            <div className='workarea-title'>
                                Inlägg
                            </div>

                            <div>
                                <div className="userpostfeaturedcard-grid">
                                    {ui?.featuredPosts()?.map(item => (
                                        <UserPostFeaturedCard key={item?.id} post={item} />
                                    ))}
                                </div>
                            </div>
                        </div>
                    )}

                    {/* Växtlistor */}
                    {ui.viewState === "lists" && (
                        <div>
                            <div className='workarea-title'>
                                Växtlistor
                            </div>
                            <div className='workarea-tagline'>
                                Alla listor
                            </div>
                            <div className='plantlist-list'>
                                {ui.plantLists?.length > 0 && ui.plantLists?.map(pl => (
                                    <PlantListListItem
                                        key={pl?.id}
                                        plantListId={pl?.id}
                                    />
                                ))}
                            </div>
                        </div>
                    )}

                </div>
            </div>
            {!!ui.sendNotificationForForumPostTypeVisible && (
                <SendOsDialogFeatured
                    post={ui.sendNotificationForForumPostTypeVisible}
                    onCancel={() => { ui.sendNotificationForForumPostTypeHide(); }}
                    onOk={() => { ui.sendNotificationForForumPostTypeHide(); }}
                />
            )}
        </div>
    )
}


const SendOsDialogFeatured = ({ post, onOk, onCancel }: { post: ForumPostType, onOk(): void, onCancel(): void }) => {

    const [alias, setAlias] = useState<string>("");
    const [title, setTitle] = useState<string>("");
    const [body, setBody] = useState<string>("");
    const [link, setLink] = useState<string>("");

    type TextsResponse = {
        title: string,
        body: string,
        link: string,
    }

    const loadTexts = async () => {
        const texts: TextsResponse = await FetchHandler.patchJson("/api/content/featuredposts/" + post?.id + "/getnotificationtext", post);
        setAlias(post?.alias);
        setTitle(texts?.title);
        setBody(texts?.body);
        setLink(texts?.link);
    }

    useEffect(() => {
        loadTexts();
    }, []);


    const sendMessage = async () => {
        const postObj = {
            userid: post?.userid,
            title: title,
            body: body,
            link: link,
        }

        const success: boolean = await FetchHandler.patchJson("/api/notification/send", postObj)

        if (success && onOk) {
            onOk();
            MessageBoxes.info("Meddelandet är skickat!")
        }

    }


    return (

        <Dialog onCancel={onCancel} onOk={sendMessage} width="800px" height="400px" okButtonText='Skicka' cancelButtonText='Avbryt'>

            <h2>Skicka notifiering till: '{alias}'</h2>
            <label className='frm'>Rubrik</label>
            <input type={"text"} className="frm" placeholder='Rubrik...' value={title} onChange={e => setTitle(e.target.value)} />
            <label className='frm'>Meddelande</label>
            <textarea className="frm" placeholder='Meddelande...' value={body} onChange={e => setBody(e.target.value)}></textarea>

        </Dialog>
    )
}

const PlantListListItem = ({ plantListId }) => {
    const item = usePlantLists().item(plantListId);
    const onDrop = (e) => { }
    const [dragging, setDragging] = useState(false)
    const onDragEnd = () => { setDragging(false) }
    const onDragStart = () => { setDragging(true) }

    return (
        <DragDropContainer
            targetKey={ClipBoardDropTargets.list}
            dragData={item}
            onDrop={onDrop}
            onDragStart={onDragStart}
            onDragEnd={onDragEnd}
        >
            <div className='plantlist'>
                {item?.name}: {item?.numberOfPlants}
            </div>
        </DragDropContainer>
    )

}

const UserPlantImageCardDragItem = ({ item }: { item: PlantWithImage }) => {
    const onDrop = (e) => { }
    const [dragging, setDragging] = useState(false)
    const onDragEnd = () => { setDragging(false) }
    const onDragStart = () => { setDragging(true) }

    return (
        <DragDropContainer
            targetKey={ClipBoardDropTargets.plantimage}
            dragData={item}
            onDrop={onDrop}
            onDragStart={onDragStart}
            onDragEnd={onDragEnd}
        >
            <UserPlantImageCard
                item={item}
                onSelectClick={null}
                selected={false}
                showSelected={false}
                openClipBoardOnDrag={false}
            />
        </DragDropContainer>
    )

}

const Search = ({ onSearch, disabled }: { onSearch(e: string): void, disabled: boolean }) => {
    const [val, setVal] = useState("");
    return (
        <input
            className={'search-input ' + (disabled ? "disabled" : "")}
            placeholder='Sök'
            //disabled={disabled}
            type={"text"}
            value={val}
            onChange={e => setVal(e.target.value)}
            onKeyDown={e => {
                if (e.key == "Enter") {
                    onSearch(val)
                }
            }}
        />
    )
}

const SearchPlantCard = ({ plantId }) => {

    const ops = usePlant();

    const plant = ops.item(plantId);


    const onDrop = (e) => { }
    const [dragging, setDragging] = useState(false)
    const onDragEnd = () => { setDragging(false) }
    const onDragStart = () => { setDragging(true) }

    return (
        <DragDropContainer
            targetKey={ClipBoardDropTargets.plant}
            dragData={plant ? plant : "NO DATA"}
            onDrop={onDrop}
            onDragStart={onDragStart}
            onDragEnd={onDragEnd}>
            <div className='search-plantcard'>
                <div className='search-plantcard-image'>
                    <JwtPlantImage plantImageId={plant?.fields?.imageid} />
                </div>
                <div className='search-plantcard-info'>
                    <div className='name'>{plant?.fields?.name}</div>
                    <div className='sort'>{plant?.fields?.specifictype}</div>
                    <div className='latin'>{plant?.fields?.namelatin}</div>
                    <div className='typename'>{plant?.fields?.__typename__}</div>
                </div>
            </div>
        </DragDropContainer>


    )
}


const RevisionsSelector = ({ revisions, addClick, deleteClick, selectClick, selectedRevision }: { revisions: SystemSettingsRevision[], addClick(e: number): void, deleteClick(e: string): void, selectClick(e: string): void, selectedRevision: string }) => {

    const [date, setDate] = useState("");
    const [epoch, setEpoch] = useState<number>(0);
    const [isvalid, setIsvalid] = useState(false);
    const epochOneMonthAgo = DateTime.now().plus({ days: -30 }).toSeconds();
    const controlRevisions = revisions.filter((m, idx) => idx < 6);

    useEffect(() => {
        const d = new Date(date);
        const _epoch = d.valueOf() / 1000;
        const exists = revisions.filter(m => m.publishepoch == _epoch)?.length > 0;
        setEpoch(_epoch);
        if (_epoch > 1556633600 && exists === false) {
            setIsvalid(true)
        } else {
            setIsvalid(false);
        }

    }, [date])

    const onAdd = () => {
        addClick(epoch);
        setDate("");
    }

    const onDelete = (id: string) => {
        const ok = MessageBoxes.confirm("Är du säker?")
        if (ok) {
            deleteClick(id);
        }
    }
    const onSelect = (id: string) => {
        selectClick(id);
    }

    const revModel = revisions?.find(m => m?.id == selectedRevision);

    return (
        <div className='revisionsselector'>
            <div>
                {selectedRevision && (
                    <div>
                        <h4>
                            Rev: {DateTime.fromMillis(revModel?.publishepoch * 1000, { zone: 'utc' }).toFormat("yyyy-LL-dd")} vald
                        </h4>
                        <div className='revisionsselector-save-cancel-box'>
                            {/* <button onClick={() => { onSelect(selectedRevision) }}>
                                spara
                            </button> */}
                            <button onClick={() => { onSelect(selectedRevision) }}>
                                stäng
                            </button>
                        </div>
                    </div>
                )}
            </div>
            <div>
                {(!selectedRevision && !!controlRevisions) && controlRevisions?.map(r => (
                    <div key={r.id} title={r.id} className={"revisionsselector-item " + (selectedRevision === r.id ? "selected" : "")}>
                        <div className='date'>
                            {DateTime.fromMillis(r.publishepoch * 1000, { zone: 'utc' }).toFormat("yyyy-LL-dd")}
                        </div>
                        <div>
                            <button onClick={() => { onDelete(r.id) }}>radera</button>
                        </div>
                        <div>
                            <button onClick={() => { onSelect(r.id) }}>Välj</button>
                        </div>
                    </div>
                ))}
            </div>

            {!selectedRevision && (
                <div className='revisionsselector-form'>
                    <div className='label'>
                        Lägg till
                    </div>
                    <div className='input'>
                        <input type={"date"} value={date} onChange={e => setDate(e.target.value)} />
                    </div>
                    <div className='button'>
                        {isvalid && (
                            <button onClick={onAdd} >
                                Lägg till
                            </button>
                        )}
                    </div>
                </div>
            )}
        </div>
    )
}
