import React, { useEffect, useState } from "react"
import { FetchHandler } from "../../Handlers/FetchHandler"
import { MessageBoxes } from "../../Handlers/MessageBoxes"
import { useDateHelper } from "../../hooks/useHelper"
import { AdminIcons } from "../Shared/AdminIcon"
import { ToolBarClean } from "../ToolBar/ToolBar"
import { ScreenHeader } from "../UI/Elements"

export const Webforms = () => {

    const ui = useWebForms();
    const dh = useDateHelper();


    useEffect(() => {
        ui.load();
    }, [])



    return (
        <div className='view-webforms'>
            <ScreenHeader text="Formulär" icon={AdminIcons.users} />

            <table className="tbl tbl-small">
                <thead>
                    <tr>
                        <th>Typ</th>
                        <th>När</th>
                        <th>Datum</th>
                        <th>Data</th>
                    </tr>
                </thead>
                <tbody>
                    {ui.data?.map(d => (
                        <tr key={d?.id}>
                            <td>
                                {d?.field_form}
                            </td>
                            <td>
                                {dh.timeAgo(d?.sys_datecreated_utc_epoch)}
                            </td>
                            <td>
                                {dh.epochToDateTime(d?.sys_datecreated_utc_epoch)}
                            </td>
                            <td>
                                {Object.keys(d).filter(key => ["id", "sys_datemodified_utc_epoch", "sys_datecreated_utc_epoch", "label"].indexOf(key) < 0).map(key => (
                                    <div key={key}>
                                        <div className="label">
                                            {key} :  {d[key]}
                                        </div>
                                        {/* <div className="value">
                                            {d[key]}
                                        </div> */}
                                    </div>
                                ))}
                            </td>
                            <td style={{ width: "120px" }}>
                                <ToolBarClean
                                    tools={[{
                                        id: "del", label: "Radera", onClick: () => {
                                            if (MessageBoxes.confirm("Är du säker")) {
                                                ui.delete(d?.id)
                                            }
                                        },
                                    }]}
                                />
                            </td>
                        </tr>
                    ))}
                </tbody>
            </table>

            <div>
            </div>



        </div>
    )

}


const useWebForms = () => {

    const [data, setData] = useState<any[]>([]);
    const api = useWebFormsApi();

    const load = async () => {
        const d = await api.getAll();
        setData(d)
    }

    return {
        load: load,
        data: data,
        delete: async (id: string) => {
            const ret = await api.delete(id);
            load();
        }
    }
}


const useWebFormsApi = () => {

    return {
        getAll: async () => {
            return await FetchHandler.getJson("/api/webforms");
        },
        delete: async (id: string): Promise<boolean> => {
            return await FetchHandler.delete("/api/webforms/" + id);
        }
    }
}