import React, { useState, useLayoutEffect } from 'react';
// import Select from 'react-select';
// import { Link } from 'react-router-dom';
import { useSelector } from 'react-redux';
import { FetchHandler } from '../../Handlers/FetchHandler';
// import { Button } from 'bootstrap';
import { FormTextInput, ThreeColumnContainer, TwoColumnContainer } from '../Shared/Formelements';
import { MessageBoxes } from '../../Handlers/MessageBoxes';
import { Elements, ScreenHeader } from '../UI/Elements';
import { Data } from '../../Handlers/Data';
import { Reloads } from '../../rdx/StoreFillerHandler';
import { Dialog } from '../Shared/Dialog';
import { usePlantTypes, useSnippetHelper, useSnippets } from '../../Handlers/Hooks';
import { SnippetsHandler } from '../../Handlers/SnippetsHandler';
import { AdminIcons } from '../Shared/AdminIcon';

const FIELD_KEY_OBJ = Data.snippetTypes;
const FIELD_KEY = Object.keys(FIELD_KEY_OBJ).map(key => FIELD_KEY_OBJ[key]);

export const Snippets = () => {
    const snippetsObj = useSelector(store => store.data.snippets);
    const snippets = Object.keys(snippetsObj).map(key => snippetsObj[key]);


    let tags = {};
    snippets.forEach(s => {
        if (s.snippetTags) {
            s.snippetTags.forEach(t => {
                tags[t] = tags[t] ? tags[t] + 1 : 1;
            })
        }
    });


    // const [list, setList] = useState(snippets);

    const [snippetId, setSnippetId] = useState("");
    const [snippetKey, setSnippetKey] = useState("");
    const [snippetName, setSnippetName] = useState("");
    const [activityName, setActivityName] = useState("");

    const [snippetDescription, setSnippetDescription] = useState("");
    const [snippetNotes, setSnippetNotes] = useState("");
    const [dialogVisible, setDialogVisible] = useState(false);
    const [dialogDisabled, setDialogDisabled] = useState(false);


    const [currentFilter, setCurrentFilter] = useState({ snippetType: "-", textValue: "", tags: [] });

    const loadData = () => {
        Reloads.reloadSnippets().then(response => {
            //filterChanged(currentFilter);
        });
    }

    useLayoutEffect(() => {
        loadData();
    }, []);

    const clear = () => {
        setSnippetId("");
        setSnippetKey("")
        setSnippetName("");
        setSnippetDescription("");
        setSnippetNotes("");
    };

    const addUpdate = () => {

        if (!snippetName || !snippetKey || !snippetDescription) {
            MessageBoxes.warning("Namn, fält och text måste vara ifyllda!")
            return;
        }

        setDialogDisabled(true);
        if (!snippetId) {
            FetchHandler.postJson("/api/snippet/add", {
                snippetName: snippetName,
                snippetKey: snippetKey,
                activityName: activityName,
                snippetDescription: snippetDescription,
                snippetNotes: snippetNotes
            }).then(() => {
                clear();
                setDialogVisible(false);
                setDialogDisabled(false);
                loadData();
            });
        } else {
            FetchHandler.postJson("/api/snippet/" + snippetId + "/update", {
                snippetName: snippetName,
                snippetKey: snippetKey,
                activityName: activityName,
                snippetDescription: snippetDescription,
                snippetNotes: snippetNotes
            }).then(() => {
                clear();
                setDialogVisible(false);
                setDialogDisabled(false);
                loadData();
            });
        }


    }

    const selectSnippet = (item) => {

        setSnippetId(item?.id);
        setSnippetKey(item?.snippetKey ? item?.snippetKey : "");
        setSnippetName(item?.snippetName);
        setActivityName(item?.activityName);
        setSnippetDescription(item?.snippetDescription);
        setSnippetNotes(item?.snippetNotes);
        setDialogVisible(true);
    }

    const filterChanged = (filter) => {
        setCurrentFilter(filter);
    }
    const addClicked = () => {
        clear();
        setDialogVisible(true);
    }
    const deleteSnippet = () => {
        if (MessageBoxes.confirm("Är du säker")) {
            FetchHandler.postJson("/api/snippet/" + snippetId + "/delete", {}).then(() => {
                clear();
                setDialogVisible(false);
                setDialogDisabled(false);
                loadData();
            });
        }
    }

    return (
        <div>
            <SnippetsHeader />
            <hr />

            <Dialog
                visible={dialogVisible}
                onOk={addUpdate}
                onCancel={() => { setDialogVisible(false) }}
                width="1200px"
                disabled={dialogDisabled}
            //  height="400px"

            >

                <ThreeColumnContainer>
                    <div>
                        <div className="form-large">
                            <Elements.Label text="Namn" />
                            <input className="full-width" type="text" value={snippetName} onChange={(e) => { setSnippetName(e.target.value) }} />
                        </div>
                        <div className="form-large">
                            <Elements.Label text="Fält" />
                            <select className="full-width" value={snippetKey} onChange={(e) => { setSnippetKey(e.target.value) }}>
                                <option value=""></option>
                                {FIELD_KEY && FIELD_KEY.map(option => (
                                    <option key={option.key} value={option.key}>{option.name}</option>
                                ))}
                            </select>
                        </div>
                        <div className="form-large">
                            <Elements.Label text="Aktivitetsnamn" />
                            <input className="full-width" type="text" value={activityName} onChange={(e) => { setActivityName(e.target.value) }} />
                        </div>

                    </div>

                    <div className="form-large">
                        <Elements.Label text="Text" />

                        <textarea
                            className="full-width"
                            value={snippetDescription}
                            onChange={(e) => { setSnippetDescription(e.target.value) }}
                            style={{
                                width: "100%",
                                height: "250px"
                            }}
                        />
                    </div>

                    <div>
                        <div className="form-large">
                            <Elements.Label text="Egna noteringar" />
                            <textarea
                                className="full-width"
                                value={snippetNotes}
                                onChange={(e) => { setSnippetNotes(e.target.value) }}
                                style={{
                                    height: "150px"
                                }}
                            />
                        </div>


                        <TwoColumnContainer>
                            <div>
                                <button className="btn" onClick={deleteSnippet}>
                                    Radera
                                </button>
                            </div>
                        </TwoColumnContainer>
                    </div>
                </ThreeColumnContainer>
            </Dialog>
            <hr />
            <SnippetsFilter filterChanged={filterChanged} availableTags={tags} />
            <hr />
            <div className="form-large">
                <button className="btn" onClick={addClicked}>Lägg till ny</button>
            </div>
            <hr />

            <SnippetTable filter={currentFilter} list={snippets
                .filter(obj => { return ((currentFilter.snippetType === "-") || obj.snippetKey === currentFilter.snippetType); })
                .filter(obj => {
                    if (currentFilter.tags.length === 0) { return true; }
                    if (obj.snippetTags) {
                        const filteredArray = currentFilter.tags.filter(value => obj.snippetTags.includes(value));
                        return filteredArray.length > 0;
                    }
                    return false;
                })
                .filter(obj => { return !currentFilter.textValue || obj.snippetName.toLowerCase().includes(currentFilter.textValue.toLowerCase()); })
            } selectSnippet={selectSnippet} />

        </div>
    )


}

const SnippetTable = ({ list, selectSnippet, filter }) => {

    return (
        <table className="tbl">
            <thead>
                <tr>
                    <th>Namn</th>
                    <th>Aktivitetsnamn</th>
                    <th>Fält</th>
                    <th>Text</th>
                </tr>
            </thead>
            <tbody>
                {list && list.map(item => (
                    <SnippetsItem key={item.id} item={item} selectSnippet={selectSnippet} />
                ))}
            </tbody>

        </table>
    )

}

const SnippetsItem = ({ item, selectSnippet }) => {

    const snippet = useSnippets(item.id);
    const snippetHelper = useSnippetHelper();

    const planttypes = usePlantTypes();

    const tagSelected = async (tag) => {

        if (snippet.snippetTags) {
            if (snippet.snippetTags.indexOf(tag) >= 0) {
                // remove
                let s = [...snippet.snippetTags];
                s.splice(snippet.snippetTags.indexOf(tag), 1);
                await SnippetsHandler.updateSnippetsTags(snippet.id, s);
            } else {
                // add
                let d = [...snippet.snippetTags];
                d.push(tag);
                await SnippetsHandler.updateSnippetsTags(snippet.id, d);
            }
        } else {
            await SnippetsHandler.updateSnippetsTags(snippet.id, [tag]);
        }
    }

    return (
        <tr>
            <td style={{ whiteSpace: "nowrap" }}>
                {snippet?.snippetName}
            </td>
            <td style={{ whiteSpace: "nowrap" }}>
                {snippet?.activityName}
            </td>

            <td className='snippet-tagger' style={{ whiteSpace: "nowrap" }}>
                {/* {FIELD_KEY_OBJ[snippet?.snippetKey]?.name} */}
                <select onChange={(e) => tagSelected(e.target.value)}>
                    <option value="">-</option>
                    {planttypes.filter(type => {
                        if (!snippet.snippetTags) { return true }
                        return snippet.snippetTags.indexOf(type.name) < 0;
                    }).map(o => (
                        <option key={o.id} value={o.name}>{o.name}</option>
                    ))}
                </select>
                {snippet?.snippetTags && snippet.snippetTags.map(tag => (
                    <div key={tag} onClick={() => { tagSelected(tag); }}>{tag}</div>
                ))}
            </td>
            <td style={{ whiteSpace: "pre-wrap" }}>
                {snippet?.snippetDescription}
            </td>
            <td>
                <button onClick={() => { selectSnippet(snippet) }}>
                    Välj
                </button>
            </td>
        </tr>
    )
}


const SnippetsHeader = () => {
    return (

        <div className="text-container-3">

            <div className="no-break">
                <ScreenHeader text="Snippets" icon={AdminIcons.paragraph} />
                <p>
                    Beskrivande texter som delas av flera växter.
                </p>
            </div>

            <div className="no-break">
                <h5>Allmän beskrivning</h5>
                <p>
                    Allmän text när man kan skriva om T.ex. Äppelträd.
                </p>
            </div>

            <div className="no-break">
                <h5>Odla & Plantera</h5>
                <p>
                    Allmän när man kan skriva om T ex Äppelträd,
                </p>
            </div>

            <div className="no-break">
                <h5>Sköta</h5>
                <p>
                    Allmän när man kan skriva om T ex Äppelträd.
                </p>
            </div>

            <div className="no-break">
                <h5>Gödsla</h5>
                <p>
                    (Kan ligga under skötsel men tänker om det är lite mer)
                </p>
            </div>

            <div className="no-break">
                <h5>Beskära</h5>
                <p>
                    Beskärning av växter.
                </p>
            </div>

            <div className="no-break">
                <h5>Tips!</h5>
                <p>
                    Tips om just den här typen av växter.
                </p>
            </div>

        </div>

    )
}

const SnippetsFilter = ({ filterChanged, availableTags }) => {

    const [textValue, setTextValue] = useState("");
    const [selectedSnippetType, setSelectedSnippetType] = useState("-");
    //const planttypes = usePlantTypes();
    const tags = useSnippetHelper().tags;// planttypes.map(obj => obj.name);
    ;

    const FIELD_KEY_OBJ = Data.snippetTypes;
    const snippetTypes = Object.keys(FIELD_KEY_OBJ).map(obj => { return { value: FIELD_KEY_OBJ[obj].key, label: FIELD_KEY_OBJ[obj].name } });

    const change = (text, type, tags) => {
        const data = {
            textValue: text,
            snippetType: type,
            tags: tags
        };
        if (filterChanged) {
            filterChanged(data);
        }
    }

    const onTextChange = (e) => {
        const val = e.target.value;
        setTextValue(val);
        change(val, selectedSnippetType, selectedTags);
    }
    const onSelectChanged = (e) => {
        const val = e.target.value;
        setSelectedSnippetType(val);
        change(textValue, val, selectedTags);
    }

    const [selectedTags, setSelectedTags] = useState([]);
    const tagClicked = (tag) => {
        let newValue = null;
        if (selectedTags.indexOf(tag) >= 0) {
            let s = [...selectedTags];
            s.splice(selectedTags.indexOf(tag), 1);
            newValue = s;
            setSelectedTags(s);
        } else {
            newValue = selectedTags.concat([tag]);
            setSelectedTags(newValue);
        }

        change(textValue, selectedSnippetType, newValue);
    }

    return (
        <div>
            <TwoColumnContainer>

                <FormTextInput
                    title="Filtrera namn"
                    name="textfilter"
                    onChange={onTextChange}
                    value={textValue}
                    className="form-large"
                    style={{ width: "100%" }}
                    invalid={null}
                />

                <div className="form-item form-large" style={{ width: "100%" }}>
                    <label>Fält</label>
                    <select value={selectedSnippetType} onChange={onSelectChanged} >
                        <option value="-">-</option>
                        {snippetTypes.map(type => (
                            <option key={type.value} value={type.value}>{type.label}</option>
                        ))}
                    </select>
                </div>
            </TwoColumnContainer>
            <div>
                <div className="snippets-tag-container">
                    {tags && tags.filter(n => availableTags?.[n]).map(n => (
                        <div key={n}
                            className={"snippets-tag " + (selectedTags.indexOf(n) >= 0 ? "selected" : "")}
                            onClick={() => { tagClicked(n) }}
                        >{n} ({availableTags?.[n]})</div>
                    ))}
                </div>
            </div>
        </div>
    )
}