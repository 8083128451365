import { useState } from "react"
import { FetchHandler } from "../../Handlers/FetchHandler";
import { MessageBoxes } from "../../Handlers/MessageBoxes";
import { StringNumberDictionary } from "../../Types";

export type PlantGroupType = {
    key: string
    name: string
    numberOfPlants: number
    examplePlantid: string
    exampleImageId: string
    examplePlantName: string
    examplePlantSpecificType: string
    label: string
    id: string
    imageid: string
    sys_datecreated_utc_epoch: number
}


export type PlantGroupLinkType = PlantGroupType & {
    reasonId: string
    reasonText: string
    saved: boolean
    sorting: number
    fitsWithKey: string
}

export const usePlantGroups = () => {

    const [rawData, setRawData] = useState<PlantGroupType[]>([]);
    const [reasonsCountPerKey, setReasonsCountPerKey] = useState<StringNumberDictionary>({})

    const [data, setData] = useState<PlantGroupType[]>([]);
    const [textFilter, _setTextFilter] = useState<string>(null);
    const [tagFilter, setTagFilter] = useState<string>(null);
    const [selectedGroupKey, setSelectedGroupKey] = useState<string>(null);
    const [selectedGroup, setSelectedGroup] = useState<string>(null);
    const [loadingReasons, setLoadingReasons] = useState<boolean>(false);
    const api = usePlantGroupsApi();

    const [reasons, setReasons] = useState<PlantGroupLinkType[]>([]);

    const loadReasonsCountPerKey = async () => {
        const d = await api.reasonsCountPerKey();
        setReasonsCountPerKey(d);
    }

    const load = async () => {
        let d: PlantGroupType[] = await api.getPlantGroups();
        d.sort((a, b) => {
            if (a?.name > b?.name) { return 1; }
            else if (a?.name < b?.name) { return -1; }
            else { return 0; }
        })
        setRawData(d);
        setData(d);
    }

    const setTextFilter = (e: string) => {
        _setTextFilter(e);
        setData(rawData.filter(m => m.name.toLowerCase().includes(e.toLowerCase())))
    }

    const sortAndSetReasons = (e: PlantGroupLinkType[]) => {
        let lst = [...e];
        lst.sort((a, b) => {
            if (a?.sorting > b?.sorting) { return 1 }
            else if (a?.sorting < b?.sorting) { return -1 }
            else { return 0 }
        })

        setReasons(lst);
        console.log("lst:", lst);
    }

    const loadReasons = async (key: string) => {
        setLoadingReasons(true)
        let r = await api.getReason(key);

        // Keep items not saved
        const not_saved = reasons.filter(m => !m?.reasonId);

        for (let i = 0; i < r.length; i++) {
            let rn = {
                ...r[i], saved: true,
            };
            r[i] = rn;
        }
        r = [...r, ...not_saved];
        console.log(r);
        sortAndSetReasons(r);
        setLoadingReasons(false)
    }

    const [copyDialogOpenSettings, setCopyDialogOpenSettings] = useState<PlantGroupLinkType>(null);


    const [showSelectPlantDialog, setshowSelectPlantDialog] = useState<boolean>(false)
    return {
        load: load,
        data: data,
        rawData: rawData,
        loadReasonsCountPerKey: loadReasonsCountPerKey,
        reasonsCountPerKey: reasonsCountPerKey,
        loadingReasons: loadingReasons,
        textFilter: textFilter, setTextFilter: setTextFilter,
        selectGroup: async (e: string) => {
            if (reasons?.filter(m => m?.saved === false)?.length > 0) {
                MessageBoxes.warning("Spara innan du byter växt");
            } else {
                setSelectedGroup(e === selectedGroup ? null : e);
                const key = rawData?.find(m => m?.id === e)?.key;
                setSelectedGroupKey(key);
                await loadReasons(key);
            }
        }, selectedGroup: selectedGroup,
        selectedGroupItem: rawData?.find(m => m.id === selectedGroup),
        deleteSelectedPlantGroup: () => {
            const key = rawData?.find(m => m.id === selectedGroup)?.key;
            api.deletePlantGroup(key);
            load();
        },
        reasons: reasons,
        tags: rawData?.filter(m =>
            (!tagFilter || m?.name?.toLowerCase().includes(tagFilter?.toLowerCase())) &&
            (reasons.findIndex(n => n.id === m?.id) < 0)
        ),
        addReason: (e: PlantGroupType, r: string) => {
            sortAndSetReasons([...reasons, { ...e, reasonText: r, saved: false, sorting: reasons?.length, reasonId: null, fitsWithKey: e?.key }])
        },
        tagFilter: tagFilter, setTagFilter: setTagFilter,


        saveReason: async (e: PlantGroupLinkType) => {
            setLoadingReasons(true);
            const ok = await api.saveReason(selectedGroupKey, e);
            const rsns = await api.getReason(selectedGroupKey);
            if (!ok) {
                MessageBoxes.warning("Save failed!")
            }

            let lst = [...reasons];
            const idx = lst.findIndex(m => m?.key === e?.key);
            if (idx >= 0) {
                lst[idx].saved = true;
                lst[idx].reasonId = rsns.find(m => lst[idx].key === m.fitsWithKey)?.reasonId;
            };
            sortAndSetReasons(lst);
            setLoadingReasons(false);
        },

        copyDialogOpenSettings: copyDialogOpenSettings,
        setCopyDialogOpenSettings: setCopyDialogOpenSettings,
        saveReasonCopy: async (e: PlantGroupLinkType, reasonText: string) => {
            setLoadingReasons(true);
            const key = e.key;
            const rKey = selectedGroupKey;
            const ok = await api.saveReason(key, { ...e, key: rKey, fitsWithKey: rKey, reasonText: reasonText, reasonId: null });
            if (!ok) {
                MessageBoxes.warning("Save failed!")
            } else {
                MessageBoxes.info("Anledning sparad!")
            }
            setLoadingReasons(false);
        },

        updateReasonText: async (e: PlantGroupLinkType, text: string) => {
            let lst = [...reasons];
            const idx = lst.findIndex(m => m?.key === e?.key);
            console.log("e:", e);
            console.log("id:", e?.key);
            if (idx >= 0) {
                lst[idx].saved = false;
                lst[idx].reasonText = text;
            };
            sortAndSetReasons(lst);
        },
        updateReasonSort: (e: PlantGroupLinkType, sort: number) => {
            let lst = [...reasons];
            console.log("updateReasonSort:", sort, e);
            const idx = lst.findIndex(m => m?.key === e?.key);
            if (idx >= 0) {
                lst[idx].saved = false
                lst[idx].sorting = sort
            };
            sortAndSetReasons(lst);
        },
        deleteReason: async (e: PlantGroupLinkType) => {
            setLoadingReasons(true);
            if (!!e.reasonId) {
                await api.deleteReason(selectedGroupKey, e);
                await loadReasons(selectedGroupKey);
            } else {
                sortAndSetReasons(reasons?.filter(m => m?.id !== e?.id));
            }
            //add db Remove...
            setLoadingReasons(false);
        },

        showSelectPlantDialog: showSelectPlantDialog,
        setshowSelectPlantDialog: setshowSelectPlantDialog,
        setGroupProfilePlant: async (plantId: string) => {
            const s = await api.setGroupProfilePlant(selectedGroupKey, plantId);
            load();
            return s;
        }
    }
}


export const usePlantGroupsApi = () => {

    return {
        getPlantGroups: async (): Promise<PlantGroupType[]> => {
            const d: PlantGroupType[] = await FetchHandler.getJson("/api/plantgroups");
            return d;
        },
        deletePlantGroup: async (key: string): Promise<boolean> => {
            const success: boolean = await FetchHandler.delete("/api/plantgroups/" + key)
            return success;
        },
        deleteReason: async (key: string, e: PlantGroupLinkType): Promise<boolean> => {
            const success: boolean = await FetchHandler.delete("/api/plantgroups/" + key + "/reasons/" + e?.reasonId)
            return success;
        },
        saveReason: async (key: string, e: PlantGroupLinkType): Promise<boolean> => {
            const success: boolean = await FetchHandler.postJson("/api/plantgroups/" + key + "/reasons", { ...e, fitsWithKey: e?.key });
            return success;
        },
        getReason: async (key: string): Promise<PlantGroupLinkType[]> => {
            const res: PlantGroupLinkType[] = await FetchHandler.getJson("/api/plantgroups/" + key + "/reasons");
            return res;
        },
        reasonsCountPerKey: async (): Promise<StringNumberDictionary> => {
            const res: StringNumberDictionary = await FetchHandler.getJson("/api/plantgroups/reasonscountperkey");
            return res;
        },
        setGroupProfilePlant: async (key: string, plantId: string) => {
            const success: boolean = await FetchHandler.patchJson("/api/plantgroups/" + key + "/setplant", { plantId: plantId });
            return success;
        }
        // 
    }

}