import React, { useEffect, useState } from "react"
import { FetchHandler } from "../../Handlers/FetchHandler"
import { StringHelper } from "../../Handlers/Helpers"
import { MessageBoxes } from "../../Handlers/MessageBoxes"
import { useDateHelper } from "../../hooks/useHelper"
import { useUsers } from "../../hooks/useUsers"
import { ForumPostType } from "../Forum/Forum"
import { ForumPostCard } from "../Forum/ForumPostCard"
import { AdminIcons } from "../Shared/AdminIcon"
import { ThreeColumnContainer } from "../Shared/Formelements"
import { ScreenHeader, ShareLinkElement } from "../UI/Elements"
import { JwtImage } from "../UI/ImageElements"

export const Comments = () => {

    const comments = useComments();
    const usserOps = useUsers();

    useEffect(() => {
        comments.loadComments();
        comments.loadReportedComments();
    }, [])

    const onDelete = (comment: CommentType) => {
        comments.deleteComment(comment)
    }
    const onShowThread = (comment: CommentType) => {
        comments.showThread(comment?.postid)
    }



    return (
        <div className="comments-screen">
            <ScreenHeader text="Kommentarer" icon={AdminIcons.comments} onReloadData={() => {
                comments.loadComments();
                comments.loadReportedComments();
            }} />
            <ThreeColumnContainer gridTemplateColumns="35% 350px 35% auto">


                {/* Rapporterade */}
                <div className="scroll-column">

                    <h3>Rapporterade</h3>
                    {comments?.reportedComments?.map(c => (
                        <CommentCard key={c?.id} comment={c} onDelete={onDelete} onShowThread={onShowThread} />
                    ))}
                </div>

                {/* Tråd */}
                <div className="scroll-column">
                    <h3>Tråd</h3>
                    {comments?.post && (
                        <div className="post">
                            <div className="post-alias">
                                {usserOps.getUser(comments?.post?.userid)?.name}
                            </div>
                            <div>
                                <JwtImage imageId={comments?.post?.imageids?.[0]} format="hd" openOnClick={true} />
                            </div>
                            <div className="post-text">
                                {comments?.post?.text}
                            </div>

                            <div className="post-share">
                                <div>
                                    Delningslänk
                                </div>
                                <ShareLinkElement id={comments?.post?.id} type={"post"} />
                            </div>
                        </div>
                    )}
                    {comments?.postComments?.map(c => (
                        <CommentCard key={c?.id} comment={c} onDelete={onDelete} showDelete={true} />
                    ))}
                </div>


                {/* Senaste */}
                <div className="scroll-column">
                    <h3>Senaste</h3>
                    {comments?.comments?.map(c => (
                        <CommentCard key={c?.id} comment={c} onDelete={onDelete} onShowThread={onShowThread} />
                    ))}
                </div>



            </ThreeColumnContainer>
        </div>
    )
}

const CommentCard = ({ comment, onDelete, onShowThread, showDelete }:
    { comment: CommentType, onDelete(e: CommentType): void, onShowThread?(e: CommentType): void, showDelete?: boolean }) => {

    const helper = useDateHelper();
    const user = useUsers();
    return (
        <div>
            <div className={"comment " + (comment?.deleted ? "comment-deleted" : "")}>
                <div>
                    <div className="alias">
                        {user.getUser(comment.userid)?.name}
                    </div>
                    <div className="title">
                        {comment?.text}
                    </div>
                </div>

                <div className="cm-button">
                    {(showDelete && !comment?.deleted) && (
                        <button onClick={() => onDelete(comment)}>
                            Radera
                        </button>
                    )}
                    {!showDelete && (
                        <button onClick={() => onShowThread(comment)}>
                            Visa tråd
                        </button>
                    )}
                </div>

                <div className="ts">
                    <span title={helper.epochToDateTime(comment?.postedepoch)}>
                        {helper.timeAgo(comment?.postedepoch)}
                    </span>
                </div>
                <div></div>

            </div>
            {comment?.deleted && (
                <div className="deleted-text">
                    Kommentaren är raderad
                </div>
            )}
            {comment?.reported && (
                <div className="deleted-text">
                    Kommentaren är rapporterad
                </div>
            )}

            {comment?.responses && (
                <div className="comment-responses">
                    {comment?.responses?.map(response => (
                        <CommentCard key={response?.id} comment={response} onDelete={onDelete} showDelete={true} ></CommentCard>
                    ))}
                </div>
            )}
        </div>
    )
}


type CommentType = {
    id: string;
    label: string;
    postedepoch: number;
    partition: string;
    name: string;
    postid: string;
    userid: string;
    changedepoch: number;
    text: string;
    sys_datecreated_utc_epoch: number;
    sys_datemodified_utc_epoch: number;
    responsetocommentid: string;
    postuserid: string;
    firstcomment?: boolean;
    reported?: boolean;
    reportedby: string;
    responses: CommentType[],
    deleted?: boolean
}
type PostType = {
    id: string;
    label: string;
    hascomments: boolean;
    lastcommentepoch: number;
    name: string;
    userid: string;
    clientpostid: string;
    epochposted: number;
    epochupdated: number;
    epochshared: number;
    gardenitemid: string;
    gardenid?: any;
    location: string;
    poststate: string;
    poststatechanged: number;
    queuekey: string;
    queuedepoch: number;
    state: string;
    text: string;
    update: boolean;
    zone: string;
    numberoflikes: number;
    soil: string[];
    sunexposure: string[];
    imageids: string[];
    plantids: string[];
    sys_datemodified_utc_epoch: number;
    sys_modified_by_user_id: string;
    partition: string;
    sys_datecreated_utc_epoch: number;
    sys_created_by_user_id: string;
}


const useComments = () => {

    const userOps = useUsers();
    const [reportedComments, setReportedComments] = useState<CommentType[]>([]);
    const [comments, setComments] = useState<CommentType[]>([]);
    const [postComments, setPostComments] = useState<CommentType[]>([]);
    const [post, setPost] = useState<PostType>(null);

    const loadComments = async () => {
        const data: CommentType[] = await FetchHandler.getJson("/api/comments/all");

        let d = {};
        data.map(m => {
            d[m?.userid] = m?.userid;
            d[m?.postuserid] = m?.postuserid;
        });
        const userids = Object.keys(d);
        userOps.ensureUserInfo(userids);

        setComments(data);
    }

    const loadReportedComments = async () => {
        const data = await FetchHandler.getJson("/api/comments/reported");
        setReportedComments(data);
    }
    const deleteComment = async (comment: CommentType) => {
        if (MessageBoxes.confirm("Radera: '" + comment?.text + "'?")) {
            const url = "/api/comments/" + comment?.id;
            const success = await FetchHandler.delete(url);
            MessageBoxes.successAlert(success, "Kommentaren togs bort!");
            if (success) {
                await loadComments();
                await loadReportedComments();
            }

            await showThread(comment?.postid);
        }
    }
    const showThread = async (postId: string) => {
        const url = "/api/comments/post/" + postId;
        let data: CommentType[] = await FetchHandler.getJson(url);

        data.sort((a, b) => {
            if (a?.postedepoch > b?.postedepoch) { return 1; }
            else if (a?.postedepoch < b?.postedepoch) { return -1; }
            else { return 0; }
        })
        let primary = data.filter(m => !m?.responsetocommentid);
        let secondaries = data.filter(m => !!m?.responsetocommentid);
        primary.forEach(prim => {
            prim.responses = secondaries.filter(m => m.responsetocommentid == prim?.id);
            secondaries = secondaries.filter(m => m.responsetocommentid !== prim?.id);
        });

        primary = primary.concat(secondaries);
        setPostComments(primary);

        await loadPost(postId);
    }
    const loadPost = async (postId: string) => {
        const url = "/api/post/" + postId;
        const data: PostType = await FetchHandler.getJson(url);
        setPost(data);
    }

    return {
        loadComments: loadComments,
        loadReportedComments: loadReportedComments,
        comments: comments,

        reportedComments: reportedComments,
        postComments: postComments,
        post: post,

        deleteComment: deleteComment,
        showThread: showThread,
        getUser: userOps.getUser,
    }
}