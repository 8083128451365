import React from 'react';
import { LoadingIcon } from './Icons';
// <LoadingElement visible={true} />
export const LoadingElement = (props) => {
    let visible = false;
    if (props.visible === null || props.visible === undefined) {
        visible = true;
    } else {
        visible = props.visible;
    }

    if (visible === true) {
        return (
            <div className="loading-el">
                Loading   <LoadingIcon />
            </div>
        );
    } else {
        return (<></>);
    }
}