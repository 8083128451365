import React, { useEffect, useState } from "react"
import { Link } from 'react-router-dom';
import { FetchHandler } from "../../Handlers/FetchHandler"
import { StringHelper } from "../../Handlers/Helpers"
import { useDateHelper } from "../../hooks/useHelper"
import { AdminIcons } from "../Shared/AdminIcon"
import { ThreeColumnContainer } from "../Shared/Formelements"
import { ScreenHeader } from "../UI/Elements"

export type OsType = "ios" | "android";
type userType = {
    id: string,
    email: string,
    alias: string,
    municipality: string,
    osnotification_checked: number,
    appversion: string,
    sys_datecreated_utc_epoch: number,
    os: OsType
}

export const ToolsScreen = () => {


    const [users, setUsers] = useState<userType[]>(null);
    const [listedUsers, setListedUsers] = useState<userType[]>([]);
    const [selectedOS, setSelectedOS] = useState<OsType>(null)

    const load = async () => {
        const data = await FetchHandler.getJson("/api/notifications/notificationscheckedusers");
        let d: userType[] = Object.keys(data ?? {}).map(key => data?.[key]);
        d.forEach(u => {
            if (u.appversion.indexOf("ios") >= 0) {
                u.os = "ios";
            } else {
                u.os = "android";
            }
        });
        d.sort((a, b) => {
            const ak = a?.osnotification_checked;
            const bk = b?.osnotification_checked;
            if (ak < bk) { return 1; }
            else if (ak > bk) { return -1; }
            else { return 0; }
        })
        setUsers(d);
    }

    let timer = null;
    useEffect(() => {
        load();
        timer = setInterval(() => { load(); }, 180 * 1000)
        return () => {
            clearInterval(timer)
        }
    }, []);

    useEffect(() => {
        setListedUsers(users?.filter(m => { return !selectedOS ? true : m.os == selectedOS; }));
    }, [users, selectedOS])




    return (
        <div className="tools-screen">
            <ScreenHeader text="Verktyg" icon={AdminIcons.hammer} />
            <div>
                <div>
                    <h3>Os notifieringar ({users?.length})</h3>
                    <div>
                        <button className="btn" onClick={() => { setSelectedOS(selectedOS == "ios" ? null : "ios") }}>
                            iOS
                        </button>
                        <button className="btn" onClick={() => { setSelectedOS(selectedOS == "android" ? null : "android") }}>
                            Android
                        </button>
                    </div>
                    <hr />
                    <table className="tbl">
                        <thead>
                            <tr>
                                <th></th>
                                <th>Alias</th>
                                <th>Kommun</th>
                                <th>Notiser hämtat</th>
                                <th>OS</th>
                                <th>Version</th>
                                <th>Konto skapat</th>
                            </tr>
                        </thead>
                        <tbody>
                            {listedUsers?.map((u, index) => (<UserCard key={u.id} user={u} index={index} />))}
                        </tbody>
                    </table>
                </div>
                {/* <div>
                    <h3>laksjdklsa</h3>
                </div>
                <div>
                    <h3>laksjdklsa</h3>
                </div> */}

            </div   >
        </div>
    )
}

const UserCard = ({ user, index }: { user: userType, index: number }) => {
    const helper = useDateHelper();
    return (
        <tr className="user-card">
            <td>{index + 1}</td>
            <td className="name">
                <Link to={"/user/" + user?.id} >
                    {user?.alias}
                </Link>
            </td>
            <td className="city">{user?.municipality}</td>
            <td className="checked">{helper.timeAgo(user?.osnotification_checked)}</td>
            <td className="app-version">{user?.os?.toUpperCase()}</td>
            <td className="app-version">{user?.appversion}</td>
            <td className="created"><TimeAgoText epoch={user?.sys_datecreated_utc_epoch} /></td>
        </tr>
    )
}


const TimeAgoText = ({ epoch }) => {
    const helper = useDateHelper();
    const text = helper.timeAgo(epoch);
    return (
        <>
            {text}
        </>
    )
}