import React, { useEffect, useState } from "react"
import { Link } from 'react-router-dom';
import { FetchHandler } from "../../Handlers/FetchHandler";
import { MessageBoxes } from "../../Handlers/MessageBoxes";
import { useDateHelper } from "../../hooks/useHelper";
import { PostExtensionType } from "../PlantImageOperations/useSocialImageOperations";
import { FeaturedPostType } from "../PostsManagement/usePostsManagement";
import { AdminIcons } from "../Shared/AdminIcon";
import { ThreeColumnContainer } from "../Shared/Formelements";
import { ToolBar } from "../ToolBar/ToolBar";
import { ScreenHeader, ShareLinkElement } from "../UI/Elements";
import { JwtImage } from "../UI/ImageElements";
import { ForumPostCard } from "./ForumPostCard";



export type ForumPostType = {
    id: string;
    label: string;
    epochposted: number;
    numberoflikes: number;
    name: string;
    userid: string;
    clientpostid: string;
    epochupdated: number;
    epochshared?: any;
    epochaccomplished?: any;
    gardenitemid?: any;
    gardenid?: any;
    location: string;
    poststate: string;
    poststatechanged?: any;
    postextension: PostExtensionType;
    combinationheader: string;
    queuekey: string;
    queuedepoch: number;
    state: string;
    text: string;
    update: boolean;
    zone: string;
    numberofcomments: number;
    lastcommentepoch: number;
    imageid: string;
    soil: any[];
    sunexposure: any[];
    imageids: string[];
    plantids: string[];
    sys_datemodified_utc_epoch: number;
    sys_modified_by_user_id: string;
    partition: string;
    sys_datecreated_utc_epoch: number;
    sys_created_by_user_id: string;
    alias: string;
    profileimage: string;

    featured?: boolean,
    featuredtype?: FeaturedPostType;
    featuredimageid?: string
}

export const Forum = () => {
    const ui = useForum();

    useEffect(() => {
        ui.load();
    }, [])

    return (
        <div className="screen-forum">
            <ScreenHeader text="Forum" icon={AdminIcons.comments} />
            <ToolBar tools={[
                { id: "0", label: "Sida: " + (ui.pageIndex + 1), onClick: () => ui.setPageIndex(0) },
                { id: "p", label: "Föregående", onClick: ui.goPrev },
                { id: "n", label: "Nästa", onClick: ui.goNext },
            ]} />

            <div className="forum-post-grid">
                {ui.pagedData?.map(post => (
                    <ForumPostCard key={post?.id} post={post} openImageOnClick={true} />
                ))}
            </div>
        </div>
    )
}


const useForum = () => {
    const [data, setData] = useState<ForumPostType[]>([]);

    const [pagedData, setPagedData] = useState<ForumPostType[]>([]);
    const [pageSize, setPageSize] = useState<number>(4);
    const [pageIndex, setPageIndex] = useState<number>(0);

    useEffect(() => {
        setPagedData(data.filter((m, idx) => {
            const min = pageIndex * pageSize;
            const max = pageIndex * pageSize + 4;
            return idx < max && idx >= min;
        }))
    }, [data, pageSize, pageIndex])


    return {
        load: async () => {
            const r: ForumPostType[] = await FetchHandler.getJson("/api/forum/all");
            setData(r);

        },
        data: data,
        pagedData: pagedData,
        pageIndex: pageIndex,
        setPageIndex: setPageIndex,
        goNext: () => { setPageIndex(pageIndex + 1) },
        goPrev: () => {
            if (pageIndex > 0) {
                setPageIndex(pageIndex - 1)
            }
        },
    }
}