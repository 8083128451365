import React, { useEffect } from "react"
import { usePlant } from "../../hooks/usePlant"
import { JwtImage, JwtPlantImage } from "./ImageElements";


export const PlantRow = ({ plantId }: { plantId: string }) => {

    const plantStore = usePlant();
    const plant = plantStore.item(plantId);

    return (
        <div className="el-plantrow-container">
            <div className="el-plantrow-img">
                <JwtPlantImage
                    plantImageId={plant?.fields?.imageid}
                    sizeType="generic" />
            </div>
            <div className="el-plantrow-info">
                <div className="name">
                    Namn: {plant?.fields?.name}
                </div>
                <div className="specifictype">
                    Sort: {plant?.fields?.specifictype}
                </div>
                <div className="planttype">
                    Typ: {plant?.plantTypeName}
                </div>
            </div>
        </div>
    )
}