import React, { useState } from 'react';
import { useSelector } from 'react-redux';
import { PlantHandler } from '../../../Handlers/PlantHandler';
import { RdxStore } from '../../../rdx/ReduxTypes';
import { Dialog } from '../Dialog';
import { TwoColumnContainer } from '../Formelements';
import { Portal } from "../Portal";

export const EditTwoTextFieldDialog = ({ field1, field2, closeDialog }) => {

    const f1 = field1.toLowerCase();
    const f2 = field2.toLowerCase();

    const field1Name = useSelector((store: RdxStore) => store.data.plantfieldsobject[f1].name);
    const field2Name = useSelector((store: RdxStore) => store.data.plantfieldsobject[f2].name);

    const ids = useSelector((store: RdxStore) => store.search.searchList.filter(obj => obj.selected).map(obj => obj.id));
    const id = ids[0];
    const plant = useSelector((store: RdxStore) => store.search.searchList.filter(obj => obj.id === id))[0];

    const [field1Value, setField1Value] = useState(plant?.fields[f1]);
    const [field2Value, setField2Value] = useState(plant?.fields[f2]);

    const update = () => {
        PlantHandler.updateTwoFields(id, field1, field2, field1Value, field2Value)
            .then(success => {
                if (success && closeDialog) {
                    closeDialog();
                }
            })
    }


    return (
        <Dialog
            onOk={update}
            onCancel={() => { closeDialog(); }}
            width="1000px"
        // okDisabled={value === null }
        // disabled={dialogdisabled}
        >
            <h1>
                {plant?.fields?.name}
            </h1>
            <h4>
                {plant?.fields?.specifictype}
            </h4>
            <div>Latinskt namn: {plant?.fields?.namelatin}</div>
            <TwoColumnContainer>
                <div>
                    <span className="bold">
                        {field1Name}
                    </span>
                    ({field1})
                </div>
                <div>
                    <span className="bold">
                        {field2Name}
                    </span>
                    ({field2})
                </div>
                <textarea style={{ height: "500px" }} value={field1Value} onChange={(e) => { setField1Value(e.target.value) }}></textarea>
                <textarea style={{ height: "500px" }} value={field2Value} onChange={(e) => { setField2Value(e.target.value) }}></textarea>
            </TwoColumnContainer>
        </Dialog>
    )

}