import React, { useEffect, useState } from "react"
import { DragDropContainer, DropTarget } from 'react-drag-drop-container';
import { FetchHandler } from "../../Handlers/FetchHandler";
import { MessageBoxes } from "../../Handlers/MessageBoxes";
import { useReduxState } from "../../hooks/reduxHook";
import { DropArea, useDropElement } from "../ClipBoard/ClipBoard";
import { ClipBoardDropTargets } from "../ClipBoard/useClipBoard";
import { ForumPostCard } from "../Forum/ForumPostCard";
import { PostImage } from "../PlantImageOperations/SocialImageOperations";
import { ApiPostWithImages } from "../PlantImageOperations/useSocialImageOperations";
import { AdminIcon, AdminIcons } from "../Shared/AdminIcon";
import { CustomColumnContainer, ThreeColumnContainer } from "../Shared/Formelements";
import { LoadingBar, ScreenHeader } from "../UI/Elements";
import { JwtImage } from "../UI/ImageElements";
import { useCombines } from "./useCombines";



export const Combines = () => {
    const ui = useCombines();
    return (
        <div>
            <ScreenHeader text="Kombinationer" icon={AdminIcons.fire} />

            <LoadingBar loading={ui.loading} />

            <ThreeColumnContainer gridTemplateColumns="250px 10px 350px auto">


                <div>
                    <h2>
                        Tillgängliga
                    </h2>
                    {ui.data?.map(item => (
                        <div key={item?.id}>
                            <div>
                                <div>
                                    <input
                                        defaultValue={item?.combinationheader}
                                        type={"text"}
                                        className="w90"
                                        placeholder="Kombinationens rubrik"
                                        onChange={e => ui.setHeader(e.target.value)}
                                        onKeyUp={e => {
                                            if (e.key == "Enter") {
                                                ui.setPostCombinationHeader(item?.id, ui.header);
                                            }
                                        }}
                                    />
                                </div>
                                <input type={"checkbox"} id={"db-" + item?.id}
                                    checked={ui.selectedPostId === item?.id}
                                    onChange={(e) => {
                                        if (e.target.checked) {
                                            ui.setSelectedPostId(item?.id)
                                        }
                                    }}
                                />
                            </div>
                            <ForumPostCard
                                post={item}
                                hideLink={true}
                                selected={false}
                                overrrideLink={true}
                            />
                        </div>

                    ))}
                </div>

                <div></div>

                <div>
                    <h2>
                        Vald
                    </h2>
                    {ui.data?.filter(m => m?.id === ui.selectedPostId)?.map(item => (
                        <div key={item?.id}>
                            <div className="forum-post-width">
                                <input
                                    defaultValue={item?.combinationheader}
                                    type={"text"}
                                    className="w100"
                                    placeholder="Kombinationens rubrik"
                                    onChange={e => ui.setHeader(e.target.value)}
                                    onKeyUp={e => {
                                        if (e.key == "Enter") {
                                            ui.setPostCombinationHeader(item?.id, ui.header);
                                        }
                                    }}
                                />
                                {/* <input type={"checkbox"} id={"db-" + item?.id}
                                    checked={ui.selectedPostId === item?.id}
                                    onChange={(e) => {
                                        if (e.target.checked) {
                                            ui.setSelectedPostId(item?.id)
                                        }
                                    }}
                                /> */}
                            </div>
                            <ForumPostCard
                                post={item}
                                hideLink={true}
                                selected={false}
                                overrrideLink={true}
                            />
                        </div>

                    ))}
                </div>

            </ThreeColumnContainer>

        </div>
    )

}