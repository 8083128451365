import React from 'react';


export const FaIcon = (props) => {
    const customClass = props.className ? " " + props.className : "";
    return (
        <>
            <i className={"faicon " + props.faClass + customClass} title={props.title}></i>
        </>
    )
}

// Generic
export const EditIcon = () => {
    return (<FaIcon faClass="far fa-edit" title="Ändra" />)
}
export const SaveIcon = () => {
    return (<FaIcon faClass="far fa-save" title="Spara" />)
}
export const CancelIcon = () => {
    return (<FaIcon faClass="far fa-window-close" title="Stäng" />)
}
export const RemoveIcon = () => {
    return (<FaIcon faClass="fas fa-minus" title="Stäng" />)
}
export const TrashIcon = ({ title }: { title?: string }) => {
    return (<FaIcon faClass="fas fa-trash-alt" title={title ? title : "Stäng"} />)
}
export const AddIcon = () => {
    return (<FaIcon faClass="fas fa-plus" title="Lägg till" />)
}
export const LinkIcon = () => {
    return (<FaIcon faClass="fas fa-link" title="Lägg till" />)
}

export const MoveDownIcon = () => {
    return (<FaIcon faClass="fas fa-chevron-down" title="Lägg till" />)
}
export const MoveUpIcon = () => {
    return (<FaIcon faClass="fas fa-chevron-up" title="Lägg till" />)
}

export const MoveLeftIcon = (props) => {
    return (<FaIcon faClass="fas fa-chevron-left" title={props.title} />)
}
export const MoveRightIcon = (props) => {
    return (<FaIcon faClass="fas fa-chevron-right" title={props.title} />)
}
export const LoadingIcon = () => {
    return (<FaIcon faClass="fas fa-sync fa-spin" title="Laddar..." />)
}


// Form
export const CheckboxCheckedIcon = () => {
    return (<FaIcon faClass="far fa-check-square" />)
}
export const CheckboxUnCheckedIcon = () => {
    return (<FaIcon faClass="far fa-square" />)
}


// UI
// StartIcon, UsersIcon, PlantsIcon, AddPlantIcon, ImagesIcon, InspirationIcon, ColorIcon, FieldIcon
export const StartIcon = (props) => {
    return (<FaIcon faClass="fab fa-pagelines" className={props.className} />)
}
export const StarIcon = (props) => {
    return (<FaIcon faClass="fas fa-star" className={props.className} />)
}
export const UsersIcon = (props) => {
    return (<FaIcon faClass="fas fa-users" className={props.className} />)
}
export const PlantsIcon = (props) => {
    return (<FaIcon faClass="fab fa-pagelines" className={props.className} />)
}
export const AddPlantIcon = (props) => {
    return (<FaIcon faClass="fas fa-plus" className={props.className} />)
}
export const ImagesIcon = (props) => {
    return (<FaIcon faClass="far fa-images" className={props.className} />)
}
export const InspirationIcon = (props) => {
    return (<FaIcon faClass="far fa-lightbulb" className={props.className} />)
}
export const SearchIcon = (props) => {
    return (<FaIcon faClass="fas fa-search" className={props.className} />)
}


export const ColorIcon = (props) => {
    return (<FaIcon faClass="fas fa-tint" className={props.className} />)
}
export const FieldIcon = (props) => {
    return (<FaIcon faClass="fab fa-envira" className={props.className} />)
}


export const VisibleIcon = (props) => {
    return (<FaIcon faClass="far fa-eye" className={props.className} />)
}
export const NotVisibleIcon = (props) => {
    return (<FaIcon faClass="far fa-eye-slash" className={props.className} />)
}


export const HasImageIcon = (props) => {
    return (<FaIcon faClass="fas fa-image" className={props.className} />)
}
export const HasntImageIcon = (props) => {
    return (
        <span className="fa-stack" style={{ verticalAlign: "top" }}>
            <i className="fas fa-image fa-stack-1x"></i>
            <i className="fas fa-ban fa-stack-2x"></i>
        </span>)
}