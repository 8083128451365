import React, { useEffect, useState } from "react"
import { Link } from 'react-router-dom';
import { FetchHandler } from "../../Handlers/FetchHandler";
import { useDateHelper } from "../../hooks/useHelper";
import { AdminIcon, AdminIcons, AndroidIcon, IOsIcon } from "../Shared/AdminIcon";
import { CustomColumnContainer } from "../Shared/Formelements";
import { OsType } from "../Tools/ToolsScreen";
import { ScreenHeader } from "../UI/Elements";
import { ProductIdsType } from "./Subscriptions";


type EmptySubscriptionType = {
    purchaseId: string,
    active: true,
    currentState: string,
    productId: ProductIdsType,
    timeStampMillis: number,
    createdTimeStampMillis: number,
}
export const SubscriptionsWithoutUser = () => {

    const dte = useDateHelper();
    const ui = useUI()

    useEffect(() => {
        ui.load();
    }, [])


    return (
        <div className="screen-subscriptions">
            <ScreenHeader text="Prenumerationer utan användare" icon={AdminIcons.dollarsign} />
            <div>
                <hr />
                <table className="tbl">
                    <thead>
                        <tr>
                            <th>Köpt</th>
                            <th>Köpt tid</th>
                            <th>Köpt tid (UTC)</th>
                            <th>Produkt</th>
                            <th>Senast</th>
                            <th>Senast (UTC)</th>
                            <th>Status</th>
                        </tr>
                    </thead>
                    <tbody>
                        {ui.data.map((sub, idx) => (
                            <TblLine key={sub?.purchaseId} sub={sub} />
                        ))}
                    </tbody>
                </table>
            </div>
        </div>
    )
}

const TblLine = ({ sub }: { sub: EmptySubscriptionType }) => {
    const dte = useDateHelper();
    return (

        <tr key={sub?.purchaseId}>
            <td>{dte.timeAgo(sub?.createdTimeStampMillis / 1000)}</td>
            <td>{dte.epochToDateTime(sub?.createdTimeStampMillis / 1000)}</td>
            <td>{dte.epochToDateTimeUtc(sub?.createdTimeStampMillis / 1000)}</td>
            <td style={{ textDecoration: !sub?.active ? "line-through" : null }}>{sub?.productId}</td>
            <td>{dte.epochToDateTime(sub?.timeStampMillis / 1000)}</td>
            <td>{dte.epochToDateTimeUtc(sub?.timeStampMillis / 1000)}</td>
            <td>{sub?.currentState}</td>
        </tr>
    )
}


const useUI = () => {

    const [data, setdata] = useState<EmptySubscriptionType[]>([]);

    const load = async () => {
        const d = await FetchHandler.getJson("/api/subscriptions/nouser");
        setdata(d);
    }

    return {
        load: load,
        data: data,
    }

}