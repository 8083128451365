import React, { useEffect, useState } from 'react';
import { Dialog } from '../Shared/Dialog';



type templateType = {
    match?: string,
    text?: string,
    suffix?: string,
    prefix?: string,
    regex?: string,
}
type formatterValue = {
    matchvalue: string,
    textValue: string
}

const emptyTemplateType = { match: "", prefix: "", suffix: "", text: "", regex: "" }

export const FormatterEditor = ({ value, onChange }: { value: string, onChange(e: string): void }) => {

    const [dialogVisible, setDialogVisible] = useState<boolean>(false);

    const [isValid, setIsValid] = useState(false);
    const [localValue, setLocalValue] = useState<templateType[]>([]);
    const [itemToAdd, setItemToAdd] = useState<templateType>({ ...emptyTemplateType })



    useEffect(() => {
        if (value) {
            if (ValidateJson(value)) {
                setLocalValue(JSON.parse(value));
            }
        }
    }, [, value])

    return (
        <div className='formattereditor'>
            <div>
                <button className='btn btn-formattereditor' onClick={() => { setDialogVisible(true) }}>
                    Ändra namn beroende på växttyp
                </button>
                <div>
                    Matcha växttyp:
                </div>
                <div className='show'>
                    {localValue && localValue.map(i => (
                        <div key={i.match}>
                            {i.match} :

                            {i.prefix ? "(Prefix: '" + i.prefix + "')" : ""}
                            {i.suffix ? "(Suffix: '" + i.suffix + "')" : ""}
                        </div>
                    ))}
                </div>
            </div>
            {dialogVisible && (
                <div>
                    <Dialog title="Ändra namn beroende på växttyp"
                        onOk={() => {
                            let trimmed = [...localValue];
                            trimmed.forEach(item => {
                                item.match = item?.match?.trim();
                                item.prefix = item?.prefix?.trim();
                                item.suffix = item?.suffix?.trim();
                                item.text = item?.text?.trim();
                                item.regex = item?.regex?.trim();
                            });
                            onChange(trimmed?.length > 0 ? JSON.stringify(trimmed) : null);
                            setDialogVisible(false);
                        }}
                        onCancel={() => { setDialogVisible(false); }}
                        width="800px"
                        height='600px'>

                        <table className='tbl'>
                            <thead>
                                <tr>
                                    <th>
                                        Match
                                    </th>
                                    {/* <th>
                                        Text
                                    </th> */}
                                    <th>
                                        Prefix 'växttyp'
                                    </th>
                                    <th>
                                        'Växttyp' Suffix
                                    </th>
                                    {/* <th>
                                        Regex
                                    </th> */}
                                    <th></th>
                                </tr>
                            </thead>
                            <tbody>
                                {localValue && localValue.map(item => (
                                    <FormatterRow key={item.match} item={item}
                                        onChange={(e => {
                                            const idx = localValue.findIndex(m => m.match == e.match);
                                            let n = [...localValue];
                                            n[idx] = e;
                                            setLocalValue(n);
                                        })}>
                                        <button onClick={() => { setLocalValue([...localValue.filter(m => m.match != item.match)]); }} >
                                            Ta bort
                                        </button>
                                    </FormatterRow>
                                ))}
                                <tr>
                                    <td colSpan={4} className="tbl-distance" ></td>
                                </tr>
                                <FormatterRow
                                    item={itemToAdd}
                                    onChange={e => {
                                        setItemToAdd(e);
                                    }}>
                                    <button onClick={() => {
                                        setLocalValue([...localValue, { ...itemToAdd }]);
                                        setItemToAdd({ match: "", prefix: "", suffix: "", text: "", regex: "" });
                                    }} disabled={localValue.findIndex(obj => obj.match === itemToAdd?.match) >= 0}> Lägg till </button>
                                </FormatterRow>
                            </tbody>
                        </table>

                    </Dialog>
                </div>
            )}
        </div>
    )
}

const FormatterRow = ({ item, onChange, children }: { item: templateType, onChange(e: templateType): void, children?: any }) => {
    return (
        <tr>
            <td>
                <input className='w100' type="text" value={item.match} onChange={e => { onChange({ ...item, match: e.target.value?.trim() }) }} />
            </td>
            {/* <td>
                <input className='w100' type="text" value={item.text} onChange={e => { onChange({ ...item, text: e.target.value }) }} />
            </td> */}
            <td>
                <input className='w100' type="text" value={item.prefix} onChange={e => { onChange({ ...item, prefix: e.target.value?.trim() }) }} />
            </td>
            <td>
                <input className='w100' type="text" value={item.suffix} onChange={e => { onChange({ ...item, suffix: e.target.value?.trim() }) }} />
            </td>
            {/* <td>
                <input className='w100' type="text" value={item.regex} onChange={e => { onChange({ ...item, regex: e.target.value }) }} />
            </td> */}
            <td>
                {children}
            </td>
        </tr>
    )
}




const ValidateJson = (val: string) => {
    try {
        const d = JSON.parse(val);
        if (d) {
            return true;
        }
    }
    catch {
        return false;
    }
    return false;
}


const JSONEditor = ({ value, onChange }) => {
    const [localValue, setLocalValue] = useState(value)

    const [valid, setValid] = useState(true);
    const validate = (val) => {
        try {
            const d = JSON.parse(val);
            if (d) {
                setValid(true);
                return;
            }
        }
        catch {

        }
        setValid(false);
    }

    useEffect(() => {
        setLocalValue(value);
        validate(value);
    }, [, value])

    const handleChange = (event) => {
        setLocalValue(event.target.value);
        validate(event.target.value);
    };

    return (
        <div>
            <textarea
                className={'w100 codeinput ' + (valid ? "valid-json" : "invalid-json")}
                value={localValue}
                onChange={handleChange}></textarea>
        </div>
    )


}