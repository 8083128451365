import { FetchHandler } from "../Handlers/FetchHandler"
import { MessageBoxes } from "../Handlers/MessageBoxes";
import { SearchHandler } from "../Handlers/SearchHandler";
import { CHANGE_COLOR_LIST, CHANGE_CURRENT_PLANTLIST, CHANGE_PLANTFIELD_OBJECT, CHANGE_PLANTIMAGES, CHANGE_PLANTLISTS, CHANGE_PLANTLISTS_LINKED_TO_OBJECT } from "./dataReducer";
import { CHANGE_SEARCH_ALLSEARCHHITS, CHANGE_SEARCH_LIST } from "./searchReducer";
import store from "./store";
import { Reloads } from "./StoreFillerHandler";


export const Operations = {

    // Lists
    linkPlantsToList: async (listId: string, plantIds: string[]) => {
        const postObj = {
            ids: plantIds
        };
        console.log("plantIds: ", postObj);
        const data = await FetchHandler.postJson("/api/plantlist/" + listId + "/link", postObj);

        if (store.getState().data.currentplantlist?.id === listId) {
            Operations.selectCurrentPlantList(listId);
        }

        return data;
    },
    removePlantsFromList: async (listId, plantIds) => {
        const postObj = {
            ids: plantIds
        };
        const data = await FetchHandler.postJson("/api/plantlist/" + listId + "/unlink", postObj);
        if (data) {
            let lists = { ...store.getState().data.plantlists };
            if (lists[listId]) {
                lists[listId].numberOfPlants = lists[listId].numberOfPlants - 1;
                store.dispatch({ type: CHANGE_PLANTLISTS, payload: lists });
            }
        }
        return data;
    },
    snippetAppliesToPlants: async (plantIds, snippetId, selectedSnippetType) => {
        const postObj = {
            ids: plantIds
        };
        const url = "api/snippet/" + snippetId + "/link";
        const data = await FetchHandler.postJson(url, postObj);

        let list = [...store.getState().search.searchList];
        for (let i = 0; i < list.length; i++) {
            if (plantIds.indexOf(list[i].id) >= 0) {
                list[i].fields[selectedSnippetType?.toLowerCase()] = snippetId;
            }
        }
        store.dispatch({ type: CHANGE_SEARCH_LIST, payload: list });
        return data;
    },
    removeSnippetFromPlants: async (plantIds, snippetType, selectedSnippetType) => {
        const postObj = {
            ids: plantIds,
            snippetType: snippetType
        };
        const url = "api/snippet/unlink";
        const data = await FetchHandler.postJson(url, postObj);

        let list = [...store.getState().search.searchList];
        for (let i = 0; i < list.length; i++) {
            if (plantIds.indexOf(list[i].id) >= 0) {
                delete list[i].fields[selectedSnippetType?.toLowerCase()];
            }

        }
        store.dispatch({ type: CHANGE_SEARCH_LIST, payload: list });

        return data;
    },
    selectCurrentPlantList: async (id: string) => {
        if (id === "-") {
            store.dispatch({ type: CHANGE_CURRENT_PLANTLIST, payload: {} });
            return;
        }

        let model = store.getState().data.plantlists[id];
        const url = "/api/plantlist/" + id + "/plants";
        const plants = await FetchHandler.getJson(url);
        model.plants = plants;
        store.dispatch({ type: CHANGE_CURRENT_PLANTLIST, payload: model });
        // CHANGE_CURRENT_PLANTLIST
    },

    toggleSelectableColor: async (colorId: string) => {
        const url = "api/plantinfo/colors/" + colorId + "/toggleselectable";
        const data = await FetchHandler.postJson(url, {});
        if (data) {
            let cs = [...store.getState().data.colors]
            cs.forEach(i => {
                if (i.id === colorId) {
                    i.selectable = !i.selectable;
                }
            })
            store.dispatch({ type: CHANGE_COLOR_LIST, payload: cs });
        }
        Reloads.reloadColors();
    },

    linkPlantListToObject: async (objectId: string, listId: string) => {
        const url = "/api/plantlist/" + listId + "/linktoobject";
        const postObj = {
            objectId: objectId
        }
        const success = await FetchHandler.postJson(url, postObj);
        if (success) {
            let o = { ...store.getState().data.plantlistslinkedtobject };
            if (o[objectId]) {
                o[objectId].push(listId);
            } else {
                o[objectId] = [listId];
            }
            store.dispatch({ type: CHANGE_PLANTLISTS_LINKED_TO_OBJECT, payload: o });
        }
        return success;
    },

    unLinkPlantListFromObject: async (objectId: string, listId: string) => {
        const url = "/api/plantlist/" + listId + "/unlinkfromobject";
        const postObj = {
            objectId: objectId
        }
        const success = await FetchHandler.postJson(url, postObj);
        if (success) {
            let o = { ...store.getState().data.plantlistslinkedtobject };
            if (o[objectId]) {
                const idx = o[objectId].indexOf(listId);
                o[objectId].splice(idx, 1);
            }
            store.dispatch({ type: CHANGE_PLANTLISTS_LINKED_TO_OBJECT, payload: o });
        }
        return success;
    },
    togglePlantFieldPublic: async (id, isPublic) => {
        const postObj = {
            fieldId: id,
            ispublic: isPublic
        };
        const success = await FetchHandler.postJson("api/plantfields/togglepublic", postObj);

        if (success) {
            let data = { ...store.getState().data.plantfieldsobject }
            data[id.toLowerCase()].public = isPublic;
            store.dispatch({ type: CHANGE_PLANTFIELD_OBJECT, payload: data });
        }
        return success;
    },
    checkIfSpecificTypeExists: async (specificType) => {
        const exists = await FetchHandler.getJson("api/plants/specifictypeexists?type=" + encodeURI(specificType));
        return exists;
    },

    getPlantImages: async (plantId) => {
        const response = await FetchHandler.getJson("/api/plants/" + plantId + "/plantimages");
        console.log("getPlantImages:", response);
        if (response && response.length > 0) {
            let plantimages = { ...store.getState().data.plantimages };
            plantimages[plantId] = { ...plantimages[plantId] };
            response.forEach(item => {
                plantimages[plantId][item.id] = item;
            })
            store.dispatch({ type: CHANGE_PLANTIMAGES, payload: plantimages });
        }
    },


    getAllPlantImages: async () => {
        const response = await FetchHandler.getJson("/api/plants/plantimages");
        if (response && response.length > 0) {
            let plantimages = { ...store.getState().data.plantimages };
            response.forEach(item => {
                if (!plantimages[item.plantId]) {
                    plantimages[item.plantId] = {};
                }
                plantimages[item.plantId][item.id] = item;
            })
            store.dispatch({ type: CHANGE_PLANTIMAGES, payload: plantimages });
        }
    },

    addPlantImage: async (plantId, data) => {

        // api/plants/{id}/addplantimage
        const formData = new FormData();
        Object.keys(data).forEach(key => {
            formData.append(key, data[key]);
        });

        const request = await fetch("/api/plants/" + plantId + "/addplantimage", { method: 'POST', body: formData });
        const response = await request.json();

        const success = response.success;
        const imageId = response.id;
        const licensing = response.licensing;
        const fileSizeKb = response.fileSizeKb;
        if (!success) {
            MessageBoxes.warning("upload failed: " + response.errormessage);
            return;
        }

        let plantimages = { ...store.getState().data.plantimages };
        plantimages[plantId] = { ...plantimages[plantId] };
        plantimages[plantId][imageId] = { ...data, id: imageId, licensing: licensing, fileSizeKb: fileSizeKb };
        store.dispatch({ type: CHANGE_PLANTIMAGES, payload: plantimages });

        MessageBoxes.info("Bild uppladdad")
    },

    removePlantImage: async (plantId: string, imageId: string) => {

        const response = await FetchHandler.postJson("/api/plants/" + plantId + "/deleteplantimage", { imageId: imageId });

        if (response === true) {
            let plantimages = { ...store.getState().data.plantimages };
            plantimages[plantId] = { ...plantimages[plantId] };
            if (plantimages[plantId][imageId]) {
                delete plantimages[plantId][imageId];
            }
            if (Object.keys(plantimages[plantId]).length == 0) {
                delete plantimages[plantId];
            }
            store.dispatch({ type: CHANGE_PLANTIMAGES, payload: plantimages });
            MessageBoxes.info("Bild raderad")
        } else {
            MessageBoxes.warning("Bild raderaded inte");
        }

    },

    makePlantImagePrimary: async (plantId: string, imageId: string) => {
        const uri = "/api/plants/" + plantId + "/makeimageprimary";
        const success = await FetchHandler.postJson(uri, { imageId: imageId });
        const reload = SearchHandler.SearchPlant(plantId);
        return success;
    },

    unPublishPlantImage: async (plantId: string, imageId: string) => {
        const uri = "/api/userimages/" + imageId + "/unapprove";
        const success = await FetchHandler.patchJson(uri);
        const reload = SearchHandler.SearchPlant(plantId);
        return success;
    }



}


export const PlantOperations = {
    createPlant: async (model) => {
        const uri = "/api/plants/createplant";
        const response = await FetchHandler.postJson(uri, model);
        const id = response.id;
        return response;
    },
    deletePlant: async (id) => {
        const postObj = {
            id: id,
            action: "delete"
        };
        const ret = await FetchHandler.postJson("/api/plants/delete", postObj);

        let ahits = [...store.getState().search.searchList.filter(obj => obj.id != id)];
        store.dispatch({ type: CHANGE_SEARCH_LIST, payload: ahits });
        let hits = { ...store.getState().search.allSearchHits };
        if (hits[id]) {
            delete hits[id];
            store.dispatch({ type: CHANGE_SEARCH_ALLSEARCHHITS, payload: hits });
        }

        return ret;
    }
}