import { MessageBoxes } from "./MessageBoxes";
const FetchHandler = {

    getJson: async (url) => {
        const response = await fetch(url);
        if (response.status === 401) {
            MessageBoxes.warning("Not authorized", true);
            // return {
            //     code: response.status,
            //     text: "Not authorized",
            //     success: false
            // };
        }

        if (!response.ok) {
            console.error(response)
        }
        const data = await response.json();
        return data;
    },

    postJson: async (url: string, postObj?: any) => {
        const response = await fetch(url, {
            method: "POST",
            headers: {
                "Accept": "application/json",
                "Content-Type": "application/json"
            },
            body: JSON.stringify(postObj ?? {})
        })
        return await response.json();
    },

    postForm: async (url: string, form: FormData) => {
        const response = await fetch(url, {
            method: "POST",
            // headers: {
            //     "Content-Type": "multipart/form-data'"
            // },
            body: form
        })
        return await [response.json()];
    },
    delete: async (url: string) => {
        const response = await fetch(url, {
            method: "DELETE",
            headers: {
                "Accept": "application/json",
                "Content-Type": "application/json"
            },
        })
        return await response.json();
    },
    patchJson: async (url: string, postObj?: any) => {
        const response = await fetch(url, {
            method: "PATCH",
            headers: {
                "Accept": "application/json",
                "Content-Type": "application/json"
            },
            body: JSON.stringify(postObj ?? {})
        })
        return await response.json();
    }
}

export { FetchHandler }