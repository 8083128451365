import React, { useEffect, useState } from "react"
import { useSelector, useDispatch } from 'react-redux';
import { StringHelper } from "../../Handlers/Helpers";
import { SearchHandler } from "../../Handlers/SearchHandler";
import { usePlant } from "../../hooks/usePlant";
import { SearchListPlantType } from "../../rdx/Redux.SearchReducerType";
import { RdxStore, SearchHitType } from "../../rdx/ReduxTypes";
import { CHANGE_SEARCH_TEXT } from "../../rdx/searchReducer";
import { Dialog } from "../Shared/Dialog"
import { AdminIcon, AdminIcons } from "../Shared/AdminIcon";

export const SelectPlantsDialog = ({ visible, preSelected, onPlantsSelected, selectSingle }: { visible: boolean, preSelected: string[], onPlantsSelected(e: string[]): void, selectSingle?: boolean }) => {
    const ui = useSelectPlantsDialog(preSelected);

    const [value, setValue] = useState<string>("")

    if (!(visible === true)) {
        return (null)
    }

    return (
        <Dialog onOk={e => {
            onPlantsSelected(ui.selected)
        }} onCancel={e => {
            onPlantsSelected(null)
        }} width="800px">

            <h2>
                {selectSingle ? "Välj växt" : "Välj växter"}
            </h2>
            <input placeholder="Sök växt" className="frm" type={"text"}
                onChange={e => setValue(e.target.value)}
                onKeyUp={e => {
                    if (e.key === 'Enter') {
                        // Do something
                        ui.search(value)
                    }
                }}
            />
            <hr />
            <div>
                {(ui.selected && ui.selected?.length > 0) && (
                    <div>
                        <h4>
                            Valda
                        </h4>
                        <table className="tbl tbl-small">
                            <thead>
                                <tr>
                                    <th>Namn</th>
                                    <th>Sort</th>
                                    <th>Typ</th>
                                    <th>Familj</th>
                                </tr>
                            </thead>
                            <tbody>
                                {ui.selected?.map(pid => {
                                    const hit = ui.get(pid);
                                    return (
                                        <tr key={pid}>
                                            <td>
                                                <input type={"checkbox"} checked={ui.isSelected(hit?.id)} onChange={e => {
                                                    console.log("selectSingle:", selectSingle)
                                                    if (!!selectSingle) {
                                                        ui.setSelectedId(ui.isSelected(hit?.id) ? null : hit?.id);
                                                    } else {
                                                        ui.toggleSelect(hit?.id);
                                                    }
                                                }} />
                                            </td>
                                            <td>{hit?.fields?.name}</td>
                                            <td>{hit?.fields?.specifictype}</td>
                                            <td>{hit?.plantTypeName}</td>
                                            <td>{hit?.fields?.familyname}</td>
                                        </tr>
                                    )
                                })}
                            </tbody>
                        </table>
                    </div>
                )}



                <h4>
                    Sök
                </h4>

                <table className="tbl tbl-small">

                    <thead>
                        <tr>
                            <th></th>
                            <th></th>
                            <th>Namn</th>
                            <th>Sort</th>
                            <th>Typ</th>
                            <th>Familj</th>
                        </tr>
                    </thead>
                    <tbody>
                        {ui.results?.filter(m => ui.isSelected(m?.id) == false)?.map(hit => (
                            <tr key={hit?.id}>
                                <td>
                                    <input type={"checkbox"} checked={ui.isSelected(hit?.id)} onChange={e => {
                                        console.log("selectSingle:", selectSingle)
                                        if (!!selectSingle) {
                                            ui.setSelectedId(hit?.id);
                                        } else {
                                            ui.toggleSelect(hit?.id);
                                        }
                                    }} />
                                </td>
                                <td>
                                    {!!hit?.fields?.imageid && (<AdminIcon icon={AdminIcons.image} />)}
                                </td>
                                <td>{hit?.fields?.name}</td>
                                <td>{hit?.fields?.specifictype}</td>
                                <td>{hit?.plantTypeName}</td>
                                <td>{hit?.fields?.familyname}</td>
                            </tr>
                        ))}
                    </tbody>
                </table>

            </div>


        </Dialog>
    )
}


const useSelectPlantsDialog = (preSelected: string[]) => {

    const dispatch = useDispatch();

    const plant = usePlant();
    const list: SearchListPlantType[] = useSelector((state: RdxStore) => state.search.searchList);

    const [selected, setSelected] = useState<string[]>([]);


    useEffect(() => {
        const s = preSelected?.filter(m => StringHelper.isGuid(m));
        if (s?.length > 0) {
            plant.loadMultiple(s);
            setSelected(s);
        }
    }, []);


    return {
        get: (plantId: string): SearchHitType => {
            return plant.item(plantId)
        },
        search: (e: String) => {
            dispatch({ type: CHANGE_SEARCH_TEXT, payload: e });
            SearchHandler.search();
            console.log("search: ", e);
        },
        selected: selected,
        isSelected: (id: string) => selected?.indexOf(id) >= 0,
        toggleSelect: (id: string) => {
            if (selected?.indexOf(id) >= 0) {
                setSelected(selected.filter(m => m !== id));
            } else {
                setSelected([...selected, id]);
            }
        },
        setSelectedId: (id: string) => {
            setSelected([id].filter(m => !!m));
        },
        results: list,
    }
}


