import React, { useEffect, useState } from "react"
import { AdClientType, AdItemType, AdItemTypeDefaultValue, AdItemTypeType } from "./AdTypes";
import { useSelector, useDispatch } from 'react-redux';
import { RdxStore } from "../../rdx/ReduxTypes";
import { CHANGE_AD_ADS, CHANGE_AD_CLIENTS } from "../../rdx/adReducer";
import { FetchHandler } from "../../Handlers/FetchHandler";
import { MessageBoxes } from "../../Handlers/MessageBoxes";
import { StringHelper } from "../../Handlers/Helpers";

const adTypes: AdItemTypeType[] = [
    "discovery_featured",
    "discovery_featured_secondary",
    "calendar_list",
    "plantsearch",
    "plantdetail_main",
    "plantdetail_main_web",
    "monthly_article",
    // "discovery_featured_plants",
];
type AdAdminViewStates = "all" | "published";

export const useAdAdmin = () => {

    const adsStore: { [key: string]: AdItemType } = useSelector((state: RdxStore) => state.ads.ads);
    const dispatch = useDispatch();
    const [viewState, setViewState] = useState<AdAdminViewStates>("published");
    const [listofIds, setListofIds] = useState<string[]>([]);
    const [listofClientIds, setListofClientIds] = useState<string[]>([]);
    const [clientsfFilter, setClientsfFilter] = useState<string[]>([]);

    const load = async () => {
        const cs: AdItemType[] = await FetchHandler.getJson("/api/ads");
        let change = {};
        cs.forEach(c => change[c?.id] = c);
        dispatch({ type: CHANGE_AD_ADS, payload: change });
    }

    useEffect(() => {
        if (adsStore) {
            const ids = Object.keys(adsStore).map(key => key);
            const c = Array.from(new Set(Object.keys(adsStore).map(key => adsStore?.[key]?.clientId)));
            setListofIds(ids);
            setListofClientIds(c);
        }
    }, [, adsStore]);

    // newAd
    const [newAdModel, setNewAdModel] = useState<AdItemType>(AdItemTypeDefaultValue);
    const [newAdValid, setNewAdValid] = useState<boolean>(false);
    const newAdClear = async () => { setNewAdModel(AdItemTypeDefaultValue); setNewAdValid(false); }

    return {
        // ui 
        viewState: viewState, setViewState: setViewState,
        adTypes: adTypes,
        load: load,
        get: (id: string): AdItemType => adsStore?.[id],
        all: (viewState === "all" ? listofIds : listofIds?.filter(m => adsStore?.[m]?.published))?.filter(m => {
            if (clientsfFilter?.length > 0) {
                return clientsfFilter?.indexOf(adsStore?.[m]?.clientId) >= 0;
            } else {
                return true
            }
        }),
        allClientIdsUsed: listofClientIds,

        clientSelected: (id: string) => { return clientsfFilter?.indexOf(id) >= 0; },
        clientsfFilterToggle: (id: string) => {
            if (clientsfFilter.indexOf(id) >= 0) {
                setClientsfFilter(clientsfFilter.filter(m => m != id));
            } else {
                setClientsfFilter([...clientsfFilter, id]);
            }
        },
        // add,
        newAdModel: newAdModel,
        newAdValid: newAdValid,
        newAdSave: async () => {
            const p: AdItemType = { ...newAdModel };
            const asas = await FetchHandler.postJson("/api/ads/add", p);
            newAdClear();
            load();
            setViewState("all");
        },
        newAdClear: newAdClear,
        newAdChange: async (item: AdItemType) => {
            const n = { ...newAdModel, ...item };
            console.log("model:", n)
            setNewAdModel(n);
            let valid = true;
            if (!(n?.name?.length > 2)) { valid = false; }
            if (!(n?.clientId?.length > 10)) { valid = false; }
            if (!(n?.type?.length > 5)) { valid = false; }
            setNewAdValid(valid);
        },
    }
}

// --------------------------------------------------------
// --------------------- useAdClients ---------------------
// --------------------------------------------------------
export const useAdClients = () => {
    const clients = useSelector((state: RdxStore) => state.ads.clients);
    const dispatch = useDispatch();
    const [listofIds, setListofIds] = useState<string[]>([]);

    const [newName, setNewName] = useState<string>("");

    useEffect(() => {
        if (clients) {
            const ids = Object.keys(clients).map(key => key);
            setListofIds(ids);
        }
    }, [, clients]);

    const load = async () => {
        const cs: AdClientType[] = await FetchHandler.getJson("/api/ads/clients");
        let change = {};
        cs.forEach(c => change[c?.id] = c);
        dispatch({ type: CHANGE_AD_CLIENTS, payload: change });
    }

    return {
        load: load,
        get: (id: string): AdClientType => clients?.[id],
        newName: newName,
        newClientUpdateName: (name: string) => { setNewName(name); },
        addNewClient: async () => {
            if (newName?.length > 3) {
                const asas = await FetchHandler.postJson("/api/ads/clients/add", { name: newName })
                setNewName("");
                await load();
            } else {
                MessageBoxes.warning("Namnet måste ara minst 4 tecken")
            }
        },
        all: listofIds
    }
}

export const useAdItemEditor = (id: string) => {
    const adsStore = useSelector((state: RdxStore) => state.ads.ads);
    const clients = useSelector((state: RdxStore) => state.ads.clients);
    const dispatch = useDispatch();

    const item: AdItemType = adsStore?.[id];
    const [editItem, setEditItem] = useState<AdItemType>(item);
    const [editedFields, setEditedFields] = useState<AdItemType>({ id: id });
    const [changed, setChanged] = useState<boolean>(false);
    useEffect(() => {
        setEditItem(item)
        setChanged(false);
    }, [item])

    const client: AdClientType = clients?.[item?.clientId];

    const loadAll = async () => {
        const cs: AdItemType[] = await FetchHandler.getJson("/api/ads");
        let change = {};
        cs.forEach(c => change[c?.id] = c);
        dispatch({ type: CHANGE_AD_ADS, payload: change });
    }



    return {
        adTypes: adTypes,
        item: editItem,
        getItemPlantIds: (): string[] => {
            if (!editItem?.plantIds) {
                return [];
            }
            return (editItem?.plantIds).split(';')?.filter(m => StringHelper.isGuid(m));
        },
        allClients: Object.keys(clients ?? {}).map(key => clients?.[key]) as AdClientType[],
        clientName: client?.name,
        edit: (model: AdItemType) => {
            console.log("model: ", model)
            const e = { ...editItem, ...model };
            setEditedFields({ ...editedFields, ...model });
            setEditItem(e);
            setChanged(true);
        },
        changed: changed,
        save: async () => {
            const success = await FetchHandler.patchJson("/api/ads/update", editedFields);
            await loadAll();
        },
        delete: async (id: string) => {
            const success = await FetchHandler.delete("/api/ads/" + id + "/delete");
            await loadAll();
        },
        togglePublish: async (id: string, published: boolean) => {
            const success = await FetchHandler.patchJson("/api/ads/" + id + "/publish", { published: published });
            await loadAll();
        }

    }
}