import store from '../rdx/store';
import { CHANGE_LOGGED_IN, CHANGE_ACTIVE_ROLES } from '../rdx/reducers';
import { FetchHandler } from "./FetchHandler";
import { LocalStore } from './LocalStore';
import { CHANGE_LOGGEDINUSER } from '../rdx/appReducer';

function loginHandler() {
    var self = this;

    self.isLoggedIn = async () => {
        const res = await FetchHandler.getJson("/api/isloggedin");
        console.log("checking if logged in...", res)
        if (res.loggedin === true) {
            store.dispatch({ type: CHANGE_LOGGEDINUSER, payload: res });
            store.dispatch({ type: CHANGE_LOGGED_IN, payload: res.loggedin });
            store.dispatch({ type: CHANGE_ACTIVE_ROLES, payload: res.roles });

            LocalStore.setJwt(res.jwt);
        }
        return res;
    };

    self.login = async (uid: string, pwd: string) => {
        console.log("trying to login: '" + uid + "'...");

        const res = await FetchHandler.postJson("/api/login", { uid: uid, pwd: pwd });
        if (res.loggedin === true) {
            store.dispatch({ type: CHANGE_LOGGED_IN, payload: res.loggedin });
            store.dispatch({ type: CHANGE_ACTIVE_ROLES, payload: res.roles });
        }
        return res.loggedin;
    }

    self.logout = async () => {
        const res = await FetchHandler.postJson("api/logout", {});
        if (res === true) {
            store.dispatch({ type: CHANGE_LOGGED_IN, payload: false });
        }
    }

    let n = 0;
    self.loginChecker = setInterval(() => {
        console.log("check login. ", n++);
    }, 5 * 60 * 1000);
}


const LoginHandler = new loginHandler();

export { LoginHandler };