import React, { useEffect, useState } from 'react';
import { FetchHandler } from "../Handlers/FetchHandler";
import { MessageBoxes } from "../Handlers/MessageBoxes";
import { Dialog } from "./Shared/Dialog";
import { ToolBarClean } from './ToolBar/ToolBar';
import { useUserRoles } from '../hooks/MiscHooks';
import { DialogCreateLink } from './UserList';
import { SuccessResponseModel } from '../Types';


export const AccountActions = ({ userId, email }: { userId: string, email: string }) => {

    const user = useUserRoles();
    const isDev = user.hasRole("DEVELOPER");


    const [showUserRolesDialog, setShowUserRolesDialog] = useState<boolean>(false);
    const [showCreateLink, setShowCreateLink] = useState<boolean>(false);
    const [showUserScore, setShowUserScore] = useState<boolean>(false);
    const [showChangeEmail, setShowChangeEmail] = useState<boolean>(false);


    // ********************* JWT *********************
    type JwtDataType = { userid: string, jwt: string };
    const [jwtData, setJwtData] = useState<JwtDataType>(undefined);
    const [jwtDialogOpen, setJwtDialogOpen] = useState<boolean>(false);
    const onLaunchJwtDialog = async () => {
        var url = "/api/accounts/" + userId + "/jwt";
        const d = await FetchHandler.getJson(url);
        setJwtDialogOpen(true);
        setJwtData(d);
    }
    const onClose = () => {
        setJwtDialogOpen(false);
        setJwtData(undefined);
    }

    return (
        <div>

            <ToolBarClean tools={[
                { id: "jwt", label: "JWT", onClick: onLaunchJwtDialog, hidden: !isDev },
                // { id: "deleteaccount", label: "Radera", onClick: onLaunchJwtDialog, },
                { id: "editroles", label: "Roller", onClick: () => setShowUserRolesDialog(true), },
                { id: "loginlink", label: "Skapa länk", onClick: () => setShowCreateLink(true), },
                { id: "userscore", label: "Score", onClick: () => setShowUserScore(true), },
                { id: "email", label: "Ändra Email", onClick: () => setShowChangeEmail(true), },
                // { id: "editflags", label: "Flaggor", onClick: onLaunchJwtDialog, },
                // { id: "editpassword", label: "Lösenord", onClick: onLaunchJwtDialog, },
            ]} />

            {/* JWT */}
            <Dialog visible={jwtDialogOpen} okButtonText="Stäng" onOk={onClose}>
                <code className='unselectable cursor-pointer' onClick={(() => {
                    navigator.clipboard.writeText(jwtData?.jwt);
                    MessageBoxes.info("Jwt kopierad");
                    onClose();
                })}>
                    {jwtData?.jwt}
                </code>
            </Dialog>

            <UserRolesDialog userId={userId} visible={showUserRolesDialog} onClose={() => setShowUserRolesDialog(false)} />
            <DialogCreateLink email={email} visible={showCreateLink} onClose={() => setShowCreateLink(false)} />
            <UserScoreDialog userId={userId} visible={showUserScore} onClose={() => setShowUserScore(false)} />
            <UserEmailDialog userId={userId} visible={showChangeEmail} onClose={() => setShowChangeEmail(false)} />

        </div>
    )
}

const UserRolesDialog = ({ userId, visible, onClose }: { userId: string, visible: boolean, onClose(): void }) => {

    const [allRoles, setAllRoles] = useState<string[]>([]);
    const [hasRoles, setHasRoles] = useState<string[]>([]);

    const load = async () => {
        const a = await FetchHandler.getJson("/api/user/roles");
        setAllRoles(a);

        const s = await FetchHandler.getJson("/api/user/" + userId + "/roles");
        setHasRoles(s);
    }

    const set = async (role: string, hasRole: boolean) => {

        const d = await FetchHandler.postJson("/api/user/roles", {
            uid: userId,
            roleName: role,
            hasRole: hasRole
        });

        await load();
    }

    useEffect(() => {
        if (visible) {
            load();
        }
    }, [visible]);
    return (
        <Dialog visible={visible} okButtonText="Stäng" onOk={onClose} >
            <h2>
                Roller
            </h2>
            <div>
                <table className='tbl tbl-small'>
                    {allRoles?.map(r => (
                        <tr key={r}>
                            <td>
                                <input
                                    className='frm' type={"checkbox"} id={"cb-" + r} checked={hasRoles.indexOf(r) >= 0}
                                    onChange={e => {
                                        if (e.target.checked) {
                                            setHasRoles([...hasRoles, r])
                                        } else {
                                            setHasRoles([...hasRoles.filter(m => m != r)]);
                                        }
                                        set(r, e.target.checked);
                                    }}
                                />
                            </td>
                            <td>
                                {r}
                            </td>
                        </tr>
                    ))}
                </table>
            </div>
        </Dialog>
    )
}


const UserScoreDialog = ({ userId, visible, onClose }: { userId: string, visible: boolean, onClose(): void }) => {

    const [baseScore, setBaseScore] = useState<number>(0);
    const [bonus, setBonus] = useState<number>(0);
    const [totalScore, settotalScore] = useState<number>(0);


    useEffect(() => {
        settotalScore(bonus + baseScore);
    }, [bonus])



    const load = async () => {
        const a = await FetchHandler.getJson("/api/user/" + userId + "/score");
        console.log(a);
        const tot = a?.useractivityscore ?? 0;
        const bon = a?.useractivityscore_bonus ?? 0;
        setBaseScore(tot - bon);
        setBonus(bon);
        // useractivityscore_bonus
        // useractivityscore
    }

    const Save = async () => {
        const obj = {
            bonus: bonus,
            totalscore: totalScore
        };
        const a: boolean = await FetchHandler.patchJson("/api/user/" + userId + "/score", obj);
        if (a) {
            MessageBoxes.info("Sparat!")
        }
    }

    useEffect(() => {
        if (visible) {
            load();
        }
    }, [visible]);

    function numberWithCommas(x) {
        return x.toString().replace(/\B(?=(\d{3})+(?!\d))/g, " ");
    }
    return (
        <Dialog
            visible={visible}
            okButtonText="Spara och stäng"
            cancelButtonText='Avbryt'
            onOk={async () => {
                await Save();
                onClose();
            }}
            onCancel={onClose} >
            <div>
                <h4>Score: {numberWithCommas(baseScore)}</h4>
            </div>
            <hr />
            <div>
                <h4>Bonus</h4>
                <div>
                    <input className='frm' type={"number"} min={0} value={bonus} onChange={e => setBonus(parseInt(e.target.value))} />
                </div>
            </div>
            <hr />
            <div>
                <h4>Total score: {numberWithCommas(totalScore)}</h4>
            </div>
        </Dialog>
    )
}

type userInfoType = {
    userId: string
    name: string // "Debug0",
    email: string // "debug@vorticity.se"
}
const UserEmailDialog = ({ userId, visible, onClose }: { userId: string, visible: boolean, onClose(): void }) => {

    const [userInfo, setUserInfo] = useState<userInfoType>(null);
    const [email, setEmail] = useState<string>("");

    const load = async () => {
        const a = await FetchHandler.postJson("/api/user/info", { ids: [userId] });
        setUserInfo(a?.[0]);
    }

    const Save = async () => {
        const response: SuccessResponseModel = await FetchHandler.patchJson("/api/user/changeemail", {
            userid: userInfo?.userId,
            old: userInfo?.email,
            new: email
        });

        if (response.success === false) {
            MessageBoxes.warning(response.errorcodetext, true);
        } else if (response.success === true) {
            MessageBoxes.info("Success!", false);
        } else {
            MessageBoxes.info(JSON.stringify(response), false);
        }

        console.log("changeemail:", response);

    }

    useEffect(() => {
        if (visible) {
            load();
            setEmail("");
        }
    }, [visible]);

    return (
        <Dialog
            visible={visible}
            okButtonText="Byt och stäng"
            cancelButtonText='Stäng'
            onOk={async () => {
                await Save();
                onClose();
            }}
            onCancel={onClose} >
            <div>
                <h4>Byt Email</h4>
            </div>
            <hr />
            <div>
                <h4>Ny Email</h4>
                <div>
                    <input className='frm' type={"text"} value={email} onChange={e => setEmail(e.target.value)} />
                </div>
            </div>
            <hr />
            <div>
                <h4>Gammal Email: {userInfo?.email?.toLowerCase()}</h4>
                <h4>Ny Email: {email?.toLowerCase()}</h4>
            </div>
        </Dialog>
    )
}