import React, { useState } from 'react';
import { useSelector } from 'react-redux';
import { Data } from '../../../Handlers/Data';
import { MessageBoxes } from '../../../Handlers/MessageBoxes';
import { PlantHandler } from '../../../Handlers/PlantHandler';
import { Operations } from '../../../rdx/Operations';
import { RdxStore } from '../../../rdx/ReduxTypes';
import { Dialog } from '../Dialog';
import { TwoColumnContainer } from '../Formelements';


export const SetPlantTypeDialog = ({ closeDialog }) => {

    const ids = useSelector((store: RdxStore) => store.search.searchList.filter(obj => obj.selected).map(obj => obj.id));
    const types = useSelector((store: RdxStore) => store.data.planttypes);

    const [disableDialog, setDisableDialog] = useState(false);
    const [inputValue, setInputValue] = useState("");
    const [inputValueText, setInputValueText] = useState("");

    const update = () => {
        setDisableDialog(true);
        PlantHandler.updatePlantsType(ids, inputValue, inputValueText).then(success => {
            if (success) {
                MessageBoxes.info("Växterna är uppdaterade!");
            } else {
                MessageBoxes.warning("Operationen misslyckades!");
            }
            setDisableDialog(false);
            if (closeDialog) {
                closeDialog(true);
            }
        });
    }

    return (
        <Dialog
            onOk={update}
            onCancel={() => { closeDialog(); }}
            width="400px"
            height="200px"
            disabled={disableDialog}
            okDisabled={inputValue?.length <= 0}
        >

            <div className="form-large">

                <label>
                    Uppdatera växttyp
                </label>

                <select onChange={e => { setInputValue(e.target.value); setInputValueText(types[e.target.selectedIndex - 1]?.name) }}>
                    <option value="">Välj...</option>
                    {types.map(type => (
                        <option key={type.id} value={type.id}>{type.name}</option>
                    ))}
                </select>


            </div>

        </Dialog>
    )
}