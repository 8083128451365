import { useEffect, useState } from "react";
import { FetchHandler } from "../Handlers/FetchHandler";
import { StringHelper } from "../Handlers/Helpers";
import { MessageBoxes } from "../Handlers/MessageBoxes";
import { SettingsStore, SystemSettingsKeys } from "./SystemObjects/useSystemSettings";


export const useUserLists = (loadOnMount: boolean) => {


    const [dataStore, setDataStore] = useState<{ [id: string]: ListOfUsersApiData }>({});
    const [data, setData] = useState<ListOfUsersApiData[]>([]);
    const [inputValue, setInputValue] = useState<string>("");
    const [searchHits, setSearchHits] = useState<SearchUserType[]>([]);

    const load = async () => {
        const _data = await UserListsApi.getAll();
        let d = { ...dataStore };
        _data.forEach(us => d[us?.id] = us);
        setData(_data);
        setDataStore(d);
    }
    const onDelete = async (id: string) => {
        const success = await UserListsApi.del(id);
        await load();
    }
    const updateName = async (id: string, name: string) => {
        let model: ListOfUsersApiData = { ...data.find(m => m.id == id) };
        if (model?.id) {
            model.name = name
        }
        const success = await UserListsApi.update(model);
        await load();
    }

    const [listOfUsersFollowSuggestionsListId, setListOfUsersFollowSuggestionsListId] = useState<string>(null);
    const systemsettingKey: SystemSettingsKeys = "LISTOFUSERS_FOLLOW_SUGGESTIONS";
    const loadSystemSettings = async () => {
        const ssData: SettingsStore = await FetchHandler.getJson("/api/system/systemsettings");
        setListOfUsersFollowSuggestionsListId(ssData?.[systemsettingKey])
    }

    useEffect(() => {
        if (loadOnMount) {
            load();
            FetchHandler.getJson("/api/system/systemsettings").then(ss =>
                console.log("systemsettings: ", ss)
            );
            loadSystemSettings();
        }
    }, [])


    // selectedList
    const [selectedList, setSelectedList] = useState<string>(null);
    const [listUsers, setListUsers] = useState<SearchUserType[]>([])

    const loadUsers = async () => {
        const as = await UserListsApi.getListUsers(selectedList);
        console.log(as);
        setListUsers(as);
    }

    useEffect(() => {
        if (selectedList) {
            loadUsers()
        } else {
            setListUsers([]);
        }
    }, [selectedList])



    return {
        dataStore: dataStore,
        data: data,
        selectedList: selectedList,
        setSelectedList: (id: string) => {
            if (selectedList == id) {
                setSelectedList(null);
            } else {
                setSelectedList(id);
            }
        },
        listUsers: listUsers,
        inputValue,
        inputChange: (e: string) => { setInputValue(e) },
        search: (e: string) => {
            UserListsApi.search(e).then(res => {
                console.log(res);
                setSearchHits(res);
            })
        },
        searchHits: searchHits,
        onDelete: onDelete,
        updateName: updateName,
        add: async () => {
            const success = await UserListsApi.add(inputValue);
            setInputValue("");
            await load();
        },
        defaultFollowList: listOfUsersFollowSuggestionsListId,
        makeDefaultFollowList: async (id: string) => {
            let postObj = {
                LISTOFUSERS_FOLLOW_SUGGESTIONS: id
            };
            const success = await FetchHandler.postJson("/api/system/systemsettings", postObj);
            if (!success) {
                MessageBoxes.warning("Kunde inte spara")
            }
            setListOfUsersFollowSuggestionsListId(id);

        },
        addUserToList: async (userId: string) => {
            if (!selectedList) {
                MessageBoxes.warning("ingen lista är vald!");
                return;
            }

            const success = await UserListsApi.addUserToList(selectedList, userId);
            if (success) {
                MessageBoxes.info("Användaren lades till");
            }

            await loadUsers();


        },
        removeUserFromList: async (userId: string) => {
            if (!selectedList) {
                MessageBoxes.warning("ingen lista är vald!");
                return;
            }

            const success = await UserListsApi.removeUserFromList(selectedList, userId);
            if (success) {
                MessageBoxes.info("Användaren togs bort");
            }

            await loadUsers();
        },
    }
}



export type ListOfUsersApiData = {
    id: string,
    name: string,
    sys_datecreated_utc_epoch: number
}
export type SearchUserType = {
    id: string;
    name: string;
    privacyprofile: string;
    zone: string;
    profileimage: string;
    municipality: string;
    municipalityVisibility: string;
    gardenName: string;
    gardenDescription: string;
    gardenImageId: string;
    startedFollowingEpoch: number;
    requestedFollowingEpoch: number;
}

const UserListsApi = {
    getAll: async (): Promise<ListOfUsersApiData[]> => {
        return FetchHandler.getJson("/api/listofusers");
    },
    add: async (name: string): Promise<boolean> => {
        return await FetchHandler.postJson("/api/listofusers", { name: name });
    },
    del: async (id: string): Promise<boolean> => {
        return await FetchHandler.delete("/api/listofusers/" + id);
    },
    update: async (model: ListOfUsersApiData): Promise<boolean> => {
        return await FetchHandler.patchJson("/api/listofusers/" + model?.id, model);
    },
    search: async (search: string): Promise<SearchUserType[]> => {
        const ret: SearchUserType[] = await FetchHandler.getJson("/api/listofusers/search?search=" + encodeURIComponent(search));
        let d = {};
        ret.forEach(item => d[item?.id] = item);
        return Object.keys(d).map(key => d[key]);
    },
    getListUsers: async (listId: string): Promise<SearchUserType[]> => {
        const url = "/api/listofusers/" + listId + "/users";
        return await FetchHandler.getJson(url);
    },
    addUserToList: async (selectedList: string, userId: string): Promise<boolean> => {
        const url = "/api/listofusers/" + selectedList + "/update";
        const postObj = {
            action: "add",
            userid: userId
        }
        return FetchHandler.patchJson(url, postObj);
    },
    removeUserFromList: async (selectedList: string, userId: string): Promise<boolean> => {
        const url = "/api/listofusers/" + selectedList + "/update";
        const postObj = {
            action: "remove",
            userid: userId
        }
        return FetchHandler.patchJson(url, postObj);
    }
}