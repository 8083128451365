import React, { Component, useState } from 'react';
import { useSelector } from 'react-redux';
import { Dialog } from '../Shared/Dialog';
import { PlantHandler } from "../../Handlers/PlantHandler";
import store from "../../rdx/store";
import { MoveRightIcon, CheckboxCheckedIcon, CheckboxUnCheckedIcon } from '../Shared/Icons';
import { TwoColumnContainer } from "../Shared/Formelements";
import { FieldInput } from "./FieldInputs/FieldInputs";
import { ColorType } from '../../rdx/Redux.DataReducerType';

interface IProps {
    selectedField?: any,
    okHandler: any,
    cancelHandler: any
}

interface IState {

    ids?: string[],
    fields?: any[],
    colors?: ColorType[],
    selectedField?: any,
    value?: string,
    dialogdisabled?: boolean,
    keepExistingValues?: boolean
}

export class EditPlantFieldDialog extends Component<IProps, IState> {
    static displayName = EditPlantFieldDialog.name;

    viewstates: any;
    fieldListRef: React.RefObject<any>;
    valueListRef: React.RefObject<any>;

    constructor(props) {
        super(props);

        this.viewstates = {
            fieldlist: "fieldlist",
            fieldlisttransout: "fieldlisttransout",
            valuelist: "valuelist",
        };

        const fields = store.getState().data.plantfields;
        const colors = store.getState().data.colors;
        const ids = store.getState().search.searchList.filter(obj => obj.selected).map(obj => obj.id);

        //selectedField
        this.state = {
            ids: ids,
            fields: fields,
            colors: colors,
            selectedField: null,
            value: null,
            dialogdisabled: false,
            keepExistingValues: false
        }

        this.fieldClicked = this.fieldClicked.bind(this);

        this.fieldListRef = React.createRef();
        this.valueListRef = React.createRef();

        this.okClicked = this.okClicked.bind(this);
    }

    componentDidMount() {
        console.log("this.props.selectedField: ", this.props.selectedField);
        if (this.props.selectedField) {
            this.fieldClicked(this.props.selectedField);
        }
    }

    fieldClicked(field) {
        this.setState({ selectedField: field });
        this.fieldListRef.current.classList.add("animate__fadeOutLeft");

        setTimeout(() => {
            this.fieldListRef.current.style.display = "none";
            this.fieldListRef.current.classList.remove("animate__fadeOutLeft");

            this.valueListRef.current.style.display = "";
            this.valueListRef.current.classList.add("animate__fadeInRight");
        }, 500);
    }

    okClicked() {
        this.setState({ dialogdisabled: true });
        PlantHandler.updatePlantsField(
            this.state.ids,
            this.state.selectedField.id,
            this.state.value,
            this.state.keepExistingValues).then(success => {
                if (success) {
                    this.setState({ dialogdisabled: false });
                    this.props.okHandler();
                } else {
                    this.setState({ dialogdisabled: false });
                }
            })
    }

    render() {
        return (<Dialog
            onCancel={this.props.cancelHandler}
            onOk={this.okClicked}
            width="600px"
            okDisabled={this.state.value === null}
            disabled={this.state.dialogdisabled}
        >
            <h4>Ange värde på {this.state.ids.length} växter. ({JSON.stringify(this.state.value)})</h4>
            <div ref={this.fieldListRef} className="animate__animated">
                <h5>Välj fält</h5>
                <FieldList fieldClicked={this.fieldClicked} />
            </div>
            <div ref={this.valueListRef} className="animate__animated" style={{ display: "none" }}>
                {this.state.selectedField && (
                    <div>
                        <h5>Välj värde för {this.state.selectedField.name}</h5>
                        <div>{this.state.selectedField.description}</div>
                        <PlantFieldEdit
                            field={this.state.selectedField}
                            colors={this.state.colors}
                            onChange={(e) => {
                                this.setState({ value: e });
                            }} />

                        {this.state.selectedField.type === 5 && (
                            <div className="dialog-info-container">
                                <div className="dialog-info-container-title">Ta inte bort befintliga värden</div>
                                <div className="dialog-info-text">
                                    Lägg till de värden jag väljer.
                                </div>
                                <input type="checkbox" checked={this.state.keepExistingValues} onChange={(e) => { this.setState({ keepExistingValues: e.target.checked }); }} />
                            </div>
                        )}
                        {/* {JSON.stringify(this.state.selectedField)} */}
                    </div>
                )}
            </div>

        </Dialog>);
    }
}

const FieldList = (props) => {
    const fields = useSelector(state => state.data.plantfields)
    return (
        <div>
            {fields.map(field => (
                <div key={field.id} onClick={() => props.fieldClicked(field)}>
                    <TwoColumnContainer className="hover-highlight cursor-pointer">
                        <div>{field.name}</div>
                        <div className="right">
                            <MoveRightIcon />
                        </div>
                    </TwoColumnContainer>
                </div>
            ))}
        </div>
    );
}


const PlantFieldEdit = (props) => {
    const settings = props.field;
    const [val, setVal] = useState<string | boolean | number>("");
    //const [open, setOpen] = useState(false);

    if (settings) {
        let control = (null);
        const onChangeHandler = (newValue) => {
            setVal(newValue);
            props.onChange(newValue);
        };

        switch (settings.type) {
            case 1: { // Text
                control = (<FieldInput.Text value={val} onChange={onChangeHandler} multiLine={null} />);
                break;
            }
            case 2: { // Integer
                control = (<FieldInput.Integer value={val} onChange={onChangeHandler} />);
                break;
            }
            case 3: { // Decimal
                control = (<FieldInput.Decimal value={val} onChange={onChangeHandler} />);
                break;
            }
            case 4: { // SingleSelect
                control = (<FieldInput.SingleSelect fieldType={settings.id} value={val} onChange={onChangeHandler} />);
                return control;
            }
            case 5: { // MultiSelect
                control = (<FieldInput.MultiSelect fieldType={settings.id} value={val} onChange={onChangeHandler} />);
                return control;
            }
            case 6: { // Color
                control = (
                    <div className="color-picker">
                        {props.colors.map(color => (
                            <div key={color.id} className="color-picker-field" onClick={() => { props.onChange(color.id); }}>
                                <div className="color" style={{ backgroundColor: color.hexNumber }}></div>
                                <div className="label">{color.name}</div>
                            </div>
                        ))}
                    </div>
                );
                break;
            }
            case 7: { // TrueFalse
                control = (
                    <div style={{ fontSize: "1.4rem", textAlign: "center" }} className="border-color-text" onClick={() => { setVal(!val); props.onChange(!val) }}>
                        {val && (
                            <CheckboxCheckedIcon />
                        )}
                        {!val && (
                            <CheckboxUnCheckedIcon />
                        )}
                    </div>
                );
                break;
            }
            case 8: { // Tags
                control = (<FieldInput.Tag fieldType={settings.id} value={val} onChange={onChangeHandler} />);
                return control;
            }
        }
        return control;
    }
};