//import { Button } from 'bootstrap';
import React from 'react';
// import { Link } from 'react-router-dom';
// import { Routes /*, Views*/ } from "../../Handlers/ViewHandler";
// import store from './../../rdx/store';
// import { StartIcon, UsersIcon, PlantsIcon, AddPlantIcon, ImagesIcon, InspirationIcon, ColorIcon, FieldIcon } from "./../Shared/Icons";

export const Overlay = ({ visible }: { visible: boolean }) => {
    if (visible !== true) {
        return (null)
    }
    return (
        <div className="overlay"></div>
    )
}