import React, { useEffect, useState } from "react"
import { FetchHandler } from "../../Handlers/FetchHandler";
import { MessageBoxes } from "../../Handlers/MessageBoxes";
import { useReduxState } from "../../hooks/reduxHook";
import Select from 'react-select';
import { AdminIcon, AdminIcons } from "../Shared/AdminIcon";
import { CustomColumnContainer, ThreeColumnContainer } from "../Shared/Formelements";
import { ScreenHeader } from "../UI/Elements";
import { SelectOptionStringType } from "../UI/UITYpes";


export const TestScreen = () => {



    return (
        <div>
            <ScreenHeader text="Ikoner mm" icon={AdminIcons.cogs} />
            <CustomColumnContainer gridTemplateColumns={"300px auto"} >
                <Ikoner />
                {/* <ApproveImageControl /> */}
                <div>
                    <ActivityColorSelector
                        value={undefined}
                        onChange={e => console.log(e)}
                    />
                </div>
                <div></div>
            </CustomColumnContainer>
        </div>
    )
}


const Ikoner = () => {

    return (
        <div>
            <h2>Ikoner</h2>
            <a href="https://fontawesome.com/v5/search?o=r&m=free" target={"_blank"}>https://fontawesome.com/v5/search?o=r&m=free</a>
            <table className="tbl">
                <thead>
                    <tr>
                        <th>
                            Namn
                        </th>
                        <th style={{ textAlign: "right" }}>
                            Ikon
                        </th>
                    </tr>
                </thead>
                <tbody>
                    {Object.keys(AdminIcons).map(key => (
                        <tr key={key}>
                            <td>AdminIcons.{key}</td>
                            <td style={{ textAlign: "right" }}>
                                <AdminIcon icon={AdminIcons[key]} />
                            </td>
                        </tr>
                    ))}
                </tbody>

            </table>
        </div>
    )
}

export const ActivityColorSelector = ({ value, onChange }: { value: string, onChange(e: SelectOptionStringType): void }) => {

    const options: SelectOptionStringType[] = [
        { value: undefined, label: "none" },
        { value: "#EDF5F0", label: "green05" },
        { value: "#E5F5F5", label: "teal20" },
        { value: "#F9F5EF", label: "beige20" },
        { value: "#FCF9E8", label: "yellow10" },
        { value: "#F0EAF3", label: "plum20" },
        { value: "#FAE9EA", label: "red20" }
    ];

    const customStyles = {
        option: (provided, state) => ({
            ...provided,
            backgroundColor: state?.data?.value,
            color: "#000",
        }),
        control: (provided) => ({
            ...provided,
            backgroundColor: color ?? "#fff",
        }),
    }
    const [color, setColor] = useState<string>(value);


    return (
        <div style={{ backgroundColor: color, }}>
            <Select
                styles={customStyles}
                value={options.find(obj => obj.value === color)}
                options={options}
                onChange={(e: SelectOptionStringType) => {
                    setColor(e?.value);
                    onChange(e)
                }}
            />
        </div>
    )
}