import React, { useEffect, useRef, useState } from "react"
import { GoogleMap, useJsApiLoader, Marker, Polyline } from '@react-google-maps/api';
import Select from 'react-select'
import { FetchHandler } from "../../Handlers/FetchHandler";
import { MessageBoxes } from "../../Handlers/MessageBoxes";
import { AdminIcon, AdminIcons } from "../Shared/AdminIcon"
import { ThreeColumnContainer } from "../Shared/Formelements";
import { ToolBar } from "../ToolBar/ToolBar";
import { AdminMultiSelect, ScreenHeader } from "../UI/Elements"
import { LatLng, MapBounds } from "./Data/MapTypes";
import { PoiDataItem, useLargeMap } from "./Data/useLargeMap";
import { Config } from "../../Config";
import { MapHelper, mapPins } from "./Data/MapHelper";

const defaultLocation = { lat: 59.433636, lng: 18.325516 };
const containerStyle = { width: '100%', height: 'calc(100vh - 380px)' };


export const PointOfInterestsMap = ({ value, onChange }: { value: LatLng, onChange(e: LatLng): void }) => {


    const useValue = Math.abs(value?.latitude) * Math.abs(value?.longitude) > 0;

    // ------------------------------------- Init Map -------------------------------------
    const { isLoaded } = useJsApiLoader({ id: 'google-map-script', googleMapsApiKey: Config.googleMapsApiKey })
    const [map, setMap] = React.useState<google.maps.Map>(null);

    const onLoad = React.useCallback((map: google.maps.Map) => {
        map.panTo(useValue ? { lat: value?.latitude, lng: value?.longitude } : defaultLocation);
        map.setZoom(11);
        setMap(map)
    }, [])
    const onUnmount = React.useCallback((map) => { setMap(null) }, []);





    return (
        <div>
            {isLoaded && (
                <GoogleMap mapContainerStyle={containerStyle}
                    center={useValue ? { lat: value?.latitude, lng: value?.longitude } : defaultLocation}
                    zoom={20}
                    onLoad={onLoad}
                    onUnmount={onUnmount}
                    options={{
                        streetViewControl: false,
                        rotateControl: false,
                    }}
                    onBoundsChanged={() => {
                        const b = map?.getBounds();
                        // MapHelper.convertBounds(b);
                        // setLastCenter(map.getCenter())
                    }}
                >
                    <Marker
                        position={useValue ? { lat: value?.latitude, lng: value?.longitude } : defaultLocation}
                        title="Edit"
                        draggable={true}
                        onDragEnd={e => {
                            onChange({ latitude: e?.latLng.lat(), longitude: e.latLng.lng() });
                        }}

                    />
                </GoogleMap>

            )}

        </div>
    )
}