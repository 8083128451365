import React, { useEffect, useState } from "react";
import { FetchHandler } from "../../Handlers/FetchHandler";
import { ForumPostCard } from "../Forum/ForumPostCard";
import { AdminIcon, AdminIcons } from "../Shared/AdminIcon";
import { ToolBar } from "../ToolBar/ToolBar";
import { LoadingBar, ScreenHeader } from "../UI/Elements";
import { JwtPlantImage } from "../UI/ImageElements";
import { useElasticSearch } from "./useElasticSearch";

export const ElasticSearchAll = () => {
    const ui = useElasticSearch();

    // useEffect(() => {
    //     ui.load();
    // }, []);

    const [search, setSearch] = useState<string>(undefined);

    return (
        <div className="screen-elastic">
            <ScreenHeader text="Appsök allt med Elastic Search" icon={AdminIcons.user} />

            <LoadingBar loading={ui.loadingBlogs} />
            <div>
                <input placeholder="Sök..." className="frm" type={"search"} value={search} onChange={e => setSearch(e.target.value)} onKeyUp={e => {
                    if (e.key === "Enter") {
                        // ui.onSearchBlogs(search, 10);
                        ui.onSearchPPois(search, 10);
                        //ui.onSearchPlants(search, 10);
                        ui.onSearchUsers(search, 10);
                    }
                }} />
            </div>

            <div>
                <div>
                    Träffar: {((ui?.blogs?.total ?? 0) + (ui?.pois?.total ?? 0) + (ui?.plants?.total ?? 0) + (ui?.users?.total ?? 0)) ?? 0}.
                </div>

                {/* Blogs */}
                <div style={{ display: "none" }}>
                    <table className="tbl tbl-small">
                        <thead>
                            <tr>
                                <th>Rubrik</th>
                                <th>Datum</th>
                                <th>Excerpt</th>
                            </tr>
                        </thead>
                        <tbody>
                            {ui.blogs?.hits?.map(hit => (
                                <tr key={hit?.id}>
                                    <td>{hit?.titlehtmldecoded}</td>
                                    <td>{hit?.publisheddatetime}</td>
                                    <td>{hit?.htmlexcerptdecoded}</td>
                                </tr>
                            ))}
                        </tbody>
                    </table>
                </div>

                {/* Plants */}
                <div style={{ display: "none" }}>
                    <table className="tbl tbl-small">
                        <thead>
                            <tr>
                                <th></th>
                                <th>Namn</th>
                                <th>Sort</th>
                                <th>Latin</th>
                                <th>Typ</th>
                            </tr>
                        </thead>
                        <tbody>
                            {ui.plants?.hits?.map(hit => (
                                <tr key={hit?.Id}>
                                    <td className="img">
                                        <JwtPlantImage plantImageId={hit?.imageid} sizeType="small" />
                                    </td>
                                    <td>{hit?.Name}</td>
                                    <td>{hit?.specifictype}</td>
                                    <td>{hit?.namelatin}</td>
                                    <td>{hit?.Type}</td>
                                </tr>
                            ))}
                        </tbody>
                    </table>
                </div>

                {/* POI */}
                <div>

                    <table className="tbl tbl-small">
                        <thead>
                            <tr>
                                <th>Namn</th>
                                <th>Adress 1</th>
                                <th>Adress 2</th>
                                <th>Adress 2</th>
                            </tr>
                        </thead>
                        <tbody>
                            {ui.pois?.hits?.map(hit => (
                                <tr key={hit?.id}>
                                    <td>{hit?.published ? (<AdminIcon icon={AdminIcons.eye} />) : (<AdminIcon icon={AdminIcons.eyeclosed} />)}</td>
                                    <td>{hit?.title}</td>
                                    <td>{hit?.addressline1}</td>
                                    <td>{hit?.addressline2}</td>
                                </tr>
                            ))}
                        </tbody>
                    </table>
                </div>

                {/* Users */}
                <div>
                    <table className="tbl tbl-small">
                        <thead>
                            <tr>
                                <th>Namn</th>
                                <th>Kommun</th>
                                <th>Trädgård</th>
                                <th>Öppen/Stängd</th>
                                <th>Score</th>
                            </tr>
                        </thead>
                        <tbody>
                            {ui.users?.hits?.map(hit => (
                                <tr key={hit?.id}>
                                    <td>{hit?.name}</td>
                                    <td>{hit?.municipality}</td>
                                    <td>{hit?.gardenname}</td>
                                    <td>{hit?.privacyprofile}</td>
                                    <td>{hit?.useractivityscore}</td>
                                </tr>
                            ))}
                        </tbody>
                    </table>
                </div>

            </div>
        </div>
    )
}
