import React from "react"
import { AdminIcons } from "../Shared/AdminIcon";
import { ThreeColumnContainer } from "../Shared/Formelements";
import { ScreenHeader } from "../UI/Elements";

export const Feed = () => {
    return (
        <div>
            <ScreenHeader text="Inlägg mm" icon={AdminIcons.comments} />
            <ThreeColumnContainer>
                {/* <div>
                <label>Status</label>
                <Select
                    defaultValue={{ value: "none", label: "none" }}
                    options={feedbackHandler.stateOptions}
                    onChange={item => {
                        feedbackHandler.setSelectedState(item?.value)
                    }}
                />
            </div> */}

                {/* <div>

                <button className='btn' onClick={() => { feedbackHandler.reLoad(); }}>
                    Ladda om
                </button>

            </div> */}
                <div></div>

            </ThreeColumnContainer>
        </div>
    )
}