import React from "react"
import { Link } from 'react-router-dom';
import { AdminIcon, AdminIcons } from "../Shared/AdminIcon"
import { ThreeColumnContainer } from "../Shared/Formelements"
import { ActionToolsList, ToolBar } from "../ToolBar/ToolBar"
import { ScreenHeader } from "../UI/Elements"
import { Overlay } from "../UI/Overlay"
import { JwtPlantImageWithPreview } from "../UI/ImageElements";
import { PlantWithImage } from "./PlantImageOperations";
import { useDateHelper } from "../../hooks/useHelper";
import { UserImage, useUserPlantImageOperations } from "./useUserPlantImageOperations";
import { Routes } from "../../Handlers/ViewHandler";
import { ClipBoardDragContainer } from "../ClipBoard/ClipBoard";

export const UserPlantImageOperations = () => {

    const ui = useUserPlantImageOperations();

    const getTools = () => {
        if (ui.viewState === "selected") {
            return [
                { id: "3", label: "Välj bilder", icon: AdminIcons.eye, onClick: () => { ui.setViewState("none") } },
            ]
        } else {
            return [
                { id: "-1", label: "Ladda om sidan", icon: AdminIcons.refresh, onClick: ui.load, },
                { id: "0", label: "Första sidan", icon: AdminIcons.house, onClick: ui.goFirstPage, },
                { id: "1", label: "Föregående sida", icon: AdminIcons.arrowleft, onClick: ui.goPrev, disabled: ui.disableGoPrev, },
                { id: "2", label: "Nästa sida", icon: AdminIcons.arrowright, onClick: ui.goNext, disabled: ui.disableGoNext, },

                { id: "3", label: "Visa valda", icon: AdminIcons.eye, onClick: () => { ui.setViewState("selected") } },
                { id: "4", label: ui.filterOnPrimaryImages ? "Visar bara primärbilder" : "Visar alla bilder", icon: ui.filterOnPrimaryImages ? AdminIcons.eyeclosed : AdminIcons.eye, onClick: () => { ui.setFilterOnPrimaryImages(!ui.filterOnPrimaryImages); } },
            ]
        }
    }

    const tools = getTools();

    return (
        <div>
            <Overlay visible={ui.loading} />
            <ScreenHeader text="Användarbilder" icon={AdminIcons.images} />
            <div>
                <ToolBar tools={tools} />
            </div>

            {ui.viewState === "none" && (
                <>
                    <SmallTools ui={ui} />

                    <ThreeColumnContainer gridTemplateColumns="auto">

                        {/* Välj bilder */}
                        <div>
                            <h2>Välj bilder</h2>
                            <div className="userplantimagecard-grid">
                                {ui.data?.map((item, idx) => (
                                    <UserPlantImageCard
                                        key={item?.imageid + item?.plantid}
                                        item={item}
                                        onSelectClick={(() => { ui.toggleSelect(item) })}
                                        selected={ui.selectedImages?.findIndex(m => m.imageid === item?.imageid) >= 0}
                                        showSelected={true}
                                        openClipBoardOnDrag={true}
                                    />
                                ))}
                                {!ui.disableGoNext && (
                                    <div className="userplantimagecard-ghost">
                                        <button className="btn" onClick={ui.goNext}>
                                            Nästa sida
                                            <AdminIcon icon={AdminIcons.arrowright} />
                                        </button>
                                    </div>
                                )}
                            </div>
                        </div>

                        {/* Valda bilder */}
                        {/* <div>
                            <h2>Valda bilder</h2>
                            <div className="userplantimagecard-grid" style={{ position: "sticky", top: "0" }}>
                                {ui.selectedImages?.map((item, idx) => (
                                    <UserPlantImageCard key={item?.imageid + item?.plantid}
                                        item={item}
                                        onSelectClick={(() => { ui.toggleSelect(item) })}
                                        selected={ui.selectedImages?.findIndex(m => m.imageid === item?.imageid) >= 0}
                                        openClipBoardOnDrag={false}
                                    />
                                ))}
                            </div>
                        </div> */}
                    </ThreeColumnContainer>
                </>
            )}
            {ui.viewState === "selected" && (
                <ThreeColumnContainer gridTemplateColumns="auto 250px">
                    <div>
                        <h2>Valda bilder</h2>
                        <div className="userplantimagecard-grid">
                            {ui.selectedImages?.map((item, idx) => (
                                <UserPlantImageCard key={item?.imageid + item?.plantid}
                                    item={item}
                                    onSelectClick={(() => { ui.toggleSelect(item) })}
                                    selected={ui.selectedImages?.findIndex(m => m.imageid === item?.imageid) >= 0}
                                    openClipBoardOnDrag={false}
                                />
                            ))}
                        </div>
                    </div>
                    <div>
                        <h2>Åtgärder</h2>
                        <ActionToolsList tools={[{ id: "0", label: "Gå till upptäck", icon: AdminIcons.eye, navigationLink: Routes.discover },]} />
                    </div>

                </ThreeColumnContainer>
            )}

            {/* {JSON.stringify(ui.peopleCount)} */}
        </div>
    )
}


const SmallTools = ({ ui }) => {
    return (

        <ThreeColumnContainer gridTemplateColumns="120px 100px 200px 200px auto">
            <div>
                Bilder: {ui.totalNumberOfHits} st
            </div>
            <div>
                Sida: {ui.page + 1}/{ui.numberOfPages}
            </div>
            <div>
                <select defaultValue={ui.pageSize} onChange={e => ui.setPageSize(parseInt(e.target.value))}>
                    <option value={10}>10</option>
                    <option value={25}>25</option>
                    <option value={50}>50</option>
                </select>
            </div>
            <div>
                <input className="default-input" type={"search"} defaultValue={ui.textFilter} onChange={e => ui.setTextFilter(e.target.value)} />
            </div>
        </ThreeColumnContainer>

    )
}

export const UserPlantImageCard = ({ item, onSelectClick, selected, showSelected, openClipBoardOnDrag }: { item: PlantWithImage, onSelectClick(): void, selected: boolean, showSelected?: boolean, openClipBoardOnDrag: boolean }) => {

    const image = item?.image as UserImage;
    const dh = useDateHelper();

    return (
        <ClipBoardDragContainer type={"plantimage"} dragData={item} openClipBoardOnDrag={openClipBoardOnDrag}>


            <div className={"userplantimagecard" + ((selected && !!showSelected) ? " selected" : "")}>

                <JwtPlantImageWithPreview imageId={image?.id} />

                <div className="text">
                    <div className="link">
                        <Link to={"/plants/plantimages/" + item?.plantid}>
                            {item?.plant?.imageid === item?.imageid && (<span>*</span>)}
                            {item?.plant?.name}
                        </Link>
                    </div>
                    <div className="sort">
                        {item?.plant?.specifictype}
                    </div>
                    <div className="name">
                        <Link to={"/user/" + image?.licensinguserid}>
                            {image?.licensingname}
                        </Link>
                    </div>
                    <div className="date">
                        {dh.epochToDateTime(image?.sys_datecreated_utc_epoch)}
                    </div>
                </div>
                <div className="tools">
                    {!!onSelectClick && (<div className="select" onClick={onSelectClick}>
                        <AdminIcon icon={selected ? AdminIcons.toggle_on : AdminIcons.toggle_off} size="lg" />
                    </div>)}

                </div>

            </div>
        </ClipBoardDragContainer>

    )

}


