import React, { useState } from 'react';
import { useSelector } from 'react-redux';
import { Data } from '../../../Handlers/Data';
import { MessageBoxes } from '../../../Handlers/MessageBoxes';
import { PlantHandler } from '../../../Handlers/PlantHandler';
import { Operations } from '../../../rdx/Operations';
import { RdxStore } from '../../../rdx/ReduxTypes';
import { Dialog } from '../Dialog';
import { TwoColumnContainer } from '../Formelements';


export const AddSnippetDialog = ({ closeDialog }) => {

    const ids = useSelector((store: RdxStore) => store.search.searchList.filter(obj => obj.selected).map(obj => obj.id));
    const snippetsObj = useSelector((store: RdxStore) => store.data.snippets);
    const snippets = Object.keys(snippetsObj).map(key => snippetsObj[key]);
    const [selectedSnippet, setSelectedSnippet] = useState("");
    const [selectedSnippetType, setSelectedSnippetType] = useState("");

    const options = snippets.filter(obj => { return obj.snippetKey === selectedSnippetType || selectedSnippetType === "-" }).map(obj => { return { value: obj.id, label: obj.name, model: obj } })

    const [disableDialog, setDisableDialog] = useState(false);

    const FIELD_KEY_OBJ = Data.snippetTypes;
    const snippetTypes = Object.keys(FIELD_KEY_OBJ).map(obj => { return { value: FIELD_KEY_OBJ[obj].key, label: FIELD_KEY_OBJ[obj].name } });


    const update = () => {
        setDisableDialog(true);
        Operations.snippetAppliesToPlants(ids, selectedSnippet, selectedSnippetType).then(success => {
            if (success) {
                MessageBoxes.info("Växterna är uppdaterade!");
            } else {
                MessageBoxes.warning("Operationen misslyckades!");
            }
            setDisableDialog(false);
            if (closeDialog) {
                closeDialog(true);
            }

        });
    }

    const selectedSnippetChanged = (e) => {
        setSelectedSnippet(e);
    }


    return (
        <Dialog
            onOk={update}
            onCancel={() => { closeDialog(); }}
            width="600px"
            height="400px"
            disabled={disableDialog}
        >
            <h1>
                Välj snippet
            </h1>
            <h4>
                Antal växter: {ids?.length}
            </h4>
            <hr />
            <div className="form-large">
                <div>
                    <label>Fält</label>
                    <select value={selectedSnippetType} onChange={(e) => setSelectedSnippetType(e.target.value)} >
                        <option value="-">-</option>
                        {snippetTypes.map(type => (
                            <option key={type.value} value={type.value}>{type.label}</option>
                        ))}
                    </select>

                    <label>Snippet</label>
                    <select value={selectedSnippet} onChange={(e) => selectedSnippetChanged(e.target.value)}>
                        <option>-</option>
                        {options.map(type => (
                            <option key={type.value} value={type.value}>{type.label}</option>
                        ))}
                    </select>
                </div>
                <div>
                    <h4>
                        {snippetsObj[selectedSnippet]?.name}
                    </h4>
                    <p className="pre-formatted">
                        {snippetsObj[selectedSnippet]?.snippetDescription}
                    </p>
                </div>
                <hr />
            </div>
        </Dialog>
    )


}


export const RemoveSnippetDialog = ({ closeDialog }) => {
    const ids = useSelector(store => store.search.searchList.filter(obj => obj.selected).map(obj => obj.id));
    const FIELD_KEY_OBJ = Data.snippetTypes;
    const snippetTypes = Object.keys(FIELD_KEY_OBJ).map(obj => { return { value: FIELD_KEY_OBJ[obj].key, label: FIELD_KEY_OBJ[obj].name } });
    const [disableDialog, setDisableDialog] = useState(false);
    const [selectedSnippetType, setSelectedSnippetType] = useState("");
    const remove = () => {
        setDisableDialog(true);
        Operations.removeSnippetFromPlants(ids, selectedSnippetType, selectedSnippetType).then(success => {
            if (success) {
                MessageBoxes.info("Växterna är uppdaterade!");
            } else {
                MessageBoxes.warning("Operationen misslyckades!");
            }
            setDisableDialog(false);
            if (closeDialog) {
                closeDialog(true);
            }
        });
    }

    return (
        <Dialog
            onOk={remove}
            onCancel={() => { closeDialog(); }}
            width="400px"
            height="200px"
            disabled={disableDialog}
        >
            <h1>
                Välj snippet
            </h1>
            <h4>
                Antal växter: {ids?.length}
            </h4>
            <hr />
            <div className="form-large">
                <div>
                    <label>Fält</label>
                    <select value={selectedSnippetType} onChange={(e) => setSelectedSnippetType(e.target.value)} >
                        <option value="-">-</option>
                        {snippetTypes.map(type => (
                            <option key={type.value} value={type.value}>{type.label}</option>
                        ))}
                    </select>
                </div>

                <hr />
            </div>
        </Dialog>
    )



}