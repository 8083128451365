import React, { useState, useLayoutEffect, ReactNode } from 'react';
import { useSelector } from 'react-redux';
import { FetchHandler } from '../../Handlers/FetchHandler';
import { LocalStore } from '../../Handlers/LocalStore';
import { ImagesIcon } from '../Shared/Icons';
import { Portal } from './Portal';

type ImageFormats = "hd" | "thumb";
export const JwtImage = ({ imageId, format = "hd", openOnClick = false, imageText, containerClassName, imageClassName, previewImageClassName, title = "" }: { imageId: string, format: ImageFormats, openOnClick?: boolean, imageText?: string, containerClassName?: string, imageClassName?: string, previewImageClassName?: string, title?: string }) => {
    const ui = useJwtImage(imageId, format);
    const uiLarge = useJwtImage(imageId, "hd");
    const [previewOpen, setPreviewOpen] = useState<boolean>(false);

    const [textWidth, setTextWidth] = useState<string>("702px");
    useLayoutEffect(() => {
        if (previewOpen) {
            setTimeout(() => {
                const w = document.getElementById("preview-" + imageId).getBoundingClientRect().width + "px";
                console.log(w)
                setTextWidth(w);
            }, 100);
        }
    }, [previewOpen])

    return (
        <>
            <div className={"generic-image-container" + (openOnClick ? " cursor-pointer" : "") + (containerClassName ? " " + containerClassName : "")} onClick={openOnClick ? () => { setPreviewOpen(!previewOpen) } : null} >
                {!!imageId && (
                    <img id={imageId} src={ui.imgSrc} className={"img " + (imageClassName ? imageClassName : "")} data-imageid={imageId} title={title} loading="lazy" />
                )}
                {!imageId && (
                    <NoImageReplacement />
                )}
                {previewOpen && (
                    <Portal>
                        <div className={"jwtimage-preview-container cursor-pointer"} onClick={() => setPreviewOpen(!previewOpen)}>
                            <img id={"preview-" + imageId} src={uiLarge.imgSrc} className={"img square" + (!!imageText ? " img-with-text" : "") + (previewImageClassName ? " " + previewImageClassName : "")} loading="lazy" />
                            {!!imageText && (
                                <div className='textarea' style={{ width: textWidth }} >{imageText}</div>
                            )}
                        </div>
                    </Portal>
                )}
            </div>
        </>
    )
}

export const useJwtImage = (imageId: string, format: ImageFormats = "hd") => {
    const [imgSrc, setImgSrc] = useState(null);
    var url = "https://images.ohgarden.se/" + format + "/" + imageId;

    useLayoutEffect(() => {

        if (imageId) {
            const jwt = LocalStore.getJwt();
            fetch(url, { method: "GET", headers: { Authorization: 'Bearer ' + jwt } })
                .then(async (response) => {
                    if (response.ok) {
                        return { success: true, blob: await response.blob() };
                    } else {
                        return { success: false, reason: 404 };
                    }
                })
                .then((data) => {
                    if (data.success) {
                        setImgSrc(URL.createObjectURL(data.blob));
                    } else {
                        setImgSrc("/imgs/404.gif");
                    }
                })
                .catch(error => console.log('error is', error));;
        } else {
            setImgSrc("/imgs/404.gif");
        }
    }, [, imageId]);

    return {
        imgSrc: imgSrc
    }
}





export const JwtPlantImage = ({ plantImageId, small, sizeType, onError }: { plantImageId: string, small?: boolean, sizeType?: null | "generic" | "small", onError?(): void }) => {
    const [imgSrc, setImgSrc] = useState(null);
    var url = "https://images.ohgarden.se/plant/thumb/" + plantImageId;

    useLayoutEffect(() => {

        if (plantImageId) {
            const jwt = LocalStore.getJwt();
            fetch(url, { method: "GET", headers: { Authorization: 'Bearer ' + jwt } })
                .then(async (response) => {
                    if (response.ok) {
                        return { success: true, blob: await response.blob() };
                    } else {
                        return { success: false, reason: 404 };
                    }
                })
                .then((data) => {
                    if (data.success) {
                        setImgSrc(URL.createObjectURL(data.blob));
                    } else {
                        setImgSrc("/imgs/404.gif");
                    }
                })
                .catch(error => {
                    console.log('error is', error)
                    if (onError) {
                        onError();
                    }
                }
                );;
        } else {
            setImgSrc("/imgs/404.gif");
        }
    }, [, plantImageId]);

    let className = small ? "jwtimage-container-small" : "jwtimage-container"; // genericSize
    className = sizeType == "generic" ? "jwtimage-container-generic" : className;
    className = sizeType == "small" ? "jwtimage-container-small" : className;
    return (
        <div className={className} >
            {!!plantImageId && (
                <img src={imgSrc} className="img" data-plantimageid={plantImageId} loading="lazy" />
            )}
            {!plantImageId && (
                <NoImageReplacement />
            )}
        </div>
    )

}


export const JwtPlantImagePreview = ({ plantImageId, visible, onClick, children }: { plantImageId: string, visible: boolean, onClick: any, children?: any }) => {
    const [imgSrc, setImgSrc] = useState(null);
    var url = "https://images.ohgarden.se/plant/hd/" + plantImageId;

    useLayoutEffect(() => {
        if (!!plantImageId && visible) {
            const jwt = LocalStore.getJwt();
            fetch(url, { method: "GET", headers: { Authorization: 'Bearer ' + jwt } })
                .then(async (response) => {
                    if (response.ok) {
                        return { success: true, blob: await response.blob() };
                    } else {
                        return { success: false, reason: 404 };
                    }
                })
                .then((data) => {
                    if (data.success) {
                        setImgSrc(URL.createObjectURL(data.blob));
                    } else {
                        setImgSrc("/imgs/404.gif");
                    }
                })
                .catch(error => console.log('error is', error));;
        } else {
            setImgSrc("/imgs/404.gif");
        }
    }, [, visible, plantImageId]);


    if (visible !== true) {
        return (null);
    }

    return (
        <Portal>
            <div className={"jwtimage-preview-container"} onClick={() => { if (onClick) { onClick(); } }}>
                <img src={imgSrc} className="img" loading="lazy" />
                {children}
            </div>
        </Portal>
    )

}

export const JwtPlantImageWithPreview = ({ imageId, onError }: { imageId: string, onError?(): void }) => {
    const [previewVisible, setPreviewVisible] = useState<boolean>(false);
    return (
        <div>
            <div className="image" onClick={() => setPreviewVisible(true)}>
                <JwtPlantImage plantImageId={imageId} sizeType="generic" onError={onError} />
            </div>
            <JwtPlantImagePreview plantImageId={imageId} visible={previewVisible} onClick={() => setPreviewVisible(false)} />
        </div>
    )
}

const NoImageReplacement = () => {
    return (
        <div className='noimage-replacement'>
            <div className='noimage-replacement-content'>
                <ImagesIcon />
            </div>
        </div>
    )
}