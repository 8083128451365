
import { useSelector, useDispatch } from 'react-redux';
import { RdxStore } from '../rdx/ReduxTypes';
import { CHANGE_TOOLS_CUSTOMREDUXSTATE } from '../rdx/toolsreducer';


export type ReduxStateName = "none" |
    "approvedimageplants";

export const useReduxState = <Type>(name: ReduxStateName, defaultValue: Type) => {
    const state: { [key: string]: Type } = useSelector((state: RdxStore) => state.tools.customReduxState);
    const dispatch = useDispatch();
    const value = (state?.[name] ?? defaultValue) as Type;
    return {
        value: value,
        set: (val: Type) => {
            const change = { ...state, [name]: val };
            dispatch({ type: CHANGE_TOOLS_CUSTOMREDUXSTATE, payload: change })
        }
    }
}