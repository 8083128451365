import React, { useState } from 'react';
import Select from 'react-select';
// import { Link } from 'react-router-dom';
import { useSelector } from 'react-redux';
import { FetchHandler } from '../../Handlers/FetchHandler';
import { ThreeColumnContainer, TwoColumnContainer, TabContainer, Tab } from '../Shared/Formelements';
import { MessageBoxes } from '../../Handlers/MessageBoxes';
import { Buttons } from '../UI/Buttons';
import { PlantHandler } from "../../Handlers/PlantHandler";
// import store from '../../rdx/store';
import { Elements, ScreenHeader, Spacer } from '../UI/Elements';
import { AdminIcon, AdminIcons } from '../Shared/AdminIcon';


const actions = { remove: "remove", consolidate: "consolidate" };

export const Tools = () => {


    const plantfields = useSelector(state => state.data.plantfields);
    const options = [{ value: "-", label: "Inget filter", model: null }].concat(plantfields.filter(obj => [4, 5].indexOf(obj.type) >= 0).map(obj => { return { value: obj.id, label: obj.name, model: obj } }));

    const [selectedField, setSelectedField] = useState(null);
    const [selectedOptions, setSelectedOptions] = useState({});
    const selectedOptionsList = Object.keys(selectedOptions).map(f => selectedOptions[f]);
    const [orphanedKeys, setOrphanedKeys] = useState([])

    const fieldChanged = (e) => {
        setOrphanedKeys([]);
        console.log(e);
        setSelectedField(e.model);
        setSelectedOptions({});

        FetchHandler.getJson("/api/plantfields/" + e.model.id + "/uniquefieldvalues").then(dbKeys => {

            const availableKeys = e.model.selectOptions.map(obj => obj.key);
            let orphaned = [];
            dbKeys.forEach(dbKey => {
                if (availableKeys.indexOf(dbKey) < 0) {
                    orphaned.push(dbKey);
                }
            });
            setOrphanedKeys(orphaned);

            // console.log("availableKeys: ", availableKeys);
            // console.log("dbKeys: ", dbKeys);
            // console.log("orphaned: ", orphaned);
        })
    }

    const selectedOptionChanged = (e, option) => {
        let r = { ...selectedOptions };
        if (e.target.checked) {
            r[option.key] = option;
        } else {
            delete r[option.key];
        }
        setSelectedOptions(r);

    }

    const [action, setAction] = useState(actions.consolidate);


    const removeClicked = () => {
        if (!MessageBoxes.confirm("All information om alternative kommer tas bort, vill do fortsätta?")) {
            MessageBoxes.warning("Operationen avbröts");
            return;
        }

        PlantHandler.removeFieldOptions(selectedField, selectedOptions).then(response => {
            setSelectedField(null);
            setSelectedOptions({});
            MessageBoxes.info("alternative är borttaget!");
        });

    }

    return (
        <div>
            <ScreenHeader text="Verktyg" icon={AdminIcons.toolbox} />
            <div>
                <UpdateSearchText />
            </div>
            <ThreeColumnContainer>
                <div>
                    <Elements.Label text="Fält" />
                    <Select
                        defaultValue={{ value: "-", label: "Inget filter", model: null }}
                        options={options}
                        onChange={fieldChanged}
                    />
                </div>
                <div>
                    <label>Alternativ</label>
                    {selectedField && (
                        <div>
                            <div>
                                <h6>
                                    {selectedField?.name}
                                </h6>
                                <Elements.Div text={"Typ: " + selectedField?.typeString} />
                                <hr />
                            </div>
                            <div>
                                <OptionsTable
                                    showItems={selectedField}
                                    selectOptions={selectedField?.selectOptions}
                                    selectedOptionChanged={selectedOptionChanged}
                                    orphanedKeys={orphanedKeys}
                                />
                            </div>
                        </div>
                    )}
                </div>
                <div>
                    <label>Åtgärd</label>

                    <TabContainer tabs={[
                        { key: actions.consolidate, label: "Konsolidera alternativ" },
                        { key: actions.remove, label: "Ta bort alternativ" },
                    ]}
                        selectedTab={action}
                        onClick={e => setAction(e)}
                    >
                        <RemoveTab action={action} selectedOptionsList={selectedOptionsList} removeClicked={removeClicked} />
                        <ConsolidateTab fieldId={selectedField?.id} action={action} selectedOptionsList={selectedOptionsList} />
                    </TabContainer>

                </div>
            </ThreeColumnContainer>
        </div>
    )


}


const OptionsTable = ({ selectOptions, selectedOptionChanged, orphanedKeys, showItems }) => {
    return (
        <table className="tbl">
            <tbody>
                {(showItems && selectOptions) && selectOptions.map(option => (
                    <tr key={option.key}>
                        <td>
                            <input type="checkbox" value={option.key} onChange={(e) => selectedOptionChanged(e, option)} />
                        </td>
                        {/* <td>
                        {option?.key}
                    </td> */}
                        <td>
                            {option?.name}
                        </td>
                        <td>
                            {option?.data}
                        </td>
                    </tr>
                ))}

                {(showItems && orphanedKeys) && orphanedKeys.map(orphanedKey => (
                    <tr key={orphanedKey}>
                        <td>
                            <input type="checkbox" value={orphanedKey} onChange={(e) =>
                                selectedOptionChanged(e, {
                                    key: orphanedKey,
                                    name: orphanedKey + " endast db värde"
                                })
                            } />
                        </td>
                        {/* <td>
                        {option?.key}
                    </td> */}
                        <td>
                            {orphanedKey}
                        </td>
                        <td>
                            {orphanedKey}
                        </td>
                    </tr>
                ))}
            </tbody>
        </table>

    )
}

const RemoveTab = ({ action, selectedOptionsList, removeClicked }) => {
    return (
        <Tab visible={action === actions.remove}>
            <p>
                Ta bort alternativet från listan och ta bort värdet på de växter som har det angivet.
            </p>
            <Spacer.Vertical size="20px" />
            <div style={{ textAlign: "center" }}>
                <Buttons.Standard label="Ta bort värde" onClick={removeClicked} />
            </div>
            <div>
                <table className="tbl">
                    <tbody>
                        {selectedOptionsList.map(obj => (
                            <tr key={obj.key}>
                                <td>
                                    {obj.name}
                                </td>
                            </tr>
                        ))}
                    </tbody>
                </table>
            </div>
        </Tab>
    )
}

const ConsolidateTab = ({ fieldId, action, selectedOptionsList }) => {
    const [targetOptionKey, setTargetOptionKey] = useState("");

    const consolidateClicked = () => {
        const keys = selectedOptionsList.map(obj => obj.key);

        if (!MessageBoxes.confirm("Alla alternativ kommer förenas till ett, vill do fortsätta?")) {
            MessageBoxes.warning("Operationen avbröts");
            return;
        }

        PlantHandler.consolidateFieldOptions(fieldId, keys, targetOptionKey).then(response => {
            MessageBoxes.info("alternativen är konsoliderade!");
        });
    }

    return (

        <Tab visible={action === actions.consolidate}>
            <p>
                Slå ihop alternativen med varandra och uppdatera värdet på de växter som har det angivet.
            </p>

            <div>
                <table className="tbl">
                    <tbody>
                        {selectedOptionsList.map(obj => (
                            <tr key={obj.key}>
                                <td>
                                    {obj.name}
                                </td>
                            </tr>
                        ))}
                    </tbody>
                </table>
            </div>
            <Spacer.Vertical size="40px" />
            <div>
                <Elements.Form>

                    <Elements.Label text="Konsolidera till" />
                    <Elements.Select
                        value={targetOptionKey}
                        options={selectedOptionsList.map(obj => { return { value: obj.key, label: obj.name } })}
                        onChange={e => {
                            setTargetOptionKey(e);
                            console.log(e);
                        }}
                        includeEmpty={true}
                    />



                    <Spacer.Vertical size="20px" />
                    <Buttons.Standard label="Konsolidera!" onClick={consolidateClicked} />

                </Elements.Form>
            </div>
        </Tab>
    )
}

const UpdateSearchText = () => {
    const [updating, setUpdating] = useState(false);
    return (
        <button className='btn' onClick={() => {
            setUpdating(true);
            FetchHandler.postJson("/api/plants/updatesearch", {}).then(e => {
                setUpdating(false);
            });
        }} disabled={updating}>
            {updating && (
                <span>Uppaterar....</span>
            )}
            {!updating && (
                <span>Uppatera sökfält</span>
            )}

        </button>


    )
}