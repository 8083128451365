import React, { ReactNode, useState } from "react"
import { DragDropContainer, DropTarget } from 'react-drag-drop-container';
import { BlogCard } from "../Blog/Blog";
import { BlogPostType } from "../Blog/useBlog";
import { ForumPostType } from "../Forum/Forum";
import { PlantWithImage } from "../PlantImageOperations/PlantImageOperations";
import { PostImage } from "../PlantImageOperations/SocialImageOperations";
import { UserPlantImageCard } from "../PlantImageOperations/UserPlantImageOperations";
import { ApiPostWithImages } from "../PlantImageOperations/useSocialImageOperations";
import { UserPostFeaturedCard } from "../PostsManagement/PostsManagement";
import { AdminIcon, AdminIcons } from "../Shared/AdminIcon";
import { ClipBoardDropTargets, ClipBoardDropTargetType, useClipBoard } from "./useClipBoard";



export const ClipBoardIcon = () => {
    const ui = useClipBoard();

    const onClick = () => {
        if (ui.clipBoardShow) {
            ui.hideClipBoard("show", true);
        } else {
            ui.showClipBoard("show");
        }
    }
    return (
        <>
            <div className="clipboard-button" onClick={onClick}>
                <AdminIcon icon={AdminIcons.clipboard} />
                {ui.totalCount}
            </div>
        </>
    )
}
export const ClipBoardElement = () => {
    const ui = useClipBoard();

    const [hovering, setHovering] = useState(false);
    const onDragEnter = (e) => {
        if (e) {
            setHovering(true);
        }
    }
    const onDragLeave = (e) => {
        setHovering(false);
    }

    type DragData<T> = { dragData: T }

    const onHitUserPostFeaturedCard = (e: DragData<ForumPostType>) => {
        console.log(e.dragData)
        ui.addSelectedFeaturedForumPost(e?.dragData)
    }
    const onHitPostImage = (e: DragData<ApiPostWithImages>) => {
        ui.addSelectedUserImagesSocial(e?.dragData)
    }
    const onHitPlantImage = (e: DragData<PlantWithImage>) => {
        ui.addSelectedUserImagesPlants(e?.dragData)
    }
    const onHitBlog = (e: DragData<BlogPostType>) => {
        ui.addSelectedBlog(e?.dragData)
    }

    return (
        <div className={"clipboard-element" + (ui.clipBoardShow ? "" : " hidden")}>

            <div className="clipboard-tabs">
                <div className={"clipboard-tab" + (ui.clipBoardState === "show" ? " selected" : "")} onClick={() => ui.setClipBoardState("show")}>
                    Alla
                </div>
                <div className={"clipboard-tab" + (ui.clipBoardState === "dropping" ? " selected" : "")} onClick={() => ui.setClipBoardState("dropping")}>

                </div>
            </div>

            {ui.clipBoardState === "dropping" && (
                <>
                    {/* UserPostFeaturedCard */}
                    <DropTarget onHit={onHitUserPostFeaturedCard} onDragEnter={onDragEnter} onDragLeave={onDragLeave} targetKey={ClipBoardDropTargets.userpostfeaturedcard}>
                        {/* Postbilder */}
                        <DropTarget onHit={onHitPostImage} onDragEnter={onDragEnter} onDragLeave={onDragLeave} targetKey={ClipBoardDropTargets.postimage}>
                            {/* Växtbilder */}
                            <DropTarget onHit={onHitPlantImage} onDragEnter={onDragEnter} onDragLeave={onDragLeave} targetKey={ClipBoardDropTargets.plantimage} >
                                {/* Växtbilder */}
                                <DropTarget onHit={onHitBlog} onDragEnter={onDragEnter} onDragLeave={onDragLeave} targetKey={ClipBoardDropTargets.blog} >
                                    <DropArea hovering={hovering} />
                                </DropTarget>
                            </DropTarget>
                        </DropTarget>
                    </DropTarget>
                </>
            )}

            {ui.clipBoardState === "show" && (
                <div className="clipboard-dataarea">
                    <div hidden={!(ui?.clipBoardData?.plantimages?.length > 0)}>
                        <h4>
                            Växt bilder
                            {/* <button className="btn" onClick={() => { ui.clearPlantImages() }} >
                                <AdminIcon icon={AdminIcons.trashcan} />
                            </button> */}
                        </h4>
                        <div className="userplantimagecard-grid">
                            {ui?.clipBoardData?.plantimages?.map((item, idx) => (
                                <DropItemEdit key={item?.imageid} onRemove={() => ui.removePlantImage(item?.imageid)}>
                                    <UserPlantImageCard
                                        item={item}
                                        //onSelectClick={(() => { ui.toggleSelect(item) })}
                                        onSelectClick={null}
                                        selected={false}
                                        openClipBoardOnDrag={false}
                                    />
                                </DropItemEdit>
                            ))}
                        </div>
                    </div>


                    <ItemSection
                        label={"Inläggs bilder"}
                        hidden={!(ui?.clipBoardData?.postimage?.length > 0)}
                        onTrashPress={() => { ui.clearPostImages() }} >
                        <div className="postimages-grid">
                            {ui?.clipBoardData?.postimage?.map(item => (
                                <DropItemEdit key={item?.imageid} onRemove={() => ui.removePostImage(item?.imageid)}>
                                    <PostImage
                                        item={item}
                                        selected={false}
                                        onSelect={null}
                                        showSelectionToggle={true}
                                        openClipBoardOnDrag={false}
                                    />
                                </DropItemEdit>
                            ))}
                        </div>
                    </ItemSection>


                    <ItemSection
                        label={"Featured"}
                        hidden={!(ui?.clipBoardData?.featuredforumpost?.length > 0)}
                        onTrashPress={() => { ui.clearFeaturedForumPost() }} >
                        {ui?.clipBoardData?.featuredforumpost?.map(item => (
                            <DropItemEdit key={item?.id} onRemove={() => ui.removeFeaturedForumPost(item?.id)}>
                                <UserPostFeaturedCard post={item} openClipBoardOnDrag={false} />
                            </DropItemEdit>
                        ))}
                    </ItemSection>


                    <ItemSection
                        label={"Bloggar"}
                        hidden={!(ui?.clipBoardData?.blogs?.length > 0)}
                        onTrashPress={() => { ui.clearBlogs() }} >
                        {ui?.clipBoardData?.blogs?.map(item => (
                            <DropItemEdit key={item?.imageid} onRemove={() => ui.removeBlog(item?.id)}>
                                <BlogCard item={item} onPress={() => { }} openClipBoardOnDrag={false} />
                            </DropItemEdit>
                        ))}
                    </ItemSection>


                </div>
            )}
        </div>
    )
}

const ItemSection = ({ hidden, label, children, onTrashPress }: { label: string, hidden: boolean, children: ReactNode, onTrashPress(): void }) => {

    return (
        <div hidden={hidden}>
            <h4>
                {label}
                {/* <button className="btn" onClick={onTrashPress} >
                    <AdminIcon icon={AdminIcons.trashcan} />
                </button> */}
            </h4>
            <div>
                {children}
            </div>
        </div>


    )
}


export const ClipBoardDragContainer = ({ children, type, dragData, openClipBoardOnDrag }: { children: ReactNode, type: ClipBoardDropTargetType, dragData: any, openClipBoardOnDrag: boolean }) => {
    const clipboard = useClipBoard();
    const onDrop = (e) => { }
    //const [dragging, setDragging] = useState(false)
    const onDragEnd = () => {
        console.log("onDragEnd;", type, dragData)
        //setDragging(false);
        if (openClipBoardOnDrag) {
            clipboard.hideClipBoard("show");
        }
    }
    const onDragStart = () => {
        console.log("onDragStart;", type, dragData)
        // setDragging(true);
        if (openClipBoardOnDrag) {
            clipboard.showClipBoard("dropping");
        }
    }

    return (
        <DragDropContainer targetKey={type} dragData={dragData} onDrop={onDrop} onDragStart={onDragStart} onDragEnd={onDragEnd}>
            {children}
        </DragDropContainer>
    )
}

export const useDropElement = () => {

    const [hovering, setHovering] = useState(false);
    const onDragEnter = (e) => {
        console.log(e)
        if (e) {
            setHovering(true);
        }
    }
    const onDragLeave = (e) => {
        setHovering(false);
    }

    return {
        hovering: hovering,
        setHovering: setHovering,
        onDragEnter: onDragEnter,
        onDragLeave: onDragLeave
    }
}

export const DropArea = ({ hovering }: { hovering: boolean }) => {
    return (
        <div className={"clipboard-droparea" + (hovering ? " hovering" : "")}>
            <span className="drop-text">
                Släpp här
            </span>
        </div>
    )
}


const DropItemEdit = ({ children, onRemove }: { children: ReactNode, onRemove(): void }) => {
    return (
        <span className="dropitem-edit">
            <span>
                <button onClick={onRemove}>
                    <AdminIcon icon={AdminIcons.close} />
                </button>
            </span>
            {children}
        </span>
    )
}