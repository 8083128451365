import { useState, useEffect } from "react";
import { FetchHandler } from "../../Handlers/FetchHandler";
import { MessageBoxes } from "../../Handlers/MessageBoxes";
import { Resources } from "../../Tools/Resources";
import { ForumPostType } from "../Forum/Forum";
import { AdminIcons } from "../Shared/AdminIcon";
import { ToolbarItemType } from "../ToolBar/ToolBar";

export type FeaturedPostType = "none" | "flower_recipe" | "flower_bed" | "friend";
const featuredPostTypes: FeaturedPostType[] = ["none", "flower_recipe", "flower_bed", "friend"];
export const FeaturedPostTypeToText = (e: FeaturedPostType) => {
    if (e === "flower_bed") {
        return "Veckans växtplats";
    } else if (e === "flower_recipe") {
        return "Veckans bukettrecept";
    } else if (e === "friend") {
        return "Växtvänner";
    } else {
        return "-"
    }
}

export const usePostsManagement = () => {
    const tools: ToolbarItemType[] = [
        { id: "1", label: "Ladda om ", onClick: () => { loadPosts() }, icon: AdminIcons.refresh },
    ]

    const api = usePostsManagementApi();
    const [posts, setPosts] = useState<ForumPostType[]>([]);


    const loadPosts = async () => {
        const d = await api.featuredPosts();
        setPosts(d);
    }

    useEffect(() => {
        loadPosts();
    }, [])


    const [selectedPost, setSelectedPost] = useState<ForumPostType>(undefined);
    const hasGardenItem = !!selectedPost?.gardenitemid;
    return {
        tools: tools,
        featuredPostTypes: hasGardenItem ? featuredPostTypes : featuredPostTypes.filter(m => m !== "flower_bed"),
        posts: posts,
        selectedPost: selectedPost,
        hasGardenItem: hasGardenItem,

        // Actions
        selectPost: (id: string) => {
            if (selectedPost?.id === id) {
                setSelectedPost(undefined);
            } else {
                setSelectedPost({ ...posts.find(m => m?.id === id) });
            }
        },
        removeFromFeatured: async () => {
            const success = await api.removeFromFeatured(selectedPost?.id);
            if (success === true) {
                await loadPosts();
                setSelectedPost(undefined);
            } else {
                MessageBoxes.warning(Resources.str.OPERATION_FAILED)
            }
        },

        setFeaturedPostType: async (type: FeaturedPostType) => {
            let change = { ...selectedPost };
            change.featuredtype = type;
            setSelectedPost(change);
        },
        setSelectedImageId: (imageId: string) => {
            setSelectedPost({ ...selectedPost, featuredimageid: imageId })
        },
        saveChangesOnSelected: async () => {
            const success = await api.saveChangesOnSelected(selectedPost?.id, selectedPost);
            if (success) {
                loadPosts();
            } else {
                loadPosts();
            }
        }
    }
}

const usePostsManagementApi = () => {
    return {
        featuredPosts: async (): Promise<ForumPostType[]> => {
            const data: ForumPostType[] = await FetchHandler.getJson("/api/content/featuredposts");
            return data;
        },
        removeFromFeatured: async (id: string): Promise<boolean> => {
            const data: boolean = await FetchHandler.delete("/api/content/featuredposts/" + id);
            return data;
        },
        saveChangesOnSelected: async (id: string, post: ForumPostType): Promise<boolean> => {
            const data: boolean = await FetchHandler.patchJson("/api/content/featuredposts/" + id, {
                featured: post?.featured,
                featuredtype: post?.featuredtype,
                featuredimageid: post?.featuredimageid,
            });
            return data;
        }
    }
}