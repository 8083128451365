import React, { Component, useEffect, useState } from 'react';
import { FetchHandler } from '../../Handlers/FetchHandler';
import { ProductIdsType } from '../Purchases/Subscriptions';
import { HasAnyRole } from './RoleElements';


type SubscriptionSummaryType = {
    productId: ProductIdsType,
    number: number
}
export const RevenueCounter = () => {

    const [numberOfYearlySubs, setNumberOfYearlySubs] = useState<number>(0);
    const [numberOfMonthlySubs, setNumberOfMonthlySubs] = useState<number>(0);
    const [revenueString, setRevenueString] = useState("-")

    const load = async () => {
        const data: SubscriptionSummaryType[] = await FetchHandler.getJson("/api/subscriptions/summary");
        setNumberOfYearlySubs(data?.find(m => m.productId == "ohgarden.subs.year")?.number);
        setNumberOfMonthlySubs(data?.find(m => m.productId == "ohgarden.subs.month")?.number);
    }

    const [loggedInLast24Hurs, setLoggedInLast24Hurs] = useState<number>(0);
    const loadActivity = async () => {
        const d: { loggedInLast24Hurs: number } = await FetchHandler.getJson("/api/stats/activity");
        setLoggedInLast24Hurs(d.loggedInLast24Hurs)
    }

    useEffect(() => {
        const revenue = (numberOfYearlySubs * 249 + numberOfMonthlySubs * 29 * 12) * 0.85 * 0.8;
        setRevenueString("SEK " + Math.round(revenue).toLocaleString());
    }, [numberOfYearlySubs, numberOfMonthlySubs]);

    useEffect(() => {
        load();
        loadActivity();
        let tc = setInterval(() => {
            load();
            loadActivity();
        }, 5 * 60 * 1000);

        return () => { clearInterval(tc); }
    }, []);

    return (
        <div className='revenue-counter'>
            {numberOfYearlySubs + numberOfMonthlySubs} prenumerationer, {numberOfYearlySubs} års- och {numberOfMonthlySubs} månadsprenumerationer.
            <HasAnyRole roles={["DEVELOPER"]}>
                <span>
                    Total intäkt: <span className='amount'>{revenueString}</span> per år.
                </span>
                <span>
                    {" Inloggade senaste 24h: " + loggedInLast24Hurs + "st"}
                </span>
            </HasAnyRole>
        </div>
    )
}