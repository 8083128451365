import React, { Component } from 'react';
//import { Link } from 'react-router-dom';
import { FetchHandler } from "../../Handlers/FetchHandler";
import { Dialog } from "../Shared/Dialog";
import { VisibleIcon, NotVisibleIcon } from "../Shared/Icons";
//import { fn } from "./../../Tools/ArrayFunctions";
import { Data } from "../../Handlers/Data";
//import { AddIcon, LoadingIcon, TrashIcon } from '../Shared/Icons';
import { LoadingElement } from '../Shared/LoadingElement';
import { EditFieldRows } from "./EditFieldRows";
import { MessageBoxes } from '../../Handlers/MessageBoxes';
import { StoreFillerHandler } from "../../rdx/StoreFillerHandler";
import { PlantFieldsMergeOptionsButton } from './PlantFieldsMergeOptions';
import { Operations } from '../../rdx/Operations';
import { useSelector } from 'react-redux';
import { AdminIcons } from '../Shared/AdminIcon';
import { ScreenHeader } from '../UI/Elements';


interface IProps { }
interface IState {
    plantfields?: any[],
    selected?: any,
    loading?: boolean,

    id?: string,
    name?: string,
    description?: string,
    plantTypeCategoryDescription?: string,
    sortOrder?: number,
    type?: number,
    typeString?: string,
    selectOptions?: any[],
    suffix?: string
}


export class PlantFields extends Component<IProps, IState> {
    static displayName = PlantFields.name;

    modelTemplate: any;
    mounted: boolean;

    constructor(props) {
        super(props);
        this.modelTemplate = {
            id: "",
            name: "",
            description: "",
            plantTypeCategoryDescription: "",
            sortOrder: 0,
            type: 0,
            typeString: "",
            selectOptions: []
        }
        this.mounted = false;
        this.state = {
            plantfields: [],
            selected: null,
            loading: false,

            id: "",
            name: "",
            description: "",
            plantTypeCategoryDescription: "",
            sortOrder: 0,
            type: 0,
            typeString: "",
            selectOptions: []
        };


        this.reload = this.reload.bind(this);
        this.selectModel = this.selectModel.bind(this);
        this.inputChanged = this.inputChanged.bind(this);
        this.saveClicked = this.saveClicked.bind(this);

        this.newClicked = this.newClicked.bind(this);
    }

    newClicked() {
        var id = MessageBoxes.prompt("Ange Id för det nya fältet");

        if (id === null || id === undefined || id.length === 0) {
            return;
        }

        if (this.state.plantfields.filter(obj => obj.id === id).length > 0) {
            MessageBoxes.warning("Id:t finns redan!")
        } else {
            FetchHandler.postJson("/api/plantfields/add", { id: id }).then(success => {
                if (success) {
                    this.reload();
                } else {
                    MessageBoxes.warning("Misslyckades!")
                }
            });
        }

    }

    inputChanged(e) {
        const name = e.target.name;
        const value = e.target.value;
        if (name === "type") {
            this.setState({ [name]: parseInt(value) });
        } else {
            this.setState({ [name]: value });
        }

    }

    selectModel(model) {
        this.setState({
            selected: model === null ? null : { ...model },

            id: model !== null ? model.id : "",
            name: model !== null ? model.name : "",
            suffix: model !== null ? model.suffix : "",
            description: model !== null ? model.description : "",
            sortOrder: model !== null ? model.sortOrder : 0,
            type: model !== null ? model.type : 0,
            typeString: model !== null ? model.typeString : "",
            selectOptions: model !== null ? model.selectOptions : []

        });
    }

    saveClicked() {
        const fields = ["id", "name", "description", "selectOptions", "type", "sortOrder", "suffix"];
        let postObj = {};

        fields.forEach(p => {
            postObj[p] = this.state[p];
        });

        console.log("postObj: ", postObj);

        FetchHandler.postJson("/api/plantfields/update", postObj).then(data => {
            this.selectModel(null);
            this.reload();
            StoreFillerHandler.ensureCache(true);
        });


    }

    reload() {
        this.setState({ plantfields: [], loading: true });
        FetchHandler.getJson("/api/plantfields").then(data => {
            if (this.mounted) {
                this.setState({ plantfields: data, loading: false });
            }
        })
    }

    componentDidMount() {
        this.mounted = true;
        this.reload();
    }

    componentWillUnmount() {
        this.mounted = false;
    }


    render() {
        return (
            <div>
                <ScreenHeader text="Växtfält" icon={AdminIcons.comment} />
                <div>
                    <p>
                        Varje fält som man definierar nedan kan läggas till en växttyp. På alla växter som tillhör växttypen kommer man därefter kunna ange information på fältet.
                    </p>
                </div>
                <hr />
                <div>
                    <button onClick={this.newClicked} className="btn">
                        Nytt växtfält...
                    </button>
                </div>
                <div>
                    {this.state.selected !== null && (
                        <Dialog width="800px" height="500px" onCancel={() => { this.selectModel(null); }} onOk={this.saveClicked} okButtonText="Spara">
                            <div className="form-container">
                                <FormField title="ID">
                                    <input type="text" name="id" id="id" value={this.state.id} onChange={this.inputChanged} />
                                </FormField>

                                <FormField title="Namn">
                                    <input type="text" name="name" id="name" value={this.state.name} onChange={this.inputChanged} />
                                </FormField>

                                <FormField title="Suffix">
                                    <input type="text" name="suffix" id="suffix" value={this.state.suffix ?? ""} onChange={this.inputChanged} />
                                </FormField>

                                <FormField title="Typ">
                                    <select name="type" id="type" value={this.state.type} onChange={this.inputChanged}>
                                        {Data.plantFieldTypes.map(item => (
                                            <option key={item.type} value={item.type}>{item.typeString}</option>
                                        ))}
                                    </select>
                                </FormField>

                                <FullFormField title="Beskrivning">
                                    <textarea className="w100" name="description" id="description" value={this.state.description} onChange={this.inputChanged}></textarea>
                                </FullFormField>

                            </div>
                            <div className={(this.state.type === 4 || this.state.type === 5) ? "" : "hidden"}>
                                <EditFieldRows rows={this.state.selectOptions} onChange={(e) => { this.setState({ selectOptions: e }); }} />
                            </div>
                        </Dialog>
                    )}
                    <hr />
                </div>

                {this.state.loading && (
                    <LoadingElement />
                )}

                <div id="plantfields">
                    <PlantFieldTable
                        plantfields={this.state.plantfields}
                        selectModel={this.selectModel}
                    />
                </div>
            </div>
        );
    }
}


const FormField = (props) => {
    return (
        <div className="form-item w25">
            <label>{props.title}</label>
            <div>
                {props.children}
            </div>
        </div>
    )
}
const FullFormField = (props) => {
    return (
        <div className="form-item w100">
            <label>{props.title}</label>
            <div>
                {props.children}
            </div>
        </div>
    )
}

const PlantFieldTable = (props) => {
    return (
        <table className="tbl">
            <thead>
                <tr>
                    <th>ID</th>
                    <th></th>
                    <th>Namn</th>
                    <th>Suffix</th>
                    <th>Typ</th>
                    <th>Antal alternativ</th>
                    <th></th>
                </tr>
            </thead>
            <tbody>
                {props.plantfields.map(item => (
                    <PlantFieldRow key={item.id} {...item} selectModel={props.selectModel} />
                ))}
            </tbody>
        </table>
    )
}

const PlantFieldRow = (props) => {
    const id = props.id.toLowerCase();
    const field = useSelector(state => state.data.plantfieldsobject[id]);



    const publicClicked = () => {
        Operations.togglePlantFieldPublic(props.id, !field.public);
    }


    const numberOfOptions = props.selectOptions.length === 0 ? "-" : props.selectOptions.length;
    const numberOfSelectableOptions = props.selectOptions.length === 0 ? "-" : props.selectOptions.filter(obj => obj.selectable).length;
    return (
        <>
            <tr>
                <td>
                    <button className="btn-link" onClick={() => { props.selectModel({ ...props }) }}>
                        {props.id}
                    </button>
                </td>
                <td>
                    <button className="btn-icon" onClick={publicClicked}>
                        {field?.public && (<VisibleIcon className="cursor-pointer" />)}
                        {!field?.public && (<NotVisibleIcon className="cursor-pointer" />)}
                    </button>
                </td>
                <td>{field.name}</td>
                <td>{field.suffix}</td>
                <td>{field.typeString}</td>
                <td>
                    {numberOfOptions} ({numberOfSelectableOptions})
                </td>
                <td>
                    {field.selectOptions.length > 0 && (
                        <PlantFieldsMergeOptionsButton id={props.id} />
                    )}
                </td>
            </tr>
        </>
    );
}
