import React, { useEffect, useState } from "react"
import { Link } from 'react-router-dom';
import { FetchHandler } from "../../Handlers/FetchHandler";
import { MessageBoxes } from "../../Handlers/MessageBoxes";
import { useDateHelper } from "../../hooks/useHelper";
import { AdminIcon, AdminIcons } from "../Shared/AdminIcon";
import { CustomColumnContainer, ThreeColumnContainer } from "../Shared/Formelements";
import { ScreenHeader, ShareLinkElement } from "../UI/Elements";
import { JwtImage } from "../UI/ImageElements";


type PurchaseType = {
    alias: string,
    email: string,
    jsonReceipt: string,
    purchaseId: number,
    timeStampUtc: string,
    userId: string
}
type NotificationTypeBase = {
    sourceString: "googleplay" | "applestore",
    purchaseId: string,
    notificationId: string
    productId: string,
    notificationTypeString: string,
}

type GoogleNotificationType = NotificationTypeBase & {
    data: string,
    isTestMessage: boolean,
    messageId: string,
    message_Id: string,
    publishTimeUtc: string,
    publish_timeUtc: string,
    subscriptionNotificationMessage: {
        notificationType: number,
        notificationTypeName: "SUBSCRIPTION_CANCELED",
        purchaseToken: string,
        subscriptionId: string,
        version: string
    }
}

type AppleNotificationType = NotificationTypeBase & {
    bundleId: string,
    environment: string,
    expiresDate: number,
    inAppOwnershipType: string,
    notificationId: string,
    notificationType: number,
    notificationUUID: string,
    originalPurchaseDate: number,
    originalTransactionId: number,
    purchaseDate: number,
    purchaseId: string,
    quantity: number,
    signedDate: number,
    source: number,
    sourceString: string,
    subscriptionGroupIdentifier: number,
    subtype: string,
    summary: string,
    type: string,
    version: string,
    webOrderLineItemId: number,
}

type TransactionType = {
    alias: string,
    currentState: string,
    email: string,
    logid: number,
    productId: string,
    purchaseId: string,
    timeStampMillis: number,
    userId: string,
}

export const Purchases = () => {
    const dh = useDateHelper();
    const [selectedUser, setSelectedUser] = useState<string>(null);
    const [allTtransactions, setAllTransactions] = useState<TransactionType[]>([]);
    const [transactions, setTransactions] = useState<TransactionType[]>([]);


    const loadNotifications = async () => {
        const url = "/api/store/gettransactions";
        const data = await FetchHandler.getJson(url);
        setAllTransactions(data);
        setTransactions(data);
    }

    useEffect(() => {
        loadNotifications();
    }, []);

    const onUserSelect = (userId: string) => {
        if (selectedUser) {
            setTransactions(allTtransactions)
            setSelectedUser(null);
        } else {
            setTransactions(allTtransactions.filter(m => m?.userId == userId))
            setSelectedUser(userId);
        }
    }

    return (
        <div className="screen-transactions">
            <ScreenHeader text="Senaste transaktioner" icon={AdminIcons.dollar} />
            <div>
                <table className="tbl">
                    <thead>
                        <tr>
                            <th></th>
                            <th>Namn</th>
                            <th>Email</th>
                            <th>State</th>
                            <th>Produkt</th>
                            <th>Tid</th>
                            <th>När</th>
                            {/* <th>purchaseToken</th> */}
                        </tr>
                    </thead>
                    <tbody>
                        {transactions?.map(m => (
                            <tr key={m?.logid} className="row" onClick={() => onUserSelect(m?.userId)}>
                                <td>
                                    <AdminIcon icon={AdminIcons.search} />
                                </td>
                                <td>
                                    {m?.alias ? m?.alias : "Användarnamn saknas"}
                                </td>
                                <td>
                                    <Link to={"/user/" + m?.userId}>
                                        {m?.email}
                                    </Link>
                                </td>
                                <td>
                                    {m?.currentState}
                                </td>
                                <td>
                                    {m?.productId}
                                </td>
                                <td>{dh.epochToDateTime(m?.timeStampMillis / 1000)}</td>
                                <td>{dh.timeAgo(m?.timeStampMillis / 1000)}</td>
                            </tr>
                        ))}
                    </tbody>
                </table>
            </div>
        </div>
    )
}
