import React, { useLayoutEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { Portal } from "../Portal";
import { PlantHandler } from '../../../Handlers/PlantHandler';
import { EditPlantFieldDialog } from '../../Plants/EditPlantFieldDialog';
import { RemovePlantFieldDialog } from '../../Plants/RemovePlantFieldDialog';
import { EditTwoTextFieldDialog } from './EditTwoTextFieldDialog';
import { AddRemovePlantFromListDialog, AddRemovePlantFromListDialogStatsuType } from '../../Plants/Dialogs/AddRemovePlantFromListDialog';
import { AddSnippetDialog, RemoveSnippetDialog } from './AddSnippetDialog';
import store from '../../../rdx/store';
import { SetFamilyNameDialog } from './SetFamilyNameDialog';
import { SetPlantTypeDialog } from './SetPlantTypeDialog';
import { SetPublicDescriptionDialog } from './SetPublicDescriptionDialog';
import { useHistory } from 'react-router-dom';
import { Routes } from '../../../Handlers/ViewHandler';

export const SearchListContextMenu = ({ position, close, menuVisible, clickedFieldKey }) => {


    var nav = useHistory();
    const plantfieldsobject = useSelector(state => state.data.plantfieldsobject);
    const plantsSelected = store.getState().search.searchList.filter(obj => obj.selected).map(obj => obj.id);
    const numberOfPlantsSelected = plantsSelected?.length;

    const pos = position;
    const style = { top: pos.y + "px", left: pos.x + "px" };


    const menuDisabled = numberOfPlantsSelected === 0;


    // --------------------------- Publish ---------------------------
    const publishPlant = () => {
        if (menuDisabled) { return; }

        PlantHandler.publishPlants().then(success => {
            if (success) {
                if (close) { close(); }
            }
        });
    }
    const unPublishPlant = () => {
        if (menuDisabled) { return; }

        PlantHandler.unPublishPlants().then(success => {
            if (success) {
                if (close) { close(); }
            }
        });
    }


    // --------------------------- Fields ---------------------------
    const [selectedFieldObject, setSelectedFieldObject] = useState(null);
    useLayoutEffect(() => {
        if (clickedFieldKey) {
            setSelectedFieldObject(plantfieldsobject[clickedFieldKey.toLowerCase()]);
        }
    }, [clickedFieldKey])
    // Update Field
    const [dialogUpdateFieldVisible, setDialogUpdateFieldVisible] = useState(false);
    const updateField = (specificField) => {
        if (menuDisabled) { return; }

        if (specificField && clickedFieldKey) {
            setSelectedFieldObject(plantfieldsobject[clickedFieldKey.toLowerCase()]);
        } else {
            setSelectedFieldObject(null);
        }
        setDialogUpdateFieldVisible(true);
        if (close) { close(); }
    }

    // Delete Field
    const [dialogDeleteFieldVisible, setDialogDeleteFieldVisible] = useState(false);
    const deleteField = () => {
        if (menuDisabled) { return; }

        setDialogDeleteFieldVisible(true);
        if (close) { close(); }
    }

    // --------------------------- Odling ---------------------------
    // Odling
    const [dialogEditOdlingSkotselVisible, setDialogEditOdlingSkotselVisible] = useState(false);

    // const editOdlingSkotsel = () => {
    //     if (menuDisabled) { return; }

    //     setDialogEditOdlingSkotselVisible(true);
    //     if (close) { close(); }
    // }


    // --------------------------- Lists ---------------------------
    // Dialog edit Lists
    const [dialogEditListVisible, setDialogEditListVisible] = useState<AddRemovePlantFromListDialogStatsuType>("closed");

    const addToList = () => {
        if (menuDisabled) { return; }

        setDialogEditListVisible("openAdd");
        if (close) { close(); }
    };

    const removeFromList = () => {
        if (menuDisabled) { return; }

        setDialogEditListVisible("openRemove");
        if (close) { close(); }
    };

    // --------------------------- Snippet ---------------------------
    // addSnippet
    const [dialogAddSnippetVisible, setDialogAddSnippetVisible] = useState(false);
    const addSnippet = () => {
        if (menuDisabled) { return; }

        setDialogAddSnippetVisible(true);
        if (close) { close(); }
    }
    // removeSnippet
    const [dialogRemoveSnippetVisible, setDialogRemoveSnippetVisible] = useState(false);
    const removeSnippet = () => {
        if (menuDisabled) { return; }

        setDialogRemoveSnippetVisible(true);
        if (close) { close(); }
    }
    // FamilyName
    const [dialogFamilyNameVisible, setDialogFamilyNameVisible] = useState(false);
    const setFamilyName = () => {
        if (menuDisabled) { return; }
        setDialogFamilyNameVisible(true);
        if (close) { close(); }
    };

    // PlantType
    const [dialogPlantTypeVisible, setDialogPlantTypeVisible] = useState(false);
    const setPlantType = () => {
        if (menuDisabled) { return; }
        setDialogPlantTypeVisible(true);
        if (close) { close(); }
    }
    // Public description
    const [dialogPublicDescriptionVisible, setDialogPublicDescriptionVisible] = useState(false);
    const setPublicDescription = () => {
        if (menuDisabled) { return; }
        setDialogPublicDescriptionVisible(true);
        if (close) { close(); }
    }

    const copyToNewPlant = () => {
        nav.push({ pathname: Routes.createplant, search: "from=" + plantsSelected[0] });
    }

    return (
        <Portal>

            {menuVisible && (
                <div className="search-context" style={style}>
                    <ul>
                        <li className="text">Valda växter: {numberOfPlantsSelected} st</li>

                        <div className={menuDisabled ? "disabled" : ""}>

                            <li className="separator"></li>

                            <div className={numberOfPlantsSelected === 1 ? "" : "disabled"}>
                                <li onClick={copyToNewPlant}>Kopiera till ny växt</li>
                            </div>

                            <li className="separator"></li>
                            {/* fält */}
                            {clickedFieldKey && (
                                <li
                                    onClick={() => updateField(true)}>
                                    Uppdatera fält :
                                    <span className="bold italic">{selectedFieldObject?.name}</span>
                                </li>
                            )}
                            <li onClick={() => updateField(false)}>Uppdatera fält</li>
                            <li onClick={deleteField}>Radera fält</li>
                            <li className="separator"></li>

                            {/* Snippet */}
                            <li onClick={addSnippet}>Lägg till snippet</li>
                            <li onClick={removeSnippet}>Ta bort snippet</li>

                            {/* Listor */}
                            <li onClick={addToList}>Lägg till i lista</li>
                            <li onClick={removeFromList}>Ta bort från lista</li>
                            <li className="separator"></li>

                            {/* Publicera */}
                            <li onClick={publishPlant}>Publicera valda</li>
                            <li onClick={unPublishPlant}>Avpublicera valda</li>
                            <li className="separator"></li>

                            {/* Familjenamn */}
                            <li onClick={setFamilyName}>Uppdatera familjenamn</li>
                            <li className="separator"></li>

                            {/* Typ */}
                            <li onClick={setPlantType}>Ändra växttyp</li>
                            <li className="separator"></li>

                            {/* Public Description */}
                            <li onClick={setPublicDescription}>Ändra växtbeskrivning</li>
                            <li className="separator"></li>
                        </div>
                        <li onClick={close}>Stäng menyn</li>

                        {/* <li onClick={addTags}>Lägg till tag</li> */}
                        {/* <li className="separator"></li> */}
                        {/* <li onClick={addTags}>Skapa lokal grupp av valda</li> */}

                    </ul>
                </div>
            )}



            {dialogUpdateFieldVisible && (
                <EditPlantFieldDialog
                    cancelHandler={() => { setDialogUpdateFieldVisible(false); }}
                    okHandler={() => { setDialogUpdateFieldVisible(false); }}
                    selectedField={selectedFieldObject}
                />
            )}


            {dialogDeleteFieldVisible && (
                <RemovePlantFieldDialog
                    cancelHandler={() => { setDialogDeleteFieldVisible(false) }}
                    okHandler={() => { setDialogDeleteFieldVisible(false) }}
                />
            )}

            {dialogEditOdlingSkotselVisible && (
                <EditTwoTextFieldDialog
                    field1="MAINTENANCE"
                    field2="CULTIVATION"
                    closeDialog={() => {
                        setDialogEditOdlingSkotselVisible(false);
                    }}
                />
            )}

            {(dialogEditListVisible !== "closed") && (<AddRemovePlantFromListDialog
                okHandler={() => { setDialogEditListVisible("closed"); }}
                cancelHandler={() => { setDialogEditListVisible("closed"); }}
                status={dialogEditListVisible}
                visible={true}
            />)}


            {dialogAddSnippetVisible && (<AddSnippetDialog closeDialog={() => {
                setDialogAddSnippetVisible(false);
            }} />)}

            {dialogRemoveSnippetVisible && (<RemoveSnippetDialog closeDialog={() => {
                setDialogRemoveSnippetVisible(false);
            }} />)}

            {dialogFamilyNameVisible && (
                <SetFamilyNameDialog closeDialog={() => {
                    setDialogFamilyNameVisible(false);
                }} />
            )}

            {dialogPlantTypeVisible && (
                <SetPlantTypeDialog closeDialog={() => {
                    setDialogPlantTypeVisible(false);
                }} />
            )}
            {dialogPublicDescriptionVisible && (
                <SetPublicDescriptionDialog closeDialog={() => {
                    setDialogPublicDescriptionVisible(false);
                }} />
            )}

        </Portal>
    )
}
