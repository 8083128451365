import React, { Component } from 'react';
import { LoginHandler } from "../../Handlers/LoginHandler";



interface IProps {
}

interface IState {
  checking?: boolean,
  loggedin?: boolean,
  loggingin?: boolean,
  uid?: string,
  pwd?: string,
  failedvisible?: boolean,
  working?: boolean,
}



export class Login extends Component<IProps, IState> {
  static displayName = Login.name;

  ismounted: boolean;

  constructor(props) {
    super(props);
    this.ismounted = false;

    this.state = {
      checking: false,
      loggedin: false,
      loggingin: false,
      uid: "",
      pwd: "",
      failedvisible: false
    };

    this.loginClicked = this.loginClicked.bind(this);
    this.handleChange = this.handleChange.bind(this);
  }

  componentDidMount() {
    this.ismounted = true;
    this.setState({ checking: true });
    LoginHandler.isLoggedIn().then(res => {
      if (this.ismounted === false) { return; }
      if (res === true) {
        this.setState({ checking: false, loggedin: res, working: false });
      } else {
        this.setState({ checking: false });
      }
    });

  }
  componentWillUnmount() {
    this.ismounted = false;
  }

  handleChange(event) {
    if (this.ismounted === false) { return; }


    const pname = event.target.name;
    this.setState({
      [pname]: event.target.value
    });
  }

  loginClicked() {
    if (this.ismounted === false) { return; }

    this.setState({ loggingin: true });
    LoginHandler.login(this.state.uid, this.state.pwd).then(success => {
      if (this.ismounted === false) { return; }
      if (success !== true) {
        this.setState({
          uid: "",
          pwd: "",
          loggingin: false,
          failedvisible: true
        });
      }
    });
  }

  render() {
    return (
      <div className="login-page">
        <div className="login-box">
          {this.state.failedvisible && (
            <div className="login-warning">
              Felaktigt användarnamn och/eller lösenord.
            </div>
          )}

          <input name="uid" value={this.state.uid} type="text" placeholder="Användarnamn" onChange={this.handleChange} disabled={this.state.checking} />
          <input name="pwd" value={this.state.pwd} type="password" placeholder="Lösenord" onChange={this.handleChange} disabled={this.state.checking} />
          <button onClick={this.loginClicked} disabled={this.state.uid.length * this.state.pwd.length === 0}>
            {this.state.loggingin && (<>Loggar in...</>)}
            {!this.state.loggingin && (<>Logga in</>)}
          </button>

        </div>
      </div>
    );
  }
}
