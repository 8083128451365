import React, { useEffect, useLayoutEffect, useState } from 'react';
import Select from 'react-select';
import { useFetch } from '../../hooks/MiscHooks';
import { usePlantTypesData } from '../../Handlers/Hooks';
import { useUsers } from '../../hooks/useUsers';
import { useAllUserPlants, useUserPlants } from '../../hooks/useUserPlants';
import { UserPlantStates, UserPlantType } from '../../rdx/toolsreducer';
import { JwtPlantImage, JwtPlantImagePreview } from '../UI/ImageElements';
import { ThreeColumnContainer } from '../Shared/Formelements';
import { StringMappingType } from 'typescript';
import { FetchHandler } from '../../Handlers/FetchHandler';
import { MessageBoxes } from '../../Handlers/MessageBoxes';
import { StringHelper } from '../../Handlers/Helpers';
import { AdminIcon, AdminIcons } from '../Shared/AdminIcon';
import { ScreenHeader } from '../UI/Elements';


const epochToString = (epoch) => {
    if (epoch) {
        return new Date(epoch * 1000).toLocaleString();
    } else {
        return "-";
    }
}


export const UserPlants = () => {

    const users = useUsers();
    const allUserPlants = useAllUserPlants();


    const options = allUserPlants.plantStates.map(state => { return { value: state, label: state } });
    const stateChanged = (e) => {
        allUserPlants.setSelectedState(e.value);
    }

    return (
        <div className='view-userplants'>
            <ScreenHeader text="Växter tillagda av användare" icon={AdminIcons.leaf} />
            <p>
                Antal: {allUserPlants.numberOfItems}
            </p>

            <hr />
            <ThreeColumnContainer>
                <div>
                    <label>Status</label>
                    <Select
                        defaultValue={{ value: "none", label: "none" }}
                        options={options}
                        onChange={stateChanged}
                    />
                </div>
            </ThreeColumnContainer>

            <hr />
            <div className={allUserPlants.error ? "" : "hidden"}>
                {allUserPlants.error}
            </div>

            <div className={allUserPlants.loading ? "" : "hidden"}>
                Laddar...
            </div>

            <table className={'tbl ' + (allUserPlants.loading ? "hidden" : "")}>
                <thead>
                    <tr>
                        <td>Namn</td>
                        <td>Sort</td>
                        <td>Växttyp</td>
                        <td>alias</td>
                        <td>Email</td>
                        <td>Status</td>
                        <td>Skapad</td>
                    </tr>
                </thead>
                <tbody>
                    {(!allUserPlants.loading && allUserPlants?.data) && allUserPlants.data.map((item: UserPlantType) => (
                        <TblRow
                            key={item.id}
                            item={item}
                            open={allUserPlants.selectedPlantId === item.id}
                            onClick={allUserPlants.itemClicked}
                        />
                    ))}
                </tbody>
            </table>
            {/* <div>
                {JSON.stringify(allUserPlants?.data?.[0])}
            </div> */}
        </div>
    )
}

const TblRow = ({ item, open, onClick }: { item: UserPlantType, open: boolean, onClick: any }) => {
    const plantTypesData = usePlantTypesData();

    const users = useUsers();


    return (
        <>
            <tr key={item.id} onClick={() => onClick(item.id)} className='item'>
                <td>{item.name}</td>
                <td>{item.specifictype}</td>
                <td>{plantTypesData.item(item.planttype)?.name}</td>
                <td>{users.getUsersName(item?.userid)}</td>
                <td>{users.getUsersEmail(item?.userid)}</td>
                <td>{item?.state}</td>
                <td>{epochToString(item.sys_datecreated_utc_epoch)}</td>
            </tr>
            {open && (
                <UserPlantDetailsPane gardenid={item.gardenid} plantid={item.id} userid={item.userid} />
            )}
        </>
    )
}
const UserPlantDetailsPane = ({ userid, gardenid, plantid }) => {
    const plantTypesData = usePlantTypesData();
    const userPlant = useUserPlants(userid, gardenid, plantid);


    return (
        <>
            <tr></tr>
            <tr>
                <td colSpan={7}>
                    <div className={'item-container' + (userPlant.loading ? " loading" : "")}>

                        <div className='item-step'>
                            <div className='name'>{userPlant.data?.name}</div>
                            <div className='specifictype'>{userPlant.data?.specifictype}</div>
                            <div className='specifictype'>Status: {userPlant.plantState}</div>
                            <div className='planttype'>{plantTypesData.item(userPlant.data?.planttype)?.name}</div>

                            <div className='text'>Skapad: {epochToString(userPlant.data?.sys_datecreated_utc_epoch)}</div>
                        </div>


                        <div className='item-step'>
                            {userPlant.loadingImages && (
                                <div>
                                    Laddar....
                                </div>
                            )}

                            {(userPlant.loadingImages == false && userPlant.images?.length === 0) && (
                                <div>
                                    Inga bilder finns.
                                </div>
                            )}
                            <div className='images'>
                                {userPlant.images.map(img => (
                                    <div key={img.id} className="image" onClick={() => { userPlant.previewShow(img.id) }} >
                                        <JwtPlantImage plantImageId={img.id} />
                                    </div>
                                ))}
                                <JwtPlantImagePreview plantImageId={userPlant.previewId} visible={userPlant.previewVisible} onClick={userPlant.previewHide} />
                            </div>
                            {/* <div className='text'>{JSON.stringify(userPlant.data)}</div> */}
                        </div>

                        <SubmitReplacement userid={userid} gardenid={gardenid} plantid={plantid} />

                        <div className='item-step'>
                            <button className='btn' onClick={userPlant.setOnhold}>Onhold</button>
                            <button className='btn' onClick={userPlant.setHide}>Hide</button>
                        </div>
                    </div>
                </td>
            </tr>
        </>

    )
}

type ReplacementModel = {
    newplantid?: string,
    newplantpartition?: string,
    message?: string,
}
const SubmitReplacement = ({ userid, gardenid, plantid }) => {
    const userPlant = useUserPlants(userid, gardenid, plantid);

    const [replacement, setReplacement] = useState<ReplacementModel>({});
    const [isValid, setIsValid] = useState(false);
    const [isSending, setIsSending] = useState(false);
    const state = userPlant.plantState;

    useEffect(() => {
        if (replacement.newplantid) { // && replacement.newplantpartition) {
            // validate newplantid
            const isguid = StringHelper.isGuid(replacement.newplantid);
            setIsValid(isguid);
        }
    }, [replacement])


    const onSend = async () => {
        setIsSending(true);

        const postObj: SendReplacePlantNotificationToUserArgs = {
            userid: userid,
            message: replacement.message,
            newplantid: replacement.newplantid,
            oldplantid: userPlant.data?.id,
            oldplantpartition: userPlant.data?.partition
        };

        console.log("postObj: ", postObj);


        const success: boolean = await FetchHandler.postJson("/api/notifications/" + gardenid + "/sendreplaceplantnotificationtouser", postObj);

        if (success) {
            MessageBoxes.info("Skickat erbjudande");
            userPlant.setReplacementSent();
        } else {
            MessageBoxes.warning("Något gick fel");
        }



        setIsSending(false);

    }

    if (state === "replacementsent") {
        return (null)
    }


    return (
        <div className='item-step replace'>
            <div>
                <label>Växt id</label>
                <input disabled={isSending} type="text" onChange={e => { setReplacement({ ...replacement, newplantid: e.target.value }) }} />
            </div>
            <div>
                <label>Meddelande</label>
                <textarea disabled={isSending} onChange={e => { setReplacement({ ...replacement, message: e.target.value }) }} ></textarea>
            </div>
            <div className='right'>
                <button disabled={!isValid || isSending} onClick={onSend}>Skicka</button>
            </div>

        </div>
    )
}


type SendReplacePlantNotificationToUserArgs = {
    userid: string,
    message: string,
    newplantid: string,
    oldplantid: string,
    oldplantpartition: string
}