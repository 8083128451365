import { DateTime } from "luxon";

export const useDateHelper = () => {
    return {
        daysToEpoch: (epochSeconds: number): number => {
            // console.log(epoch);
            if (!epochSeconds) { return 0; }

            const e: number = typeof epochSeconds === 'string' ? parseInt(epochSeconds) : epochSeconds;

            const past = new Date(e * 1000);
            const now = new Date();
            const diff = past.valueOf() - now.valueOf();

            return Math.ceil(diff / (1000 * 60 * 60 * 24));
        },
        epochToDate: (epoch: number): string => {
            if (!epoch) { return ""; }
            const e: number = typeof epoch === 'string' ? parseInt(epoch) : epoch;
            const dt: DateTime = DateTime.fromSeconds(e);
            return dt.toLocal().setLocale('sv').toISODate();
        },
        epochToDateTimeUtc: (epoch: number): string => {
            if (!epoch) { return ""; }
            const e: number = typeof epoch === 'string' ? parseInt(epoch) : epoch;
            const dt: DateTime = DateTime.fromSeconds(e);
            return dt.toFormat("yyyy-LL-dd HH:mm:ss");
        },
        epochToDateTime: (epoch: number): string => {
            if (!epoch) { return ""; }

            const e: number = typeof epoch === 'string' ? parseInt(epoch) : epoch;
            const dt: DateTime = DateTime.fromSeconds(e);

            return dt.setLocale('sv').toLocal().toFormat("yyyy-LL-dd HH:mm:ss");
        },
        timeAgo: (epochSeconds: number) => {
            // console.log(epoch);
            if (!epochSeconds) { return ""; }

            const e: number = typeof epochSeconds === 'string' ? parseInt(epochSeconds) : epochSeconds;

            const past = new Date(e * 1000);
            const now = new Date();
            const diff = now.valueOf() - past.valueOf();

            const seconds = Math.floor(diff / (1000));
            const minutes = Math.floor(diff / (1000 * 60));
            const hours = Math.floor(diff / (1000 * 60 * 60));
            const days = Math.floor(diff / (1000 * 60 * 60 * 24));
            const weeks = Math.floor(diff / (1000 * 60 * 60 * 24 * 7));
            const years = Math.floor(diff / (1000 * 60 * 60 * 24 * 365));

            // console.log("seconds:", seconds);
            // console.log("minutes:", minutes);
            // console.log("hours:", hours);
            // console.log("days:", days);
            // console.log("weeks:", weeks);
            // console.log("years:", years);

            if (years > 0) {
                return years == 1 ? "ett år sedan" : years + " år sedan";
            } else if (weeks > 0) {
                return weeks == 1 ? "en vecka sedan" : weeks + " veckor sedan";
            } else if (days > 0) {
                return days == 11 ? "en dag sedan" : days + " dagar sedan";
            } else if (hours > 0) {
                return hours == 1 ? "en timme sedan" : hours + " timmar sedan";
            } else if (minutes > 0) {
                return minutes == 1 ? "en minut sedan" : minutes + " minuter sedan";
            } else if (seconds > 0) {
                return seconds == 1 ? "en sekund sedan" : seconds + " sekunder sedan";
            } else {
                return "nu"
            }
        },
    }
}
