import React, { useEffect, useState } from "react"
import { Link, useHistory } from 'react-router-dom';
import { MessageBoxes } from "../../Handlers/MessageBoxes";
import { useDateHelper } from "../../hooks/useHelper";
import { Label } from "../FormComponents/Primitives";
import { UploadImageElement } from "../FormComponents/UploadImageElement";
import { AdminIcon, AdminIcons } from "../Shared/AdminIcon"
import { Dialog } from "../Shared/Dialog";
import { TabContainer, ThreeColumnContainer } from "../Shared/Formelements";
import { GenericTabs, LoadingBar, ScreenHeader, ScreenMenu } from "../UI/Elements"
import { JwtImage } from "../UI/ImageElements";
import { GenericImageType, MonthlyActivityLineType, MonthlyActivityType, useMonthlyActivitiesAdmin } from "./useMonthlyActivitiesAdmin";

export const MonthlyActivitiesAdmin = () => {


    const ui = useMonthlyActivitiesAdmin();
    const dh = useDateHelper();

    useEffect(() => {
        ui.loadData();
    }, [])


    return (
        <div className="screen-posts-management">
            {/* <Overlay visible={false} /> */}
            <ScreenHeader text="Månadsaktiviteter" icon={AdminIcons.spa} />
            <LoadingBar loading={ui.isSaving || ui.isLoading} />
            <ThreeColumnContainer>
                {/* Lista */}
                <div>
                    <h2>Alla</h2>
                    <table className="tbl tbl-small">
                        <thead>
                            <tr>
                                <th>Månad</th>
                                <th>Namn</th>
                            </tr>
                        </thead>
                        <tbody>
                            {ui.months?.map(month => (
                                <tr className={"clickable-hoover" + (month == ui.selectedItem?.periodNumber ? " selected" : "")} key={month} onClick={() => ui.onItemSelect(month)}>
                                    <td>{month}</td>
                                    <td>{ui.monthlyActivities?.["monthlyactivity:" + month]?.name ?? "-"}</td>
                                </tr>
                            ))}
                        </tbody>
                    </table>
                </div>


                {/* Detalj */}
                <div>

                    <div>
                        {!!ui.selectedItem && (
                            <button
                                className={"btn pull-right"}
                                disabled={!ui.changed}
                                onClick={ui.save} >
                                Spara aktivitet
                            </button>
                        )}
                    </div>
                    <h2>Detalj</h2>
                    <Editor
                        selectedItem={ui.selectedItem}
                        editSelected={ui.editSelected}
                        changed={ui.changed}
                        save={ui.save}
                        loadImages={ui.loadImages}
                        deleteImage={ui.deleteImage}
                    />
                </div>

                {/* Rader */}
                <div className="section-lines">
                    <h2>Rader</h2>

                    {/* Add button */}
                    <div className="lines-tools">
                        {(!!ui.selectedItem) && (
                            <div>
                                <button className={"btn" + (!!ui.lineEditing ? " btn-disabled" : "")}
                                    onClick={ui.addline}>
                                    <AdminIcon icon={AdminIcons.plus} />
                                </button>
                            </div>
                        )}
                    </div>
                    <hr />
                    {/* Lines */}
                    <div className="lines">
                        {!ui.lineEditing && ui.selectedItem?.lines?.map((l, idx) => (
                            <LineElement
                                key={l?.linenumber}
                                item={l}
                                editing={ui.lineEditing?.linenumber == l?.linenumber}
                                onEditing={() => ui.setSelectedLineNumber(l?.linenumber)}
                            />
                        ))}


                    </div>
                    <div>
                        {!!ui.lineEditing && (
                            <LineEditor
                                item={ui.lineEditing}
                                edit={ui.editLine}
                                onClose={(() => { ui.setSelectedLineNumber(undefined) })}
                                onDiscard={ui.removeLine}
                                onSave={ui.saveLine}
                                images={ui.selectedItem?.images}
                            />
                        )}
                    </div>
                </div>
            </ThreeColumnContainer>

        </div>

    )
}

const Editor = ({ selectedItem, editSelected, changed, save, loadImages, deleteImage }:
    {
        selectedItem: MonthlyActivityType, editSelected(e: MonthlyActivityType): void, changed: boolean,
        save(): Promise<boolean>, loadImages(): void, deleteImage(e: string): void
    }) => {

    const [viewState, setViewState] = useState<"default" | "image" | "misc" | "allimages">("default");
    const visible = !!selectedItem;

    const [imageSelectorOpen, setImageSelectorOpen] = useState<boolean>(false);

    return (
        <div>
            <div>
                {visible && (
                    <GenericTabs items={[
                        { id: "default", label: "Detaljer", selected: viewState == "default", onClick: () => setViewState("default") },
                        { id: "image", label: "Försättsbild", selected: viewState == "image", onClick: () => setViewState("image") },
                        { id: "misc", label: "Övrigt", selected: viewState == "misc", onClick: () => setViewState("misc") },
                        { id: "allimages", label: "Alla bilder", selected: viewState == "allimages", onClick: () => setViewState("allimages") },
                    ]} />
                )}
            </div>

            {(visible && viewState == "default") && (
                <div>
                    <div>
                        <label>Namn</label>
                        <input type={"text"} className="frm" value={selectedItem?.name}
                            onChange={e => editSelected({ name: e.target.value })}
                        />
                    </div>

                    <div>
                        <label>Rubrik</label>
                        <input type={"text"} className="frm" value={selectedItem?.header}
                            onChange={e => editSelected({ header: e.target.value })}
                        />
                    </div>
                    <div>
                        <label>Text</label>
                        <textarea className="frm" value={selectedItem?.text}
                            onChange={e => editSelected({ text: e.target.value })}
                        ></textarea>
                    </div>

                </div>
            )}

            {(visible && viewState == "misc") && (
                <div>
                    <div>
                        <label>App länk</label>
                        <input type={"text"} className="frm" value={selectedItem?.internallink}
                            onChange={e => editSelected({ internallink: e.target.value })}
                        />
                    </div>
                    <div>
                        <label>Web länk</label>
                        <input type={"text"} className="frm" value={selectedItem?.externallink}
                            onChange={e => editSelected({ externallink: e.target.value })}
                        />
                    </div>
                </div>
            )}



            {(visible && viewState == "allimages") && (
                <div>
                    <div className="imagelist">
                        {selectedItem?.images?.map(img => (
                            <div key={img?.id}>
                                <JwtImage imageId={img?.id} format={"thumb"} />
                                <div className="btn-mini" onClick={() => { deleteImage(img?.id) }}>
                                    <AdminIcon icon={AdminIcons.trashcan} />
                                </div>
                            </div>
                        ))}
                    </div>
                </div>
            )}


            {(visible && viewState == "image") && (
                <div>
                    <div className="editor-select-image-container">
                        {/* Försättsbild */}
                        <div>
                            <label>Försättsbild</label>
                            <div>
                                <button className="btn" onClick={() => setImageSelectorOpen(true)}>
                                    Välj försättsbild
                                </button>
                                {!!selectedItem?.imageid && (
                                    <div>
                                        <JwtImage
                                            imageId={selectedItem?.imageid}
                                            format={"hd"}
                                            containerClassName="jwtimage-square-200"
                                            openOnClick={true} />
                                    </div>
                                )}
                            </div>
                        </div>

                        {/* Imagelist */}
                        <div className="imagelist">
                            {selectedItem?.images?.map(img => (
                                <div key={img?.id}>
                                    <JwtImage imageId={img?.id} format={"thumb"} openOnClick={true} />
                                </div>
                            ))}
                        </div>
                    </div>

                    {/* Dialog */}
                    <Dialog
                        title="Välj bild"
                        visible={imageSelectorOpen}
                        onOk={() => setImageSelectorOpen(false)}
                        okButtonText="Stäng"
                    >

                        <div className="imagelist">
                            {selectedItem?.images?.map(img => (
                                <div key={img?.id}
                                    className={"selectable" + (selectedItem?.imageid == img?.id ? " selected" : "")}
                                    onClick={() => {
                                        if (selectedItem?.imageid == img?.id) {
                                            editSelected({ imageid: undefined })
                                        } else {
                                            editSelected({ imageid: img?.id })
                                        }
                                    }}
                                >
                                    <JwtImage imageId={img?.id} format={"thumb"} />
                                </div>
                            ))}
                        </div>
                    </Dialog>

                    <hr />
                    {/* Upload */}
                    <div>
                        <UploadImageElement
                            id={selectedItem.id}
                            onFileUploaded={() => {
                                if (loadImages) {
                                    loadImages()
                                }
                            }} />
                    </div>
                </div>
            )}

        </div>
    )
}

const LineElement = ({ item, editing, onEditing, }:
    { item: MonthlyActivityLineType, editing: boolean, onEditing(): void, }) => {

    if (!editing) {
        return (
            <div className="line-card line">

                <div className="linenumber">
                    {!!item?.imageid && (
                        <JwtImage
                            containerClassName="jwtimage-circle-25"
                            imageId={item?.imageid}
                            format={"thumb"}
                        />
                    )}
                </div>
                <div className="header">
                    {item?.header}
                </div>
                <div className="buttons">
                    <button className="btn" onClick={onEditing}>
                        <AdminIcon icon={AdminIcons.edit} />
                    </button>
                </div>
            </div>
        )
    } else {
        return (null)
    }

}

const LineEditor = ({ item, edit, onClose, onDiscard, onSave, images }: { item: MonthlyActivityLineType, edit(e: MonthlyActivityLineType): void, onClose(): void, onDiscard(): void, onSave(): void, images: GenericImageType[] }) => {

    const [imageSelectorOpen, setImageSelectorOpen] = useState<boolean>(false);

    const [selectedImageId, setSelectedImageId] = useState<string>(item?.imageid);
    useEffect(() => {
        setSelectedImageId(item?.imageid);
    }, [, item]);

    const [viewState, setViewState] = useState<"default" | "image" | "misc">("default");

    return (
        <div className="line-card">

            <div>
                <button className="btn" onClick={() => {
                    if (MessageBoxes.confirm("Radera")) {
                        onDiscard();
                    }
                }}>
                    <AdminIcon icon={AdminIcons.trashcan} />
                </button>

                <button className="btn" style={{ float: "right" }} onClick={onSave}>
                    <AdminIcon icon={AdminIcons.save} />
                </button>
            </div>

            <hr />

            <GenericTabs items={[
                { id: "default", label: "Detaljer", selected: viewState == "default", onClick: () => setViewState("default") },
                { id: "image", label: "bild", selected: viewState == "image", onClick: () => setViewState("image") },
                { id: "misc", label: "Övrigt", selected: viewState == "misc", onClick: () => setViewState("misc") },
            ]} />

            <hr />

            {viewState === "default" && (
                <div>
                    <div>
                        <label>Rubrik</label>
                        <input type={"text"} className="frm" defaultValue={item?.header}
                            onChange={e => edit({ linenumber: item.linenumber, header: e.target.value })} />
                    </div>
                    <div>
                        <label>Text</label>
                        <textarea className="frm" defaultValue={item?.text}
                            onChange={e => edit({ linenumber: item.linenumber, text: e.target.value })}
                        ></textarea>
                    </div>
                </div>
            )}

            {viewState === "image" && (
                <div>

                    <div>
                        <label>Bild</label>
                    </div>

                    <div>
                        <div>
                            <button className="btn" onClick={() => setImageSelectorOpen(true)}>
                                Välj bild
                            </button>
                        </div>
                        <div className="imagelist">
                            {!!item?.imageid && (
                                <JwtImage
                                    imageId={item?.imageid}
                                    format={"thumb"} openOnClick={true}
                                />
                            )}
                        </div>
                        <Dialog
                            title="Välj bild"
                            visible={imageSelectorOpen}
                            onOk={() => setImageSelectorOpen(false)}
                            okButtonText="Stäng"

                        >

                            <div className="imagelist">
                                {images?.map(img => (
                                    <div key={img?.id}
                                        className={"selectable" + (selectedImageId == img?.id ? " selected" : "")}
                                        onClick={() => {
                                            if (selectedImageId == img?.id) {
                                                edit({ linenumber: item.linenumber, imageid: undefined })
                                                setSelectedImageId(undefined);
                                            } else {
                                                edit({ linenumber: item.linenumber, imageid: img?.id })
                                                setSelectedImageId(img?.id);
                                            }
                                        }}
                                    >
                                        <JwtImage imageId={img?.id} format={"thumb"} />
                                    </div>
                                ))}
                            </div>
                        </Dialog>

                    </div>
                </div>
            )}

            {(viewState == "misc") && (
                <div>
                    <div>
                        <label>App länk</label>
                        <input type={"text"} className="frm" value={item?.internallink}
                            onChange={e => edit({ linenumber: item.linenumber, internallink: e.target.value })}
                        />
                    </div>
                    <div>
                        <label>Web länk</label>
                        <input type={"text"} className="frm" value={item?.externallink}
                            onChange={e => edit({ linenumber: item.linenumber, externallink: e.target.value })}
                        />
                    </div>
                </div>
            )}

        </div>

    )
}