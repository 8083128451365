import React, { useEffect } from "react"
import { ConfirmDelete, ScreenHeader } from "../UI/Elements"
import { AdminIcon, AdminIcons } from "../Shared/AdminIcon"
import { PlantGroupType, usePlantGroups } from "./usePlantGroups"
import { CustomColumnContainer } from "../Shared/Formelements"
import { PlantImages } from "../Plants/PlantImages"
import { JwtPlantImage } from "../UI/ImageElements"
import { ToolBarClean } from "../ToolBar/ToolBar"
import { Dialog } from "../Shared/Dialog"
import { SelectPlantsDialog } from "../Dialogs/SelectPlantsDialog"


export const PlantGroups = () => {

    const ui = usePlantGroups();
    useEffect(() => { ui.load(); }, []);
    useEffect(() => {
        ui.loadReasonsCountPerKey();
        setInterval(() => {
            ui.loadReasonsCountPerKey();
        }, 15 * 60 * 1000)
    }, []);

    return (
        <div className="screen-plantgroups">
            <ScreenHeader text="Samplantering" icon={AdminIcons.spa} />
            <hr />
            <div>
                <CustomColumnContainer gridTemplateColumns="550px auto" >
                    <div className="table-container">
                        <input type="text" className="full-width frm"
                            value={ui.textFilter ?? ""}
                            onChange={e => ui.setTextFilter(e.target.value)}
                            placeholder={"Filtrera bland " + (ui.data?.length ?? 0) + " växtgrupper."}
                        />
                        <div>
                            {ui.data?.length <= 0 && (
                                <div>
                                    Laddar växtgrupper
                                </div>
                            )}
                            <table className="tbl">
                                <thead>
                                    <tr>
                                        <th></th>
                                        <th>Gruppnamn</th>
                                        <th>Växter</th>
                                        <th>Kopplingar</th>
                                        <th>Profilväxt</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    {ui.data?.map(item => (
                                        <ItemElement
                                            key={item?.id}
                                            item={item}
                                            onPress={() => ui.selectGroup(item?.id)}
                                            reasonsCount={ui.reasonsCountPerKey?.[item?.key]}
                                            selected={ui.selectedGroup == item?.id} />
                                    ))}
                                </tbody>
                            </table>
                        </div>
                    </div>
                    <div>

                        {!!ui.selectedGroupItem?.name && (
                            <div className="edit-container">



                                <SelectPlantsDialog
                                    visible={ui.showSelectPlantDialog}
                                    preSelected={[]}
                                    onPlantsSelected={async (e: string[]) => {
                                        if (e?.length > 0) {
                                            await ui.setGroupProfilePlant(e[0]);
                                        }
                                        ui.setshowSelectPlantDialog(false);
                                    }}
                                    selectSingle={true}
                                />



                                <div>
                                    <div className="example-plant">
                                        <div className="name">
                                            <label>
                                                Gruppnamn
                                            </label>
                                            <div>
                                                {ui.selectedGroupItem?.name}
                                            </div>
                                        </div>
                                        <div className="e-name">
                                            <label>
                                                Profilväxt
                                            </label>
                                            <div>
                                                {ui.selectedGroupItem?.examplePlantName + (!!ui.selectedGroupItem?.examplePlantSpecificType ? ", " + ui.selectedGroupItem?.examplePlantSpecificType : "")}
                                            </div>


                                            <button className="btn" onClick={() => ui.setshowSelectPlantDialog(true)}>
                                                Byt ut växt
                                            </button>
                                        </div>

                                        <div className="image">
                                            {!!ui.selectedGroupItem?.exampleImageId && (
                                                <JwtPlantImage plantImageId={ui.selectedGroupItem?.exampleImageId} />
                                            )}
                                        </div>
                                        <div className="count">
                                            Innehåller {ui.selectedGroupItem?.numberOfPlants ?? "-"} växter
                                        </div>
                                        <div className="delete">
                                            <ConfirmDelete text={"Radera '" + ui.selectedGroupItem?.name + "'"} onDelete={ui.deleteSelectedPlantGroup} />
                                        </div>
                                    </div>
                                </div>
                                <hr />
                                <h4 className="center">
                                    {ui.selectedGroupItem?.name} passar med...
                                </h4>
                                <div className={ui.loadingReasons ? "element-disabled" : ""}>
                                    <table className="tbl">
                                        <thead>
                                            <tr>
                                                <th>
                                                    Växtgrupp
                                                </th>
                                                <th>
                                                    Sortering
                                                </th>
                                                <th>
                                                    Anledning
                                                </th>
                                                <th>
                                                </th>
                                            </tr>
                                        </thead>
                                        <tbody>

                                            {ui.reasons?.map(r => (
                                                <tr key={r?.key}>
                                                    <td className="r-name">
                                                        {r.name}
                                                    </td>
                                                    <td className="r-sort">
                                                        <input type="number" value={r?.sorting ?? ""} onChange={e => ui.updateReasonSort(r, parseInt(e.target.value))} className="frm sorter" />
                                                    </td>
                                                    <td className="r-text">
                                                        <textarea className="frm" onChange={e => ui.updateReasonText(r, e.target.value)} value={r.reasonText} ></textarea>
                                                        <div className="num-chars">Tecken: {r?.reasonText?.length ?? 0}</div>
                                                    </td>
                                                    <td className="r-tool">
                                                        <ToolBarClean tools={[
                                                            { id: "save", label: "Spara", onClick: () => ui.saveReason(r), disabled: r.saved },
                                                            {
                                                                id: "copy", label: "Kopiera", onClick: () => {
                                                                    ui.setCopyDialogOpenSettings(r)
                                                                }, disabled: !r.saved
                                                            },
                                                            { id: "remove", label: "Ta bort", onClick: () => ui.deleteReason(r) },
                                                        ]} />
                                                    </td>
                                                </tr>
                                            ))}

                                        </tbody>
                                    </table>

                                    <Dialog visible={!!ui.copyDialogOpenSettings}
                                        title={"Kopiera anledning till grupp"}
                                        onOk={async () => {
                                            await ui.saveReasonCopy(ui.copyDialogOpenSettings, ui.copyDialogOpenSettings?.reasonText);
                                            ui.setCopyDialogOpenSettings(null);
                                        }}
                                        onCancel={() => { ui.setCopyDialogOpenSettings(null); }}
                                        okButtonText="Lägg till"
                                        cancelButtonText="Ångra"
                                    >
                                        <div>
                                            <textarea className="frm" defaultValue={ui.copyDialogOpenSettings?.reasonText} onChange={e =>
                                                ui.setCopyDialogOpenSettings({ ...ui.copyDialogOpenSettings, reasonText: e.target.value })}
                                            ></textarea>
                                        </div>
                                    </Dialog>

                                    {ui.reasons?.length <= 0 && (
                                        <div className="no-plants-info">
                                            {ui.loadingReasons ? "Laddar..." : "Inga växter är ännu tillagda som passande"}
                                        </div>
                                    )}
                                </div>
                                <hr />
                                <div>
                                    <input
                                        type="text"
                                        value={ui.tagFilter ?? ""}
                                        onChange={e => ui.setTagFilter(e.target.value)}
                                        className="frm"
                                        placeholder={"Filtrera ut växtgrupper som '" + ui.selectedGroupItem?.name + "' passar med"}
                                    />
                                </div>
                                <div className="tag-container">
                                    {ui.tags?.map(item => (
                                        <span key={item?.id} className="tag" onClick={() => ui.addReason(item, "")}>
                                            {item?.name}
                                        </span>
                                    ))}
                                </div>

                            </div>)}



                    </div>
                </CustomColumnContainer>
            </div>
        </div>
    )
}


const ItemElement = ({ item, selected, reasonsCount, onPress }: { item: PlantGroupType, selected: boolean, reasonsCount: number, onPress(): void }) => {
    return (
        <tr key={item?.id} onClick={onPress} className={"item" + (selected ? " selected" : "")}>
            <td>
                {!!item?.exampleImageId && (<AdminIcon icon={AdminIcons.image} />)}
            </td>
            <td title={item?.key}>{item?.name}</td>
            <td>{item?.numberOfPlants}</td>
            <td>{reasonsCount ?? "-"}</td>
            <td>
                {item?.examplePlantName}
                {!!item?.examplePlantSpecificType ? ", " + item?.examplePlantSpecificType : ""}
            </td>
        </tr>
    )
}