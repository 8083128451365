import React, { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import { JwtPlantImage, JwtPlantImagePreview } from '../UI/ImageElements';
import { useUsers } from '../../hooks/useUsers';
import { useFiles } from '../../hooks/useFiles';
import { usePlant } from '../../hooks/usePlant';
import { UserPlantImageActionType, useUserPlantImages } from './UserPlantImages.useUserPlantImages';
import { DateFormatter } from '../../Handlers/Helpers';
import { AdminIcon, AdminIcons } from '../Shared/AdminIcon';
import { ScreenHeader } from '../UI/Elements';
import { useReduxState } from '../../hooks/reduxHook';


export const UserPlantImages = () => {
    const ui = useUserPlantImages(true);

    const plant = usePlant();

    const state = useReduxState<string[]>("approvedimageplants", []);

    return (
        <div className='view-userplantimages'>
            <ScreenHeader text="Bilder tillagda av användare" icon={AdminIcons.images} />
            <p>
                Senaste
                <select defaultValue={ui.pageSize} onChange={e => ui.setPageSize(parseInt(e.target.value))} className="inline-select">
                    <option value={5}>5</option>
                    <option value={10}>10</option>
                    <option value={20}>20</option>
                    <option value={50}>50</option>
                </select> bilderna (Visar {ui.plantIds?.length} växter)
                {/* <button className='btn'>
                    <AdminIcon icon={AdminIcons.refresh} />
                    Ladda om
                </button> */}
            </p>
            {/* <div className='plant-images-link-container'>
                {ui.viewList?.map(p => (
                    <span key={p} className="plant-images-link">
                        <Link to={"/plants/plantimages/" + p}>
                            Bilder för: {plant.item(p)?.fields?.name} {plant.item(p)?.fields?.specifictype}
                        </Link>
                    </span>
                ))}


                {state.value?.map(p => (
                    <span key={p} className="plant-images-link">
                        <Link to={"/plants/plantimages/" + p}>
                            Bilder för: {plant.item(p)?.fields?.name} {plant.item(p)?.fields?.specifictype}
                        </Link>
                    </span>
                ))}
            </div> */}

            <div>
                {ui.plantIds?.map(plantId => (
                    <PlantImages key={plantId} plantId={plantId} />
                ))}
            </div>

            {ui.loading && (
                <div>
                    Laddar...
                </div>
            )}

        </div>
    )
}


const PlantImages = ({ plantId }: { plantId: string }) => {
    const ui = useUserPlantImages(false);
    const images = ui.plantImages(plantId);
    const plants = usePlant();
    const plant = plants.item(plantId);
    const users = useUsers();

    const [imageOpen, setImageOpen] = useState<string>(null);

    const state = useReduxState<string[]>("approvedimageplants", []);

    const onApprove = async (imageId: string) => {
        state.set([...state.value, plantId]);
        await ui.approveImage(imageId)
    }
    const onReject = async (imageId: string) => {
        await ui.rejectImage(imageId)
    }
    const onAction = async (imageId: string, actionType: UserPlantImageActionType) => {
        await ui.onAction(imageId, plantId, actionType)
    }

    return (
        <div className='plant-card'>
            <div className='info'>
                <div className='name'>
                    {plant?.fields?.name} {!plant?.fields?.imageid ? <AdminIcon icon={AdminIcons.images} /> : ""}
                </div>
                <div className='specifictype'>
                    {plant?.fields?.specifictype}
                </div>
                <div className='namelatin'>
                    {plant?.fields?.namelatin}
                </div>
                <div>
                    <Link to={"/plants/edit/" + plant?.id}>
                        Växtdetaljer
                    </Link>
                </div>
                <div>
                    <Link to={"/plants/plantimages/" + plant?.id}>
                        Växtbilder
                    </Link>
                </div>
                <div className='userinfo'>
                    <div className='username'>
                        {users.getUsersName(images?.[0]?.userid)}
                    </div>
                    <div className='useremail'>
                        {users.getUsersEmail(images?.[0]?.userid)}
                    </div>
                </div>
            </div>

            <div className='images'>
                {images?.map(image => (
                    <div key={image.id} className='image'>
                        <div onClick={() => setImageOpen(image.id)} className="image-item">
                            <JwtPlantImage plantImageId={image.id} sizeType="generic" />
                        </div>
                        <div>
                            <div className='ts'>
                                {DateFormatter.epochToDateAndTime(image?.sys_datecreated_utc_epoch)}
                            </div>
                            <SelectAction onSelect={e => onAction(image?.id, e)} />

                            {/* <button className='approve' onClick={() => onApprove(image?.id)}>OK</button>
                            <button className='deny' onClick={() => onReject(image?.id)}>NO</button> */}
                        </div>
                        <JwtPlantImagePreview
                            plantImageId={image.id}
                            visible={imageOpen === image.id}
                            onClick={() => setImageOpen(imageOpen ? null : image.id)} >
                        </JwtPlantImagePreview>
                    </div>
                ))}
            </div>

            <div>

            </div>
        </div>
    )
}

const SelectAction = ({ onSelect }: { onSelect(e: UserPlantImageActionType): void }) => {

    const [disabled, setDisabled] = useState<boolean>(true);
    const [value, setValue] = useState<UserPlantImageActionType>("none")
    const onChange = (e: UserPlantImageActionType) => {
        setValue(e);
    }

    useEffect(() => {
        setDisabled(value == "none");
    }, [value]);

    return (

        <div className='actions'>
            <select defaultValue={"none"} className='select-action' onChange={e => onChange(e.target.value as UserPlantImageActionType)}>
                <option value={"none"}>-</option>
                <option value={"goodimageselected"}>Godkänn, sätt primär</option>
                <option value={"goodimage"}>Godkänn</option>
                <option value={"wrongimage"}>Felaktig bild</option>
                <option value={"copyrightissuesimage"}>Copyright problem?</option>
                <option value={"imagebreaksrules"}>Bryter mot policy</option>
            </select>
            <button disabled={disabled} className='btn-select-action' onClick={() => onSelect(value)}>Välj</button>

        </div>
    )
}

