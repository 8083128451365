import React, { Component, useState, useReducer, useEffect } from 'react';
import Select from 'react-select';
import { Link } from 'react-router-dom';
import { useSelector } from 'react-redux';
import { FetchHandler } from "../../Handlers/FetchHandler";
import { Routes } from "../../Handlers/ViewHandler";
import { CancelIcon, EditIcon, LoadingIcon, RemoveIcon, SearchIcon } from '../Shared/Icons';
import { TagSelector } from '../Shared/TagSelector';
import { EditPlantFieldDialog } from './EditPlantFieldDialog';
import store from "../../rdx/store";
import { Dialog } from '../Shared/Dialog';
import { FieldInput } from './FieldInputs/FieldInputs';
import { MessageBoxes } from '../../Handlers/MessageBoxes';
import { FourColumnContainer } from '../Shared/Formelements';
import { RemovePlantFieldDialog } from "./RemovePlantFieldDialog";
import queryString from "query-string";

interface IProps {
  location: any,
  history: any,
}
interface IState {
  plants?: any[],
  selectedplants?: any[],
  columnnames?: any[],
  loadingsearch?: boolean,
  searchOptions?: any,
  tags?: any[],

  fields?: any,
  fieldnames?: any,
  planttypes?: any,
  planttypesobject?: any,
  planttagsobject?: any,

  // EditPlantFieldDialog,
  editPlantFieldDialogVisible?: boolean,
  removePlantFieldDialogVisible?: boolean,
  sorted?: string

}

export class Plants extends Component<IProps, IState> {
  static displayName = Plants.name;

  mounted: boolean;

  constructor(props) {
    super(props);

    this.mounted = false;

    // PlantTypes

    const data = store.getState().data;
    let planttypes: any[] = data.planttypes.map(o => { return { ...o, value: o.id, label: o.name, fields: o.fields } });
    planttypes = [{ value: "-", label: "Inget filter" }].concat(planttypes);

    const planttypesobject = data.planttypesobject;
    const planttagsobject = data.planttagsobject;
    const fields = data.plantfieldsobject;
    const fieldnames = data.plantfields.reduce((acc, curr) => (acc[curr.id.toLowerCase()] = curr.name, acc), {});


    // console.log("store.getState().data: ", store.getState().data);

    this.state = {
      plants: [],
      selectedplants: [],
      columnnames: [],
      loadingsearch: false,
      searchOptions: {},
      tags: [],

      fields: fields,
      fieldnames: fieldnames,
      planttypes: planttypes,
      planttypesobject: planttypesobject,
      planttagsobject: planttagsobject,

      // EditPlantFieldDialog,
      editPlantFieldDialogVisible: false,
      removePlantFieldDialogVisible: false
    };

    this.extractColumnNames = this.extractColumnNames.bind(this);
    this.extractTags = this.extractTags.bind(this);
    this.rowClicked = this.rowClicked.bind(this);
    this.editFieldButtonClicked = this.editFieldButtonClicked.bind(this);
    this.removeFieldButtonClicked = this.removeFieldButtonClicked.bind(this);
    this.loadSearch = this.loadSearch.bind(this);
    this.sortTable = this.sortTable.bind(this);
  }

  // api/plants/updatesearch
  updateSearchField() {
    FetchHandler.postJson("/api/plants/updatesearch", {}).then(num => {
      MessageBoxes.info(num + " plantor uppdaterades!")
    });
  }

  editFieldButtonClicked() {
    const items = this.state.plants.filter(obj => obj.selected);
    this.setState({ editPlantFieldDialogVisible: true, selectedplants: items });
  }
  removeFieldButtonClicked() {
    const items = this.state.plants.filter(obj => obj.selected);
    this.setState({ removePlantFieldDialogVisible: true, selectedplants: items });
  }

  rowClicked(e) {
    let a = this.state.plants;
    for (var i = 0; i < a.length; i++) {
      if (a[i].id === e) {
        a[i].selected = !a[i].selected;
        continue;
      }
    }
    this.setState({ plants: a });
  }
  extractTags(data) {
    let tags = [];
    for (let i = 0; i < data.length; i++) {
      const js = data[i].fields.tags;
      if (js) {
        const a = JSON.parse(js);
        for (let j = 0; j < a.length; j++) {
          tags.push(a[j]);
        }
      }
    }
    return tags;
  }
  extractColumnNames(data) {
    var obj = {};
    for (var i = 0; i < data.length; i++) {
      Object.getOwnPropertyNames(data[i].fields).forEach(c => obj[c] = true);
    }
    let cols = Object
      .getOwnPropertyNames(obj)
      .map(c => { return { key: c, name: c } })
      .filter(obj => { return ["name", "description", "specifictype", "namelatin", "searchtext"].indexOf(obj.key) < 0; })
      .filter(obj => { return "planttype" !== obj.key; });

    return cols;
  }

  componentDidMount() {
    this.mounted = true;
    if (this.props.location.search) {
      this.loadSearch(queryString.parse(this.props.location.search));
    } else {
      this.loadSearch();
    }
  }



  loadSearch(options?: any) {
    console.log("options: ", options)
    if (options) {
      this.setState({ searchOptions: options })
    } else {
      console.log("this.state.searchOptions:", this.state.searchOptions);
      options = this.state.searchOptions;
    }

    var url = "/api/plants?";
    var qstring = "?";
    var hasQuery = false;
    if (options.search) { qstring += "&search=" + options.search; hasQuery = true; }
    if (options.type) { qstring += "&type=" + options.type; hasQuery = true; }
    if (options.tags && options.tags.length > 0) { qstring += "&tags=" + encodeURIComponent(JSON.stringify(options.tags)); hasQuery = true; }


    if (hasQuery) {
      this.setState({ loadingsearch: true });
      this.props.history.push({ pathname: Routes.plants, search: qstring });
      FetchHandler.getJson(url + qstring).then(data => {
        if (this.mounted) {
          data.forEach(p => { p.selected = false; });
          const tags = this.extractTags(data);

          this.setState({
            loadingsearch: false,
            plants: data,
            columnnames: this.extractColumnNames(data),
            tags: tags
          });
        }
      });

    }
  }

  sortTable(field) {
    const desc = this.state.sorted === field;
    let a = this.state.plants;
    a.sort((a, b) => {
      if (a.fields[field] > b.fields[field]) { return desc ? -1 : 1; }
      if (a.fields[field] < b.fields[field]) { return desc ? 1 : -1; }
      else { return 0; }
    });
    this.setState({ plants: a, sorted: desc ? "" : field });

  }
  componentWillUnmount() {
    this.mounted = false;
  }


  render() {
    const editplantsdisabled = this.state.plants.filter(obj => obj.selected).length === 0;


    return (
      <div>
        <h1>Växter</h1>
        <hr />

        <SearchSection onSearch={this.loadSearch} tags={this.state.tags} />
        <hr />

        <div>
          Valda: {this.state.plants.filter(obj => obj.selected).length}
          <button className="btn" disabled={editplantsdisabled} onClick={this.editFieldButtonClicked}>
            <EditIcon /> Ändra Fält
          </button>

          <button className="btn" disabled={editplantsdisabled} onClick={this.removeFieldButtonClicked}>
            <EditIcon /> Radera fält
          </button>

          <button className="btn pull-right" onClick={this.updateSearchField}>
            <EditIcon /> Uppdatera sök
          </button>

          {this.state.editPlantFieldDialogVisible && (
            <EditPlantFieldDialog
              cancelHandler={() => { this.setState({ editPlantFieldDialogVisible: false }) }}
              okHandler={() => { this.setState({ editPlantFieldDialogVisible: false }); this.loadSearch(); }}
            //plants={this.state.selectedplants}
            />
          )}

          {this.state.removePlantFieldDialogVisible && (
            <RemovePlantFieldDialog
              cancelHandler={() => { this.setState({ removePlantFieldDialogVisible: false }) }}
              okHandler={() => { this.setState({ removePlantFieldDialogVisible: false }); this.loadSearch(); }}
            //plants={this.state.selectedplants}
            />
          )}
        </div>
        <hr />
        <div style={{ overflow: "auto" }}>

          <PlantsTable loading={this.state.loadingsearch}
            columnnames={this.state.columnnames}
            fieldnames={this.state.fieldnames}
            plants={this.state.plants}
            planttypesobject={this.state.planttypesobject}
            planttagsobject={this.state.planttagsobject}
            rowClicked={this.rowClicked}
            fields={this.state.fields}
          />

        </div>
      </div>
    );
  }
}


const PlantsTable = (props) => {

  const [plants, setPlants] = useState(props.plants);
  useEffect(() => { setPlants(props.plants); }, [props.plants]);

  const [sorted, setSorted] = useState("");

  const sortTable = (field) => {
    const desc = sorted === field;
    let a = plants;

    a.sort((a, b) => {
      if (a.fields[field] > b.fields[field]) { return desc ? -1 : 1; }
      if (a.fields[field] < b.fields[field]) { return desc ? 1 : -1; }
      else { return 0; }
    });

    setSorted(desc ? "" : field);
    setPlants(a);
  };

  if (props.loading) {
    return (
      <div className="center" style={{ fontSize: "1.5rem" }}>
        <LoadingIcon />
      </div>
    )
  }

  return (
    <table className="tbl plant-search-tbl">
      <thead>
        <tr>
          <th></th>
          <SortingHeaderCell title="Namn" field="name" onClick={sortTable} />
          <SortingHeaderCell title="Latinskt namn" field="namelatin" onClick={sortTable} />
          <SortingHeaderCell title="Sort" field="specifictype" onClick={sortTable} />
          <th>Typ</th>

          {props.columnnames.map(c => (
            <SortingHeaderCell key={c.key} title={props.fieldnames[c.key]} field={c.key} onClick={sortTable} />
          ))}
        </tr>
      </thead>

      <tbody>
        {plants.map(plant => (
          <PlantRow key={plant.id} item={plant} onClick={props.rowClicked} className="hover-highlight">
            <td>
              <input type="checkbox" checked={plant.selected} onChange={() => { }} />
            </td>
            <td>
              <Link to={Routes.getplantroute(plant.id)}>
                {plant.fields.name}
              </Link>
            </td>
            {/* <BasicCell value={plant.fields.name} /> */}
            <BasicCell value={plant.fields.namelatin} />
            <BasicCell value={plant.fields.specifictype} />
            <BasicCell value={props.planttypesobject[plant.fields.planttype].name} />

            {props.columnnames.map(c => (
              <ValueCell
                key={c.key}
                fieldinfo={props.fields[c.key]}
                value={plant.fields[c.key]}
                planttagsobject={props.planttagsobject}
              >
              </ValueCell>
            ))}

          </PlantRow>
        ))}
      </tbody>

    </table>
  )

}


const SortingHeaderCell = (props) => {
  return (<th onClick={() => { props.onClick(props.field); }}>
    {props.title}
  </th>)
}

const PlantRow = (props) => {
  let className = props.className ? props.className : "";
  return (
    <tr onClick={() => props.onClick(props.item.id)} className={className + (props.item.selected ? " selected" : "")}>
      {props.children}
    </tr>
  )
}

const BasicCell = (props) => {
  if (props.type === "description") {
    return (<td className="td-basic">{props.value.substr(0, 35)}...</td>);
  } else {
    return (<td className="td-basic">{props.value}</td>);
  }
}

const ValueCell = (props) => {
  if (props.value == null) {
    return (<td className="td-value">&nbsp;</td>)
  }

  const info = props.fieldinfo;
  let text = null;

  if (info && (info.type === 4 || info.type === 5 || info.type === 6)) {
    try {
      const selected = props.value ? JSON.parse(props.value) : [];
      text = info.selectOptions.filter(obj => selected.indexOf(obj.key) >= 0).map(obj => obj.name).join(",");
    } catch {
      console.error("props.value: ", { pv: props.value });
      text = "#ERR:" + props.value;
    }
  }

  if (info && (info.type === 7)) {
    text = props.value == null || props.value == undefined ? "" :
      props.value ? "Ja" : "Nej";
  }

  if (info && props.planttagsobject && (info.type === 8)) {
    try {
      const selected = props.value ? JSON.parse(props.value) : [];
      text = selected.map(s => props.planttagsobject[s]).join(",");
    }
    catch {
      console.log("<ValueCell /> Could not parse '" + props.value + "'");
    }
    if (!text) {
      text = "-";
    }
  }


  return (
    <td className="td-value">{text ? text : props.value}</td>
  )
}

const SearchSection = (props) => {
  const [search, setSearch] = useState("");
  const [type, setType] = useState("");
  const [selectedTags, setSelectedTags] = useState([]);
  const types = useSelector(state => state.data.planttypes.map(o => { return { value: o.id, label: o.name } }));
  const planttagsobject = useSelector(state => state.data.planttagsobject);

  const options = [{ value: "-", label: "Inget filter" }].concat(types);

  useEffect(() => {
    searchHandler();
  }, [type]);

  const searchHandler = () => {
    var options = {
      search: search,
      type: type === "-" ? null : type,
      tags: selectedTags
    };

    if (props.onSearch) {
      props.onSearch(options)
    }
  }

  const tagsAdded = (tag) => {
    let a = selectedTags;
    if (a.indexOf(tag.tag) < 0) {
      a.push(tag.tag);
      setSelectedTags(a);
      searchHandler();
    }
  }
  const tagsRemoved = (id) => {
    let a = selectedTags;
    if (a.indexOf(id) >= 0) {
      a.splice(a.indexOf(id), 1);
      setSelectedTags(a);
      searchHandler();
    }

  }

  const typeChanged = (e) => {
    setType(e.value);
    //searchHandler();
  }

  return (
    <div className="filter-container form-large">

      <FilterBox label="Sök">
        <input name="text" type="text"
          value={search}
          onChange={(e) => { setSearch(e.target.value); }}
          onKeyUp={(e) => { if (e.key === 'Enter') { searchHandler(); }; }}
          autoComplete="off"
        />
      </FilterBox>

      <FilterBox label="Växttyp">
        <Select
          defaultValue={{ value: "-", label: "Inget filter" }}
          options={options}
          onChange={typeChanged}
        />
      </FilterBox>

      {/* <FilterBox label="&nbsp;">
          <button className="btn" onClick={searchHandler}>
              <SearchIcon /> Sök
            </button>
        </FilterBox> */}

      <hr className="clearfix" />

      <div>

        <div className="tagfilter-header">Alla taggar</div>
        <TagSelector tags={props.tags} onClick={tagsAdded} />


        <div className="tagfilter-header">Filtrerade taggar</div>
        <div className="taglist">
          {selectedTags.map(t => (
            <FilteredTag key={t} value={t} label={planttagsobject[t]} onClick={tagsRemoved} />
          ))}
        </div>


      </div>

      {/* <OptionalFields onChange={(e) => console.log(e)} /> */}

    </div>
  )
}

const FilteredTag = (props) => {
  const onClick = () => {
    if (props.onClick) {
      props.onClick(props.value);
    }
  }
  return (<div className="tag-filter">
    <span>
      {props.label}
    </span>
    <span className="icon" onClick={onClick}>
      <CancelIcon />
    </span>

  </div>)
}

const OptionalFields = (props) => {
  const [ignored, forceUpdate] = useReducer(x => x + 1, 0);

  const [selectedFields, setSelectedFields] = useState([]);
  const [selectValue, setSelectValue] = useState(null);

  const fields = useSelector(state => state.data.plantfields.map(o => { return { value: o.id, label: o.name, tag: o } }));
  const options = [{ value: "-", label: "-" }].concat(fields.filter(obj => { return selectedFields.indexOf(obj.value) < 0; }));

  const selectChanged = (e) => { setSelectValue(e); };

  const addClicked = () => {
    if (selectValue !== null) {
      let a = selectedFields;
      a.push(selectValue.value);
      setSelectedFields(a);
    }
  };

  const removeClicked = (e) => {
    if (e !== null) {
      let a = selectedFields;
      a.splice(a.indexOf(e), 1);
      setSelectedFields(a);
      forceUpdate();
    }
  }

  const removeAllClicked = (e) => {
    setSelectedFields([]);
    forceUpdate();
  };


  return (

    <>
      <hr />
      <FourColumnContainer>
        <div title="Lägg till filter">
          <div>Lägg till filter</div>
          <Select options={options} onChange={selectChanged} />
        </div>
        <div>
          <button className="btn" onClick={addClicked}>
            Add...
          </button>
        </div>
        <div>
          <button className="btn" onClick={removeAllClicked}>
            Clear...
          </button>
        </div>
      </FourColumnContainer>
      <hr />
      <FourColumnContainer>


        {selectedFields.map(f => (
          <FilterBox key={f} label={f}>
            <span className="remove-ico" onClick={() => removeClicked(f)}>
              <CancelIcon />
            </span>
            Buttons
          </FilterBox>
        ))}

      </FourColumnContainer>
    </>
  )
}

const FilterBox = (props) => {
  return (
    <div className="filter-box">
      <label>
        {props.label}
      </label>
      <div>
        {props.children}
      </div>
    </div>
  )
}