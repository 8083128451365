import React, { isValidElement, useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { DateTime } from "luxon";
import { Link } from 'react-router-dom';
import { createSourceMapSource } from 'typescript';
import { MessageBoxes } from '../../Handlers/MessageBoxes';
import { usePlant } from '../../hooks/usePlant';
import { RdxStore, SearchHitType } from '../../rdx/ReduxTypes';
import { TwoColumnContainer } from '../Shared/Formelements';
import { JwtImage, JwtPlantImage, JwtPlantImageWithPreview } from '../UI/ImageElements';
import { DiscoveryUIType, ObjectWithId, SystemSettingsRevision, useDiscover } from './useDiscover';
import { DragDropContainer, DropTarget } from 'react-drag-drop-container';
import { usePlantLists } from '../../hooks/usePlantLists';
import { PlantListType } from '../../rdx/Redux.DataReducerType';
import { SystemSettingsKeys } from '../SystemObjects/useSystemSettings';
import { AdminIcon, AdminIcons } from '../Shared/AdminIcon';
import { ScreenHeader } from '../UI/Elements';
import { UserPlantImageCard } from '../PlantImageOperations/UserPlantImageOperations';
import { PlantWithImage } from '../PlantImageOperations/PlantImageOperations';
import { PostImage } from '../PlantImageOperations/SocialImageOperations';
import { ApiPostWithImages } from '../PlantImageOperations/useSocialImageOperations';
import { ClipBoardDropTargets } from '../ClipBoard/useClipBoard';
import { UserPostFeaturedCard } from '../PostsManagement/PostsManagement';
import { ForumPostType } from '../Forum/Forum';
import { usePostStore } from '../PostsManagement/usePostStore';
import { FetchHandler } from '../../Handlers/FetchHandler';
import { Dialog } from '../Shared/Dialog';

export type HeaderWithOsNotificationType = "discoveryweeklyplant" | "discoveryweeklypostimage" | "discoveryweeklyplantimage" | "discoveryuserpostfeaturedcard";

export const HeaderWithOsNotification = ({ header, notificationEnabled, objectId, type }: { header: string, notificationEnabled: boolean, objectId: string, type: HeaderWithOsNotificationType }) => {


    type GenericOsNotificationTexts = {
        userid: string,
        alias: string,
        title: string,
        body: string,
        link: string,
    }

    const [notificationInfo, setNotificationInfo] = useState<GenericOsNotificationTexts>(undefined);
    const [dialogVisible, setDialogVisible] = useState<boolean>(false);

    const loadInfo = async () => {
        if (notificationEnabled) {
            const url = "/api/content/discovery/item/" + objectId + "/getnotificationtext/" + type;
            const texts: GenericOsNotificationTexts = await FetchHandler.getJson(url);
            setNotificationInfo(texts);
        }
    }
    const onClick = () => {
        setDialogVisible(true);

    }

    useEffect(() => {
        if (notificationEnabled && !!objectId) {
            loadInfo();
        } else {
            setNotificationInfo(undefined);
        }
    }, [, notificationEnabled, objectId])

    return (
        <div className='header-with-osnotification'>
            <h2 data-itemid={objectId} title={objectId}>{header}</h2>
            <div className={'notification-button' + ((notificationEnabled && !!notificationInfo?.userid) ? "" : " disabled")} onClick={onClick}>
                <AdminIcon icon={AdminIcons.comment} />
            </div>

            {dialogVisible && (

                <SendOsNotificationDialog
                    userid={notificationInfo?.userid}
                    alias={notificationInfo?.alias}
                    title={notificationInfo?.title}
                    body={notificationInfo?.body}
                    link={notificationInfo?.link}
                    onOk={() => setDialogVisible(false)}
                    onCancel={() => setDialogVisible(false)}
                />
            )}
        </div>
    )
}


const SendOsNotificationDialog = (
    { userid, alias, title, body, link, onOk, onCancel }:
        { userid: string, alias: string, title: string, body: string, link: string, onOk(): void, onCancel(): void }) => {

    const [_title, setTitle] = useState(title);
    const [_body, setBody] = useState(body);

    const sendMessage = async () => {
        onOk();

        const postObj = {
            userid: userid,
            title: _title,
            body: _body,
            link: link,
        }
        const success: boolean = await FetchHandler.patchJson("/api/notification/send", postObj)

        if (success && onOk) {
            onOk();
            MessageBoxes.info("Meddelandet är skickat!")
        }
    }


    return (

        <Dialog onCancel={onCancel} onOk={sendMessage} width="800px" height="400px" okButtonText='Skicka' cancelButtonText='Avbryt'>

            <h2>Skicka notifiering till: '{alias}'</h2>
            <label className='frm'>Rubrik</label>
            <input type={"text"} className="frm" placeholder='Rubrik...' value={_title} onChange={e => setTitle(e.target.value)} />
            <label className='frm'>Meddelande</label>
            <textarea className="frm" placeholder='Meddelande...' value={_body} onChange={e => setBody(e.target.value)}></textarea>

        </Dialog>
    )
}
