import { useEffect, useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { FetchHandler } from '../../Handlers/FetchHandler';
import { MessageBoxes } from '../../Handlers/MessageBoxes';
import { RdxStore } from '../../rdx/ReduxTypes';
import { CHANGE_TOOLS_COMBINATIONS_DATA } from '../../rdx/toolsreducer';
import { ForumPostType } from '../Forum/Forum';
import { ApiPostWithImages, PostItem } from '../PlantImageOperations/useSocialImageOperations';

export type CombinationsData = {
    id?: string,
    postImages: ApiPostWithImages[]
}

export const useCombines = () => {

    const combinationsData: CombinationsData = useSelector((state: RdxStore) => state.tools.combinationsData);
    const dispatch = useDispatch();
    const [loading, setLoading] = useState<boolean>(false);
    const [header, setHeader] = useState<string>("");
    const [selectedPostId, setSelectedPostId] = useState<string>("");


    const [data, setData] = useState<ForumPostType[]>([])
    const load = async () => {
        setLoading(true);
        const d = await FetchHandler.getJson("/api/content/combinations");
        setData(d)
        setLoading(false);
    }

    useEffect(() => {
        load();
    }, []);



    return {
        addPostImage: (item: ApiPostWithImages, position?: number) => {

            if (position === null || position === undefined) {
                const c = [...(combinationsData?.postImages ?? []), item];
                dispatch({ type: CHANGE_TOOLS_COMBINATIONS_DATA, payload: { ...combinationsData, postImages: c } });
            } else {
                let c = [...(combinationsData?.postImages ?? [])];
                c[position] = item;
                dispatch({ type: CHANGE_TOOLS_COMBINATIONS_DATA, payload: { ...combinationsData, postImages: c } });

            }

        },
        postImages: combinationsData?.postImages,
        data: data,
        loading: loading,

        header: header,
        setHeader: setHeader,
        setPostCombinationHeader: async (postid: string, newHeader: string) => {
            const postObj = {
                postid: postid,
                header: newHeader
            };

            setLoading(true);

            const success: boolean = await FetchHandler.patchJson("/api/content/combinations/" + postid + "/setheader", postObj);

            if (success) {
                setHeader("");
                setLoading(false);
                MessageBoxes.info("Rubriken uppdaterades!")
            } else {
                setLoading(false);
                MessageBoxes.warning("Rubriken uppdaterades INTE!")
            }

        },
        selectedPostId: selectedPostId,
        setSelectedPostId: setSelectedPostId,



    }
}