
import React, { Component, useEffect, useLayoutEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { FetchHandler } from '../../Handlers/FetchHandler';
import { StringHelper } from '../../Handlers/Helpers';
import { MessageBoxes } from '../../Handlers/MessageBoxes';
import { usePlant } from '../../hooks/usePlant';
import { RdxStore } from '../../rdx/ReduxTypes';

export type SystemSettingsKeys = "PLANT_OF_THE_WEEK" |
    "IMAGE_OF_THE_WEEK" |
    "DISCOVERY_LIST_01" |
    "DISCOVERY_LIST_02" |
    "DISCOVERY_LIST_03" |
    "DISCOVERY_LIST_04" |
    "MINIMUM_PACKAGE_VERSION" |
    "DISCOVERY_USER_POST_FEATURED_CARD_01" |
    "DISCOVERY_USER_POST_FEATURED_CARD_02" |
    "DISCOVERY_USER_POST_FEATURED_CARD_03" |
    "LISTOFUSERS_FOLLOW_SUGGESTIONS" |
    "PLANT_IMAGE_OF_THE_WEEK" |
    "POST_IMAGE_OF_THE_WEEK";


export type SettingsStore = {
    [key: string]: string
}

type packageVersionsType = {
    success: boolean,
    data:
    { [key: string]: number }
}

export const useSystemSettings = () => {
    const [loading, setLoading] = useState<boolean>(false);
    const [settings, setSettings] = useState<SettingsStore>(null);


    const loadData = async () => {
        setLoading(true);
        const data: SettingsStore = await FetchHandler.getJson("/api/system/systemsettings");
        setSettings(data);
        setLoading(false);

        var minimumVersion = data?.["MINIMUM_PACKAGE_VERSION"];
        if (minimumVersion) {
            setMinimumVersionMajor(parseInt(minimumVersion?.split('.')?.[0]));
            setMinimumVersionMinor(parseInt(minimumVersion?.split('.')?.[1]));
            setMinimumVersionBuild(parseInt(minimumVersion?.split('.')?.[2]));
        }
    }

    const [minimumVersionMajor, setMinimumVersionMajor] = useState<number>(-1);
    const [minimumVersionMinor, setMinimumVersionMinor] = useState<number>(-1);
    const [minimumVersionBuild, setMinimumVersionBuild] = useState<number>(-1);

    const [packageVersions, setPackageVersions] = useState<{ packageVersion: string, count: number }[]>(null);

    const getStats = async () => {
        const data: packageVersionsType = await FetchHandler.getJson("/api/stats/packageversions");
        const transformed = Object.keys(data?.data ?? {}).map(key => { return { packageVersion: key, count: data?.data?.[key] } });
        setPackageVersions(transformed);
        console.log("packageversions: ", data);
    }


    useLayoutEffect(() => {
        loadData();
        getStats();
    }, [])


    const minimumVersionString = minimumVersionMajor + "." + minimumVersionMinor + "." + minimumVersionBuild;


    return {
        loading: loading,
        settings: settings,

        // MinimumVersion
        packageVersions: packageVersions,
        savedMinimumVersionString: settings?.["MINIMUM_PACKAGE_VERSION"],
        minimumVersionString: minimumVersionString,
        minimumVersionMajor: minimumVersionMajor,
        minimumVersionMinor: minimumVersionMinor,
        minimumVersionBuild: minimumVersionBuild,
        setMinimumVersionMajor: (v: string) => { setMinimumVersionMajor(parseInt(v)); },
        setMinimumVersionMinor: (v: string) => { setMinimumVersionMinor(parseInt(v)); },
        setMinimumVersionBuild: (v: string) => { setMinimumVersionBuild(parseInt(v)); },
        onSaveMinimumVersion: async () => {
            let postObj = {
                MINIMUM_PACKAGE_VERSION: minimumVersionString
            };
            const success = await FetchHandler.postJson("/api/system/systemsettings", postObj);
            if (!success) {
                MessageBoxes.warning("Kunde inte spara")
            }
            await loadData();
        },
        loadData: loadData,

        onSave: async () => {
            let postObj = {};
            const success = FetchHandler.postJson("/api/system/systemsettings", postObj);
            if (!success) {
                MessageBoxes.warning("Kunde inte spara")
            }
        },
    }
}


