import React, { useEffect, useState } from "react"
import { useSelector, useDispatch } from 'react-redux';
import { FetchHandler } from "../../Handlers/FetchHandler"
import { RdxStore } from "../../rdx/ReduxTypes";
import { CHANGE_TOOLS_CLIPBOARD_DATA, CHANGE_TOOLS_PLANTIMAGES, CHANGE_TOOLS_USERIMAGESOPS, CHANGE_TOOLS_USERPLANTIMAGESUI } from "../../rdx/toolsreducer";
import { StringNumberDictionary, StringStringDictionary } from "../../Types";
import { ClipBoardDataType } from "../ClipBoard/useClipBoard";
import { PlantWithImage } from "./PlantImageOperations";
import { SocialPlantImagesUI } from "./useSocialImageOperations";


export interface UserImage {
    id: string
    label: string
    licensingkey: string
    name: string,
    licensingname: string,
    licensingurl: string,
    licensinguserid: string,
    sys_datecreated_utc_epoch: number
}

type UserPlantImagesUIViewState = "none" | "selected"
export interface UserPlantImagesUI {
    selectedPage?: number,
    pageSize?: number,
    viewState?: UserPlantImagesUIViewState,
    textFilter?: string,
    dataType?: UserPlantImageData
}

export type UserPlantImageData = "new_users_images";
export const useUserPlantImageOperations = () => {

    const userPlantImages: PlantWithImage[] = useSelector((state: RdxStore) => state.tools.userPlantImages);
    const userPlantImagesUISettings: UserPlantImagesUI = useSelector((state: RdxStore) => state.tools.userPlantImagesUISettings);
    const clipBoardData: ClipBoardDataType = useSelector((state: RdxStore) => state.tools.clipBoardData);
    const dispatch = useDispatch();

    const [loading, setLoading] = useState(false);
    const [page, setPage] = useState<number>(userPlantImagesUISettings?.selectedPage ?? 0);
    const [pageSize, setPageSize] = useState(userPlantImagesUISettings?.pageSize ?? 50);
    const [dataType, setDataType] = useState<UserPlantImageData>(userPlantImagesUISettings?.dataType ?? "new_users_images");


    const updateUI = (m: UserPlantImagesUI) => {
        dispatch({ type: CHANGE_TOOLS_USERPLANTIMAGESUI, payload: { ...userPlantImagesUISettings, ...m } })
    }
    const updateClipboard = (m: ClipBoardDataType) => {
        dispatch({ type: CHANGE_TOOLS_CLIPBOARD_DATA, payload: { ...userPlantImagesUISettings, ...m } })
    }


    const load = async () => {
        if (dataType == "new_users_images") {
            setLoading(true);
            let d: PlantWithImage[] = await FetchHandler.getJson("/api/plantops/userplantimages");
            setLoading(false);
            dispatch({ type: CHANGE_TOOLS_USERIMAGESOPS, payload: d })
        }
    }

    useEffect(() => {
        if (!userPlantImages || (userPlantImages?.length ?? 0) == 0) {
            load();
        }
    }, [, dataType])



    const [viewState, setViewState] = useState<UserPlantImagesUIViewState>(userPlantImagesUISettings?.viewState ?? "none");
    const [textFilter, setTextFilter] = useState<string>(userPlantImagesUISettings?.textFilter ?? "");
    const [filterOnPrimaryImages, setFilterOnPrimaryImages] = useState<boolean>(false);

    const [people, setPeople] = useState<StringStringDictionary>({});
    const [peopleCount, setPeopleCount] = useState<StringNumberDictionary>({});
    useEffect(() => {
        if (userPlantImages && userPlantImages?.length > 0) {
            let d: StringStringDictionary = {};
            let n: StringNumberDictionary = {};
            userPlantImages.map(e => e.image as UserImage).forEach(m => {
                d[m.licensinguserid] = m.licensingname;
                n[m.licensinguserid] = (n[m.licensinguserid] ?? 0) + 1;
            }
            );
            setPeople(d);
            setPeopleCount(n);
        }
    }, [userPlantImages]);


    const filterData = () => {
        const filtered = (userPlantImages ?? [])
            .filter(m => (filterOnPrimaryImages && m?.imageid === m?.plant?.imageid) || filterOnPrimaryImages === false)
            .filter(m => {
                const txt = (m.plant?.name?.toLowerCase() ?? "") + (m.plant?.specifictype?.toLowerCase() ?? "") + ((m.image as UserImage)?.licensingname?.toLowerCase() ?? "");
                if (!!txt && !!textFilter) {
                    return txt?.includes(userPlantImagesUISettings?.textFilter?.toLowerCase());
                } else {
                    return true;
                }
            });
        const paged = filtered?.map((m, i) => { return { ...m, n: i } })
            .filter((item, idx) => { return idx >= (page * pageSize) && idx < (page * pageSize + pageSize); });

        return [paged, filtered?.length];
    }

    const [data, totalNumberOfHits] = filterData();
    const numberOfPages = Math.floor((totalNumberOfHits as number) / pageSize) + 1;


    return {
        data: data as PlantWithImage[],
        people: people,
        peopleCount: peopleCount,
        page: page,
        goFirstPage: () => { setPage(0); updateUI({ selectedPage: 0 }) },
        goPrev: () => { setPage(page - 1); updateUI({ selectedPage: page - 1 }) },
        goNext: () => { setPage(page + 1); updateUI({ selectedPage: page + 1 }) },
        disableGoPrev: page == 0,
        disableGoNext: page >= (numberOfPages - 1),
        load: load,
        totalNumberOfHits: totalNumberOfHits,
        numberOfPages: numberOfPages,
        loading: loading,

        toggleSelect: (item: PlantWithImage) => {
            console.log("item:", item)
            const selected = clipBoardData?.plantimages;
            if (selected) {
                if (selected.findIndex(m => m.imageid == item?.imageid) >= 0) {
                    const s = selected.filter(m => m.imageid != item?.imageid);
                    updateClipboard({ plantimages: s });
                    return;
                } else {
                    updateClipboard({ plantimages: [...selected, item] });
                    return;
                }
            } else {
                updateClipboard({ plantimages: [item] });
            }
        },
        selectedImages: clipBoardData?.plantimages ?? [],
        pageSize: pageSize, setPageSize: (n: number) => { setPageSize(n); updateUI({ pageSize: n }); },
        viewState: viewState, setViewState: (e: UserPlantImagesUIViewState) => {
            setViewState(e);
            updateUI({ viewState: e });
        },
        filterOnPrimaryImages: filterOnPrimaryImages,
        setFilterOnPrimaryImages: setFilterOnPrimaryImages,
        textFilter: textFilter, setTextFilter: (e: string) => {
            setTextFilter(e)
            updateUI({ textFilter: e, selectedPage: 0 });
            setPage(0);
        },
        dataType: dataType, setDataType: (e: UserPlantImageData) => {
            setDataType(e)
            updateUI({ dataType: e, selectedPage: 0 });
            setPage(0);
        },
    }
}


