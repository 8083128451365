import React, { useState } from 'react';
import { useSelector } from 'react-redux';
import { LoggedInUserType, RdxStore } from '../rdx/ReduxTypes';


export const useUser = () => {
    const user: LoggedInUserType = useSelector((state: RdxStore) => state.app.loggedInUser);

    return {
        model: user,
    }
}