import { useEffect, useState } from "react";
import { useSelector, useDispatch } from 'react-redux';
import { FetchHandler } from "../../Handlers/FetchHandler";
import { useUsers } from "../../hooks/useUsers";
import { RdxStore } from "../../rdx/ReduxTypes";
import { CHANGE_TOOLS_CLIPBOARD_DATA, CHANGE_TOOLS_SOCIALIMAGESOPS, CHANGE_TOOLS_SOCIALPLANTIMAGESUI } from "../../rdx/toolsreducer";
import { PostState } from "../../Types";
import { ClipBoardDataType } from "../ClipBoard/useClipBoard";

export type SocialImageOperationsViewState = "filtering" | "selected";


export const useSocialImageOperations = () => {

    const clipBoardData: ClipBoardDataType = useSelector((state: RdxStore) => state.tools.clipBoardData);
    const socialPlantImagesUISettings: SocialPlantImagesUI = useSelector((state: RdxStore) => state.tools.socialPlantImagesUISettings);
    const socialPlantImages: ApiPostWithImages[] = useSelector((state: RdxStore) => state.tools.socialPlantImages);

    const dispatch = useDispatch();
    const u = useUsers();

    //const [data, setData] = useState<ApiPostWithImages[]>([]);
    const [userIds, setUserIds] = useState<string[]>([]);
    const [viewState, setViewState] = useState<SocialImageOperationsViewState>(socialPlantImagesUISettings?.viewState ?? "filtering");
    const [loading, setLoading] = useState<boolean>(false);

    const load = async () => {
        setLoading(true);
        const d: ApiPostWithImages[] = await FetchHandler.getJson("/api/forum/images/all");
        setUserIds(Array.from(new Set(d?.map(m => m?.post?.sys_created_by_user_id))));
        setLoading(false);
        dispatch({ type: CHANGE_TOOLS_SOCIALIMAGESOPS, payload: d })
    }

    const [page, setPage] = useState<number>(socialPlantImagesUISettings?.selectedPage ?? 0);
    const [pageSize, setPageSize] = useState(socialPlantImagesUISettings?.pageSize ?? 50);
    const updateUI = (m: SocialPlantImagesUI) => {
        dispatch({ type: CHANGE_TOOLS_SOCIALPLANTIMAGESUI, payload: { ...socialPlantImagesUISettings, ...m } })
    }

    const updateClipboard = (m: ClipBoardDataType) => {
        dispatch({ type: CHANGE_TOOLS_CLIPBOARD_DATA, payload: { ...clipBoardData, ...m } })
    }

    const filterData = () => {
        const filtered = (socialPlantImages ?? []);
        // .filter(m => {
        //     const txt = (m.?.name?.toLowerCase() ?? "") + (m.plant?.specifictype?.toLowerCase() ?? "") + ((m.image as UserImage)?.licensingname?.toLowerCase() ?? "");
        //     if (!!txt && !!textFilter) {
        //         return txt?.includes(userPlantImagesUISettings?.textFilter?.toLowerCase());
        //     } else {
        //         return true;
        //     }
        // });
        const paged = filtered?.map((m, i) => { return { ...m, n: i } })
            .filter((item, idx) => { return idx >= (page * pageSize) && idx < (page * pageSize + pageSize); });

        return [paged, filtered?.length];
    }
    const [data, totalNumberOfHits] = filterData();
    const numberOfPages = Math.floor((totalNumberOfHits as number) / pageSize) + 1;

    useEffect(() => {
        load();
    }, []);

    useEffect(() => {
        if (userIds && userIds?.length > 0) {
            u.ensureUserInfo(userIds);
        }
    }, [userIds])


    return {
        loading: loading,
        data: data as ApiPostWithImages[],
        totalNumberOfHits: totalNumberOfHits,
        numberOfPages: numberOfPages,
        page: page,
        pageSize: pageSize, setPageSize: (n: number) => { setPageSize(n); updateUI({ pageSize: n }); },
        viewState: viewState,
        setViewState: (e: SocialImageOperationsViewState) => { setViewState(e); updateUI({ viewState: e }); },
        load: load,
        goFirstPage: () => { setPage(0); updateUI({ selectedPage: 0 }) },
        goPrev: () => { setPage(page - 1); updateUI({ selectedPage: page - 1 }) },
        goNext: () => { setPage(page + 1); updateUI({ selectedPage: page + 1 }) },
        disableGoPrev: page == 0,
        disableGoNext: page >= (numberOfPages - 1),
        onToggleFeatured: async (postid: string, featured: boolean): Promise<boolean> => {

            const bak = [...socialPlantImages];
            let change = [...socialPlantImages];
            for (let i = 0; i < change?.length; i++) {
                if (change[i].postid == postid) {
                    change[i].post.featured = featured
                }
            }
            dispatch({ type: CHANGE_TOOLS_SOCIALIMAGESOPS, payload: change });
            const success = await FetchHandler.patchJson("/api/content/featuredposts/" + postid + "/toggle", { featured: featured });
            if (!success) {
                dispatch({ type: CHANGE_TOOLS_SOCIALIMAGESOPS, payload: bak });
            }

            return success
        },
        toggleSelect: (item: ApiPostWithImages) => {
            const selected = clipBoardData?.postimage;
            if (selected) {
                if (selected.findIndex(m => m.imageid == item?.imageid) >= 0) {
                    const s = selected.filter(m => m.imageid != item?.imageid);
                    updateClipboard({ ...clipBoardData, postimage: s });
                    return;
                } else {
                    updateClipboard({ ...clipBoardData, postimage: [...clipBoardData?.postimage, item] });
                    return;
                }
            } else {
                updateClipboard({ ...clipBoardData, postimage: [item] });
            }
        },
        selectedImages: clipBoardData?.postimage ?? [],
    }
}

export interface SocialPlantImagesUI {
    selectedPage?: number,
    pageSize?: number,
    viewState?: SocialImageOperationsViewState,
    textFilter?: string,
}


export interface ApiPostWithImages {
    postid: string,
    imageid: string,
    userid: string,
    post: PostItem,
    image: Image,
}

export type PostExtensionType = "none" | "combination";
export interface PostItem {
    id: string
    label: string
    epochposted: number
    poststate: PostState
    postextension: PostExtensionType
    numberoflikes: number
    imageids: string
    sys_datemodified_utc_epoch: number
    partition: string
    name: string
    userid: string
    clientpostid: string
    epochupdated: number
    location: string
    queuekey: string
    queuedepoch: number
    primaryimageindex: number
    state: string
    update: boolean
    zone: string
    numberofcomments: number
    lastcommentepoch: number
    soil: string
    sunexposure: string
    plantids: string[]
    sys_datecreated_utc_epoch: number
    sys_created_by_user_id: string
    imageid?: string
    text?: string
    gardenitemid?: string
    epochshared: any
    epochaccomplished: any
    gardenid: any
    poststatechanged: any
    sys_modified_by_user_id?: string
    featured?: boolean
}

export interface Image {
    id: string
    label: string
    partition: string
    title: string
    imagereference: string
    filename: string
    length: number
    contenttype: string
    dateaddedutc: string
    publicimageid: string
    name: string
    sys_datecreated_utc_epoch: number
    sys_datemodified_utc_epoch: number
    sys_created_by_user_id: string
}
