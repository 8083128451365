import React /*, { Component, useState }*/ from 'react';
// import Select from 'react-select';
import { useSelector } from 'react-redux';
import ts from 'typescript';
// import { FetchHandler } from '../../../Handlers/FetchHandler';
import { Operations } from '../../../rdx/Operations';
import { RdxStore } from '../../../rdx/ReduxTypes';
import { JwtPlantImage } from '../../UI/ImageElements';
// import { TwoColumnContainer } from '../../Shared/Formelements';


export const PlantListDetailsDialog = () => {
    const listsObj = useSelector((state: RdxStore) => state.data.plantlists);
    const lists = Object.keys(listsObj).filter(obj => obj !== "loaded").map(k => listsObj[k]);

    const currentplantlist = useSelector((state: RdxStore) => state.data.currentplantlist);

    const setSelected = (id) => {
        Operations.selectCurrentPlantList(id);
    }

    return (
        <div className="plantlist-editor">
            <div className="form-large">
                <select value={currentplantlist?.selectedId} onChange={(e) => { setSelected(e.target.value); }}>
                    <option value="-">-</option>
                    {lists && lists.map(option => (
                        <option key={option.id} value={option.id}>{option.name} ({option.numberOfPlants})</option>
                    ))}
                </select>
            </div>
            <div className="plantlist-editor-plants">

                <table>
                    <tbody>

                        {currentplantlist?.plants && currentplantlist?.plants.map(item => (
                            <tr key={item.id}>
                                <td>
                                    <JwtPlantImage plantImageId={item?.imageid} small={true} />
                                </td>
                                <td className="name">
                                    {item.name}
                                </td>
                                <td className="specifictype">
                                    {item.specifictype}
                                </td>
                            </tr>
                        ))}

                    </tbody>
                </table>
            </div>
        </div>
    )
}