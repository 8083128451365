import { ClipBoardDataType, ClipBoardStateType } from "../components/ClipBoard/useClipBoard";
import { CombinationsData } from "../components/Combines/useCombines";
import { DiscoveryUI } from "../components/Discover/useDiscover";
import { ForumPostType } from "../components/Forum/Forum";
import { MonthlyActivitiesAdminUIType } from "../components/Monthlyactivities/useMonthlyActivitiesAdmin";
import { PlantWithImage } from "../components/PlantImageOperations/PlantImageOperations";
import { ApiPostWithImages, SocialPlantImagesUI } from "../components/PlantImageOperations/useSocialImageOperations";
import { UserPlantImagesUI } from "../components/PlantImageOperations/useUserPlantImageOperations";

const CHANGE_TOOLS_USERPLANTS = "CHANGE_TOOLS_USERPLANTS";
const CHANGE_TOOLS_USERIMAGES = "CHANGE_TOOLS_USERIMAGES";
const CHANGE_TOOLS_HANDLED_USERIMAGES = "CHANGE_TOOLS_HANDLED_USERIMAGES";
const CHANGE_TOOLS_CUSTOMREDUXSTATE = "CHANGE_TOOLS_CUSTOMREDUXSTATE";
const CHANGE_TOOLS_PLANTIMAGES = "CHANGE_TOOLS_PLANTIMAGES";
const CHANGE_TOOLS_USERIMAGESOPS = "CHANGE_TOOLS_USERIMAGESOPS";
const CHANGE_TOOLS_USERPLANTIMAGESUI = "CHANGE_TOOLS_USERPLANTIMAGESUI";
const CHANGE_TOOLS_SOCIALIMAGESOPS = "CHANGE_TOOLS_SOCIALIMAGESOPS";
const CHANGE_TOOLS_SOCIALPLANTIMAGESUI = "CHANGE_TOOLS_SOCIALPLANTIMAGESUI";
const CHANGE_TOOLS_DISCOVERYUI = "CHANGE_TOOLS_DISCOVERYUI";

const CHANGE_TOOLS_CLIPBOARD_DATA = "CHANGE_TOOLS_CLIPBOARD_DATA";
const CHANGE_TOOLS_CLIPBOARD_SHOW = "CHANGE_TOOLS_CLIPBOARD_SHOW";
const CHANGE_TOOLS_CLIPBOARD_STATE = "CHANGE_TOOLS_CLIPBOARD_STATE";

const CHANGE_TOOLS_COMBINATIONS_DATA = "CHANGE_TOOLS_COMBINATIONS_DATA";

const CHANGE_TOOLS_POSTS_STORE = "CHANGE_TOOLS_POSTS_STORE";
const CHANGE_MONTHLY_ACTIVITIES_ADMIN_UI = "CHANGE_MONTHLY_ACTIVITIES_ADMIN_UI";


export type ToolsReducerType = {
    userplants: { [key: string]: UserPlantType },
    userImages: { [key: string]: UserImageType },
    handledUserImagesPlants: { [key: string]: string },
    customReduxState: { [key: string]: any },
    plantImages: PlantWithImage[],
    userPlantImages: PlantWithImage[],
    userPlantImagesUISettings: UserPlantImagesUI,
    socialPlantImages: ApiPostWithImages[],
    socialPlantImagesUISettings: SocialPlantImagesUI,
    discoveryUI: DiscoveryUI,
    clipBoardShow: boolean,
    clipBoardState: ClipBoardStateType,
    clipBoardData: ClipBoardDataType,
    combinationsData: CombinationsData,
    postStore: ForumPostStore,
    monthlyActivitiesAdminUI: MonthlyActivitiesAdminUIType
}


const ToolsReducerStore: ToolsReducerType = {
    userplants: null,
    userImages: {},
    handledUserImagesPlants: {},
    customReduxState: {},
    plantImages: [],
    userPlantImages: [],
    userPlantImagesUISettings: null,
    socialPlantImages: [],
    socialPlantImagesUISettings: null,
    discoveryUI: null,
    clipBoardShow: false,
    clipBoardState: "dropping",
    clipBoardData: null,
    combinationsData: null,
    postStore: {},
    monthlyActivitiesAdminUI: {}
}


export type UserPlantStates = "none" | "onhold" | "hide" | "replacementsent";
export type ForumPostStore = {
    [x: string]: ForumPostType;
}

export type UserPlantType = {
    id: string,
    partition: string,
    specifictype: string,
    name: string,
    planttype: string,
    gardenid: string,
    userid: string,
    sys_datecreated_utc_epoch: number,
    state: UserPlantStates
}

export type UserImageType = {
    id: string,
    partition: string,
    length: 713524,
    contenttype: string,
    sharetype: "public" | "private",
    userid: string,
    plantid: string,
    approved: false,
    sys_datecreated_utc_epoch: number
}


const toolsReducer = (state = { ...ToolsReducerStore }, action) => {
    switch (action.type) {
        case CHANGE_TOOLS_USERPLANTS: { state = { ...state, userplants: action.payload }; break; }
        case CHANGE_TOOLS_USERIMAGES: { state = { ...state, userImages: action.payload }; break; }
        case CHANGE_TOOLS_HANDLED_USERIMAGES: { state = { ...state, handledUserImagesPlants: action.payload }; break; }
        case CHANGE_TOOLS_CUSTOMREDUXSTATE: { state = { ...state, customReduxState: action.payload }; break; }
        case CHANGE_TOOLS_PLANTIMAGES: { state = { ...state, plantImages: action.payload }; break; }
        case CHANGE_TOOLS_USERIMAGESOPS: { state = { ...state, userPlantImages: action.payload }; break; }
        case CHANGE_TOOLS_USERPLANTIMAGESUI: { state = { ...state, userPlantImagesUISettings: action.payload }; break; }
        case CHANGE_TOOLS_SOCIALIMAGESOPS: { state = { ...state, socialPlantImages: action.payload }; break; }
        case CHANGE_TOOLS_SOCIALPLANTIMAGESUI: { state = { ...state, socialPlantImagesUISettings: action.payload }; break; }
        case CHANGE_TOOLS_DISCOVERYUI: { state = { ...state, discoveryUI: action.payload }; break; }
        case CHANGE_TOOLS_CLIPBOARD_SHOW: { state = { ...state, clipBoardShow: action.payload }; break; }
        case CHANGE_TOOLS_CLIPBOARD_STATE: { state = { ...state, clipBoardState: action.payload }; break; }
        case CHANGE_TOOLS_CLIPBOARD_DATA: { state = { ...state, clipBoardData: action.payload }; break; }
        case CHANGE_TOOLS_COMBINATIONS_DATA: { state = { ...state, combinationsData: action.payload }; break; }
        case CHANGE_TOOLS_POSTS_STORE: { state = { ...state, postStore: { ...(state.postStore), ...(action.payload) } }; break; }
        case CHANGE_MONTHLY_ACTIVITIES_ADMIN_UI: { state = { ...state, monthlyActivitiesAdminUI: action.payload }; break; }

        default: { return state; }
    }
    return state;
};
export {
    toolsReducer,
    CHANGE_TOOLS_USERPLANTS, CHANGE_TOOLS_USERIMAGES, CHANGE_TOOLS_HANDLED_USERIMAGES,
    CHANGE_TOOLS_CUSTOMREDUXSTATE, CHANGE_TOOLS_PLANTIMAGES, CHANGE_TOOLS_USERIMAGESOPS,
    CHANGE_TOOLS_USERPLANTIMAGESUI, CHANGE_TOOLS_SOCIALIMAGESOPS, CHANGE_TOOLS_SOCIALPLANTIMAGESUI,
    CHANGE_TOOLS_CLIPBOARD_DATA, CHANGE_TOOLS_COMBINATIONS_DATA,
    CHANGE_TOOLS_DISCOVERYUI, CHANGE_TOOLS_CLIPBOARD_SHOW, CHANGE_TOOLS_CLIPBOARD_STATE,
    CHANGE_TOOLS_POSTS_STORE, CHANGE_MONTHLY_ACTIVITIES_ADMIN_UI
}