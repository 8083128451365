import React, { useEffect, useRef, useState } from "react"
import { useSelector, useDispatch } from 'react-redux';
import { FetchHandler } from "../../../Handlers/FetchHandler";
import { PoiType, PoiTypeType, usePointOfInterests } from "./usePointOfInterests";
import { LatLng, MapBounds, PoiGarden } from "./MapTypes";
import { RdxStore, UILargeMapViewState, uiLargeMapType } from "../../../rdx/ReduxTypes";
import { CHANGE_UILARGEMAP } from "../../../rdx/appReducer";

export type PoiDataItem = LatLng & {
    id: string,
    poi?: PoiType,
    garden?: PoiGarden
}
export const useLargeMap = () => {

    const dispatch = useDispatch();
    const uiSettings: uiLargeMapType = useSelector((state: RdxStore) => state.app.uiLargeMap);

    const [allItems, setAllItems] = useState<PoiDataItem[]>([]);
    const [selected, setSelected] = useState<PoiDataItem>(undefined);
    const viewState = uiSettings?.mapViewstate;

    const api = useLargeMapApi();

    const [isLoading, setIsLoading] = useState<boolean>(false)
    const loadPois = async () => {
        setIsLoading(true);
        const data: PoiType[] = await api.getPois();
        let gardens: PoiGarden[] = await api.getGardens();

        gardens.sort((a, b) => {
            if (a?.locationset_epoch > b?.locationset_epoch) { return 1; }
            else if (a?.locationset_epoch < b?.locationset_epoch) { return -1; }
            else { return 0; }
        })

        setAllItems([
            ...(data.map(m => { return { id: m?.id, poi: m, latitude: m?.latitude, longitude: m?.longitude } })),
            ...(gardens.map(m => { return { id: m?.gardenId, garden: m, latitude: m?.latitude, longitude: m?.longitude } }))
        ]);
        setIsLoading(false);
    }

    const [isEditing, setIsEditing] = useState<boolean>(false);

    return {
        /// UI
        isLoading: isLoading,
        viewState: viewState,
        setViewState: (e: UILargeMapViewState) => {
            dispatch({ type: CHANGE_UILARGEMAP, payload: { ...uiSettings, mapViewstate: e } })
        },
        isEditing: isEditing, setIsEditing: setIsEditing,

        // Data
        allPoui: allItems?.filter(m => viewState === "none" || (viewState === "gardens" && !!m.garden) || (viewState === "pois" && !!m.poi)),
        loadPois: loadPois,

        // 
        selectedPoi: selected,
        selectPoi: (id: string) => {
            if (selected?.id == id) {
                setSelected(undefined);
            } else {
                setSelected(allItems?.find(m => m?.id == id));
            }
        },
        setPoiPosition: async (poiId: string, coord: LatLng): Promise<boolean> => {
            var success = await api.setPoiPosition(poiId, coord);
            let all = [...allItems];
            let p = all.find(m => m?.id == poiId);
            p.latitude = coord.latitude;
            p.longitude = coord.longitude;
            setAllItems([...allItems.filter(m => m?.id != poiId), p]);
            return success;
        }

    }
}



const useLargeMapApi = () => {

    return {
        getPois: async (): Promise<PoiType[]> => {
            const data: PoiType[] = await FetchHandler.getJson("/api/poi");
            return data;
        },
        getGardens: async (): Promise<PoiGarden[]> => {
            const data: PoiGarden[] = await FetchHandler.getJson("/api/poi/gardens");
            return data;
        },
        setPoiPosition: async (poiId: string, coord: LatLng): Promise<boolean> => {
            return await FetchHandler.patchJson("/api/poi/" + poiId + "/setcoords", coord);
        }
    }
}