import React, { Component } from 'react';
import { MoveDownIcon, MoveLeftIcon, MoveRightIcon, MoveUpIcon } from '../Shared/Icons';
import { FetchHandler } from "../../Handlers/FetchHandler";
import { fn } from "../../Tools/ArrayFunctions";
import { TwoColumnContainer } from "../Shared/Formelements";
import { StoreFillerHandler } from "../../rdx/StoreFillerHandler";
import { AdminIcon, AdminIcons } from '../Shared/AdminIcon';
import { ScreenHeader } from '../UI/Elements';

interface IProps { }
interface IState {
    planttypes?: any[],
    planttypeid?: string,

    plantfields?: any[],
    selectedplantfields?: any[],
    plantfields_base?: any[],
    selectedplantfields_base?: any[],
    changed?: boolean,
    saving?: boolean,
    loading?: boolean
}

export class FieldsPerType extends Component<IProps, IState> {
    static displayName = FieldsPerType.name;

    mounted: boolean;

    constructor(props) {
        super(props);

        this.mounted = false;

        this.state = {
            planttypes: [],
            planttypeid: "",

            plantfields: [],
            selectedplantfields: [],
            plantfields_base: [],
            selectedplantfields_base: [],

            changed: false,
            saving: false,


            loading: false
        };

        this.selectChanged = this.selectChanged.bind(this);
        this.save = this.save.bind(this);

        this.addClicked = this.addClicked.bind(this);
        this.removeClicked = this.removeClicked.bind(this);
        this.moveUp = this.moveUp.bind(this);
        this.moveDown = this.moveDown.bind(this);

    }


    componentDidMount() {
        this.mounted = true;

        FetchHandler.getJson("/api/planttypes").then(data => {
            if (this.mounted) {
                this.setState({ planttypes: data, selectedplantfields: [] })
            }
        });

    }
    componentWillUnmount() {
        this.mounted = false;
    }

    selectChanged(planttypeid) {

        const selected_pt = this.state.planttypes.filter(m => m.id === planttypeid)[0];
        this.setState({ planttypeid: planttypeid, loading: true });
        FetchHandler.getJson("/api/plantfields").then(data => {

            let selected = selected_pt.fields.map(_id => data.filter(_ => _.id === _id)[0]).filter(obj => obj); // data.filter(obj => selected_pt.fields.indexOf(obj.id) >= 0);
            let unselected = data.filter(obj => selected_pt.fields.indexOf(obj.id) < 0).filter(obj => obj);

            // console.log("planttypeid: ", planttypeid);
            // console.log("selected_pt: ", selected_pt);
            // console.log("data: ", data);
            // console.log("selected: ", selected);
            // console.log("unselected: ", unselected);

            if (this.mounted) {
                this.setState({
                    selectedplantfields: selected,
                    plantfields: unselected,
                    selectedplantfields_base: selected,
                    plantfields_base: unselected,
                    loading: false,
                    changed: false
                });
            }

        });
    }

    addClicked(id) {
        if (this.state.saving) { return; }

        let unselected = this.state.plantfields;
        let selected = this.state.selectedplantfields;
        for (var i = 0; i < unselected.length; i++) {
            if (unselected[i].id === id) {
                selected.push(unselected.splice(i, 1)[0]);
                continue;
            }
        }
        this.setState({
            plantfields: unselected,
            selectedplantfields: selected,
            changed: true
        });

    }
    removeClicked(id) {
        if (this.state.saving) { return; }

        let unselected = this.state.plantfields;
        let selected = this.state.selectedplantfields;
        for (var i = 0; i < selected.length; i++) {
            if (selected[i].id === id) {
                unselected.push(selected.splice(i, 1)[0]);
                continue;
            }
        }
        this.setState({
            plantfields: unselected,
            selectedplantfields: selected,
            changed: true
        });

    }

    save() {
        this.setState({ saving: true });
        var postObj = {
            id: this.state.planttypeid,
            fields: this.state.selectedplantfields.map(m => m.id)
        };
        FetchHandler.postJson("/api/planttypes/setfields", postObj).then(success => {
            FetchHandler.getJson("/api/planttypes").then(data => {
                this.setState({ planttypes: data, selectedplantfields: [], changed: false }, () => {
                    this.selectChanged(this.state.planttypeid);
                    this.setState({ saving: false });

                    StoreFillerHandler.ensureCache(true);

                });
            });
        });
    }

    moveUp(id) {
        if (this.state.saving) { return; }

        let a = this.state.selectedplantfields;
        let idx = -1;
        for (var i = 0; i < a.length; i++) {
            if (a[i].id === id) {
                idx = i;
            }
        }
        fn.move(a, idx, idx - 1)
        this.setState({ selectedplantfields: a, changed: true });
    }
    moveDown(id) {
        if (this.state.saving) { return; }

        let a = this.state.selectedplantfields;
        let idx = -1;
        for (var i = 0; i < a.length; i++) {
            if (a[i].id === id) {
                idx = i;
            }
        }
        fn.move(a, idx, idx + 1)
        this.setState({ selectedplantfields: a, changed: true });
    }

    render() {

        return (
            <div>
                <ScreenHeader text="  Fält per typ" icon={AdminIcons.link} />
                <p>
                    Vilka fält ska finnas på vilka typer, och i vilken ordning ska de visas.
                </p>

                <select value={this.state.planttypeid} onChange={(e) => { this.selectChanged(e.target.value); }} disabled={this.state.saving} style={{ minWidth: "200px" }}>
                    <option value="">Välj en växttyp...</option>
                    {this.state.planttypes.map(item => (
                        <option key={item.id} value={item.id}>{item.name}</option>
                    ))}
                </select>

                <button className="btn" onClick={this.save} disabled={!this.state.changed || this.state.saving}>
                    Spara
                </button>


                <hr />
                <TwoColumnContainer>
                    <div className={this.state.saving ? "element-disabled" : ""}>
                        <h3>Valda fält</h3>
                        {this.state.selectedplantfields.map((item, i) => (
                            <div key={item?.id} className="plantfields-listitem">
                                <div className="name">{item?.name}</div>
                                <div className="id">{item?.id}</div>
                                <div className="icon float-right">
                                    <button className="btn-icon" onClick={() => { this.removeClicked(item?.id); }}>
                                        <MoveRightIcon />
                                    </button>
                                </div>

                                {i !== this.state.selectedplantfields.length - 1 && (
                                    <div className="icon float-right">
                                        <button className="btn-icon" onClick={() => { this.moveDown(item?.id); }}>
                                            <MoveDownIcon />
                                        </button>
                                    </div>
                                )}

                                {i !== 0 && (
                                    <div className="icon float-right">
                                        <button className="btn-icon" onClick={() => { this.moveUp(item?.id); }}>
                                            <MoveUpIcon />
                                        </button>
                                    </div>
                                )}


                            </div>
                        ))}
                    </div>
                    <div className={this.state.saving ? "element-disabled" : ""} >
                        <h3>Ej valda fält</h3>
                        <div>
                            {this.state.plantfields.map(item => (
                                <div key={item.id} className="plantfields-listitem">
                                    <div className="icon" onClick={() => { this.addClicked(item.id); }}>
                                        <button className="btn-icon">
                                            <MoveLeftIcon />
                                        </button>
                                    </div>
                                    <div className="name">{item.name}</div>
                                    <div className="id">{item.id}</div>
                                </div>
                            ))}
                        </div>
                    </div>
                </TwoColumnContainer>
            </div>
        );
    }
}
