import { SearchReducerType } from "./ReduxTypes";

const CHANGE_SEARCH_SEARCHING = "CHANGE_SEARCH_SEARCHING";
const CHANGE_SEARCH_TEXT = "CHANGE_SEARCH_TEXT";
const CHANGE_SEARCH_TYPE = "CHANGE_SEARCH_TYPE";
const CHANGE_SEARCH_PUBLISHED = "CHANGE_SEARCH_PUBLISHED";
const CHANGE_SEARCH_LIST = "CHANGE_SEARCH_LIST";
const CHANGE_SEARCH_ALLSEARCHHITS = "CHANGE_SEARCH_ALLSEARCHHITS";
const CHANGE_SEARCH_VISIBLEFIELDS = "CHANGE_SEARCH_VISIBLEFIELDS";

const visibleFieldsInit = localStorage.getItem("visibleFields") ? JSON.parse(localStorage.getItem("visibleFields")) : null;

const SearchReducerStore: SearchReducerType = {
    isSearching: false,
    searchText: "",
    typeId: "",
    searchList: [],
    published: null,
    visibleFields: visibleFieldsInit ? visibleFieldsInit : ["EGENSKAPER_TEMP", "SUN_EXPOSURE"],
    allSearchHits: {}
}

const searchReducer = (state = { ...SearchReducerStore }, action) => {
    switch (action.type) {
        case CHANGE_SEARCH_SEARCHING: { state = { ...state, isSearching: action.payload }; break; }
        case CHANGE_SEARCH_TEXT: { state = { ...state, searchText: action.payload }; break; }
        case CHANGE_SEARCH_TYPE: { state = { ...state, typeId: action.payload }; break; }
        case CHANGE_SEARCH_PUBLISHED: { state = { ...state, published: action.payload }; break; }
        case CHANGE_SEARCH_LIST: { state = { ...state, searchList: action.payload }; break; }
        case CHANGE_SEARCH_ALLSEARCHHITS: { state = { ...state, allSearchHits: action.payload }; break; }

        case CHANGE_SEARCH_VISIBLEFIELDS: {
            state = { ...state, visibleFields: action.payload };
            localStorage.setItem('visibleFields', JSON.stringify(action.payload));
            break;
        }

        default: { return state; }
    }
    return state;
};
export {
    searchReducer,

    CHANGE_SEARCH_TEXT,
    CHANGE_SEARCH_TYPE,
    CHANGE_SEARCH_PUBLISHED,
    CHANGE_SEARCH_SEARCHING,
    CHANGE_SEARCH_LIST,
    CHANGE_SEARCH_ALLSEARCHHITS,
    CHANGE_SEARCH_VISIBLEFIELDS
}