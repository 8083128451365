import React, { useLayoutEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { Data } from '../../../Handlers/Data';
import { MessageBoxes } from '../../../Handlers/MessageBoxes';
import { PlantHandler } from '../../../Handlers/PlantHandler';
import { Operations } from '../../../rdx/Operations';
import { RdxStore } from '../../../rdx/ReduxTypes';
import store from '../../../rdx/store';
import { Dialog } from '../Dialog';
import { ThreeColumnContainer, TwoColumnContainer } from '../Formelements';

const parseJson = (txt) => {
    try {
        return JSON.parse(txt);
    } catch (e) {
        console.log(txt, e);
        return txt;
    }
}


export const SetPublicDescriptionDialog = ({ closeDialog }) => {

    const plantfields = useSelector((store: RdxStore) => store.data.plantfields);
    const fieldName = useSelector((store: RdxStore) => store.data.plantfieldsobject?.public_description?.name);
    let searchList = [...useSelector((store: RdxStore) => store.search.searchList.filter(obj => obj.selected))];
    const [disableDialog, setDisableDialog] = useState(false);
    const [selectedIndex, setSelectedIndex] = useState(0);

    const [inputValue, setInputValue] = useState(searchList[selectedIndex]?.fields?.public_description ? parseJson(searchList[selectedIndex]?.fields?.public_description) : "");
    const [saveOnChnage, setSaveOnChnage] = useState(false);

    const update = async (id, publicDescription) => {


        setDisableDialog(true);
        const success = await PlantHandler.updatePlantsField([id], "PUBLIC_DESCRIPTION", publicDescription);
        setDisableDialog(false);
        if (success) {
            MessageBoxes.info("Växten är uppdaterad!");
        } else {
            MessageBoxes.warning("Operationen misslyckades!");
        }
    }

    const selectIndex = (idx) => {



        if (idx < searchList.length && idx >= 0) {


            if (saveOnChnage) {
                const id = searchList[selectedIndex].id;
                const publicDescription = inputValue;
                console.log("save", id, publicDescription);
                update(id, publicDescription);
            }

            searchList[selectedIndex].fields.public_description = inputValue;

            setSelectedIndex(idx);
            if (searchList[idx]?.fields?.public_description) {
                setInputValue(parseJson(searchList[idx]?.fields?.public_description));
            } else {
                setInputValue("");
            }
        }
    }

    const onOk = () => {
        const id = searchList[selectedIndex].id;
        const publicDescription = inputValue;
        console.log("save", id, publicDescription);
        update(id, publicDescription).then(() => {
            closeDialog();
        });
    }

    return (
        <Dialog
            onOk={onOk}
            onCancel={() => { closeDialog(); }}
            width="90%"
            height="800px"
            top="50px"
            disabled={disableDialog}
        >

            <div className="form-large">

                <label>
                    Uppdatera Beskrivning {searchList[selectedIndex]?.fields?.name}, {searchList[selectedIndex]?.fields?.specifictype}
                </label>
                <hr />
                <div className="public-description-dialog-form">
                    <div className="cols">
                        {plantfields.map(f => (
                            <div key={f.id}>
                                {searchList[selectedIndex]?.fields[f.id.toLowerCase()] && (
                                    <div>
                                        <FieldValue fieldName={f.id} plant={searchList[selectedIndex]} />
                                    </div>
                                )}

                            </div>
                        ))}
                    </div>
                    <div>
                        <label>{fieldName} - (PUBLIC_DESCRIPTION)</label>
                        <textarea style={{ height: "400px" }} value={inputValue} onChange={(e) => setInputValue(e.target.value)}></textarea>
                        <div>
                            Antal ord: {inputValue.split(/\S+/).length - 1}
                        </div>
                    </div>
                </div>

                <ThreeColumnContainer visible={true}>
                    <div>
                        <button className="btn" onClick={() => { selectIndex(selectedIndex - 1) }} disabled={selectedIndex === 0}>&lt;&lt;&lt;</button>
                    </div>
                    <div style={{ textAlign: "center" }}>
                        Växt: {selectedIndex + 1}
                    </div>
                    <div style={{ textAlign: "right" }}>
                        <button className="btn" onClick={() => { selectIndex(selectedIndex + 1) }} disabled={selectedIndex === searchList.length - 1} >&gt;&gt;&gt;</button>
                    </div>
                </ThreeColumnContainer>

                <ThreeColumnContainer visible={true}>
                    <div></div>
                    <div style={{ textAlign: "center" }}>
                        <input type="checkbox" id="saveonnext" checked={saveOnChnage} onChange={e => setSaveOnChnage(e.target.checked)} />
                        <label htmlFor="saveonnext">Spara vid byte av växt</label>
                    </div>
                    <div style={{ textAlign: "right" }}>
                    </div>
                </ThreeColumnContainer>
            </div>
        </Dialog>
    )
}



const FieldValue = ({ fieldName, plant }) => {
    const fieldValue = plant?.fields[fieldName.toLowerCase()];
    const fieldProps = useSelector((state: RdxStore) => state.data.plantfieldsobject[fieldName.toLowerCase()]);

    if (!fieldValue) {
        return (null);
    }

    const name = fieldProps?.name;
    //const public = fieldProps?.public;
    const selectOptions = fieldProps?.selectOptions;
    const type = fieldProps?.type;

    let value = "";
    if (type === Data.fieldTypes.string) {
        value = fieldValue;
    } else if (type === Data.fieldTypes.integer) {
        value = fieldValue;
    } else if (type === Data.fieldTypes.decimal) {
        value = fieldValue;
    } else if (type === Data.fieldTypes.snippet) {
        value = plant?.fields[fieldName.toLowerCase() + "_text"];
    } else if (type === Data.fieldTypes.singleSelect || type === Data.fieldTypes.multiSelect || type === Data.fieldTypes.color) {
        const v = JSON.parse(plant?.fields[fieldName.toLowerCase()]);
        const sel = selectOptions.filter(obj => v.indexOf(obj.key) >= 0).map(obj => obj.name);
        value = sel.join(',');
    } else {
        value = fieldValue;
    }

    // console.log("fieldName: ",fieldName);
    // console.log("plant: ",plant);
    // console.log("fieldProps: ",fieldProps);
    // console.log("name: ",name);
    // console.log("selectOptions: ",selectOptions);


    return (
        <div className="plant-info-small">
            <span className="label">{name}:</span>
            <span className="value">{value}</span>
        </div>
    )
}