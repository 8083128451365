import React, { useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { FetchHandler } from '../Handlers/FetchHandler';
import { RdxStore, SearchHitType } from "../rdx/ReduxTypes";
import { CHANGE_SEARCH_ALLSEARCHHITS, CHANGE_SEARCH_LIST } from '../rdx/searchReducer';

export const usePlant = () => {
    const db = useSelector((state: RdxStore) => state.search.allSearchHits);
    const dispatch = useDispatch();
    return {
        item: (plantId: string): SearchHitType => {
            return db?.[plantId];
        },
        loadPlant: async (plantId: string) => {
            var url = "/api/plants?id=" + plantId;
            const plants = await FetchHandler.getJson(url);
            let allHits = { ...db };
            plants.forEach(plant => { allHits[plant.id] = plant });
            dispatch({ type: CHANGE_SEARCH_ALLSEARCHHITS, payload: allHits });
        },
        loadMultiple: async (plantIds: string[]) => {
            if (!plantIds || plantIds?.length == 0) { return; }
            const aids = plantIds.filter(id => !!id && !db?.[id]);
            const getDataId = async (id: string): Promise<any> => {
                var url = "/api/plants?id=" + id;
                var items = await FetchHandler.getJson(url);
                return items[0];
            }
            Promise.all(aids.map(m => getDataId(m))).then(res => {

                console.log("loadMultiple: ", res, plantIds);
                let dataObject = { ...db };
                res.forEach(obj => {
                    dataObject[obj?.id] = obj;
                });
                dispatch({ type: CHANGE_SEARCH_ALLSEARCHHITS, payload: dataObject });
            });
        }
    }
}