import React, { useState } from 'react';
import { useSelector } from 'react-redux';
import { Link } from 'react-router-dom';
import { createSourceMapSource } from 'typescript';
import { AdminIcon, AdminIcons } from '../Shared/AdminIcon';
import { ScreenHeader } from '../UI/Elements';
import { JwtPlantImage } from '../UI/ImageElements';

export const AllPlantImages = () => {

    const allimages = useSelector(state => state.data.plantimages);
    const plants = Object.keys(allimages).map(key => allimages[key]);
    let images = [];
    plants.forEach(plant => {
        Object.keys(plant).map(key => plant[key]).forEach(im => images.push(im));
    });
    console.log(images)

    const pageSize = 40;
    const numPages = Math.ceil(images?.length / pageSize);
    const [pageNumber, setPageNumber] = useState(0);

    const paginate = (array) => {
        return array.slice(pageNumber * pageSize, (pageNumber + 1) * pageSize);
    }

    const selectedImages = paginate(images);
    const pages = Array.from({ length: numPages }, (x, i) => i);

    return (
        <div className="plantimage-grid">
            <ScreenHeader text={images?.length + " bilder"} icon={AdminIcons.images} />

            <div>
                {pages.map(n => (
                    <div key={n} className={"tabbed-table-pagenumber " + (n === pageNumber ? "selected" : "")} onClick={() => { setPageNumber(n) }}>{n + 1}</div>
                ))}
            </div>
            <div>
                <ImageGrid images={selectedImages} />
            </div>
        </div>
    )
}

const ImageGrid = ({ images }) => {
    return (
        <div>
            {images.map(im => (
                <div key={im.id} className="item">
                    <Link to={"/plants/plantimages/" + im.plantId}>
                        <JwtPlantImage plantImageId={im.id} />
                    </Link>
                    <div className="licensing-name">
                        {im?.licensing?.licensingName}
                    </div>
                    <div className="licensing-name">
                        {im?.fileSizeKb} Kb
                    </div>
                </div>
            ))}
        </div>
    )
}