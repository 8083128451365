import { LatLng, MapBounds } from "./MapTypes";
import { PoiDataItem } from "./useLargeMap";

export const mapPins = {
    red: "/icons/red.pin.png",
    blue: "/icons/blue.pin.png",
    green: "/icons/green.pin.png",
    orange: "/icons/orange.pin.png",
    yellow: "/icons/yellow.pin.png",

    user: "/icons/user.png",
    garden: "/icons/garden.png",
    store: "/icons/store.png",
}
const toRad = function (deg: number) {
    return deg * Math.PI / 180;
}
const distanceKm = (lon1: number, lat1: number, lon2: number, lat2: number) => {
    var R = 6371; // Radius of the earth in km
    var dLat = toRad((lat2 - lat1));  // Javascript functions in radians
    var dLon = toRad(lon2 - lon1);
    var a = Math.sin(dLat / 2) * Math.sin(dLat / 2) +
        Math.cos(toRad(lat1)) * Math.cos(toRad(lat2)) *
        Math.sin(dLon / 2) * Math.sin(dLon / 2);
    var c = 2 * Math.atan2(Math.sqrt(a), Math.sqrt(1 - a));
    var d = R * c; // Distance in km
    return d;
}
export const MapHelper = {

    getBoundsFromCoords: (coords: LatLng[], padding?: number): MapBounds => {
        let b: MapBounds = { latitudeMax: coords?.[0]?.latitude, latitudeMin: coords?.[0]?.latitude, longitudeMax: coords?.[0]?.longitude, longitudeMin: coords?.[0]?.longitude };
        coords.forEach(c => {
            b.latitudeMax = Math.max(b.latitudeMax, c.latitude);
            b.latitudeMin = Math.min(b.latitudeMin, c.latitude);

            b.longitudeMax = Math.max(b.longitudeMax, c.longitude);
            b.longitudeMin = Math.min(b.longitudeMin, c.longitude);
        });
        return b;
    },

    convertBounds: (b: google.maps.LatLngBounds) => {
        const NELat = b?.getNorthEast()?.lat();
        const SWLat = b?.getSouthWest()?.lat();
        const NELng = b?.getNorthEast()?.lng();
        const SWLng = b?.getSouthWest()?.lng();
        const f: MapBounds = {
            latitudeMin: Math.min(NELat, SWLat),
            latitudeMax: Math.max(NELat, SWLat),
            longitudeMin: Math.min(NELng, SWLng),
            longitudeMax: Math.max(NELng, SWLng),
        };
        console.log(f);
    },

    getCenterFromBounds: (b: google.maps.LatLngBounds): LatLng => {
        const NELat = b?.getNorthEast()?.lat();
        const SWLat = b?.getSouthWest()?.lat();
        const NELng = b?.getNorthEast()?.lng();
        const SWLng = b?.getSouthWest()?.lng();
        const f: LatLng = {
            latitude: (NELat + SWLat) / 2,
            longitude: (NELng + SWLng) / 2
        };
        return f;
    },
    getIcon: (poi: PoiDataItem) => {
        if (!!poi?.garden) {
            return mapPins.user;
        } else {
            if (poi?.poi?.type == "store") {
                return mapPins.store;
            } else {
                return mapPins.garden;
            }
        }
    },
    distanceKm: (p0: LatLng, p1: LatLng) => {
        if (!p0 || !p1) { return 0 }
        return distanceKm(p0.longitude, p0.latitude, p1.longitude, p1.latitude);
    }
}