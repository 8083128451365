import React, { useState, useLayoutEffect, useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { Overlay } from '../UI/Overlay';
import { MessageBoxes } from '../../Handlers/MessageBoxes';
import { Operations } from '../../rdx/Operations';
import { usePlantImages, usePlantSearchHit, useUISettings } from '../../Handlers/Hooks';
import { Padder } from '../UI/Elements';
import { RemoveIcon, StarIcon, TrashIcon } from '../Shared/Icons';
import { Data } from '../../Handlers/Data';
import { JwtPlantImage, JwtPlantImagePreview } from '../UI/ImageElements';
import { SearchHandler } from '../../Handlers/SearchHandler';
import { PlantImageType } from '../../rdx/ReduxTypes';
import { useUsers } from '../../hooks/useUsers';
import { FetchHandler } from '../../Handlers/FetchHandler';

type actionType = "delete" | "makeprimary" | "unpublish";
export const PlantImages = (props) => {

    const plantId = props.match.params.id;
    const [working, setWorking] = useState(false);
    const [droppable, setDroppable] = useState(false);
    const plantImageObject = usePlantImages(plantId);
    const plantImages: PlantImageType[] = Object.keys(plantImageObject ?? {}).map(key => plantImageObject?.[key]);
    const plant = usePlantSearchHit(plantId);
    const dispatch = useDispatch();

    const toolStates = { add: "add", delete: "delete" };
    const [toolState, setToolState] = useState(toolStates.add);

    console.log("plantImages:", plantImages);

    const ui = useUISettings();
    const [licensingInfo, setLicensingInfo] = useState(Data.licensingOptions)
    const [selectedLicensingInfo, setSelectedLicensingInfo] = useState(Data.defaultLicensingOptionKey);

    useLayoutEffect(() => {
        dispatch(() => { Operations.getPlantImages(plantId); })
        if (!plant) {
            dispatch(async () => { await SearchHandler.SearchPlant(plantId); })
        }
    }, []);


    const users = useUsers();
    useEffect(() => {
        if (plantImages && plantImages?.length > 0) {
            const userids = Array.from(new Set(plantImages?.map(pi => pi.licensing?.licensingUserId).filter(m => m)));
            console.log("userids: ", userids);
            users.ensureUserInfo(userids);
        }
    }, [])



    const dragOverHandler = (ev) => {
        setDroppable(true);
        ev.preventDefault();
    }
    const dragLeaveHandler = (ev) => {
        setDroppable(false);
        ev.preventDefault();
    }

    const dropHandler = (ev) => {
        setDroppable(false);
        console.log('File(s) dropped');
        let image = null;
        let file = null;

        // Prevent default behavior (Prevent file from being opened)
        ev.preventDefault();

        if (ev.dataTransfer.items) {
            // Use DataTransferItemList interface to access the file(s)
            for (var i = 0; i < ev.dataTransfer.items.length; i++) {
                // If dropped items aren't files, reject them
                if (ev.dataTransfer.items[i].kind === 'file') {
                    file = ev.dataTransfer.items[i].getAsFile();
                    console.log('... file[' + i + '].name = ' + file.name);
                    console.log(file);
                    image = ev.dataTransfer.getData('text/plain');
                }
            }

        } else {
            // Use DataTransfer interface to access the file(s)
            for (var i = 0; i < ev.dataTransfer.files.length; i++) {
                console.log('DataTransfer... file[' + i + '].name = ' + ev.dataTransfer.files[i].name);
                console.log(ev.dataTransfer.files[i]);
                image = ev.dataTransfer.getData('text/plain');
            }
        }

        dispatch(() => {
            Operations.addPlantImage(plantId, {
                plantId: plantId,
                name: image?.name,
                image: image,
                file: file,
                licensing: JSON.stringify(licensingInfo?.[selectedLicensingInfo])
            });
        })


    }

    const toolClicked = (imageId: string, action: actionType) => {
        if (action === "delete") {
            const del = MessageBoxes.confirm("Är du säker?")
            if (del) {
                Operations.removePlantImage(plantId, imageId);
                setToolState(toolStates.add);
            }
        }
        if (action === "makeprimary") {
            Operations.makePlantImagePrimary(plantId, imageId);
            setToolState(toolStates.add);
        }

        if (action === "unpublish") {
            //MessageBoxes.warning("Not implemented");
            Operations.removePlantImage(plantId, imageId);

            // Operations.makePlantImagePrimary(plantId, imageId);
            // setToolState(toolStates.add);
        }

    }

    return (
        <div>
            <Overlay visible={working} />
            <h1>{plant?.fields?.name}</h1>

            <table style={{ width: "100%" }}>
                <thead>
                    <tr style={{ fontWeight: "bold" }}>
                        <td>Namn</td>
                        <td>Sort</td>
                        <td>Latin</td>
                        <td style={{ width: "50%" }}>Eng</td>
                        <td style={{ width: "200px" }}></td>
                    </tr>
                </thead>
                <tbody>
                    <tr>
                        <td>{plant?.fields?.name}</td>
                        <td>{plant?.fields?.specifictype}</td>
                        <td>{plant?.fields?.namelatin}</td>
                        <td>{plant?.fields?.nameenglish}</td>
                        <td>
                            <RemoveDefaultImage plantId={plantId} />
                        </td>
                    </tr>
                </tbody>
            </table>
            <hr />
            <div className="addimages-grid">
                <div>
                    Licensiering: <select value={selectedLicensingInfo} onChange={(e) => {
                        setSelectedLicensingInfo(e.target.value);
                    }}>
                        {Object.keys(licensingInfo).map(key => (
                            <option key={key} value={key}>{licensingInfo[key].name}</option>
                        ))}
                    </select>

                    <Padder.Horizontal size="20px" />

                    <div className="addimages-droparea" style={{ backgroundColor: droppable ? "#ecffeb" : "#f3f3f3" }}
                        id="drop_zone" onDrop={dropHandler}
                        onDragOver={dragOverHandler}
                        onDragLeave={dragLeaveHandler}
                    >
                        <div>Släpp bilden här</div>
                    </div>

                </div>


                <div>
                    <div>
                    </div>
                    <Padder.Horizontal size="20px" />
                    {plantImages && plantImages.filter(m => m?.licensing?.licensingKey).map(im => (
                        <PlantImage key={im.id} im={im} toolClicked={toolClicked} plant={plant} />
                    ))}
                </div>
            </div>
        </div>
    )

}


const PlantImage = ({ im, toolClicked, plant }: { im: PlantImageType, toolClicked(id: string, t: actionType): void, plant: any }) => {

    const isPrimaryImage = im?.id === plant?.fields?.imageid;
    const [imageOpen, setImageOpen] = useState<string>(null);
    const users = useUsers();
    return (
        <div key={im.id} className={"addimages-listitem" + (isPrimaryImage ? " primary" : "")} >
            <div>
                <div onClick={() => setImageOpen(im.id)} className="cursor-pointer">
                    <JwtPlantImage plantImageId={im.id} />
                </div>
                <JwtPlantImagePreview
                    plantImageId={im.id}
                    visible={imageOpen === im.id}
                    onClick={() => setImageOpen(imageOpen ? null : im.id)} >
                </JwtPlantImagePreview>
            </div>
            <div>
                <div className="license"> Licens: {im?.licensing?.licensingName ? im?.licensing?.licensingName : users.getUsersName(im?.licensing?.licensingUserId)} </div>
                <div className="url"> Url: {im?.licensing?.licensingUrl} </div>
                <div className="audience"> Publik: {im?.licensing?.licensingAudience} </div>
                <div className="size"> Storlek: {im?.fileSizeKb} kb </div>

                {isPrimaryImage && (
                    <div className="primary"> Primärbild </div>
                )}
            </div>
            <div>

                {im?.licensing?.licensingKey != "user" && (
                    <button className="button-delete" onClick={() => toolClicked(im.id, "delete")} title="Radera">
                        <TrashIcon />
                    </button>
                )}

                {im?.licensing?.licensingKey === "user" && (
                    <button className="button-delete" onClick={() => toolClicked(im.id, "unpublish")} title="Avpublicera">
                        <RemoveIcon />
                    </button>
                )}

                <button className="button-makeprimary" onClick={() => toolClicked(im.id, "makeprimary")} title="Gör till primärbild">
                    <StarIcon />
                </button>

            </div>
        </div>
    )

}

const RemoveDefaultImage = ({ plantId }: { plantId: string }) => {

    const onClick = async () => {

        if (MessageBoxes.confirm("Är du säker?")) {
            const success = await FetchHandler.patchJson("/api/plant/" + plantId + "/removedefaultimage", {});
            MessageBoxes.info("Defaultbilden togs bort!")
        }
    }


    return (
        <button className='btn' onClick={onClick}>
            Töm defaultbild
        </button>
    )
}