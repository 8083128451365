import * as signalR from "@microsoft/signalr";
import { HubConnectionBuilder } from "@microsoft/signalr";
import { useEffect, useState } from "react";
import { useSelector, useDispatch } from 'react-redux';
import { useUsers } from "../hooks/useUsers";
import { UsersInfoType } from "./Redux.DataReducerType";
import { RdxStore } from "./ReduxTypes";
import { CHANGE_SIGNALR_CONNECTED_USERS, CHANGE_SIGNALR_LAST_PING, ConnectedUserType } from "./signalrReducer";

type SignalRReduxMessage = {
    type: string,
    payload: any
}

export const useSignalR = () => {

    const ops = useSignalRMessaging();
    const lastPing: string = useSelector((state: RdxStore) => state.signalr.lastPing);
    const [hubConnection, setHubConnection] = useState<signalR.HubConnection>();
    const dispatch = useDispatch();

    const setupConnection = async () => {
        let connection = new signalR.HubConnectionBuilder()
            .withUrl("/hub")
            .build();

        connection.on("messageReceived", msg => { console.log("Message from server ", msg); });
        connection.on("jsonReceived", data => { console.log("Json from server: ", JSON.parse(data)); });

        // Redux
        connection.on("reduxMessage", (message: string) => {
            const msg: SignalRReduxMessage = JSON.parse(message);
            dispatch(msg);
        });

        // Userlist
        connection.on("userList", data => {
            ops.setConnectedUsers(JSON.parse(data));
        });

        await connection.start();
        connection.invoke("send", "Hello");
        return connection;
    }


    useEffect(() => {
        setupConnection().then(c => {
            setHubConnection(c);
        });
    }, []);

    // Pinf when lastPing changes
    useEffect(() => {
        if (hubConnection && hubConnection?.state == signalR.HubConnectionState.Connected) {
            hubConnection.invoke("ping", lastPing)
        }
    }, [lastPing])


    return {}
}


export const useSignalRMessaging = () => {

    const dispatch = useDispatch();
    const usersOps = useUsers();

    // connectedUsers
    const connectedUsers: ConnectedUserType[] = useSelector((state: RdxStore) => state.signalr.connectedUsers);
    const lastPing: string = useSelector((state: RdxStore) => state.signalr.lastPing);
    useEffect(() => {
        if (connectedUsers) {
            usersOps.ensureUserInfo(connectedUsers?.map(m => m?.userId))
        }
    }, [connectedUsers]);


    return {
        // connectedUsers
        setConnectedUsers: (e: any) => {
            dispatch({ type: CHANGE_SIGNALR_CONNECTED_USERS, payload: e });
        },
        connectedUsers: (connectedUsers?.map(m => {
            const u = usersOps.getUser(m?.userId);
            return {
                userId: m?.userId,
                name: u?.name,
                email: u?.email,
                added: m?.added,
                ping: m?.ping,
                header: m?.header,
                action: m?.action
            }
        })) as (UsersInfoType & { added: number, ping: number })[],

        // misc
        ping: (path: string, action?: string) => {
            const p: PingType = { path: path, action: action ?? "navigation" }
            dispatch({ type: CHANGE_SIGNALR_LAST_PING, payload: JSON.stringify(p) });
        },
        action: (action?: string) => {
            const p: PingType = { action: action }
            dispatch({ type: CHANGE_SIGNALR_LAST_PING, payload: JSON.stringify(p) });
        },
    }
}

type PingType = {
    path?: string,
    action: string
}

