import React, { useState } from 'react';
import { useSelector } from 'react-redux';
import { Data } from '../../../Handlers/Data';
import { MessageBoxes } from '../../../Handlers/MessageBoxes';
import { PlantHandler } from '../../../Handlers/PlantHandler';
import { Operations } from '../../../rdx/Operations';
import { RdxStore } from '../../../rdx/ReduxTypes';
import { Dialog } from '../Dialog';
import { TwoColumnContainer } from '../Formelements';


export const SetFamilyNameDialog = ({ closeDialog }) => {

    const ids = useSelector((store: RdxStore) => store.search.searchList.filter(obj => obj.selected).map(obj => obj.id));
    const [disableDialog, setDisableDialog] = useState<boolean>(false);
    const [inputValue, setInputValue] = useState<string>("");

    const update = () => {
        setDisableDialog(true);

        PlantHandler.updatePlantsField(ids, "FAMILYNAME", inputValue, false).then(success => {
            if (success) {
                MessageBoxes.info("Växterna är uppdaterade!");
            } else {
                MessageBoxes.warning("Operationen misslyckades!");
            }
            setDisableDialog(false);
            if (closeDialog) {
                closeDialog(true);
            }
        });
    }

    return (
        <Dialog
            onOk={update}
            onCancel={() => { closeDialog(); }}
            width="400px"
            height="200px"
            disabled={disableDialog}
        >

            <div className="form-large">

                <label>
                    Uppdatera familjenamn
                </label>

                <input type="text" value={inputValue}
                    onChange={e => setInputValue(e.target.value)}
                    disabled={disableDialog}
                />

            </div>

        </Dialog>
    )
}