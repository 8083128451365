import React, { useEffect, useState } from "react"
import { Link } from 'react-router-dom';
import { FetchHandler } from "../../Handlers/FetchHandler";
import { MessageBoxes } from "../../Handlers/MessageBoxes";
import { usePlant } from "../../hooks/usePlant";
import { Resources } from "../../Tools/Resources";
import { ClipBoardDragContainer } from "../ClipBoard/ClipBoard";
import { ForumPostType } from "../Forum/Forum";
import { ForumPostCard } from "../Forum/ForumPostCard";
import { AdminIcons } from "../Shared/AdminIcon"
import { CustomColumnContainer, ThreeColumnContainer } from "../Shared/Formelements";
import { CustomTool, Tool, ToolBar, ToolbarItemType } from "../ToolBar/ToolBar"
import { ScreenHeader } from "../UI/Elements"
import { JwtImage, useJwtImage } from "../UI/ImageElements";
import { Overlay } from "../UI/Overlay"
import { PlantRow } from "../UI/PlantRenderElements";
import { FeaturedPostType, FeaturedPostTypeToText, usePostsManagement } from "./usePostsManagement";

export const PostsManagement = () => {
    const ui = usePostsManagement();
    return (
        <div className="screen-posts-management">
            {/* <Overlay visible={false} /> */}
            <ScreenHeader text="Inlägg" icon={AdminIcons.posts} />
            {/* <div>
                <ToolBar tools={ui.tools} />
            </div> */}
            <CustomColumnContainer gridTemplateColumns="350px 700px auto">
                <div>

                    <div className="scroll-column forum-post-all-featured">

                        <h3>Utvalda inlägg</h3>
                        {ui.posts?.map(p => (
                            <div className="cursor-pointer" key={p?.id} onClick={() => ui.selectPost(p?.id)} >
                                <ForumPostCard
                                    post={p}
                                    hideLink={true}
                                    selected={ui.selectedPost?.id === p?.id}
                                    overrrideLink={true}
                                />
                            </div>
                        ))}

                    </div>
                </div>
                <div>
                    <div className="scroll-column forum-post-selected-items">
                        <h3>Preview</h3>




                        {!!ui.selectedPost?.id && (
                            <div>


                                <ToolBar tools={[
                                    {
                                        id: "1", label: "Ta bort från valda", onClick: () => {
                                            if (MessageBoxes.confirm("Ta bort post: '" + ui?.selectedPost?.alias + "'")) {
                                                ui.removeFromFeatured();
                                            }
                                        }
                                    },
                                ]} >
                                    <CustomTool >
                                        Välj typ:
                                        <select value={ui.selectedPost?.featuredtype ?? "none"} onChange={e => ui.setFeaturedPostType(e.target.value as FeaturedPostType)}>
                                            {ui.featuredPostTypes.map(item => (
                                                <option key={item} value={item}>{FeaturedPostTypeToText(item)}</option>
                                            ))}
                                        </select>
                                    </CustomTool>

                                    <Tool item={{
                                        id: "save",
                                        label: "Spara",
                                        onClick: ui.saveChangesOnSelected,
                                        disabled: ((ui.selectedPost?.featuredtype ?? "none") == "none" ? true : false) || !ui.selectedPost?.featuredimageid,
                                    }} />

                                </ToolBar>


                                <PostImagesGrid
                                    post={ui.selectedPost}
                                    setSelectedImageId={ui.setSelectedImageId}
                                />
                            </div>
                        )}
                    </div>
                </div>
                <div>
                    <div className="preview-card-container">
                        <h2>
                            App
                        </h2>
                        {(!!ui.selectedPost?.featuredimageid && !!ui.selectedPost?.id) && (
                            <UserPostFeaturedCard
                                post={ui.selectedPost} openClipBoardOnDrag={true}
                                disableDrag={true}
                            />
                        )}
                    </div>
                </div>
            </CustomColumnContainer>

        </div>
    )
}



const PostImagesGrid = ({ post, setSelectedImageId }: { post: ForumPostType, setSelectedImageId(e: string): void }) => {
    const plantStore = usePlant();
    useEffect(() => {
        if (post?.plantids?.length > 0) {
            plantStore.loadMultiple(post?.plantids);
        }
    }, [, post])

    return (
        <div>
            <div className="post-alias">
                {post?.alias}
            </div>
            <div className="post-text">
                {post?.text}
            </div>
            <hr />
            <div className="post-gardenitem">
                {!post?.gardenitemid ? "Ingen växtplats" : "Har växtplats"}
            </div>
            <hr />
            <div className="post-image-list">
                {post?.imageids?.map(i => (
                    <div key={i} className="post-image-row">
                        <JwtImage
                            imageId={i}
                            format="hd"
                            openOnClick={true}
                        />
                        <input type={"radio"} checked={post?.featuredimageid == i} name="selectedimageid" value={i} onChange={e => {
                            if (e.target.checked) {
                                setSelectedImageId(i)
                            }
                        }} />
                    </div>
                ))}
            </div>
            <div>
                {post?.plantids?.map(p => (
                    <PlantRow key={p} plantId={p} />
                ))}
            </div>
        </div>
    )
}


export const UserPostFeaturedCard = ({ post, openClipBoardOnDrag, disableDrag }: { post: ForumPostType, openClipBoardOnDrag?: boolean, disableDrag?: boolean }) => {
    const uiLarge = useJwtImage(post?.featuredimageid, "hd");

    const Elem = () => (
        <div className="preview-card">
            <img src={uiLarge?.imgSrc} loading="lazy" />
            <div className="preview-text-container">
                <div>
                    <div className="preview-text-type">
                        {FeaturedPostTypeToText(post?.featuredtype)}
                    </div>
                </div>
                <div className="preview-text-alias">
                    {post?.alias}
                </div>
            </div>
        </div>
    );

    if (disableDrag === true) {
        return (<Elem />);
    }

    return (
        <ClipBoardDragContainer type={"userpostfeaturedcard"} dragData={post} openClipBoardOnDrag={openClipBoardOnDrag}>
            <Elem />
        </ClipBoardDragContainer>
    )

}
