import React, { Component } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { FetchHandler } from "../Handlers/FetchHandler";
import { EditIcon, SaveIcon, CancelIcon, AddIcon } from "./Shared/Icons"
import { efn } from "../Handlers/ElementFunctions";

const defaultType = "-";

interface IProps { }
interface IState {
  types?: any[],
  list?: any[],
  selectedtype?: string,
  selectedtypemodel?: any,
  selectedKey?: string,
  multiSelect?: boolean,

  key?: string,
  text?: string,

  newkey?: string,
  newtext?: string,
}

export class ListItems extends Component<IProps, IState> {
  static displayName = ListItems.name;

  constructor(props) {
    super(props);


    this.state = {
      types: [],
      list: [],
      selectedtype: defaultType,
      selectedtypemodel: null,
      selectedKey: "",
      multiSelect: false,

      key: "",
      text: "",

      newkey: "",
      newtext: ""
    };

    this.onChange = this.onChange.bind(this);
    this.enterDetect = this.enterDetect.bind(this);
    this.loadType = this.loadType.bind(this);

    // gui
    this.selectedTypeChanged = this.selectedTypeChanged.bind(this);
    this.addClicked = this.addClicked.bind(this);
    this.editClicked = this.editClicked.bind(this);
    this.saveClicked = this.saveClicked.bind(this);
    this.cancelClicked = this.cancelClicked.bind(this);

  }
  onChange(e) {
    if (e.target.name === "multiSelect") {
      // set state
      this.setState({ [e.target.name]: e.target.checked });

      // update db
      FetchHandler.postJson("/api/listitems/updatesettings",
        { type: this.state.selectedtype, multiSelect: e.target.checked }
      ).then(success => {
        if (!success) {
          alert("Failed");
        }
      });

      let lst = this.state.types;
      lst.forEach(item => {
        if (item.type === this.state.selectedtype) {
          item.settings.multiSelect = e.target.checked
        }
      });

    } else {
      this.setState({ [e.target.name]: e.target.value });
    }
  }

  enterDetect(e) {
    if (e.keyCode === 13) {
      this.saveClicked();
    }
  }

  componentDidMount() {
    FetchHandler.getJson("/api/listitems/gettypes").then(data => {
      this.setState({ types: data });
    });
  }

  selectedTypeChanged(e) {
    const type = e.target.value;

    const model = this.state.types.filter(obj => obj.type === type)[0];
    this.setState({
      selectedtype: type,
      selectedtypemodel: this.state.types.filter(obj => obj.type === type)[0],
      multiSelect: model ? model.settings.multiSelect : false
    });

    if (type === defaultType) {
      this.setState({ list: [] });
    } else {
      this.loadType(type);
    }
  }

  loadType(type) {
    FetchHandler.getJson("/api/listitems/get/" + type).then(data => {
      this.setState({ list: data });
    });
  }



  editClicked(e) {
    const key = efn.getElementDataset(e.target, "key");
    console.log("edit: ", key);

    this.setState({
      selectedKey: key,
      key: key,
      text: this.state.list.filter(obj => obj.key === key)[0].text
    });
  }
  saveClicked() {
    const postObj = {
      type: this.state.selectedtype,
      key: this.state.key,
      text: this.state.text
    };
    FetchHandler.postJson("/api/listitems/update/" + postObj.type, postObj)
      .then(success => {
        if (success) {
          this.setState({ selectedKey: "" });
          this.loadType(postObj.type);
        } else {
          alert("Fail");
        }

      });
  }

  cancelClicked(e) {
    this.setState({ selectedKey: "" });
  }

  addClicked() {
    const postObj = {
      type: this.state.selectedtype,
      key: this.state.newkey,
      text: this.state.newtext
    };

    FetchHandler.postJson("/api/listitems/create/" + postObj.type, postObj)
      .then(success => {
        if (success) {
          this.setState({ selectedKey: "", newkey: "", newtext: "" });
          this.loadType(postObj.type);
        } else {
          alert("Fail");
        }

      });
  }

  render() {
    return (
      <div>
        <h1>Kategorier</h1>
        <hr />
        <div className="filterBox-container">

          <div className="filterBox w4">
            <label>Typ</label>
            <div>
              <select onChange={this.selectedTypeChanged}>
                <option value={defaultType}>-</option>
                {this.state.types.map(type => (
                  <option key={type.type} value={type.type}>{type.type}</option>
                ))}
              </select>
            </div>
          </div>
          {this.state.selectedtypemodel && (
            <>
              <div className="filterBox w2">
                <label>Beskrivning</label>
                <div>
                  {this.state.selectedtypemodel.description}
                </div>
              </div>

              <div className="filterBox w2">
                <label>Flerval</label>
                <div>
                  <input type="checkbox" name="multiSelect" checked={this.state.multiSelect} onChange={this.onChange} />
                </div>
              </div>
            </>
          )}



        </div>

        <hr className="clearfix" />
        <table className="tbl" style={{ width: "50%" }}>
          <thead>
            <tr>
              <th style={{ width: "120px" }}>Key</th>
              <th>Text</th>
              <th style={{ width: "100px" }}></th>
            </tr>
          </thead>
          <tbody>
            {this.state.list.map(item => (
              <tr key={item.key} data-key={item.key}>

                {item.key === this.state.selectedKey && (
                  <>
                    <td>
                      {this.state.key}
                    </td>
                    <td>
                      <input type="text" name="text" value={this.state.text} onChange={this.onChange} onKeyUp={this.enterDetect} />
                    </td>
                    <td className="right">
                      <button className="btn-icon" data-key={item.key} onClick={this.cancelClicked}>
                        <CancelIcon />
                      </button>
                      <button className="btn-icon" data-key={item.key} onClick={this.saveClicked}>
                        <SaveIcon />
                      </button>
                    </td>
                  </>
                )}

                {item.key !== this.state.selectedKey && (
                  <>
                    <td>{item.key}</td>
                    <td>{item.text}</td>
                    <td className="right">
                      <button className="btn-icon" data-key={item.key} onClick={this.editClicked}>
                        <EditIcon />
                      </button>
                    </td>
                  </>
                )}

              </tr>
            ))}

            {this.state.selectedtype !== defaultType && (
              <tr>
                <td>
                  <input type="text" name="newkey" value={this.state.newkey} onChange={this.onChange} className="full-width" />
                </td>
                <td>
                  <input type="text" name="newtext" value={this.state.newtext} onChange={this.onChange} className="full-width" />
                </td>
                <td className="right">
                  <button className="btn-icon" onClick={this.addClicked}>
                    <AddIcon />
                  </button>
                </td>
              </tr>
            )}
          </tbody>
        </table>
      </div>
    );
  }
}

