import React, { useEffect, useState } from 'react';
import Select from 'react-select';
import { TwoOverThreeContainer } from '../Shared/Formelements';
import { MessageBoxes } from '../../Handlers/MessageBoxes';
import { PlantOperations } from '../../rdx/Operations';
import { usePlantFieldData, usePlantTypes, useSnippetData } from '../../Handlers/Hooks';
import { PlantField } from './CreatePlant';
import { Data } from '../../Handlers/Data';
import { useHistory } from 'react-router-dom';
import { Routes } from '../../Handlers/ViewHandler';


type NewPlaneMpodelType = {
    plantTypeId?: string
}

export const AddNewPlant = () => {
    var nav = useHistory();
    const plantTypes = usePlantTypes();
    const plantFields = usePlantFieldData();

    const options = plantTypes.map(obj => {
        return {
            value: obj.id,
            label: obj.name
        }
    })

    const [model, setModel] = useState<NewPlaneMpodelType>({});
    const [modelIsValid, setModelIsValid] = useState(true);
    const [type, setType] = useState(null);
    const [typesFields, setTypesFields] = useState([]);
    const [selectedFields, setSelectedFields] = useState([
        "NAME",
        "SPECIFICTYPE",
        "PUBLIC_DESCRIPTION",
        "SUN_EXPOSURE",
        "SOIL",
    ]);

    const typeChanged = (e) => {
        const m = plantTypes.filter(obj => obj.id === e.value)[0];
        setType(m);
        const fields = m?.fields?.filter(f => plantFields.item(f)?.public);
        setTypesFields(fields);
        setSelectedFields(fields);

        const plantModel = { ...model, plantTypeId: e.value };
        setModel(plantModel);

    }

    const toggleField = (e) => {
        let _new = [...selectedFields];
        if (selectedFields.indexOf(e) < 0) {
            _new.push(e);
            let m = { ...model };
            m[e.toLowerCase()] = null;
            setModel(m);
        } else {
            _new.splice(selectedFields.indexOf(e), 1);
            let m = { ...model };
            delete m[e.toLowerCase()];
            delete m[e.toLowerCase() + "_text"];
            setModel(m);
        }
        setSelectedFields(_new);
    }
    const valueChanged = (valuePair) => {
        let m = { ...model, ...valuePair };
        //m[key] = val;
        console.log(m);
        setModel(m)
    }

    useEffect(() => {
        validate();
    }, [model]);
    const validate = () => {
        console.log("validate")
        const keys = selectedFields.map(k => k.toLowerCase());
        let valid = true;
        keys.forEach(key => {
            if (!model[key] || model[key]?.length == 0) {
                valid = false;
            }
        });
        if (!model.plantTypeId) {
            valid = false;
        }

        setModelIsValid(valid);
    }


    const onSave = () => {
        let postObj = {
            id: null,
            plantTypeId: model.plantTypeId,

            fields: {
                ...model,
                searchtext: "",
                public: false,
                planttype: model.plantTypeId,
            }
        };
        delete postObj.fields.plantTypeId;
        PlantOperations.createPlant(postObj).then(response => {
            if (response.success) {
                const id = response.id;
                MessageBoxes.info("Växten skapades");
                nav.push({ pathname: Routes.start });
                console.log(response);
            } else {
                MessageBoxes.warning("Växten skapades inte! " + response.message);
            }
        });
    }

    return (
        <div className='addnewplant'>
            Lägg till ny växt
            {/* {JSON.stringify(plantFields.object)} */}
            <div className='top'>
                <div className='top-item'>
                    <Select
                        defaultValue={{ value: "-", label: "Inget filter" }}
                        options={options}
                        onChange={typeChanged}
                    />
                </div>
                <div className='top-item'>
                    <button
                        className={'btn ' + (modelIsValid ? "btn-enabled" : "btn-disabled")}
                        disabled={!modelIsValid}
                        onClick={onSave}
                    >
                        Spara
                    </button>
                </div>

            </div>
            <hr />
            <TwoOverThreeContainer>
                <div>

                    <table className='tbl'>
                        <tbody>
                            {selectedFields && selectedFields.map(f => (
                                <tr key={f}>
                                    <td title={f}>{plantFields.item(f)?.name}</td>
                                    <td className='col-input'>
                                        <div>
                                            <TableFieldInput field={f} onValueChanged={valueChanged} value={model?.[f]} />
                                        </div>
                                    </td>
                                    <td>
                                        {(!model[f.toLowerCase()] || model[f.toLowerCase()]?.length == 0) && (
                                            <span className='error'>Error</span>
                                        )}
                                    </td>
                                    <td className='r'>
                                        <button className='btn' onClick={() => { toggleField(f); }}>Ta bort</button>
                                    </td>
                                </tr>
                            ))}
                        </tbody>
                    </table>
                    {/* DEBUG */}
                    {/* <div>
                        {modelIsValid ? "valid"  :"Not valid"}
                        {JSON.stringify(model)}
                    </div> */}

                </div>
                <div>
                    <table className='tbl'>
                        <tbody>
                            {typesFields.filter(m => selectedFields.indexOf(m) < 0).map(f => (
                                <tr key={f}>
                                    <td>
                                        <button className='btn' onClick={() => { toggleField(f); }}>
                                            Lägg till
                                        </button>
                                    </td>
                                    <td title={f}>{plantFields.item(f)?.name}</td>
                                    <td>{plantFields.item(f)?.description}</td>
                                    <td>{plantFields.item(f)?.typeString}</td>
                                    {/* <td>{JSON.stringify( plantFields.item(f))}</td> */}
                                </tr>
                            ))}
                        </tbody>
                    </table>
                </div>
                <div></div>
            </TwoOverThreeContainer>


        </div>
    )
}


const TableFieldInput = ({ field, onValueChanged, value }) => {
    const fieldKey = field?.toLowerCase();
    const f = usePlantFieldData().item(fieldKey);
    const snippetTypes = Data.snippetTypes;
    const snippets = useSnippetData();


    const onChange = (k, v) => {
        let valuePair = {};
        valuePair[k] = v;
        onValueChanged(valuePair);
    }

    const snippetChanged = (e) => {
        console.log("snippetChanged", e);
        const fieldName = e.snippetKey.toLowerCase();
        let valuePair = {};
        valuePair[fieldName] = e.id;
        valuePair[fieldName + "_text"] = e.snippetDescription;
        onValueChanged(valuePair);
    }

    if (snippetTypes[field]) {
        const snippetList = snippets.getOptionsWithKey(field);
        const options = snippetList.map(obj => {
            return {
                id: obj.id,
                snippetKey: obj.snippetKey,
                snippetName: obj.snippetName,
                snippetDescription: obj.snippetDescription,
                value: obj.id,
                label: obj.snippetName
            };
        });

        return (
            <div>
                <Select
                    defaultValue={{ value: "-", label: "Inget filter" }}
                    options={options}
                    onChange={snippetChanged}
                />
            </div>


        )
    }

    if (field === "SUN_EXPOSURE") {
        return (
            <SunExposureSelector onValueChanged={onValueChanged} />
        )
    }

    return (
        <div>
            <PlantField
                fieldKey={fieldKey}
                onValueChanged={onChange}
                value={value}
                hideLabel={true}
                multiLine={fieldKey.includes("descr")}
            />
        </div>
    )
}


const SunExposureSelector = ({ onValueChanged }) => {
    const options = [
        { value: ["SOL"], label: "Sol" },
        { value: ["HALVSKUGGA"], label: "Halvskugga" },
        { value: ["SKUGGA"], label: "Skugga" },
        { value: ["SOL", "HALVSKUGGA"], label: "Sol-halvskugga" },
        { value: ["HALVSKUGGA", "SKUGGA"], label: "Halvskugga-Skugga" },
        { value: ["SOL", "SKUGGA"], label: "Sol-skugga" },
    ];
    const onChanged = (e) => {
        let valuePair: any = {};
        valuePair.sun_exposure = e.value;
        onValueChanged(valuePair);
    }
    return (
        <div>
            <Select
                defaultValue={{ value: "-", label: "Inget filter" }}
                options={options}
                onChange={onChanged}
            />
        </div>
    )
}