import React, { useEffect, useState } from "react"
import { Link } from 'react-router-dom';
import { Config } from "../../Config";
import { FetchHandler } from "../../Handlers/FetchHandler";
import { SortHelper } from "../../Handlers/Helpers";
import { MessageBoxes } from "../../Handlers/MessageBoxes";
import { Routes } from "../../Handlers/ViewHandler";
import { useReduxState } from "../../hooks/reduxHook";
import { useDateHelper } from "../../hooks/useHelper";
import { AdminIcon, AdminIcons } from "../Shared/AdminIcon";
import { CustomColumnContainer, ThreeColumnContainer } from "../Shared/Formelements";
import { LoadingBar, ScreenHeader, ScreenMenu } from "../UI/Elements";
import { JwtImage } from "../UI/ImageElements";
import { useStatisticsMenu } from "./useStatisticsMenu";

export const UserStatistics = () => {

    const ui = useUserStatistics();
    const dh = useDateHelper();
    const menu = useStatisticsMenu();

    return (
        <div>
            <ScreenHeader text="Användarstatistik" icon={AdminIcons.chart} />
            <ScreenMenu items={menu?.items} />
            <div>
                <div style={{ display: "flex" }}>
                    <button className="btn" onClick={ui.load}>
                        <AdminIcon icon={ui.loading ? AdminIcons.refreshspin : AdminIcons.refresh} />
                    </button>

                    <div className="taglist">
                        Versioner med antal:
                        {ui.versionInfo?.map(item => (
                            <span key={item?.version} className="tag">
                                {item?.version?.includes("ios") ? (<AdminIcon icon={AdminIcons.apple} />) : (<AdminIcon icon={AdminIcons.android} />)}:
                                {item?.version?.replace('ios:', '')?.replace('android:', '')}:{item?.number}
                            </span>
                        ))}

                    </div>

                </div>
                <hr />
                <div>

                    <table className="tbl tbl-small">
                        <thead>
                            <tr>
                                <th></th>
                                <th>Alias</th>
                                <th>Email</th>
                                <th>Kommun</th>
                                <th>Aktivitet</th>
                                <th>Senast login</th>
                                <th>Märke</th>
                                <th>Skapad</th>
                                <th>Privacy</th>
                                <th>Version</th>
                            </tr>
                        </thead>
                        <tbody>
                            {ui.data?.map(item => (
                                <tr key={item?.id}>
                                    <td>{(!!item.profileimage) ? (<JwtImage
                                        imageId={item.profileimage}
                                        format="thumb"
                                        openOnClick={true}
                                        containerClassName="tbl-profile-image"
                                    />) : (null)}</td>
                                    <td>{item.alias}</td>
                                    <td>
                                        <Link to={"/user/" + item.id}>
                                            {item.email}
                                        </Link>
                                    </td>
                                    <td>{item?.municipality}</td>
                                    <td>{item?.useractivityscore} -
                                        <span style={{ float: "right", paddingRight: "5px" }} title="P:Dagbok, L:Lista, V: Växtplats, G: Trädgårdsbild, X: Foruminlägg, M: placering på karta, O: Deeplink växt" >
                                            {item?.achievement_post_created > 1000 ? "P" : ""}
                                            {item?.achievement_userlist_created > 1000 ? "L" : ""}
                                            {item?.achievement_gardenitem_created > 1000 ? "V" : ""}
                                            {item?.achievement_garden_image_added > 1000 ? "G" : ""}
                                            {item?.achievement_published_post_created > 1000 ? "X" : ""}
                                            {item?.achievement_map_garden_placed > 1000 ? "M" : ""}
                                            {item?.achievement_plant_opened_from_shared > 1000 ? "O" : ""}
                                        </span>
                                    </td>
                                    <td>{dh.timeAgo(item?.lastlogin)}</td>
                                    {/* <td>{item?.flag_hide_ads ? "Göm annonser" : ""}</td> */}
                                    <td>{item?.devicebrand}</td>
                                    <td>{dh.timeAgo(item?.sys_datecreated_utc_epoch)}</td>
                                    <td>{item?.privacyprofile}</td>
                                    <td>{item?.appversion}</td>
                                </tr>
                            ))}
                        </tbody>
                    </table>
                </div>
            </div>
        </div>
    )
}




const useUserStatistics = () => {


    const [versionInfo, setVersionInfo] = useState<VersionIntoType[]>([]);
    const [data, setData] = useState<UserType[]>(undefined);
    const [loading, setLoading] = useState<boolean>(false);

    const load = async () => {
        setLoading(true);
        const d: UserType[] = await FetchHandler.getJson("/api/stats/lastactiveusers");
        setData(d);

        let p = {}
        d.forEach(e => {
            if (!!p?.[e?.appversion]) {
                p[e?.appversion]++;
            } else {
                p[e?.appversion] = 1;
            }
        });
        let lst: VersionIntoType[] = Object.keys(p ?? {}).map(key => { return { version: key, number: p?.[key] }; })
        lst.sort(
            (a: VersionIntoType, b: VersionIntoType): number => {
                const ak = a?.number;
                const bk = b?.number;
                if (ak > bk) { return -1; }
                else if (ak < bk) { return 1; }
                else { return 0; }
            }
        )
        setVersionInfo(lst);
        setLoading(false);
    }

    useEffect(() => {
        load();
    }, [])



    return {
        data: data,
        versionInfo: versionInfo,
        loading: loading,
        load: load,
    }
}

type VersionIntoType = {
    version: string,
    number: number
}

type UserType = {
    id: string
    label: string
    lastlogin: number
    osnotification_token: string
    onboardingstarted?: boolean
    osnotification_checked?: number
    appversion: string
    devicebrand: string
    devicemodel: string
    packageversion: string
    devicesize: string
    systemversion: string
    name: string
    image?: string
    publicprofile: boolean
    profileimage?: string
    profilebackgroundimage?: string
    follows: number
    followers: number
    posts: number
    zone?: string
    onboardingfinished: boolean
    email: string
    username: string
    firstname?: string
    lastname: string
    alias?: string
    alias_lowercase?: string
    biography?: string
    municipality?: string
    municipalitylowercase?: string
    frost?: string
    privacyprofile?: string
    privacygarden?: string
    privacygardenitems?: string
    privacygardenlist?: string
    municipalityvisibility?: string
    useractivityscore: number
    imageid?: string
    sys_datemodified_utc_epoch: number
    partition: string
    sys_datecreated_utc_epoch: number
    achievement_gardenitem_created?: number
    achievement_garden_image_added?: number
    flag_hide_puff_archivedgardenitems?: boolean
    flag_planning_first_launch_pop_shown?: boolean
    flag_hide_under_construction?: boolean
    onboardingdata?: string
    achievement_userlist_created?: number
    achievement_post_created?: number
    achievement_gardenitem_image_added?: number
    achievement_gardenitems_sorted?: number
    achievement_profile_image_added?: number
    flag_plant_hide_propose_change?: boolean
    flag_hide_puff_gardenmap?: boolean
    flag_trial_ends_soon_splash_shown?: boolean
    achievement_published_post_created?: number
    flag_feedtalk_puff_hidden?: boolean
    flag_feed_puff_hidden?: boolean
    flag_feed_access_requested?: boolean
    email_for_feedaccess?: string
    flag_hide_puff_calendarinfo?: boolean
    flag_discovery_hide_propose_change?: boolean
    achievement_map_garden_placed?: number
    achievement_plant_opened_from_shared?: number
    flag_hide_ads?: boolean
    profile_roles?: string
    flag_show_new_gardenmap?: boolean
    flag_discovery_new_show_popular_posts?: boolean
    flag_show_osnotifications?: boolean
    flag_show_affiliate_link?: boolean
    flag_show_new_feed?: boolean
    flag_show_publish_post?: boolean
    flag_show_plant_owners?: boolean
    flag_calendar_show_deleted_activities?: boolean
    flag_plant_show_share_plant?: boolean
    flag_show_new_calendar?: boolean
    flag_addplants_show_state_data?: boolean
    flag_gardenitem_show_tasks?: boolean
    flag_show_new_navigation?: boolean
    achievement_plant_shared?: number
    flag_show_map_debug?: boolean
    flag_view_as_admin?: boolean
    latitude?: number
    longitude?: number
    flag_hide_push_ohgardenmessages?: boolean
    flag_hide_push_like?: boolean
    flag_hide_push_calendarreminders?: boolean
    flag_hide_push_comment?: boolean
    flag_hide_push_friendrequests?: boolean
    flag_hide_push_advertisements?: boolean
    flag_use_ads?: boolean
    flag_show_ads?: boolean
    flag_hide_plantlocation_list_puff?: boolean
    flag_show_no_notification_permissions?: boolean
    flag_show_sort_gardenitems?: boolean
    flag_flag_show_osnotifications?: boolean
    flag_use_gardenuidesign2?: boolean
    privacydenyprofile?: boolean
    privacydenygarden?: boolean
    privacydenygardenitems?: boolean
    privacydenygardenlist?: boolean
    useractivityscore_bonus?: number
}
