import React, { useLayoutEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import { useParams } from 'react-router-dom';
import { FetchHandler } from "../Handlers/FetchHandler";
import { MessageBoxes } from '../Handlers/MessageBoxes';
import { useDateHelper } from '../hooks/useHelper';
import { ForumPostType } from './Forum/Forum';
import { ForumPostCard } from './Forum/ForumPostCard';
import { TwoColumnContainer } from './Shared/Formelements';
import { ShareLinkElement, Spacer } from './UI/Elements';

type analyticsType = {
    userId: string
    numberOfPlots: number,
    numberOfLists: number,
    numberOfImages: number,
    numberOfLikes: number,
    numberOfRates: number,
    numberOfPlants: number,
    numberOfPosts: number,

    numberOfFollowers: number,
    numberOfFollows: number,
    postData: {
        public: number,
        private: number,
        published: number
    }
}

export const User = ({ }) => {
    const { id } = useParams();
    const dispatch = useDispatch();
    const helper = useDateHelper();

    const [model, setModel] = useState<ApiRootObject>(null);
    const [analytics, setAnalytics] = useState<analyticsType>(null);
    const [posts, setPosts] = useState<ForumPostType[]>([]);

    const getUser = async () => {
        var json = await FetchHandler.getJson("/api/userdata/" + id);
        setModel(json);
        var _analytics = await FetchHandler.getJson("/api/useranalytics/" + id);
        setAnalytics(_analytics);
        console.log("analytics: ", analytics)
    }

    useLayoutEffect(() => {
        dispatch(getUser);
        dispatch(userPosts);
    }, []);

    const clicktoCopy = (e: string) => {
        navigator.clipboard.writeText(e);
        MessageBoxes.info("Data '" + e + "' kopierad")
    }

    const userPosts = async () => {
        const r: ForumPostType[] = await FetchHandler.getJson("/api/forum/" + id);
        setPosts(r);
    };


    return (
        <div className='view-user'>
            <div>
                <div>
                    <h1>
                        UserData
                    </h1>
                    <TwoColumnContainer>
                        <div>
                            <label>Partition</label>
                            <code onClick={() => { clicktoCopy(model?.accountPartition) }} className="copyable-text">
                                {model?.accountPartition}
                            </code>
                        </div>
                        <div>
                            <label>Delningslänk</label>
                            <ShareLinkElement id={id} type="user" />
                        </div>
                    </TwoColumnContainer>
                </div>
            </div>
            <hr />
            <div className='analytics'>
                {Object.keys(analytics ?? {}).filter(key => key !== "userId" && key !== "postData").map(key => (
                    <div>
                        <div className='label'>{key}</div>
                        <div className='value'>{analytics?.[key]}</div>
                    </div>
                ))}

                <div>
                    <div className='label'>Inlägg Forum</div>
                    <div className='value'>{analytics?.postData?.published}</div>
                </div>

                <div>
                    <div className='label'>Inlägg publika</div>
                    <div className='value'>{analytics?.postData?.public}</div>
                </div>

                <div>
                    <div className='label'>Inlägg privata</div>
                    <div className='value'>{analytics?.postData?.private}</div>
                </div>
            </div>
            <hr />

            <TwoColumnContainer>
                <PropTable object={model?.accountInfo} title="accountInfo" />
                <PropTable object={model?.garden} title="garden" removeKeys={["GardenItems"]} />
            </TwoColumnContainer>
            <div>
                <h3>GardenItems</h3>
                <table className='tbl'>
                    <thead>
                        <tr>
                            <td>id</td>
                            <td>Namn</td>
                            <td>Beskrivning</td>
                            <td>PrivacyState</td>
                            <td>imageid</td>
                            <td>Skapad</td>
                        </tr>
                    </thead>
                    <tbody>
                        {model?.garden?.GardenItems && model?.garden?.GardenItems.map(item => (
                            <tr key={item?.id}>
                                <td onClick={() => { clicktoCopy(item?.id) }} className="copyable-text">{item?.id}</td>
                                <td onClick={() => { clicktoCopy(item?.Title) }} className="copyable-text">{item?.Title}</td>
                                <td onClick={() => { clicktoCopy(item?.Description) }} className="copyable-text">{item?.Description}</td>
                                <td onClick={() => { clicktoCopy(item?.PrivacyState) }} className="copyable-text">{item?.PrivacyState}</td>
                                <td onClick={() => { clicktoCopy(item?.imageid) }} className="copyable-text">{item?.imageid}</td>
                                <td onClick={() => { clicktoCopy(helper.epochToDateTime(item?.sys_datecreated_utc_epoch)) }} className="copyable-text">{helper.epochToDateTime(item?.sys_datecreated_utc_epoch)}</td>
                            </tr>
                        ))}
                    </tbody>
                </table>
            </div>
            <Spacer.Vertical size="20px" />
            <div className='forum-post-grid'>
                {posts && posts?.map(post => (
                    <ForumPostCard key={post?.id} post={post} />
                ))}
            </div>
        </div>
    )
}

const PropTable = ({ object, title, removeKeys }: { object: any, title: any, removeKeys?: any[] }) => {
    const keysToRemove = removeKeys ? removeKeys : [];
    if (!object) {
        return (null);
    }

    const keys = Object.keys(object);



    return (
        <div>
            <h3>{title}</h3>
            <table className='tbl'>
                <thead>
                    <tr>
                        <th>Key</th>
                        <th>Value</th>
                    </tr>
                </thead>
                <tbody className='userdata-table'>
                    {keys.filter(key => keysToRemove.indexOf(key) < 0).map(k => (
                        <TblRow key={k} object={object} k={k} />
                    ))}
                    <tr></tr>
                </tbody>
            </table>
        </div>
    )

}

const TblRow = ({ object, k }) => {

    const helper = useDateHelper();
    const render = (obj: any): string => {
        if (typeof obj === "string") {
            return obj;
        } else {
            return JSON.stringify(obj);
        }
    }

    const clicktoCopy = (e: string) => {
        navigator.clipboard.writeText(e);
        MessageBoxes.info("Data '" + e + "' kopierad")
    }
    const value = render(object?.[k]);

    return (
        <>
            <tr>
                <td className='userdata-table-label'>{k}</td>
                <td className='userdata-table-value copyable-text' onClick={() => clicktoCopy(render(object?.[k]))}>
                    <EpochEnabledSpan value={value} />
                </td>
            </tr>
            {k === "sys_datecreated_utc_epoch" && (
                <tr>
                    <td className='userdata-table-label'>Skapad</td>
                    <td className='userdata-table-value copyable-text' onClick={() => clicktoCopy(helper.epochToDateTime(object?.[k] as number))}>{helper.epochToDateTime(object?.[k] as number)}</td>
                </tr>
            )}
        </>

    )
}


const EpochEnabledSpan = ({ value }: { value: string }) => {
    const helper = useDateHelper();
    const isEpoch = (e: any) => {
        if (isNaN(e)) {
            return false;
        } else {
            const n = parseInt(e);
            return n > 946684800 && n < 4102444800;
        }
    }

    return (
        <span title={isEpoch(value) ? helper.epochToDateTime(parseInt(value)) : ""}>
            {value}
        </span>
    )
}



interface OnboardingData {
    intresse: number;
    tid: number;
    location: string;
    zone: string;
    frost: string;
    frostcalculated: string;
    knowhow: number;
    types: string[];
    style: string[];
    colors: string[];
    frienemies: string[];
    closeOnNext: boolean;
    alias: string;
}

interface AccountInfo {
    label: string;
    Name: string;
    Image: string;
    PublicProfile: boolean;
    ProfileImage: string;
    ProfileBackgroundImage: string;
    Follows: number;
    Followers: number;
    Posts: number;
    Zone: string;
    OnboardingFinished: boolean;
    OnboardingData: OnboardingData;
    Email: string;
    UserName: string;
    FirstName: string;
    LastName: string;
    Alias: string;
    Biography: string;
    Municipality: string;
    Frost: string;
    PrivacyProfile: string;
    PrivacyGarden: string;
    PrivacyGardenItems: string;
    PrivacyGardenList: string;
    MunicipalityVisibility: string;
    PackageVersion: string;
    AppVersion: string;
    DeviceBrand: string;
    DeviceModel: string;
    DeviceSize: string;
    OnboardingStarted: boolean;
    id: string;
    imageid: string;
    sys_datecreated_utc_epoch: number;
}

interface Line {
    Text: string;
    LineId: string;
    NameLatin?: any;
    SpecificType?: any;
    PlantTypeId?: any;
    PlantId: string;
    Quantity: number;
}

interface GardenItem {
    Title: string;
    Description: string;
    LocationString: string;
    GardenId: string;
    IgnoreWarnings: boolean;
    Lines: Line[];
    NumberOfPlants: number;
    Soil: string[];
    SunExposure: string[];
    PlantIds?: any;
    PrivacyState: string;
    id: string;
    imageid: string;
    sys_datecreated_utc_epoch: number;
}

interface Garden {
    Name: string;
    Description: string;
    Title: string;
    Sorting?: any;
    GardenItems: GardenItem[];
    id: string;
    imageid: string;
    sys_datecreated_utc_epoch: number;
    gardenPartition: string;
}

interface ApiRootObject {
    accountPartition: string;
    accountInfo: AccountInfo;
    garden: Garden;
}

