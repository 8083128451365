import React, { useEffect, useState } from "react"
import { FetchHandler } from "../../Handlers/FetchHandler";
import { MessageBoxes } from "../../Handlers/MessageBoxes";
import { useReduxState } from "../../hooks/reduxHook";
import { AdminIcon, AdminIcons } from "../Shared/AdminIcon";
import { CustomColumnContainer, ThreeColumnContainer } from "../Shared/Formelements";
import { LoadingBar, ScreenHeader, ScreenMenu } from "../UI/Elements";
import { useStatisticsMenu } from "./useStatisticsMenu";




export const CityStatistics = () => {


    const menu = useStatisticsMenu();

    return (
        <div>
            <ScreenHeader text="Statistik" icon={AdminIcons.chart} />
            <ScreenMenu items={menu?.items} />
            <CityStats />

        </div>
    )
}

const CityStats = () => {

    const ui = useCityStats();



    const toPercent = (n: number) => {
        return n ? (Math.round(10 * n) / 10) + "%" : "";
    }
    const addSpacer = (n: number) => {
        return !n ? "" : n.toString().replace(/\B(?=(\d{3})+(?!\d))/g, " ");
    }



    return (

        <div>
            <h2>
                Statistik per kommun
            </h2>

            <LoadingBar loading={ui.loading} />
            <table className="tbl">
                <thead>
                    <tr>
                        <th></th>
                        <th>
                            Kommun
                        </th>
                        <th style={{ textAlign: "right" }}>
                            Användare
                        </th>
                        <th style={{ textAlign: "right" }}>
                            Premiumanvändare
                        </th>


                        <th style={{ textAlign: "right" }}>
                            % Premium av användare
                        </th>
                        <th style={{ textAlign: "right" }}>
                            Invånare
                        </th>

                        <th style={{ textAlign: "right" }}>
                            % användare av invånare
                        </th>
                        <th>&nbsp;</th>
                    </tr>
                </thead>
                <tbody>
                    {ui.data && ui.data?.map((s, idx) => (
                        <tr key={s.city}>
                            <td>
                                {idx + 1}
                            </td>
                            <td>
                                {s?.city}
                            </td>
                            <td style={{ textAlign: "right" }}>
                                {s?.usersInCity}
                            </td>
                            <td style={{ textAlign: "right" }}>
                                {s?.numberOfPremiumUsers}
                            </td>
                            <td style={{ textAlign: "right" }}>
                                {toPercent(s?.premiumPercentageOfUsers)}
                            </td>

                            <td style={{ textAlign: "right" }}>
                                {addSpacer(s?.numberOfCitizensInCity)}
                            </td>
                            <td style={{ textAlign: "right" }}>
                                {toPercent(s?.usersPercentageOfPopulation)}
                            </td>
                            <td>&nbsp;</td>
                        </tr>
                    ))}
                </tbody>
                <tfoot>
                    <tr>
                        <th></th>
                        <th>Summa</th>
                        <th style={{ textAlign: "right" }}>
                            {addSpacer(ui.usersInCity)}
                        </th>
                        <th style={{ textAlign: "right" }}>
                            {addSpacer(ui.premiumUsersInCity)}
                        </th>
                        <th></th>
                        <th style={{ textAlign: "right" }}>
                            {addSpacer(ui.numberOfCitizensInCity)}
                        </th>
                        <th style={{ textAlign: "right" }}>
                            {toPercent(100 * ui.usersInCity / ui.numberOfCitizensInCity)}
                        </th>
                        <th>&nbsp;</th>
                    </tr>
                </tfoot>
            </table>
        </div>
    )

}


type CityDataType = {
    city: string
    usersInCity: number
    premiumPercentageOfUsers: number
    usersPercentageOfPopulation: number
    numberOfCitizensInCity: number
    numberOfPremiumUsers: number
}

const useCityStats = () => {

    const [data, setData] = useState<CityDataType[]>([]);
    const [numberOfCitizensInCity, setNumberOfCitizensInCity] = useState<number>(null);
    const [usersInCity, setUsersInCity] = useState<number>(null);
    const [premiumUsersInCity, setPremiumUsersInCity] = useState<number>(null);

    useEffect(() => {
        FetchHandler.getJson("/api/stats/userstatspercity").then((s: CityDataType[]) => {
            setData(s);
            setNumberOfCitizensInCity(s.reduce((partialSum, a) => partialSum + a.numberOfCitizensInCity, 0));
            setUsersInCity(s.reduce((partialSum, a) => partialSum + a.usersInCity, 0));
            setPremiumUsersInCity(s.reduce((partialSum, a) => partialSum + a.numberOfPremiumUsers, 0));
        });
    }, []);


    return {
        loading: false,
        data: data,
        numberOfCitizensInCity: numberOfCitizensInCity,
        usersInCity: usersInCity,
        premiumUsersInCity: premiumUsersInCity,
    }
}


