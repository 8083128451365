export const efn = {
    getElementDataset: (el, name) => {
                        let i = 0;
                        let str = el.dataset[name];
                        while(!str && i < 30) {
                        el = el.parentElement;
                        str = el.dataset[name];
                        i++;
                        }
                        return str;
                    }
}