import React, { Component, useLayoutEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import { FetchHandler } from "../Handlers/FetchHandler";
import { MessageBoxes } from "../Handlers/MessageBoxes";
import { Dialog } from "./Shared/Dialog";
import { LoadingElement } from "./Shared/LoadingElement";
import QRCode from "qrcode.react";
import { ThreeColumnContainer, TwoColumnContainer } from './Shared/Formelements';
import { DateTime } from 'luxon';
import { AdminIcon, AdminIcons } from './Shared/AdminIcon';
import { ScreenHeader } from './UI/Elements';
import { FlagButton } from './UserList.Components';

interface IProps { }
interface IState {
  loading?: boolean,
  firstName?: string,
  lastName?: string,
  email?: string,
  phoneNumber?: string,
  users?: UserModelType[],
  dialogUser?: any,
  dialogDisabled?: boolean,
  dialogCreateLinkVisible?: boolean,
  dialogCreateLinkEmail?: string,
  roles?: string[],
  selectedRoles?: string[],
  search?: string,
  sortField?: "alias" | "email" | "city" | "userCreatedUtc",
  sortDirection?: "asc" | "desc",
  showDebugUsers?: boolean
}

type UserModelType = {
  id?: string,
  email?: string,
  firstName?: string,
  lastName?: string,
  phoneNumber?: string,
  roles?: string[],
  alias?: string,
  city?: string,
  premiumExpires?: string,
  trialExpires?: number,
  userCreatedUtc?: string
}

export class UserList extends Component<IProps, IState> {
  static displayName = UserList.name;

  constructor(props) {
    super(props);

    this.state = {
      loading: false,
      firstName: "",
      lastName: "",
      email: "",
      phoneNumber: "",
      users: [],
      dialogUser: null,
      dialogDisabled: false,
      dialogCreateLinkVisible: false,
      dialogCreateLinkEmail: "",
      roles: [],
      selectedRoles: [],
      search: "",
      sortField: "email",
      sortDirection: "asc",
      showDebugUsers: false
    };

    this.inputChanged = this.inputChanged.bind(this);
    this.addClicked = this.addClicked.bind(this);
    this.validate = this.validate.bind(this);
    this.deleteClicked = this.deleteClicked.bind(this);
    this.setPasswordClicked = this.setPasswordClicked.bind(this);
    this.createLinkClicked = this.createLinkClicked.bind(this);

    this.dialogRolesclicked = this.dialogRolesclicked.bind(this);
    this.dialogOkclicked = this.dialogOkclicked.bind(this);
    this.roleChanged = this.roleChanged.bind(this);
    this.sortChanged = this.sortChanged.bind(this);
    this.showDebugUsers = this.showDebugUsers.bind(this);
  }

  validate() {
    return this.state.firstName.length *
      this.state.lastName.length *
      this.state.email.length > 0;

  }
  showDebugUsers() {
    this.setState({ showDebugUsers: !this.state.showDebugUsers });
  }

  componentDidMount() {
    this.setState({ loading: true });

    let users = [];
    FetchHandler.getJson("/api/user").then(data => {
      users = data;
      //this.setState({users : data})
    }).then(() => {
      FetchHandler.getJson("/api/user/roles").then(data => {
        this.setState({
          roles: data,
          users: users,
          loading: false
        })
      });
    });
  }
  inputChanged(e) {
    this.setState({ [e.target.name]: e.target.value })
  }
  deleteClicked(e) {
    var id = e.target.dataset.id;
    const email = this.state.users.find(obj => obj.id == id)?.email;
    var confirm = MessageBoxes.confirm("Radera '" + email + "'?");
    if (confirm !== true) { return; }

    FetchHandler.postJson("/api/user/delete", { id: e.target.dataset.id })
      .then(res => {
        FetchHandler.getJson("/api/user").then(data => {
          this.setState({ users: data })
        });
      });
  }
  setPasswordClicked(e) {
    const pwd = MessageBoxes.prompt("Ange nytt lösenord");
    if (pwd.length < 5) {
      MessageBoxes.warning("minst 5 tecken långt");
      return;
    }
    FetchHandler.postJson("/api/user/setpassword", { id: e.target.dataset.id, pwd: pwd })
      .then(res => {
        if (res) {
          MessageBoxes.info("OK");
        } else {
          MessageBoxes.warning("Failed");
        }
      });
  }
  createLinkClicked(e) {
    this.setState({
      dialogCreateLinkVisible: true,
      dialogCreateLinkEmail: e.target.dataset.email
    });
  }
  addClicked() {

    if (
      this.state.users.filter(obj => obj.email === this.state.email).length > 0 ||
      this.state.email.length === 0
    ) {
      MessageBoxes.warning("E-Posten finns redan");
      return;
    }

    const u = {
      firstName: this.state.firstName,
      lastName: this.state.lastName,
      email: this.state.email,
      phoneNumber: this.state.phoneNumber
    };

    // postdata
    FetchHandler.postJson("/api/user/add", u)
      .then(res => {
        if (res) {
          FetchHandler.getJson("/api/user").then(data => {
            this.setState({
              users: data,
              firstName: "", lastName: "",
              email: "", phoneNumber: ""
            })
          });
        } else {
          MessageBoxes.warning("Failed");
        }
      });
  }

  dialogRolesclicked(e) {
    var id = e.target.dataset.id;
    this.setState({
      dialogUser: this.state.users.filter(obj => obj.id === id)[0]
    });
  }

  dialogOkclicked() {
    this.setState({ dialogUser: null });
  }

  // checkbox in dialog changed
  roleChanged(uid, roleName, hasRole) {
    this.setState({ dialogDisabled: true });

    const postObj = {
      uid: uid,
      roleName: roleName,
      hasRole: hasRole
    };

    FetchHandler.postJson("/api/user/roles", postObj).then(response => {
      if (response) {
        var u = this.state.dialogUser;
        if (hasRole) {
          u.roles.push(roleName);
        } else {
          u.roles.splice(u.roles.indexOf(roleName), 1);
        }
        this.setState({ dialogUser: u });
      }
      this.setState({ dialogDisabled: false });
    });
    console.log("user: " + uid + ", role: " + roleName + ", checked: " + hasRole);
  }

  sortChanged(sort: IState) {

    const f = sort.sortField;
    const dir = this.state.sortField == sort.sortField && this.state.sortDirection == "asc" ? "desc" : "asc";
    let u = [...this.state.users];

    u.sort((a, b) => {
      if (a?.[f] > b?.[f]) { return dir == "asc" ? 1 : -1; }
      else if (a?.[f] < b?.[f]) { return dir == "asc" ? -1 : 1; }
      else { return 0 }
    });

    this.setState({ users: u, sortField: f, sortDirection: dir });
  }

  render() {
    return (
      <div>
        <ScreenHeader text="Användare" icon={AdminIcons.users} />
        <div className="form-container">
          <FormTextInput title="Förnamn" name="firstName" value={this.state.firstName} onChange={this.inputChanged} />
          <FormTextInput title="Efternamn" name="lastName" value={this.state.lastName} onChange={this.inputChanged} />
          <FormTextInput title="E-post" name="email" value={this.state.email} onChange={this.inputChanged} />
          <FormTextInput title="Telefon" name="phoneNumber" value={this.state.phoneNumber} onChange={this.inputChanged} />

          <div className="form-item">
            <label>&nbsp;</label>
            <button onClick={this.addClicked} className="btn">Lägg till</button>
          </div>

        </div>

        <hr />

        <div>
          <FormTextInput title="Filtrera E-Post" name="search" value={this.state.search} onChange={this.inputChanged} />
          <RoleSelector allRoles={this.state.roles} value={this.state.selectedRoles} onChange={e => this.setState({ selectedRoles: e })} />

          <button className='btn' onClick={this.showDebugUsers}>Debug</button>
        </div>

        <hr />
        <table className="tbl userlist">
          <thead>
            <tr>
              <th className='cursor-pointer' onClick={() => { this.sortChanged({ sortField: "alias" }); }}>Alias</th>
              <th className='cursor-pointer' onClick={() => { this.sortChanged({ sortField: "email" }); }}>E-post</th>
              <th className='cursor-pointer' onClick={() => { this.sortChanged({ sortField: "city" }); }}>Kommun</th>
              <th className='cursor-pointer' onClick={() => { this.sortChanged({ sortField: "userCreatedUtc" }); }}>Skapad</th>
              <th>Roller</th>
              <th></th>
              <th></th>
              <th></th>
            </tr>
          </thead>
          <tbody>
            {this.state.users.filter(u => {

              if (this.state.showDebugUsers) {
                const e = u.email.toLowerCase();
                if (!e.endsWith("gmail.com")) {
                  return false;
                }


                let regexp = /[a-d]/gi;
                return e.match(regexp)?.length > 0;

              } else if (this.state.search) {
                // console.log("filter:' " + u.email.toLowerCase() + "', '" + this.state.search.toLowerCase() + "'");
                if (u.email.toLowerCase().includes(this.state.search.toLowerCase()) == false) {
                  return false;
                }
              }

              if (this.state.selectedRoles?.length > 0) {
                return this.state.selectedRoles.filter(r => u.roles.indexOf(r) >= 0)?.length > 0;
              } else {
                return true;
              }
            }).map(item => (
              <tr key={item.email}>
                <td>{item.alias}</td>
                <td>
                  <Link to={"/user/" + item.id} >
                    {item.email}
                  </Link>
                </td>
                <td>{item.city}</td>
                <td>{item.userCreatedUtc ? DateTime.fromISO(item.userCreatedUtc).toFormat("yyyy-LL-dd HH:mm:ss") : "-"}</td>
                <td>{item.roles.map(r => (<span className="roleName" key={r}>{r} </span>))}</td>
                <td>
                  <button data-id={item.id} className="btn" onClick={this.deleteClicked}>Delete</button>
                  <button data-id={item.id} className="btn" onClick={this.setPasswordClicked}>Lösenord</button>
                  <button data-id={item.id} data-email={item.email} className="btn" onClick={this.createLinkClicked}>Skapa länk</button>
                </td>
                <td>
                  <button className="btn" onClick={this.dialogRolesclicked} data-id={item.id}>Roller</button>
                </td>

                <td>
                  <FlagButton userId={item?.id} />
                </td>
              </tr>
            ))}
          </tbody>
        </table>
        <div>
          <LoadingElement visible={this.state.loading} />
        </div>

        {this.state.dialogUser && (
          <Dialog onOk={this.dialogOkclicked} disabled={this.state.dialogDisabled}>
            <div>
              <p>Lägg till och ta bort roller</p>
              {this.state.roles.map(r => (
                <div key={r}>
                  <DialogCheckBox
                    dialogUserId={this.state.dialogUser.id}
                    role={r}
                    userroles={this.state.dialogUser.roles}
                    roleChanged={this.roleChanged}
                  />
                </div>
              ))}
            </div>
          </Dialog>
        )}

        <DialogCreateLink
          email={this.state.dialogCreateLinkEmail}
          visible={this.state.dialogCreateLinkVisible}
          onClose={() => { this.setState({ dialogCreateLinkVisible: false }); }}
        />

      </div>
    );
  }
}


export const DialogCreateLink = ({ email, visible, onClose }) => {
  const [hours, setHours] = useState(12);
  const [linkUrl, setLinkUrl] = useState(null);
  if (!visible) {
    return (null);
  };

  const createLinkClicked = () => {
    const postObj = {
      email: email,
      hours: hours,
    };
    FetchHandler.postJson("/api/user/createlink", postObj).then(response => {
      console.log("postObj:", postObj);
      console.log("response:", response);
      setLinkUrl(response.loginLink);
    })
  }
  const closeClicked = () => {
    setLinkUrl(null);
    if (onClose) {
      onClose();
    }
  }

  return (
    <Dialog
      onCancel={closeClicked}
      cancelButtonText="Stäng"
      width="800px"
    >
      <div>
        Konto: {email}
      </div>
      <ThreeColumnContainer>
        <div>
          <div>
            <label>Giltighetstid</label>
          </div>
          <div>
            <select value={hours} onChange={(e) => { setHours(parseInt(e.target.value)); console.log(e.target.value) }}>
              <option value="12">12 timmar</option>
              <option value="24">1 dagar</option>
              <option value="48">2 dagar</option>
              <option value="96">4 dagar</option>
              <option value="192">8 dagar</option>
            </select>
          </div>
        </div>
        <div>
          <div>
            <button className="btn" onClick={createLinkClicked}>
              Skapa länk
            </button>
          </div>
        </div>

      </ThreeColumnContainer>
      {linkUrl && (
        <div className='create-link-container'>
          <div className='link-container' onClick={() => {
            navigator.clipboard.writeText(linkUrl);
            MessageBoxes.info("Länk '" + linkUrl + "' kopierad")
          }}
          >
            {linkUrl}
          </div>
          <div className='qr-code-container'>
            <QRCode value={linkUrl} size={256} />
          </div>
        </div>
      )}


    </Dialog>

  )

}

const DialogCheckBox = (props) => {
  const eId = "r-" + props.role;
  const hasrole = props.userroles.filter(obj => obj === props.role).length > 0;
  return (
    <div>
      <input
        type="checkbox"
        name={props.role}
        value={props.role}
        id={eId}
        onChange={(e) => { props.roleChanged(props.dialogUserId, props.role, e.target.checked); }}
        checked={hasrole}
      />
      <label
        htmlFor={"r-" + props.role}
        style={{ paddingLeft: "5px" }}
      >{props.role}</label>

    </div>);
}

// <FormInput title="firstname" name="firstName" value={this.state.firstName}  onChange={this.inputChanged} />
const FormTextInput = (props) => {
  return (
    <div className="form-item">
      <label>{props.title}</label>
      <input type="text" name={props.name} value={props.value} onChange={props.onChange} />
    </div>
  );
}

const RoleSelector = ({ allRoles, value, onChange }: { allRoles: string[], value: string[], onChange(e: string[]): void }) => {

  const [roleList, setRoleList] = useState([]);
  const [selected, setSelected] = useState(value);

  useLayoutEffect(() => {
    if (allRoles) {
      setRoleList(allRoles);
    }
  }, [, allRoles]);

  const toggleRole = (role) => {
    let n = [...selected];
    if (n.indexOf(role) < 0) {
      n.push(role)
    } else {
      n.splice(n.indexOf(role), 1);
    }
    setSelected(n);
    onChange(n);
  }

  return (
    <div className='snippets-tag-container'>
      {roleList && roleList?.map(r => (
        <div key={r} className={'snippets-tag ' + (selected.indexOf(r) < 0 ? "" : "selected")} onClick={() => { toggleRole(r) }}>
          {r}
        </div>
      ))}
    </div>
  )
}

