import React from "react";
import { useState } from "react";
import { FetchHandler } from "../Handlers/FetchHandler";
import { Dialog } from "./Shared/Dialog";

export const FlagButton = ({ userId }: { userId: string }) => {
    const handler = useFlagButton(userId);
    const [dialogVisible, setDialogVisible] = useState(false);

    const onShowDialogClicked = () => {
        setDialogVisible(true);
        handler.load();
    }

    return (
        <>
            <button className="btn" onClick={onShowDialogClicked}>Flaggor</button>


            {dialogVisible && (
                <Dialog disabled={handler.isLoading} onOk={() => setDialogVisible(false)} >
                    <div>
                        <table className="tbl">
                            <thead>
                                <tr>
                                    <th>Flagga</th>
                                    <th>Nuvarande värde</th>
                                    <th>Ändra</th>
                                </tr>
                            </thead>
                            <tbody>
                                {handler?.data?.map(item => (
                                    <tr key={item?.flag}>
                                        <td>{item?.flag}</td>
                                        <td>{JSON.stringify(item?.value)}</td>
                                        <td>
                                            <input type={"checkbox"} checked={item?.value} onChange={e => {
                                                handler.setFlag(item?.flag, e.target.checked)
                                            }} />
                                        </td>
                                    </tr>
                                ))}
                            </tbody>
                        </table>
                    </div>
                </Dialog>
            )}

        </>
    )
}

const useFlagButton = (userId: string) => {

    const [isLoading, setIsLoading] = useState(false);
    const [data, setData] = useState<FlagValueType[]>(null);


    const load = async () => {
        setIsLoading(true);
        const url = "/api/userdata/" + userId + "/flags";
        const json = await FetchHandler.getJson(url);
        const base = {
            "show_new_feed": null,
        }
        let d: FlagValueType[] = Object.keys({ ...base, ...json }).map(key => { return { flag: key as ApplicationFlag, value: json[key] } })
        d.sort((a, b) => {
            if (a?.flag > b?.flag) { return -1; }
            else if (a?.flag < b?.flag) { return 1; }
            else { return 0; }
        });
        setData(d);
        setIsLoading(false);
    }

    const setFlag = async (flag: ApplicationFlag, value: boolean) => {
        const url = "api/userdata/" + userId + "/flags";
        const postObj = {
            flag: flag,
            value: value
        };
        const success = await FetchHandler.postJson(url, postObj);
        await load();
    }

    return {
        isLoading: isLoading,
        load: load,
        data: data,
        setFlag: setFlag
    }
}

// --------------------------- Application flags ---------------------------
type FlagValueType = {
    flag: ApplicationFlag,
    value: boolean
}
type ApplicationFlag =
    "gardenitem_show_tasks" |
    "plantbank_queryconstructor_show" |
    "discovery_hide_propose_change" |
    "plant_hide_propose_change" |
    "addplants_show_state_data" |
    "show_new_calendar" |
    "plant_show_share_plant" |
    "calendar_show_deleted_activities" |
    "show_plant_owners" |
    // "show_new_navigation" |
    "show_publish_post" |
    "show_sort_gardenitems" |
    "show_new_feed" |

    "hide_under_construction" |
    "hide_puff_calendarinfo" |


    "none";