import React, { useEffect, useLayoutEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import Select from 'react-select';
import { Link } from 'react-router-dom';
import { Data } from '../../Handlers/Data';
import { Reloads } from '../../rdx/StoreFillerHandler';
import { RdxStore } from '../../rdx/ReduxTypes';
import { TwoColumnContainer } from '../Shared/Formelements';
import { PlantListType } from '../../rdx/Redux.DataReducerType';
import { DragDropContainer, DropTarget } from 'react-drag-drop-container';
import { TrashIcon } from '../Shared/Icons';
import { useOnboardingLists } from './useOnboardingLists';
import { AdminIcon, AdminIcons } from '../Shared/AdminIcon';
import { ScreenHeader } from '../UI/Elements';

export const OnboardingLists = () => {
    const handler = useOnboardingLists();
    useEffect(() => {
        Reloads.reloadPlantlists();
    }, [])


    return (
        <div className='onboardinglists'>
            <ScreenHeader text=" Listor från onboarding" icon={AdminIcons.questionCircle} />
            <p>&nbsp;</p>

            <TwoColumnContainer>
                {/* Frågealternativ */}
                <div>
                    <div className='unselectable sticky'>

                        <div className='toolbar'>
                            {handler.questionOptions.map(s => (
                                <button
                                    key={s.value}
                                    className={'btn ' + (s.value === handler.selectedQuestion ? "selected" : "")}
                                    onClick={(e) => handler.onSelectedQuestionChange(s.value)}
                                    disabled={s.value === handler.selectedQuestion}
                                >{s.label}</button>
                            ))}
                        </div>
                        <div className='keyword-container'>
                            {handler.selectedQuestionOptions.map(m => (
                                <OnboardingAlternativeItem key={m.value} label={m.label} value={m.value}
                                    onListDropped={(data: PlantListType) => handler.listDroppedOnAlternative(m.value, data)}
                                    onRemove={(listId) => { handler.listRemovedFromAlternative(m.value, listId) }}
                                    lists={handler.getAlternativeLists(m.value)}
                                />
                            ))}
                        </div>
                    </div>
                </div>


                {/* Växtlistor */}
                <div className='unselectable'>
                    {handler.array.map(l => (
                        <ListItem key={l.id}
                            item={l}
                            alternatives={handler.alternativesAssociated(l.id)}
                        />
                    ))}
                </div>

            </TwoColumnContainer>
        </div>
    )
}

const OnboardingAlternativeItem = ({ label, value, onListDropped, onRemove, lists }: { label: string, value: string, onListDropped: any, onRemove(e: string): void, lists: PlantListType[] }) => {
    const [hovering, setHovering] = useState(false);
    const onDragEnter = (e) => {
        setHovering(true);
    }
    const onDragLeave = (e) => {
        setHovering(false);
    }
    const onHit = (e) => {
        const data = e?.dragData as PlantListType;
        console.log("list '" + data?.name + "' dropped on '" + label + "'.");
        setHovering(false);
        if (onListDropped) {
            onListDropped(data);
        }
    }

    return (
        <DropTarget
            onHit={onHit}
            onDragEnter={onDragEnter}
            onDragLeave={onDragLeave}
            targetKey="list" >
            <div className={"keyword " + (hovering ? "hovering" : "")}>
                <div className='title'>{label}</div>
                {lists && lists.map(l => (
                    <DroppedPLantList key={l.id} item={l} onRemove={() => {
                        if (onRemove) {
                            onRemove(l.id);
                        }
                    }}

                    />
                ))}
            </div>
        </DropTarget>
    )
}

const DroppedPLantList = ({ item, onRemove }: { item: PlantListType, onRemove(): void }) => {
    return (
        <div className='item'>
            {item.name + " (" + item.numberOfPlants + ")"}
            <div className='remove' onClick={onRemove}>
                <TrashIcon title='Ta bort' />
            </div>
        </div>
    )
}



const ListItem = ({ item, alternatives }: { item: PlantListType, alternatives: string[] }) => {
    const onDrop = (e) => {
        //console.log("dropped:", e);
    }
    const [dragging, setDragging] = useState(false)
    const onDragEnd = () => { setDragging(false) }
    const onDragStart = () => { setDragging(true) }

    return (
        <div>
            <DragDropContainer
                targetKey="list"
                dragData={item}
                onDrop={onDrop}
                onDragStart={onDragStart}
                onDragEnd={onDragEnd}
            >
                <div className={"listitem " + (dragging ? "dragging" : "")}>
                    <div className='name'>{item?.name}</div>
                    <div className='description'>{item?.description}
                        {(item?.tags?.length > 0) && (
                            <span className='tags'>| tags: ({item?.tags?.join(", ")})  </span>
                        )}
                    </div>
                    <div className='alternatives'>{alternatives?.join(',')}</div>
                    <div className='numberOfPlants'>{item?.numberOfPlants}</div>
                </div>
            </DragDropContainer>
        </div>
    )
}

