import React from 'react';
import { useSelector } from 'react-redux';
import { CHANGE_TOASTS } from "../rdx/appReducer";
import { ToastCollectionType } from '../rdx/ReduxTypes';
import store from "../rdx/store";

let currentId = 0;
const getNewId = () => {
    return "toastid_" + currentId++;
}

const sendNewAlert = (type, txt, keepOpen) => {
    let toasts: ToastCollectionType = { ...store.getState().app.toasts };
    const toast = { id: getNewId(), type: type, text: txt };
    toasts[toast.id] = toast;
    store.dispatch({ type: CHANGE_TOASTS, payload: toasts });
    if (keepOpen !== true) {
        setTimeout(() => {
            closeAlert(toast.id);
        }, 4000);
    }
}
const closeAlert = (id: string) => {
    let toasts = { ...store.getState().app.toasts };
    delete toasts[id];
    store.dispatch({ type: CHANGE_TOASTS, payload: toasts });
    return;

    // let toasts = {...store.getState().app.toasts};
    // if(toasts[id]) {
    //     toasts[id].closing = true;
    //     store.dispatch({ type: CHANGE_TOASTS, payload: toasts});
    // }
    // setTimeout(() => {
    //     let toasts = {...store.getState().app.toasts};
    //     delete toasts[id];
    //     store.dispatch({ type: CHANGE_TOASTS, payload: toasts});
    // }, 500);
}

const MessageBoxes = {
    // type: "error","warning", "info". default: "info"

    info: (txt: string, keepOpen?: boolean) => {
        sendNewAlert("info", txt, keepOpen);
        //return window.alert(txt); 
    },
    warning: (txt: string, keepOpen?: boolean) => {
        sendNewAlert("warning", txt, keepOpen);
        //return window.alert(txt); 
    },
    successAlert: (success, txt) => {
        if (success) {
            MessageBoxes.info(txt)
        } else {
            MessageBoxes.warning("Operationen misslyckades!")
        }
    },

    confirm: (txt) => { return window.confirm(txt); },

    prompt: (txt) => {
        const ret = window.prompt(txt);
        if (ret) { return ret; }
        else { return ""; }
    }
}

export { MessageBoxes };


export const MessageBoxContainer = () => {
    const toastsObj = useSelector(state => state.app.toasts);
    const toasts = Object.keys(toastsObj).map(m => toastsObj[m]);

    return (
        <div className="msgbox-list">
            {toasts && toasts.map(toast => (
                <MessageBoxItem key={toast.id} {...toast} />
            ))
            }
        </div>
    )
}

const MessageBoxItem = ({ id, type, text, closing }) => {
    const title = type === "info" ? "Info" :
        type === "warning" ? "Varning" : "N/A";

    return (
        <div className={"animate__animated animate__bounceInDown msgbox-list-item " + type} onClick={() => closeAlert(id)}>
            <div className="title">{title}</div>
            <div className="text">{text}</div>
        </div>
    )
}
