import React, { useState } from "react"

import { DropTarget } from 'react-drag-drop-container';
import { Link } from 'react-router-dom';
import { Routes } from "../../Handlers/ViewHandler";
import { useDateHelper } from "../../hooks/useHelper"
import { useUsers } from "../../hooks/useUsers"
import { ClipBoardDragContainer } from "../ClipBoard/ClipBoard";
import { ClipBoardDropTargetType } from "../ClipBoard/useClipBoard";
import { AdminIcon, AdminIcons } from "../Shared/AdminIcon"
import { ThreeColumnContainer } from "../Shared/Formelements"
import { ActionToolsList, ToolBar } from "../ToolBar/ToolBar"
import { ScreenHeader } from "../UI/Elements"
import { JwtImage } from "../UI/ImageElements"
import { Overlay } from "../UI/Overlay"
import { ApiPostWithImages, useSocialImageOperations } from "./useSocialImageOperations"

export const SocialImageOperations = () => {

    const ui = useSocialImageOperations();

    const getTools = () => {
        if (ui.viewState === "selected") {
            return [
                { id: "3", label: "Välj bilder", icon: AdminIcons.eye, onClick: () => { ui.setViewState("filtering") } },
            ]
        } else {
            return [
                { id: "-1", label: "Ladda om sidan", icon: AdminIcons.refresh, onClick: ui.load, },
                { id: "0", label: "Första sidan", icon: AdminIcons.house, onClick: ui.goFirstPage, },
                { id: "1", label: "Föregående sida", icon: AdminIcons.arrowleft, onClick: ui.goPrev, disabled: ui.disableGoPrev, },
                { id: "2", label: "Nästa sida", icon: AdminIcons.arrowright, onClick: ui.goNext, disabled: ui.disableGoNext, },

                { id: "3", label: "Visa valda", icon: AdminIcons.eye, onClick: () => { ui.setViewState("selected") } },
            ]
        }
    }
    const tools = getTools();




    return (
        <div className="social-image-operations">
            <Overlay visible={ui.loading} />
            <ScreenHeader text="Inläggs bilder" icon={AdminIcons.images} />
            <p>
                Bilder från inlägg från öppna användare
            </p>
            <div>
                <ToolBar tools={tools} />
            </div>

            <SmallTools ui={ui} />

            <hr />
            {ui.viewState === "filtering" && (
                <ThreeColumnContainer gridTemplateColumns="auto">
                    <div>
                        <h2>
                            Alla
                        </h2>
                        <div className="postimages-grid">
                            {ui?.data?.filter((img, i) => i < 50)?.map(item => (
                                <PostImage
                                    key={item?.imageid}
                                    item={item}
                                    selected={ui.selectedImages?.findIndex(m => m?.imageid === item?.imageid) >= 0}
                                    onSelect={() => { ui.toggleSelect(item) }}
                                    showSelectionToggle={true}
                                    showIfSelected={true}
                                    openClipBoardOnDrag={false}
                                    enableMenu={true}
                                    onToggleFeatured={async () => {
                                        return await ui.onToggleFeatured(item?.postid, !item?.post?.featured);
                                    }}
                                />
                            ))}
                            {!ui.disableGoNext && (
                                <div className="userplantimagecard-ghost">
                                    <button className="btn" onClick={ui.goNext}>
                                        Nästa sida
                                        <AdminIcon icon={AdminIcons.arrowright} />
                                    </button>
                                </div>
                            )}
                        </div>
                    </div>
                    {/* <div>
                        <div className="sticky0">
                            <h2>
                                Valda
                            </h2>
                            <div className="postimages-grid">
                                {ui?.selectedImages?.map(item => (
                                    <PostImage
                                        key={item?.imageid}
                                        item={item}
                                        selected={ui.selectedImages?.findIndex(m => m?.imageid === item?.imageid) >= 0}
                                        onSelect={() => { ui.toggleSelect(item) }}
                                        showSelectionToggle={true}
                                    />
                                ))}
                            </div>
                        </div>
                    </div> */}


                </ThreeColumnContainer>

            )}
            {ui.viewState === "selected" && (
                <ThreeColumnContainer gridTemplateColumns="auto 250px">
                    <div>
                        <h2>
                            Valda
                        </h2>
                        <div className="postimages-grid">
                            {ui?.selectedImages?.map(item => (
                                <PostImage
                                    key={item?.imageid}
                                    item={item}
                                    selected={ui.selectedImages?.findIndex(m => m?.imageid === item?.imageid) >= 0}
                                    onSelect={() => { ui.toggleSelect(item) }}
                                    showSelectionToggle={true}
                                    openClipBoardOnDrag={true}
                                />
                            ))}
                        </div>

                    </div>
                    <div>
                        <h2>Åtgärder</h2>
                        <ActionToolsList tools={[{ id: "0", label: "Gå till upptäck", icon: AdminIcons.eye, navigationLink: Routes.discover },]} />
                    </div>
                </ThreeColumnContainer>
            )}

        </div>
    )
}

export const PostImage = ({ item, selected, onSelect, showSelectionToggle, showIfSelected, openClipBoardOnDrag, enableMenu, onToggleFeatured }: { item: ApiPostWithImages, selected: boolean, onSelect(): void, showSelectionToggle: boolean, showIfSelected?: boolean, openClipBoardOnDrag: boolean, enableMenu?: boolean, onToggleFeatured?(): Promise<boolean> }) => {

    const dh = useDateHelper();
    const [menuopen, setMenuopen] = useState(false);

    const u = useUsers()?.getUser(item?.post?.userid);
    return (
        <ClipBoardDragContainer type={"postimage"} dragData={item} openClipBoardOnDrag={openClipBoardOnDrag}>
            <div className={"postimage-card " + (item?.post?.poststate === "public" ? "public" : "published") + ((showIfSelected && selected) ? " selected" : "")}>
                <div className="type">
                    <span onClick={enableMenu ? () => { setMenuopen(true) } : null}>
                        {item?.post?.poststate === "public" ? "Dagbok" : "Forum"}
                    </span>
                    {item?.post?.featured ? (<AdminIcon className="featured" icon={AdminIcons.fire} />) : (null)}
                </div>
                {(!!enableMenu && menuopen) && (
                    <div className="postimage-card-menu">
                        <div className="postimage-card-menu-item" onClick={() => { setMenuopen(false); }}>
                            <div>Stäng meny</div>
                            <div className="ico-c">
                                <AdminIcon icon={AdminIcons.close} size="lg" />
                            </div>
                        </div>
                        <div className="postimage-card-menu-item" onClick={async () => {
                            if (onToggleFeatured) {
                                await onToggleFeatured();
                            }
                            setMenuopen(false);
                        }} >
                            <div>Featured</div>
                            <div className="ico-c">
                                <AdminIcon icon={item?.post?.featured ? AdminIcons.toggle_on : AdminIcons.toggle_off} size="lg" />
                            </div>
                        </div>
                    </div>
                )}
                <JwtImage imageId={item?.imageid} format={"thumb"} openOnClick={true} imageText={item?.post?.text} />
                <div className="name">
                    <Link to={Routes.getUserView(item?.userid)}>
                        {u?.name}
                    </Link>
                </div>
                <div className="text" title={item?.post?.text}>
                    {item?.post?.text}
                </div>
                <div className="date">
                    {dh.epochToDateTime(item?.post?.epochposted)}
                </div>
                <div className="stats">
                    <div>
                        <AdminIcon icon={AdminIcons.leaf} /> {item?.post?.plantids?.filter(m => !!m)?.length}
                    </div>
                    <div>
                        <AdminIcon icon={AdminIcons.heart} />  {item?.post?.numberoflikes}
                    </div>
                    <div>
                        <AdminIcon icon={AdminIcons.comment} />  {item?.post?.numberofcomments}
                    </div>
                </div>
            </div>
        </ClipBoardDragContainer>
    )
}

const DropArea = ({ type, dataDropped }: { type: ClipBoardDropTargetType, dataDropped(e: ApiPostWithImages): void }) => {

    const [hovering, setHovering] = useState(false);
    const onDragEnter = (e) => {
        if (e) {
            setHovering(true);
        }
    }
    const onDragLeave = (e) => {
        setHovering(false);
    }
    const onHit = (e) => {
        const data = e?.dragData as ApiPostWithImages;
        setHovering(false);
        dataDropped(data);
    }


    return (


        <DropTarget
            onHit={onHit}
            onDragEnter={onDragEnter}
            onDragLeave={onDragLeave}
            targetKey={type} >
            <div className={"drop-target" + (hovering ? " hovering" : "")}>

            </div>
        </DropTarget>
    )
}

const SmallTools = ({ ui }) => {
    return (

        <ThreeColumnContainer gridTemplateColumns="120px 100px 200px 200px auto">
            <div>
                Bilder: {ui.totalNumberOfHits} st
            </div>
            <div>
                Sida: {ui.page + 1}/{ui.numberOfPages}
            </div>
            <div>
                <select defaultValue={ui.pageSize} onChange={e => ui.setPageSize(parseInt(e.target.value))}>
                    <option value={10}>10</option>
                    <option value={25}>25</option>
                    <option value={50}>50</option>
                </select>
            </div>
            {/* <div>
                <input className="default-input" type={"search"} defaultValue={ui.textFilter} onChange={e => ui.setTextFilter(e.target.value)} />
            </div> */}
        </ThreeColumnContainer>

    )
}

