import React, { useState } from 'react';
import Select from 'react-select'
import { useSelector } from 'react-redux';
import { CheckboxCheckedIcon, CheckboxUnCheckedIcon, MoveRightIcon, MoveLeftIcon } from "../../Shared/Icons";
import { useColors } from '../../../Handlers/Hooks';

// props
//      value
//      onChange
//      fieldType: uppercase or lowercase
export const FieldInput = {
    Text: ({ value, onChange, multiLine }) => {
        const [_value, setValue] = useState(value ? value : "");
        const singleLine = !value || value.length < 50;
        const _onChange = (e) => {
            var val = e.target.value;
            setValue(val);
            onChange(val);
        }

        if (singleLine && !multiLine) {
            return (<input
                type="text"
                className="w100"
                autoComplete="off"
                value={_value}
                onChange={_onChange}
            />);
        } else {
            return (<textarea
                className="w100"
                autoComplete="off"
                value={_value}
                onChange={_onChange}
            />);
        }
    },
    Integer: (props) => {
        const [value, setValue] = useState(props.value ? props.value : "");
        return (<input
            type="number"
            min="0"
            step="1"
            value={value}
            onChange={e => {
                var val = parseInt(e.target.value);
                setValue(val);
                props.onChange(val);
            }}
        />);
    },
    Decimal: (props) => {
        const [value, setValue] = useState(props.value ? props.value : "");
        return (<input
            type="number"
            min="0"
            step="0.01"
            value={value}
            onChange={e => {
                var val = parseFloat(e.target.value);
                setValue(val);
                props.onChange(val);
            }} />);
    },
    SingleSelect: (props) => {
        const fieldType = props.fieldType;
        const settings = useSelector(state => state.data.plantfieldsobject[fieldType.toLowerCase()]);
        if (!settings || !settings.selectOptions) {
            return (null);
        }

        const options = settings.selectOptions.map(o => { return { value: o.key, label: o.name } });
        const selectedOption = options.filter(o => {
            if (!props) { return null; }
            if (!props.value) { return null; }
            return o.value === props.value[0];
        });

        return (
            <Select
                value={selectedOption}
                options={options}
                onChange={e => { props.onChange([e.value]) }}
                menuPortalTarget={document.body}
                styles={{ menuPortal: base => ({ ...base, zIndex: 9999 }) }}
            />
        );
    },
    MultiSelect: (props) => {
        const fieldType = props.fieldType;
        const [value, setValue] = useState(props.value);
        const settings = useSelector(state => state.data.plantfieldsobject[fieldType.toLowerCase()]);
        if (!settings) {
            return (null);
        }

        const options = settings.selectOptions.filter(obj => { return obj.selectable ? true : false; }).map(o => { return { value: o.key, label: o.name } });
        const selectedOption = options.filter(o => {
            if (!value) { return null; }
            return value.indexOf(o.value) >= 0;
        });
        const handleChange = (e) => {
            var v = e.map(o => o.value);
            setValue(v);
            props.onChange(v)
        };
        return (
            <Select
                value={selectedOption}
                options={options}
                isMulti={true}
                onChange={handleChange}
                menuPortalTarget={document.body}
                styles={{ menuPortal: base => ({ ...base, zIndex: 9999 }) }}
            />
        );
    },
    Color: (props) => {
        let value = props.value;
        if (Object.prototype.toString.call(value) === "[object String]") {
            value = JSON.parse(props.value);
        }
        const colors = useColors();
        const [selectedColors, setSelectedColors] = useState(Array.isArray(value) ? value : [value]);
        const [open, setOpen] = useState(false);

        const colorClick = (id) => {
            let val = [];
            if (selectedColors.indexOf(id) < 0) {
                val = selectedColors.concat([id]).filter(o => { return o !== null; });
            } else {
                val = selectedColors.filter(c => c !== id).filter(o => { return o !== null; });
            }
            setSelectedColors(val);
            props.onChange(JSON.stringify(val));
        }

        return (
            <div className="color-picker">

                {colors.map(color => (
                    <div key={color.id}
                        className={("color-picker-field ") +
                            (selectedColors.indexOf(color.id) >= 0 ? "selected " : (open === false ? "hidden " : ""))

                        }
                        onClick={() => { colorClick(color.id); }}>
                        <div className="color" style={{ backgroundColor: color.hexNumber }}></div>
                        <div className="label">{color.name}</div>
                    </div>
                ))}
                <div className="color-picker-expand" onClick={() => { setOpen(!open); }}>
                    {!open && (
                        <>
                            <MoveRightIcon />
                            <div className="label">Fler...</div>
                        </>
                    )}
                    {open && (
                        <>
                            <MoveLeftIcon />
                            <div className="label">Färre...</div>
                        </>
                    )}

                </div>

            </div>
        );
    },
    TrueFalse: (props) => {
        const [checked, setChecked] = useState(props.value);
        return (
            <div style={{ fontSize: "1.4rem", textAlign: "center" }}
                className="border-color-text"
                onClick={() => {
                    setChecked(!checked);
                    props.onChange(checked)
                }}>

                {checked && (
                    <CheckboxCheckedIcon />
                )}
                {!checked && (
                    <CheckboxUnCheckedIcon />
                )}

            </div>
        );
    },
    Tag: (props) => {
        const options = useSelector(state => state.data.planttags).map(t => { return { value: t.id, label: t.tag } });
        const [selectedTags, setSelectedTags] = useState(Array.isArray(props.value) ? props.value : [props.value]);
        const selectedOptions = options.filter(o => { return selectedTags.indexOf(o.value) >= 0; });
        const handleChange = (e) => {
            const selected = e.map(o => o.value);
            setSelectedTags(selected);
            props.onChange(selected);
        };

        return (
            <Select
                value={selectedOptions}
                options={options}
                isMulti={true}
                onChange={handleChange}
                menuPortalTarget={document.body}
                styles={{ menuPortal: base => ({ ...base, zIndex: 9999 }) }}
            />);
    },
    Link: (props) => {
        const [link, setLink] = useState(props.value);
        return (
            <div>
                <input
                    type="text"
                    className="w100"
                    autoComplete="off"
                    value={link}
                    onChange={(e) => {
                        setLink(e.target.value);
                        props.onChange(e.target.value);
                    }}

                />
                <div>
                    {link && (
                        <a href={link} target="_blank" className="text-small">{link}</a>
                    )}
                    {!link && (
                        <a href="#" onClick={() => { return false; }} className="text-small">Ange länk</a>
                    )}
                </div>
            </div>
        )

    }
}