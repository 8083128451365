import { useSelector } from 'react-redux';
import { PlantListType } from "../rdx/Redux.DataReducerType";
import { RdxStore } from "../rdx/ReduxTypes";

export const usePlantLists = () => {
    const listsObject = useSelector((state: RdxStore) => state.data.plantlists);
    return {
        item: (id: string): PlantListType => {
            return listsObject?.[id];
        }

    }

}
