import React, { useEffect, useState } from 'react';
import { usePlant } from '../../hooks/usePlant';
import { SearchHitType } from '../../rdx/ReduxTypes';
import { JwtImage, JwtPlantImage, JwtPlantImageWithPreview } from '../UI/ImageElements';
import { DiscoveryUIType, ObjectWithId } from './useDiscover';
import { DropTarget } from 'react-drag-drop-container';
import { usePlantLists } from '../../hooks/usePlantLists';
import { PlantListType } from '../../rdx/Redux.DataReducerType';
import { SystemSettingsKeys } from '../SystemObjects/useSystemSettings';
import { PlantWithImage } from '../PlantImageOperations/PlantImageOperations';
import { ApiPostWithImages } from '../PlantImageOperations/useSocialImageOperations';
import { ClipBoardDropTargets } from '../ClipBoard/useClipBoard';
import { UserPostFeaturedCard } from '../PostsManagement/PostsManagement';
import { ForumPostType } from '../Forum/Forum';
import { usePostStore } from '../PostsManagement/usePostStore';
import { HeaderWithOsNotification } from './HeaderWithOsNotification';


export const DiscoverScreen = ({ config, onChange }: { config: DiscoveryUIType, onChange(key: SystemSettingsKeys, data: ObjectWithId, droppedObject: any): void }) => {

    const plant = usePlant();


    const [plantOfTheWeek, setPlantOfTheWeek] = useState<string>(null);

    useEffect(() => {
        if (config?.plantOfTheWeek) {
            plant.loadMultiple([config?.plantOfTheWeek])
            setPlantOfTheWeek(config?.plantOfTheWeek);
        }
    }, [, config])

    const dataDropped = (key: SystemSettingsKeys, data: { id: string }, droppedObject?: any) => {
        onChange(key, data, droppedObject);
    }

    return (
        <div className='discover-screen'>


            <div>
                <DiscoveryWeeklyPlantImage image={config?.plantImageOfTheWeek} dataDropped={data => { dataDropped("PLANT_IMAGE_OF_THE_WEEK", data) }} />
            </div>

            {/* List 02 */}
            <div>
                <DiscoveryPlantList personal={true} title="Lista 2 ej angiven" plantListId={config?.list02} dataDropped={data => { dataDropped("DISCOVERY_LIST_02", data) }} />
            </div>


            <div>
                <GenericSection label='Annons' text='discovery_featured' />
            </div>

            <div>
                <GenericSection label='Trädgårdar att följa' text='Vänner i din kommun' />
            </div>


            <DiscoveryWeeklyPlant plant={plantOfTheWeek} dataDropped={data => { dataDropped("PLANT_OF_THE_WEEK", data) }} />


            <div>
                <GenericSection label='Blogginlägg' text="Featured blogginlägg" />
            </div>

            <div>
                <DiscoveryUserPostFeaturedCard title='Kombination 1' postId={config?.featured01} dataDropped={(data, droppedObject) => { dataDropped("DISCOVERY_USER_POST_FEATURED_CARD_01", data, droppedObject) }} />
            </div>

            <div>
                <DiscoveryUserPostFeaturedCard title='Kombination 2' postId={config?.featured02} dataDropped={(data, droppedObject) => { dataDropped("DISCOVERY_USER_POST_FEATURED_CARD_02", data, droppedObject) }} />
            </div>

            <div>
                <GenericSection label='Från forumet' text='Populära inlägg' />
            </div>


            <div>
                <GenericSection label='Blogginlägg' text='Två senaste blogginlägg' />
            </div>

            <div>
                <DiscoveryWeeklyPostImage image={config?.postImageOfTheWeek} dataDropped={data => { dataDropped("POST_IMAGE_OF_THE_WEEK", data) }} />
            </div>

            <div>
                <GenericSection label='Vänner i din zon' text='Vänner i din zon' />
            </div>

            {/* 
            <div>
                <DiscoveryWeeklyPlantOrPostImage image={config?.imageOfTheWeek} dataDropped={data => { dataDropped("IMAGE_OF_THE_WEEK", data) }} />
            </div> */}




            {/* List 01 */}
            <div>
                <DiscoveryPlantList personal={false} title="Lista 1 ej angiven" plantListId={config?.list01} dataDropped={data => { dataDropped("DISCOVERY_LIST_01", data) }} />
            </div>

            {/* List 03 */}
            <div>
                <DiscoveryPlantList personal={false} title="Lista 3 ej angiven" plantListId={config?.list03} dataDropped={data => { dataDropped("DISCOVERY_LIST_03", data) }} />
            </div>

            {/* List 04 */}
            <div>
                <DiscoveryPlantList personal={false} title="Lista 4 ej angiven" plantListId={config?.list04} dataDropped={data => { dataDropped("DISCOVERY_LIST_04", data) }} />
            </div>

        </div>
    )
}

const DiscoveryWeeklyPlant = ({ plant, dataDropped }: { plant: string, dataDropped(e: ObjectWithId): void }) => {

    const [hovering, setHovering] = useState(false);
    const onDragEnter = (e) => {
        if (e) {
            setHovering(true);
        }
    }
    const onDragLeave = (e) => {
        setHovering(false);
    }
    const onHit = (e) => {
        const data = e?.dragData as PlantListType;
        setHovering(false);
        dataDropped(data);
    }

    const ops = usePlant();

    const [currentPlant, setCurrentPlant] = useState<SearchHitType>(null);
    useEffect(() => {
        setCurrentPlant(ops.item(plant));
    }, [, plant, ops.item(plant)])



    return (

        <DropTarget
            onHit={onHit}
            onDragEnter={onDragEnter}
            onDragLeave={onDragLeave}
            targetKey={ClipBoardDropTargets.plant} >
            <div className={'weeklyplant-container' + (hovering ? " hovering" : "")}>
                <HeaderWithOsNotification
                    header={plant ? (currentPlant?.fields?.name + " " + currentPlant?.fields?.specifictype) : "Veckans växt ej angiven"}
                    notificationEnabled={!!plant}
                    objectId={plant}
                    type="discoveryweeklyplant"
                />
                {currentPlant?.fields?.imageid && (
                    <div className='plant-of-the-week-image'>
                        <JwtPlantImage plantImageId={currentPlant?.fields?.imageid} sizeType="generic" />
                    </div>
                )}
            </div>
        </DropTarget>
    )
}

// ##########################################################################################################################

const DiscoveryWeeklyPostImage = ({ image, dataDropped }: { image: string, dataDropped(e: ObjectWithId): void }) => {

    const [hovering, setHovering] = useState(false);
    const onDragEnter = (e) => {
        console.log(e)
        if (e) {
            setHovering(true);
        }
    }
    const onDragLeave = (e) => {
        setHovering(false);
    }

    const onHitPostImage = (e) => {
        const data = e?.dragData as ApiPostWithImages;
        setHovering(false);
        dataDropped({ id: data?.imageid });
        console.log("postimage");
    }

    const [failedLoad, setFailedLoad] = useState<boolean>(false);

    useEffect(() => {
        setFailedLoad(false);
    }, [image])


    return (
        <DropTarget onHit={onHitPostImage}
            onDragEnter={onDragEnter}
            onDragLeave={onDragLeave}
            targetKey={ClipBoardDropTargets.postimage}
        >
            <div className={'weeklyplant-container' + (hovering ? " hovering" : "")}>
                <HeaderWithOsNotification
                    header={"Veckans inläggs bild"}
                    notificationEnabled={!!image}
                    objectId={image}
                    type="discoveryweeklypostimage"
                />
                <JwtImage imageId={image} format={'hd'} openOnClick={true} />
            </div>
        </DropTarget>
    )
}

const DiscoveryWeeklyPlantImage = ({ image, dataDropped }: { image: string, dataDropped(e: ObjectWithId): void }) => {

    const [hovering, setHovering] = useState(false);
    const onDragEnter = (e) => {
        if (e) {
            setHovering(true);
        }
    }
    const onDragLeave = (e) => {
        setHovering(false);
    }

    const onHitPlantImage = (e) => {
        const data = e?.dragData as PlantWithImage;
        setHovering(false);
        dataDropped({ id: data?.imageid });
        console.log("plantimage");
    }

    const [failedLoad, setFailedLoad] = useState<boolean>(false);

    useEffect(() => {
        setFailedLoad(false);
    }, [image])


    return (
        <DropTarget
            onHit={onHitPlantImage}
            onDragEnter={onDragEnter}
            onDragLeave={onDragLeave}
            targetKey={ClipBoardDropTargets.plantimage}
        >
            <div className={'weeklyplant-container' + (hovering ? " hovering" : "")}>
                <HeaderWithOsNotification
                    header='Veckans växt bild'
                    notificationEnabled={!!image}
                    objectId={image}
                    type="discoveryweeklyplantimage"
                />
                <JwtPlantImageWithPreview imageId={image} onError={() => setFailedLoad(true)} />
            </div>
        </DropTarget>
    )
}



const DiscoveryUserPostFeaturedCard = ({ title, postId, dataDropped }: { title: string, postId: string, dataDropped(e: ObjectWithId, droppedObject: ForumPostType): void }) => {

    const posts = usePostStore();
    const postData = posts?.db?.[postId];

    const [hovering, setHovering] = useState(false);
    const onDragEnter = (e) => {
        if (e) {
            setHovering(true);
        }
    }
    const onDragLeave = (e) => {
        setHovering(false);
    }



    useEffect(() => {
        if (postId) {
            posts.ensurePosts([postId]);
        }
    }, [, postId])


    const onHitPlantImage = (e) => {
        const data = e?.dragData as ForumPostType;
        setHovering(false);
        dataDropped({ id: data?.id }, data);
    }

    return (
        <DropTarget
            onHit={onHitPlantImage}
            onDragEnter={onDragEnter}
            onDragLeave={onDragLeave}
            targetKey={ClipBoardDropTargets.userpostfeaturedcard}
        >
            <div className={'weeklyplant-container' + (hovering ? " hovering" : "")}>
                <HeaderWithOsNotification
                    header={title}
                    notificationEnabled={!!postId}
                    objectId={postId}
                    type="discoveryuserpostfeaturedcard"
                />
                {!!postData && (
                    <UserPostFeaturedCard post={postData} disableDrag={true} />
                )}
            </div>
        </DropTarget>
    )
}

// ##########################################################################################################################


const DiscoveryPlantList = ({ title, plantListId, dataDropped, personal }) => {

    const [_title, set_title] = useState(title);

    const [hovering, setHovering] = useState(false);
    const onDragEnter = (e) => {
        if (e) {
            setHovering(true);
        }
    }
    const onDragLeave = (e) => {
        setHovering(false);
    }
    const onHit = (e) => {
        const data = e?.dragData as PlantListType;
        setHovering(false);
        dataDropped(data);
    }
    const [selectedId, setSelectedId] = useState(plantListId);
    useEffect(() => {
        setSelectedId(plantListId);
    }, [plantListId])

    const currentList = usePlantLists().item(selectedId);

    return (
        <DropTarget
            onHit={onHit}
            onDragEnter={onDragEnter}
            onDragLeave={onDragLeave}
            targetKey={ClipBoardDropTargets.list} >


            <div className={'plantlist-container' + (hovering ? " hovering" : "")}>
                <h3>
                    {currentList ? currentList?.name : title}
                </h3>
                {personal && (
                    <div className='personal-text'>
                        Ersätts med personlig lista.
                    </div>
                )}
                {/* <div className={'plantlist'}>
                    <DiscoveryPlantListPlantCard plantId={null} />
                    <DiscoveryPlantListPlantCard plantId={null} />
                </div> */}
            </div>
        </DropTarget>
    )
}



const GenericSection = ({ label, text }: { label: string, text: string }) => {
    return (
        <div className='generic-section'>
            <div className='label'>
                {label}
            </div>
            <div className='text'>
                {text}
            </div>

        </div>
    )
}

