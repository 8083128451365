import { DataReducerType } from "./ReduxTypes";

const CHANGE_COLOR_LIST = "CHANGE_COLOR_LIST";
const CHANGE_PLANTFIELD_LIST = "CHANGE_PLANTFIELD_LIST";
const CHANGE_PLANTFIELD_OBJECT = "CHANGE_PLANTFIELD_OBJECT";
const CHANGE_PLANTTYPES_LIST = "CHANGE_PLANTTYPES_LIST";
const CHANGE_PLANTTYPES_OBJECT = "CHANGE_PLANTTYPES_OBJECT";
const CHANGE_PLANTTAGS_LIST = "CHANGE_PLANTTAGS_LIST";
const CHANGE_PLANTTAGS_OBJECT = "CHANGE_PLANTTAGS_OBJECT";
const CHANGE_PLANTLISTS = "CHANGE_PLANTLISTS";
const CHANGE_PLANTLISTS_LINKED_TO_OBJECT = "CHANGE_PLANTLISTS_LINKED_TO_OBJECT";
const CHANGE_CURRENT_PLANTLIST = "CHANGE_CURRENT_PLANTLIST";
const CHANGE_SNIPPETS = "CHANGE_SNIPPETS";
const CHANGE_PLANTIMAGES = "CHANGE_PLANTIMAGES";
const CHANGE_USERS = "CHANGE_USERS";

const CHANGE_REPORT_SETTINGS = "CHANGE_REPORT_SETTINGS";

const DataReducerStore: DataReducerType = {
    colors: [],
    plantfields: [],
    plantfieldsobject: {},
    planttypes: [],
    planttypesobject: {},
    planttags: [],
    planttagsobject: {},
    plantlists: null,
    plantlistslinkedtobject: {},
    currentplantlist: null,
    snippets: {},
    plantimages: {},
    users: {},
    reportSettings: {}
}

const dataReducer = (state = { ...DataReducerStore }, action) => {
    switch (action.type) {
        case CHANGE_COLOR_LIST: { state = { ...state, colors: action.payload }; break; }
        case CHANGE_PLANTFIELD_LIST: { state = { ...state, plantfields: action.payload }; break; }
        case CHANGE_PLANTFIELD_OBJECT: { state = { ...state, plantfieldsobject: action.payload }; break; }
        case CHANGE_PLANTTYPES_LIST: { state = { ...state, planttypes: action.payload }; break; }
        case CHANGE_PLANTTYPES_OBJECT: { state = { ...state, planttypesobject: action.payload }; break; }
        case CHANGE_PLANTTAGS_LIST: { state = { ...state, planttags: action.payload }; break; }
        case CHANGE_PLANTTAGS_OBJECT: { state = { ...state, planttagsobject: action.payload }; break; }
        case CHANGE_PLANTLISTS: { state = { ...state, plantlists: action.payload }; break; }
        case CHANGE_CURRENT_PLANTLIST: { state = { ...state, currentplantlist: action.payload }; break; }
        case CHANGE_SNIPPETS: { state = { ...state, snippets: action.payload }; break; }
        case CHANGE_PLANTLISTS_LINKED_TO_OBJECT: { state = { ...state, plantlistslinkedtobject: action.payload }; break; }
        case CHANGE_PLANTIMAGES: { state = { ...state, plantimages: action.payload }; break; }
        case CHANGE_USERS: { state = { ...state, users: { ...state.users, ...action.payload } }; break; }
        case CHANGE_REPORT_SETTINGS: { state = { ...state, reportSettings: action.payload }; break; }


        default: { return state; }
    }
    return state;
};
export {
    dataReducer,

    CHANGE_COLOR_LIST,
    CHANGE_PLANTFIELD_LIST,
    CHANGE_PLANTFIELD_OBJECT,
    CHANGE_PLANTTYPES_LIST,
    CHANGE_PLANTTYPES_OBJECT,
    CHANGE_PLANTTAGS_LIST,
    CHANGE_PLANTTAGS_OBJECT,
    CHANGE_PLANTLISTS,
    CHANGE_PLANTLISTS_LINKED_TO_OBJECT,
    CHANGE_CURRENT_PLANTLIST,
    CHANGE_SNIPPETS,
    CHANGE_PLANTIMAGES,
    CHANGE_USERS,
    CHANGE_REPORT_SETTINGS
}