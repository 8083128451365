import React, { useState } from 'react';
import Select from 'react-select';
import { useSelector } from 'react-redux';
import { Dialog } from '../../Shared/Dialog';
import { Operations } from '../../../rdx/Operations';
import { MessageBoxes } from '../../../Handlers/MessageBoxes';
import { RdxStore } from '../../../rdx/ReduxTypes';
import { SelectOptionType } from '../../UI/UITYpes';

export type AddRemovePlantFromListDialogStatsuType = "openAdd" | "openRemove" | "closed";

export const AddRemovePlantFromListDialog = (
    { okHandler, cancelHandler, status, visible }: { okHandler: any, cancelHandler: any, status: AddRemovePlantFromListDialogStatsuType, visible: boolean }
) => {
    const ids = useSelector((state: RdxStore) => state.search.searchList.filter(obj => obj.selected).map(obj => obj.id));
    const currentplantlist = useSelector((state: RdxStore) => state.data.currentplantlist);
    const listsObject = useSelector((state: RdxStore) => state.data.plantlists);

    const [value, setValue] = useState<SelectOptionType>(currentplantlist?.id ? { value: currentplantlist?.id, label: currentplantlist?.name } : null);
    const [dialogdisabled, setDialogdisabled] = useState(false);

    const lists = Object.keys(listsObject).filter(o => o !== "loaded").map(k => listsObject[k]);
    const options = lists.map(o => { return { value: o.id, label: o.name } });

    const adding = status === "openAdd" ? true : status === "openRemove" ? false : null;

    if (!visible) {
        return (null);
    }

    const onOkClicked = () => {

        if (adding === true) {
            Operations.linkPlantsToList(value?.value?.toString(), ids).then(success => {
                if (success) {
                    MessageBoxes.info("Växterna är kopplade!")
                } else {
                    MessageBoxes.warning("Operationen misslyckades!")
                }
                setDialogdisabled(true);
                okHandler();
            })
        } else if (adding === false) {
            Operations.removePlantsFromList(value?.value, ids).then(success => {
                if (success) {
                    MessageBoxes.info("Växterna är borttagna!")
                } else {
                    MessageBoxes.warning("Operationen misslyckades!")
                }
                setDialogdisabled(true);
                okHandler();
            })
        } else {
            alert("invalid state: " + adding);
            setDialogdisabled(true);
            okHandler();
        }
    }

    return (<Dialog
        onCancel={cancelHandler}
        onOk={onOkClicked}
        width="600px"
        // okDisabled={value === null }
        disabled={dialogdisabled}
    >
        {adding === true && (<h4>Lägg till i lista </h4>)}
        {adding === false && (<h4>Ta bort från lista </h4>)}
        {adding === null && (<h4>N/A</h4>)}

        <div>
            <Select
                value={value}
                options={options}
                onChange={e => {
                    setValue(e);
                }}
                menuPortalTarget={document.body}
                styles={{ menuPortal: base => ({ ...base, zIndex: 9999 }) }}
            />
        </div>
        <div>
            {adding === true && (<span>Koppla {ids?.length} växter till '{listsObject[value?.value]?.name}'. </span>)}
            {adding === false && (<span>Ta bort {ids?.length} växter från '{listsObject[value?.value]?.name}'. </span>)}
            {adding === null && (<span>---------------------</span>)}
        </div>
    </Dialog>)
}