//import { AdReducerType } from "./ReduxTypes";

export type ConnectedUserType = { userId: string, added: number, ping: number, header: string, action: string };
export type SignalrReducerType = {
    connectedUsers: ConnectedUserType[],
    lastPing: string
}


const CHANGE_SIGNALR_CONNECTED_USERS = "CHANGE_SIGNALR_CONNECTED_USERS";
const CHANGE_SIGNALR_LAST_PING = "CHANGE_SIGNALR_LAST_PING";

const signalrReducerStore: SignalrReducerType = {
    connectedUsers: [],
    lastPing: undefined,
};


const signalrReducer = (state = { ...signalrReducerStore }, action) => {
    switch (action.type) {
        case CHANGE_SIGNALR_CONNECTED_USERS: { state = { ...state, connectedUsers: action.payload }; break; }
        case CHANGE_SIGNALR_LAST_PING: { state = { ...state, lastPing: action.payload }; break; }
        default: { return state; }
    }
    return state;
};

export {
    signalrReducer,
    CHANGE_SIGNALR_CONNECTED_USERS, CHANGE_SIGNALR_LAST_PING
}