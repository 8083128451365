import React, { useEffect, useLayoutEffect, useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { useFetch, useUserRoles } from '../../hooks/MiscHooks';
import { usePlantTypesData } from '../../Handlers/Hooks';
import { JwtPlantImage, JwtPlantImagePreview } from '../UI/ImageElements';
import { useUsers } from '../../hooks/useUsers';
import { useFiles } from '../../hooks/useFiles';
import { FetchHandler } from '../../Handlers/FetchHandler';
import { usePlant } from '../../hooks/usePlant';
import { CHANGE_TOOLS_HANDLED_USERIMAGES, CHANGE_TOOLS_USERIMAGES, UserImageType } from '../../rdx/toolsreducer';
import { RdxStore } from '../../rdx/ReduxTypes';
import { MessageBoxes } from '../../Handlers/MessageBoxes';
import { useSignalRMessaging } from '../../rdx/useSignalR';


export type UserPlantImageActionType = "none" |
    "goodimage" |
    "goodimageselected" |
    "badimage" |
    "wrongimage" |
    "copyrightissuesimage" |
    "imagebreaksrules"

export const useUserPlantImages = (loadOnMount: boolean) => {
    const db: { [key: string]: UserImageType } = useSelector((state: RdxStore) => state.tools.userImages);
    const handledUserImagesPlants: { [key: string]: string } = useSelector((state: RdxStore) => state.tools.handledUserImagesPlants);
    const dispatch = useDispatch();
    const users = useUsers();
    const plants = usePlant();
    const sign = useSignalRMessaging();

    const addHandled = (plantId: string) => {
        const change = { ...handledUserImagesPlants, [plantId]: plantId };
        dispatch({ type: CHANGE_TOOLS_HANDLED_USERIMAGES, payload: change });
    }

    const [loading, setLoading] = useState<boolean>(false)

    useEffect(() => {
        if (loadOnMount && Object.keys(db ?? {}).keys.length == 0) {
            setLoading(true);
            FetchHandler.getJson("/api/userimages/forapproval").then((baseData: UserImageType[]) => {
                const data = baseData.filter((m, idx) => idx <= pageSize);
                let model = data.reduce((acc, curr) => (acc[curr.id] = curr, acc), {});
                const _plantIds = Array.from(new Set(data.map(m => m.plantid)));
                const _userIds = Array.from(new Set(data.map(m => m.userid)));
                users.ensureUserInfo(_userIds);
                plants.loadMultiple(_plantIds);
                dispatch({ type: CHANGE_TOOLS_USERIMAGES, payload: model });
                setLoading(false);
            });
        }
    }, [])

    useEffect(() => {
        const data = Object.keys(db ?? {}).map(key => db[key]);
        let _plantIds = Array.from(new Set(data.map(m => m.plantid)));
        _plantIds.sort((a, b) => {
            const ak = data.find(m => m.plantid === a)?.sys_datecreated_utc_epoch;
            const bk = data.find(m => m.plantid === b)?.sys_datecreated_utc_epoch;
            if (ak > bk) { return 1; }
            else if (ak < bk) { return -1; }
            else { return 0; }
        })
        setPlantIds(_plantIds);
    }, [db])


    const [plantIds, setPlantIds] = useState<string[]>([]);

    const [page, setPage] = useState(0);
    const [numPages, setNumPages] = useState(0);
    const [pageSize, setPageSize] = useState(20);

    return {
        db: db,
        pageSize: pageSize,
        setPageSize: setPageSize,
        db0: handledUserImagesPlants,
        viewList: Object.keys(handledUserImagesPlants ?? {})?.filter((n, idx) => idx > pageSize * page && idx < pageSize * (page + 1)),
        item: (id: string) => { return db?.[id] },
        handledUserImagesPlants: async () => {
            var keys = Object.keys(handledUserImagesPlants ?? {});
            const d = keys.map(key => plants.item(key)).filter(m => m);
            if (d?.length != keys?.length) {
                await plants.loadMultiple(keys);
            }
            console.log("handledUserImagesPlants:", handledUserImagesPlants);
            console.log("d:", d);
            return d;
        },
        plantIds: plantIds?.filter((n, idx) => idx > pageSize * page && idx < pageSize * (page + 1)),
        plantImages: (plantId: string) => {
            return Object.keys(db ?? {}).map(key => db?.[key]).filter((m: UserImageType) => m.plantid == plantId) ?? [];
        },
        imageIds: Object.keys(db ?? {}),
        loading: loading,
        approveImage: async (imageId: string) => {
            const bak = { ...db }
            let change = { ...db }
            if (change?.[imageId]) {
                addHandled(change?.[imageId]?.plantid);
                delete change[imageId];
            }
            dispatch({ type: CHANGE_TOOLS_USERIMAGES, payload: change });

            const response = await useUserPlantImagesApi.approveImage(imageId);
            if (!response.success) {
                dispatch({ type: CHANGE_TOOLS_USERIMAGES, payload: bak });
                MessageBoxes.warning("failed")
            }
            console.log(response);
            sign.action("user_plant_images: approveImage");
        },
        rejectImage: async (imageId: string) => {
            const bak = { ...db }
            let change = { ...db }
            if (change?.[imageId]) {
                //addHandled(change?.[imageId]?.plantid);
                delete change[imageId];
            }
            console.log("change: ", change)
            dispatch({ type: CHANGE_TOOLS_USERIMAGES, payload: change });

            const success = await useUserPlantImagesApi.rejectImage(imageId);
            if (!success) {
                dispatch({ type: CHANGE_TOOLS_USERIMAGES, payload: bak });
                MessageBoxes.warning("failed")
            }
            console.log("success: ", success);
            sign.action("user_plant_images: rejectImage");
        },
        onAction: async (imageId: string, plantId: string, actionType: UserPlantImageActionType) => {
            const bak = { ...db }
            let change = { ...db }
            if (change?.[imageId]) {
                addHandled(change?.[imageId]?.plantid);
                delete change[imageId];
            }
            dispatch({ type: CHANGE_TOOLS_USERIMAGES, payload: change });

            const success = await useUserPlantImagesApi.onImageAction(imageId, plantId, actionType);
            if (!success) {
                dispatch({ type: CHANGE_TOOLS_USERIMAGES, payload: bak });
                MessageBoxes.warning("failed")
            }
            console.log("success: ", success);
        },



    }
}


type ApproveImageResponseType = {
    userId: string,
    plantId: string,
    license: LicensingInfoModelType,
    imageId: string,
    approved: boolean,
    success: boolean
}
type LicensingInfoModelType = {
    licensingKey: string,
    licensingName: string,
    licensingUrl: string,
    licensingAudience: string,
    licensingImageUrl: string,
}

const useUserPlantImagesApi = {
    approveImage: async (imageId: string): Promise<ApproveImageResponseType> => {
        const url = "api/userimages/" + imageId + "/approve";
        return await FetchHandler.patchJson(url);
    },
    rejectImage: async (imageId: string): Promise<boolean> => {
        const url = "api/userimages/" + imageId + "/reject";
        return await FetchHandler.patchJson(url);
    },
    onImageAction: async (imageId: string, plantId: string, actionType: UserPlantImageActionType) => {
        const url = "api/userimages/" + imageId + "/action";
        const postObj = {
            plantId: plantId,
            action: actionType,
        }
        return await FetchHandler.patchJson(url, postObj);
    }
}