import React, { Component, useEffect, useState } from 'react';

// import { Link } from 'react-router-dom';
import { LoginHandler } from "../../Handlers/LoginHandler"
import { useBuild } from '../../hooks/useBuild';
import { useSignalR } from '../../rdx/useSignalR';
import { ClipBoardIcon } from '../ClipBoard/ClipBoard';
import { AdminIcon, AdminIcons } from '../Shared/AdminIcon';
import { Dialog } from '../Shared/Dialog';
import { Portal } from './Portal';
import { RevenueCounter } from './RevenueCounter';

export const TopMenu = () => {

  const signalr = useSignalR();
  const [logginout, setLogginout] = useState(false);

  const logoutClicked = () => {
    setLogginout(true);
    LoginHandler.logout();
  };


  const build = useBuild();




  return (
    <div id="ui-menu-top">
      {/* Header */}
      <div className="main-header">
        <div>
          Oh!Garden
        </div>
      </div>


      {/* Top Content */}
      <div id="ui-menu-top-content">

        {/* 1 */}
        <div className='tools'>

          <RevenueCounter />


          {/* <button className='btn-tool btn-small'>
            asdjlaskjdla
          </button> */}

          {/* <button className='btn-tool btn-small'>
            <AdminIcon icon={AdminIcons.globe} /> asla
          </button> */}

          {/* <button className='btn-tool btn-small'>asskjdla</button> */}
          {/* <button className='btn-tool btn-small'>asskjdla</button> */}
          {/* <button className='btn-tool btn-small'>asd jlask jdla</button> */}
          {/* <button className='btn-tool btn-small'>asd j lask jdla</button> */}

        </div>
        {/* 2 */}
        <div>
          {(build.dismissed) && (
            <button className="btn-tool btn-reload" onClick={() => { window.location.reload(); }}>
              Klicka här för att uppdatera klienten
            </button>
          )}

        </div>

        {/* Logout button */}
        {/* 3 */}
        <div>

          <ClipBoardIcon />
          <button className="btn-tool btn-login" onClick={logoutClicked}>
            <AdminIcon icon={AdminIcons.signout} />
            {logginout && (<>Loggar ut...</>)}
            {!logginout && (<>Logga ut</>)}
          </button>
        </div>

      </div>


      {/* Dialogs */}
      <Portal>
        <Dialog
          title='Ladda om klient'
          visible={build?.shouldRefreshClient}
          onOk={() => { window.location.reload(); }}
          onCancel={build.dismiss}
          okButtonText="Uppdatera"
          cancelButtonText="Stäng"
        >
          <div>
            <p style={{ textAlign: "center" }}>
              Ladda om klienten för att uppdatera!
            </p>
          </div>
        </Dialog>
      </Portal>

    </div>
  );
}
