import React, { useEffect, useState } from "react";
import { FetchHandler } from "../../Handlers/FetchHandler";
import { useDebounce } from "../../hooks/useDebounce";
import { ForumPostCard } from "../Forum/ForumPostCard";
import { AdminIcon, AdminIcons } from "../Shared/AdminIcon";
import { ToolBar } from "../ToolBar/ToolBar";
import { LoadingBar, ScreenHeader, ScreenMenu } from "../UI/Elements";
import { JwtImage, JwtPlantImage } from "../UI/ImageElements";
import { useElasticMenu } from "./useElasticMenu";
import { useElasticSearch } from "./useElasticSearch";

export const ElasticSearchPoi = () => {
    const ui = useElasticSearch();


    // useEffect(() => {
    //     ui.load();
    // }, []);

    const [search, setSearch] = useState<string>(undefined);
    const menu = useElasticMenu();
    return (
        <div className="screen-elastic">
            <ScreenHeader text="Appsök POI med Elastic Search" icon={AdminIcons.search} />
            <ScreenMenu items={menu?.items} />

            <LoadingBar loading={ui.loadingPois} />
            <div>
                <input placeholder="Sök..." className="frm" type={"search"} value={search} onChange={e => {
                    const val = e.target.value;
                    setSearch(val);
                    ui.onSearchPPoisChanged(val);
                }} onKeyUp={e => {
                    if (e.key == "Enter") {
                        ui.onSearchPPois(search);
                    }
                }} />
            </div>
            <div className="autocomplete-container">
                <span>Förslag: </span>
                {ui.poisAutoComplete?.map((a, i) => (

                    <span key={a}>
                        {i == 0 ? "" : ", "}
                        <span className="autocomplete-item" onClick={(() => {
                            setSearch(a);
                            ui.onSearchPPoisChanged(a);
                            ui.onSearchPPois(a);
                        })}>
                            {a}
                        </span>
                    </span>
                ))}
            </div>
            <div>
                <div>
                    Träffar: {ui?.pois?.total ?? 0} - Svarstid: {ui.pois?.durationms} ms.
                </div>
                <div>

                    {(ui.pois?.suggestions?.length > 0) && (
                        <div className="suggestions-container">
                            <label>
                                Menade du?
                            </label>

                            <div className="suggestions">
                                {(ui.pois?.suggestions?.length > 0) && ui.pois?.suggestions?.map(s => (
                                    <div className="suggestion" key={s} onClick={() => {
                                        ui.onSearchPPois(s);
                                        setSearch(s);
                                    }} >
                                        {s}
                                    </div>
                                ))}
                            </div>
                        </div>
                    )}

                </div>
                <table className="tbl tbl-small">
                    <thead>
                        <tr>
                            <th></th>
                            <th>Namn</th>
                            <th>Typ</th>
                            <th>Adress 1</th>
                            <th>Adress 2</th>
                        </tr>
                    </thead>
                    <tbody>
                        {ui.pois?.hits?.map(hit => (
                            <tr key={hit?.id}>
                                <td>{hit?.published ? (<AdminIcon icon={AdminIcons.eye} />) : (<AdminIcon icon={AdminIcons.eyeclosed} />)}</td>
                                <td>{hit?.title}</td>
                                <td>{ui.poiTypeName(hit?.type?.[0])}</td>
                                <td>{hit?.addressline1}</td>
                                <td>{hit?.addressline2}</td>
                            </tr>
                        ))}
                    </tbody>
                </table>
            </div>
        </div>
    )
}
