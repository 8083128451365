import React, { useEffect, useState } from "react"
import { FetchHandler } from "../../Handlers/FetchHandler";
import { ClipBoardDragContainer } from "../ClipBoard/ClipBoard";
import { AdminIcon, AdminIcons } from "../Shared/AdminIcon";
import { ThreeColumnContainer } from "../Shared/Formelements";
import { ToolBar, ToolbarItemType } from "../ToolBar/ToolBar";
import { ScreenHeader } from "../UI/Elements";
import { Overlay } from "../UI/Overlay";
import { BlogPostType, useBlog } from "./useBlog";

export const Blog = () => {
    const ui = useBlog();

    const tools: ToolbarItemType[] = [
        { id: "1", label: "Öppna länk", icon: AdminIcons.link, onClick: () => { window.open(ui.selectedItem?.permalink, "_blank") }, },
        { id: "2", label: "Gör 'Featured'", icon: AdminIcons.checkbox_checked, onClick: () => { ui.makeFeatured(ui.selectedItem?.idNumber) }, disabled: !!ui.selectedItem?.featured },
        { id: "3", label: "Ta bort 'Featured'", icon: AdminIcons.checkbox_checked, onClick: () => { ui.removeFeatured(ui.selectedItem?.idNumber) }, disabled: !ui.selectedItem?.featured },
        {
            id: "4",
            label: !!ui.selectedItem?.visibleOnWeb ? "Göm på web" : "Visa på web",
            icon: !ui.selectedItem?.visibleOnWeb ? AdminIcons.eye : AdminIcons.eyeclosed,
            onClick: () => {
                ui.setVisibleOnWeb(ui.selectedItem?.idNumber, !ui.selectedItem?.visibleOnWeb)
            },
            disabled: ui.isWaiting
        },
    ];
    return (
        <div>
            <Overlay visible={ui.isWaiting} />
            <ScreenHeader text="Blogg artiklar" icon={AdminIcons.blog} />

            <div>
                <ToolBar tools={[{ id: "1", label: "Uppdatera DB", icon: AdminIcons.cogs, onClick: () => { ui.updateDb() }, },]} />

            </div>
            <ThreeColumnContainer gridTemplateColumns="33% auto">
                <div>
                    <HelpElement items={[
                        "Författaren skriver artikeln under eget namn i wordpress.",
                        "Se till att publiceringsdatum passerats",
                        "Klicka på knappen 'Uppdatera DB' ovan.",
                        "Artikeln visas nedan men utan 'ögat'. Artikeln är nu bara synlig på stage. Nu kan man kontrollera utseendet.",
                        "Om justeringar görs, klicka på 'Uppdatera DB' för att kolla resultatet",
                        "När man är redo att publicera, klicka på 'Visa på web'",
                        "Artikeln är nu synlig"

                    ]} />
                    {ui.blogPosts?.map(d => (
                        <div className={"blog-item" + (!!d?.visibleOnWeb ? " blog-item-visible" : "")} key={d?.id}
                            onClick={() => { ui.setSelected(d?.id); }}
                        >
                            <div className="blog-icon-column">
                                <div className="blog-visible">
                                    {d.visibleOnWeb === true ? (<AdminIcon icon={AdminIcons.eye} />) : (<AdminIcon icon={AdminIcons.eyeclosed} />)}
                                </div>
                                <div className="blog-featured">
                                    {d.featured === true ? (<AdminIcon icon={AdminIcons.ribbon} />) : (null)}
                                </div>
                            </div>
                            <div>
                                <div className="blog-title">
                                    {d.titleHtmlDecoded}
                                </div>
                                <div className="blog-excerpt">
                                    {d.htmlExcerptDecoded}
                                </div>
                                <div className="blog-author">
                                    {d.author} : {d.publishedDateTime}
                                </div>
                            </div>
                            <div>

                                <img src={d?.image} loading="lazy" />
                            </div>
                        </div>
                    ))}

                    {false && ui.blogPosts?.map(d => (
                        <BlogCard key={d?.id} item={d} onPress={() => { ui.setSelected(d?.id); }} openClipBoardOnDrag={true} />
                    ))}
                </div>
                <div>
                    {!!ui.selectedItem && (
                        <div className="blog-selected-item">
                            <ToolBar tools={tools} />
                            <h2>
                                {ui.selectedItem?.titleHtmlDecoded}
                            </h2>
                            <p>
                                {ui.selectedItem?.htmlExcerptDecoded}
                            </p>
                            {/* {JSON.stringify(ui.selectedItem)} */}
                        </div>
                    )}
                </div>
            </ThreeColumnContainer>
        </div>
    )
}

export const BlogCard = ({ item, onPress, openClipBoardOnDrag }: { item: BlogPostType, onPress(): void, openClipBoardOnDrag: boolean }) => {

    return (
        <ClipBoardDragContainer type={"blog"} dragData={item} openClipBoardOnDrag={openClipBoardOnDrag}>
            <div className={"blog-card " + (item?.featured ? "featured" : "")} onClick={onPress}>
                <div className="blog-card-section">
                    <div className="blog-card-name">
                        {item?.titleHtmlDecoded}
                    </div>
                    <div className="blog-card-author">
                        {item?.author}
                    </div>
                    <div className="blog-card-date">
                        {item?.publishedDateTime?.substring(0, 10)}
                    </div>
                    <div className="blog-card-excerpt">
                        {item?.htmlExcerptDecoded}
                    </div>
                </div>

                <div>
                    <img src={item?.image} loading="lazy" />
                    <div className="blog-icon-featured">
                        {item?.featured && (
                            <AdminIcon icon={AdminIcons.ribbon} title="Featured" />
                        )}
                    </div>
                </div>
            </div>
        </ClipBoardDragContainer>
    )

}

const HelpElement = ({ items }: { items: string[] }) => {

    const [open, setOpen] = useState<boolean>(false);
    const [popPosition, setPopPosition] = useState<{ x: number, y: number }>(undefined)


    return (
        <div className="help">
            <div className="help-icon" onClick={e => {
                setOpen(!open);
                setPopPosition({ x: e.pageX, y: e.pageY });
            }}>
                <AdminIcon icon={AdminIcons.help} />
            </div>

            {open && (
                <div className="help-pop" onClick={() => { setOpen(!open) }} style={{ top: popPosition?.y, left: popPosition?.x }}>
                    <AdminIcon icon={AdminIcons.close} />
                    <ul className="help-list">
                        {items?.map(item => (
                            <li key={item}>{item}</li>
                        ))}
                    </ul>
                </div>
            )}
        </div>
    )
}