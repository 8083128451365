const licensingPrivacyLevel = { none: "none", members: "members", all: "all" };

type timePeriodType = {
    key: string,
    name: string
};

export const Data = {

    roles: {
        ADMINISTRATOR: "ADMINISTRATOR",
        SYSADMIN: "SYSADMIN",
        PLANTDATAADMIN: "PLANTDATAADMIN",
        CONTENTADMINISTRATOR: "CONTENTADMINISTRATOR",
        PARTNER: "PARTNER",
        MODERATOR: "MODERATOR",
        SUPPORT: "SUPPORT",
        TESTER: "TESTER",
        BLOGAUTHOR: "BLOGAUTHOR",
        ADMANAGER: "ADMANAGER",
    },

    plantFieldTypes: [
        { type: 0, typeString: "NotSet" },
        { type: 1, typeString: "String" },
        { type: 2, typeString: "Integer" },
        { type: 3, typeString: "Decimal" },
        { type: 4, typeString: "SingleSelect" },
        { type: 5, typeString: "MultiSelect" },
        { type: 6, typeString: "Color" },
        { type: 7, typeString: "TrueFalse" },
        { type: 8, typeString: "Tags" },
        { type: 9, typeString: "Link" },
        { type: 10, typeString: "Snippet" }
    ],

    fieldTypes: {
        notSet: 0,
        string: 1,
        integer: 2,
        decimal: 3,
        singleSelect: 4,
        multiSelect: 5,
        color: 6,
        trueFalse: 7,
        tags: 8,
        link: 9,
        snippet: 10
    },

    plantFieldCategories: [
        { key: "DESC", label: "Beskrivning", sortOrder: 0 },
        { key: "CULTIVATION", label: "Odling", sortOrder: 1 },
        { key: "MAINTENANCE", label: "Skötsel", sortOrder: 2 }
    ],


    snippetTypes: {
        SNIPPET_GENERAL_DESRIPTION: { key: "SNIPPET_GENERAL_DESRIPTION", name: "Allmän beskrivning" },
        SNIPPET_GROW_AND_PLANT: { key: "SNIPPET_GROW_AND_PLANT", name: "Odla & Plantera" },
        SNIPPET_TAKLE_CARE: { key: "SNIPPET_TAKLE_CARE", name: "Sköta" },
        SNIPPET_FERTILIZE: { key: "SNIPPET_FERTILIZE", name: "Skörda" },
        SNIPPET_CROP: { key: "SNIPPET_CROP", name: "Beskära" },
        SNIPPET_ADVICE: { key: "SNIPPET_ADVICE", name: "Tips!" },
    },
    licensingOptions: {
        shutterstock: { key: "shutterstock", name: "Shutterstock", url: "https://www.shutterstock.com/", audience: licensingPrivacyLevel.all },
        gap: { key: "gap", name: "GAP Photos (Members)", url: "https://www.gapphotos.com/", audience: licensingPrivacyLevel.members },
        gappublic: { key: "gappublic", name: "GAP Photos (Public)", url: "https://www.gapphotos.com/", audience: licensingPrivacyLevel.all },
        anna: { key: "anna", name: "Anna Dymling", url: "https://www.ohgarden.se/", audience: licensingPrivacyLevel.all },
        jonas: { key: "jonas", name: "Jonas Öberg", url: "https://www.ohgarden.se/", audience: licensingPrivacyLevel.all },
    },
    defaultLicensingOptionKey: "shutterstock",

    timePeriods: [
        { key: 'P01', name: 'Jan' },
        { key: 'P02', name: 'Feb' },
        { key: 'P03', name: 'Mar' },
        { key: 'P04', name: 'Apr' },
        { key: 'P05', name: 'Maj' },
        { key: 'P06', name: 'Jun' },
        { key: 'P07', name: 'Jul' },
        { key: 'P08', name: 'Aug' },
        { key: 'P09', name: 'Sep' },
        { key: 'P10', name: 'Okt' },
        { key: 'P11', name: 'Nov' },
        { key: 'P12', name: 'Dec' },
        { key: 'P21', name: 'Vår' },
        { key: 'P22', name: 'Sommar' },
        { key: 'P23', name: 'Höst' },
        { key: 'P24', name: 'Vinter' },
        { key: 'JAS', name: 'JAS' },
    ],
    onBoardingQuestionsAnswers: {
        style: [
            "Romantisk",
            "Minimalistisk",
            "Japanskt",
            "Funktion före form",
            "Färgsprakande",
            "Tuktat & Stramt",
            "Vilt och yvigt",
            "Medelhavstema",
            "Lugna färgtoner",
            "Äsch, jag blandar hejvilt"
        ],
        types: [
            "Buskar & häckar",
            "Blommande växter",
            "Gröna lummiga växter",
            "Prydnadsträd",
            "Frukt & bär",
            "Rhododendron",
            "Rosor",
            "Klättrande växter",
            "Bi- och fjärilsväxter",
            "Barrväxter",
            "Rabatter",
            "Grönsaker",
            "Växthus"
        ],
        colors: ["Röd", "Vit", "Gul", "Lila", "Blå", "Rosa", "Grön", "Orange", "Alla"],
        frienemies: [
            { value: "hare", label: "Hare" },
            { value: "sork", label: "Sork" },
            { value: "snigel", label: "Snigel" },
            { value: "radjur", label: "Rådjur" },
        ]
    },
}

