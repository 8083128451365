import React, { useEffect, useState } from "react"
import { FetchHandler } from "../../Handlers/FetchHandler";
import { MessageBoxes } from "../../Handlers/MessageBoxes";
import { AdminIcons } from "../Shared/AdminIcon"
import { ScreenHeader } from "../UI/Elements"
import { HasAnyRole } from "../UI/RoleElements";

type LinksApiType = {
    links: {
        DomainId: number,
        OwnerId: number,
        archived: boolean,
        cloaking: boolean,
        createdAt: string,
        icon: string,
        id: string,
        idString: string,
        lcpath: string,
        originalURL: string,
        path: string,
        secureShortURL: string,
        shortURL: string,
        source: "website",
        tags: string[],
        title: string,
        updatedAt: string
    }[],
    nextPageToken: string,
    count: number
}

export const CreateQrCode = () => {


    const [url, setUrl] = useState<string>("");
    const [qrcodeImgUrl, setQrcodeImgUrl] = useState(null);

    const onKeyUp = (e: React.KeyboardEvent<HTMLInputElement>) => {
        console.log("e.key: ", e.key)
        if (e.key == "Enter") {
            setQrcodeImgUrl("/api/qrcode/any?url=" + encodeURIComponent(url));
        } else {
            setQrcodeImgUrl(null)
        }
    }


    const [links, setLinks] = useState<LinksApiType>(null);
    const loadLinks = async () => {
        const data = await FetchHandler.getJson("/api/shortlinks");
        setLinks(data);
    }
    useEffect(() => {
        loadLinks();
    }, []);


    const renderUrlAsQrCode = (url) => {
        setQrcodeImgUrl("/api/qrcode/any?url=" + encodeURIComponent(url));
        setUrl(url);
    }


    return (
        <div className="create-qrcode-screen">
            <ScreenHeader text="Skapa QR kod" icon={AdminIcons.qrcode} />
            <div className="create-qrcode-grid">
                <div>
                    {/* {JSON.stringify(links)} */}
                    <HasAnyRole roles={["DEVELOPER"]} >
                        <div className="links">
                            {links?.links?.filter(m => m.tags.indexOf("admin") >= 0)?.map(l => (
                                <div key={l.id} className="link" onClick={() => {
                                    renderUrlAsQrCode(l?.secureShortURL);
                                    navigator.clipboard.writeText(l?.secureShortURL);
                                    MessageBoxes.info("Länk kopierad: " + l?.secureShortURL)
                                }}>
                                    <div className="link-title">
                                        {l?.title}
                                    </div>
                                    <div title={l?.originalURL} className="link-url">
                                        {l?.secureShortURL}
                                    </div>
                                    <div title={l?.originalURL} className="link-url-full">
                                        {l?.originalURL}
                                    </div>
                                    {/* <div className="link-tags">
                                        {l?.tags?.map(t => (
                                            <div key={t} className="link-tag">
                                                {t}
                                            </div>
                                        ))}
                                    </div> */}
                                </div>
                            ))}
                        </div>
                    </HasAnyRole>
                </div>


                <div className="tools">
                    <h2>Länk</h2>
                    <input type={"text"} onKeyUp={onKeyUp} onChange={e => setUrl(e.target.value)} />
                    <div className="url">
                        {url}
                    </div>

                    <div className="img">
                        <img src={qrcodeImgUrl} loading="lazy" />
                    </div>
                </div>


                <div></div>
            </div>
        </div>
    )
}