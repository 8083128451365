import React, { useState } from 'react';
import { AddIcon, TrashIcon } from '../Shared/Icons';

export const EditFieldRows = (props) => {
    const [rows, setRows] = useState(props.rows);

    const [key, setKey] = useState("");
    const [name, setName] = useState("");

    const add = () => {
        let r = rows;
        r.push({ key: key, name: name });
        r.sort((a, b) => {
            if (a.key > b.key) { return 1; }
            if (b.key > a.key) { return -1; }
            return 0;
        });
        setRows(r);

        setKey("");
        setName("");

        props.onChange(r);
    };

    const remove = (k) => {
        let r = rows;
        for (var i = 0; i < r.length; i++) {
            if (r[i].key === k) {
                r.splice(i, 1);
                continue;
            }
        }
        setRows(r);
        props.onChange(r);
    };

    const toggleSelectable = (k) => {
        console.log("toggleSelectable: ", k);
        let r = [...rows];
        for (var i = 0; i < r.length; i++) {
            if (r[i].key === k) {
                r[i].selectable = r[i].selectable ? false : true;
                continue;
            }
        }
        setRows(r);
        props.onChange(r);
    };

    const buttonEnabled =
        key.length > 0 &&
        name.length > 0 &&
        rows.filter(obj => obj.key === key).length === 0;

    const optionNameChanged = (id, name) => {

        let r = rows;
        for (var i = 0; i < r.length; i++) {
            if (r[i].key === id) {
                r[i].name = name;
                continue;
            }
        }
        setRows(r);
        props.onChange(r);
        console.log("optionNameChanged:", id, name);
        console.log("r:", r);
    }

    return (
        <div>
            <table className="tbl">
                <tbody>
                    {rows.map((item) => (
                        <EditFieldRowsRow
                            key={item.key}
                            id={item.key}
                            name={item.name}
                            selectable={item.selectable}
                            toggleSelectable={toggleSelectable}
                            remove={remove}
                            optionNameChanged={optionNameChanged}
                        />
                    ))}

                    <tr>
                        <td>
                            <input type="text" name="new_key" value={key} onChange={(e) => { setKey(e.target.value); }} />
                        </td>
                        <td>
                            <input type="text" name="new_name" value={name} onChange={(e) => { setName(e.target.value); }} />
                        </td>
                        <td>
                            <button className="btn-icon" onClick={add} disabled={!buttonEnabled}>
                                <AddIcon />
                            </button>
                        </td>
                    </tr>
                </tbody>
            </table>
        </div>
    )
}


const EditFieldRowsRow = ({ id, name, selectable, toggleSelectable, remove, optionNameChanged }) => {

    const [newName, setNewName] = useState(name);

    const nameChanged = (n) => {
        setNewName(n);
        if (optionNameChanged) {
            optionNameChanged(id, n);
        }
    }

    return (
        <tr>
            <td className="tr-max-w200">{id}</td>
            <td className="cursor-pointer " onClick={() => { toggleSelectable(id) }}>

                {selectable ? "Valbar" : "Ej valbar"}
            </td>
            <td>
                <input
                    style={{ minWidth: "300px" }}
                    type="text"
                    value={newName}
                    onChange={(e) => { nameChanged(e.target.value) }} />
                {(name !== newName ? "*" : "")}
            </td>
            <td>
                <button className="btn-icon" onClick={() => { remove(id); }} >
                    <TrashIcon />
                </button>
            </td>
        </tr>
    )
}
