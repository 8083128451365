import React, { Component, useState } from 'react';
import store from "../rdx/store";
import { ThreeColumnContainer } from './Shared/Formelements';
import { FieldInput } from "./Plants/FieldInputs/FieldInputs";
import { SearchOptionsViewer, SearchPublishFilter, SearchTextBox, SearchTypeDropDown } from './Shared/Search/SearchElements';
import { SearchHits } from './Shared/Search/SearchList';
import { PlantListDetailsDialog } from './Plants/components/PlantListDetailsDialog';
import { PlantHandler } from '../Handlers/PlantHandler';
import { SearchHandler } from '../Handlers/SearchHandler';
import { SelectOptionType } from './UI/UITYpes';

interface IProps { }
interface IState {

  list?: any[],
  val?: string,
  vals?: SelectOptionType[],
  //store: store.getState().data,
  showplantlistdialog?: boolean,
  stats?: any
}

export class Home extends Component<IProps, IState> {
  static displayName = Home.name;

  unsubscribe: any;
  statsTimer: any;

  constructor(props) {
    super(props);
    this.state = {
      list: [1, 2, 3, 4, 5, 6, 7, 8, 9].map(obj => { return { value: obj, label: obj + " text" }; }),
      val: "2",
      vals: [{ value: 2, label: "2 text" }, { value: 3, label: "3 text" }],
      showplantlistdialog: false,
      stats: null
    };

    this.inputChanged = this.inputChanged.bind(this);
    this.selectChanged = this.selectChanged.bind(this);
    this.refreshStats = this.refreshStats.bind(this);

  }
  componentDidMount() {
    this.unsubscribe = store.subscribe(() => {
      this.setState({
      });
    });

    this.statsTimer = setInterval(() => {
      PlantHandler.getStatistics().then(model => this.setState({ stats: model }));
    }, 5 * 60 * 1000);

    PlantHandler.getStatistics().then(model => this.setState({ stats: model }));
  }

  refreshStats() {
    PlantHandler.getStatistics().then(model => this.setState({ stats: model }));
  }

  componentWillUnmount() {
    this.unsubscribe();

    if (this.statsTimer) {
      clearInterval(this.statsTimer)
    }
  }


  inputChanged(e) {
    const name = e.target.name;
    const value = e.target.value;
    this.setState({ [name]: parseInt(value) });
  }
  selectChanged(name, data) {
    this.setState({ [name]: data });
  };

  render() {
    return (
      <div>
        <div className="filter-container">
          <div>
            <div>
              <button
                className={"btn btn-toggle " + (this.state.showplantlistdialog ? "pressed" : "")}
                onClick={() => { this.setState({ showplantlistdialog: !this.state.showplantlistdialog }) }}>
                Visa Växtlistor detaljer.
              </button>
            </div>
          </div>
          <hr />

          <ThreeColumnContainer>
            <div className="dbstats">Växter: <span> {this.state.stats?.numberOfPlants}</span></div>
            <div className="dbstats">Publicerade växter: <span> {this.state.stats?.numberOfPublicPlants} </span> </div>
            <div className="dbstats">Ej publicerade växter: <span>{this.state.stats?.numberOfPlants - this.state.stats?.numberOfPublicPlants} </span></div>
          </ThreeColumnContainer>
          <hr />
          <ThreeColumnContainer>

            <div className="filter-box">
              <label>Sök</label>
              <div>
                <SearchTextBox />
              </div>
            </div>

            <div className="filter-box">
              <label>Typ</label>
              <div>
                <SearchTypeDropDown />
              </div>
            </div>

            <div className="filter-box">
              <label>Publicerade</label>
              <div>
                <SearchPublishFilter />
              </div>
            </div>



          </ThreeColumnContainer>
        </div>
        <hr />
        <div>
          <button className="btn" onClick={() => { store.dispatch(SearchHandler.searchActionPlantsWithImagesWithoutPrimary) }}>
            Växter med bilder men utan "Primärbild"
          </button>
          {/* 
          <button className="btn" onClick={() => { store.dispatch(SearchHandler.searchActionPlantsWithImagesWithoutPrimary) }}>
            Växter med bilder men utan "Primärbild"
          </button> */}
        </div>
        {/* <hr />
        <div>
          <div>filter</div>
          <SearchOptionsViewer />
        </div> */}
        <hr />
        <SearchHits />
        {/* <DataInspector /> */}

        {this.state.showplantlistdialog && (
          <PlantListDetailsDialog />
        )}

      </div>
    );
  }
}
const RenderList = (props) => {
  if (props.list !== null && props.list.length > 0) {
    return (<table className="tbl">
      <thead>
        <tr>
          {props.fields.map(f => (
            <th key={f}>{f}</th>
          ))}
        </tr>
      </thead>
      <tbody>
        {props.list.map(item => (
          <tr key={item.id}>
            {props.fields.map(f => (
              <td key={f}>{item[f]}</td>
            ))}
          </tr>
        ))}
        <tr>
        </tr>
      </tbody>
    </table>)
  }
  return (null);
}
const SingleSelect = (props) => {
  const [value, setValue] = useState([""]);
  return (<FieldInput.SingleSelect fieldType="zone" value={value} onChange={e => { console.log(e); setValue(e); }}
  />);
}
