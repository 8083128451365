import { useEffect, useState } from "react";
import { BuildInfo } from "../BuildInfo";
import { FetchHandler } from "../Handlers/FetchHandler";


export type ApiBuildInfo = {
    hash: string,
    build: string,
    tick: number,
    branch: string
}

export const useBuild = () => {

    const clientBuildHash = BuildInfo.BuildHash;

    const checkBuild = async () => {
        const build: ApiBuildInfo = await FetchHandler.getJson("/api/build");
        console.log("build: ", build.hash, clientBuildHash);
        if (build.hash != clientBuildHash) {
            setShouldRefreshClient(true);
        }
    }

    useEffect(() => {
        setTimeout(() => {
            checkBuild();
        }, 5000);


        setInterval(() => {
            checkBuild()
        }, 10 * 60 * 1000);
    }, []);

    const [shouldRefreshClient, setShouldRefreshClient] = useState<boolean>(false);
    const [dismissed, setDismissed] = useState(false);

    return {
        shouldRefreshClient: shouldRefreshClient && (dismissed != true),
        dismissed: dismissed,
        dismiss: () => { setDismissed(true); },
        unDismiss: () => { setDismissed(false); },
    }
}