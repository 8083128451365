
import React, { Component, useEffect, useLayoutEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import Select from 'react-select';
import { FetchHandler } from '../../Handlers/FetchHandler';
import { MessageBoxes } from '../../Handlers/MessageBoxes';
import { FeedbackStateType, FeedbackType, useFeedback } from '../../hooks/useFeedback';
import { usePlant } from '../../hooks/usePlant';
import { useUsers } from '../../hooks/useUsers';
import { AdminIcon, AdminIcons } from '../Shared/AdminIcon';
import { ThreeColumnContainer } from '../Shared/Formelements';
import { FaIcon } from '../Shared/Icons';
import { ScreenHeader } from '../UI/Elements';
import { useFetch } from "./../../hooks/MiscHooks"



const epochToString = (epoch) => {
    if (epoch) {
        return new Date(epoch * 1000).toLocaleString();
    } else {
        return "-";
    }
}

export const Feedback = () => {

    const feedbackHandler = useFeedback();
    const plant = usePlant();

    useEffect(() => {
        if (feedbackHandler.items && feedbackHandler.items?.length > 0) {
            const plantids = Array.from(new Set(feedbackHandler.items.map(obj => obj?.plantid))).filter(id => id?.length > 8);
            plant.loadMultiple(plantids);
        }
    }, [feedbackHandler.items])


    return (
        <div>
            <ScreenHeader text="Feedback" icon={AdminIcons.comments} />
            <ThreeColumnContainer>
                <div>
                    <label>Status</label>
                    <Select
                        defaultValue={{ value: "none", label: "none" }}
                        options={feedbackHandler.stateOptions}
                        onChange={item => {
                            feedbackHandler.setSelectedState(item?.value)
                        }}
                    />
                </div>
                <div>

                    <button className='btn' onClick={() => { feedbackHandler.reLoad(); }}>
                        Ladda om
                    </button>

                </div>
            </ThreeColumnContainer>

            <hr />

            <div>
                <table className='tbl feedback-table'>
                    <thead>
                        <tr>
                            <th>Skärm</th>
                            <th>Sektion</th>
                            <th>Växt</th>
                            <th>Meddelande</th>
                            <th>Status</th>

                            <th>Namn</th>
                            <th>Email</th>
                            <th>Timestamp</th>
                        </tr>
                    </thead>
                    <tbody>
                        {feedbackHandler?.items && feedbackHandler.items?.filter(obj => obj?.state === feedbackHandler.selectedState).map(item => (
                            <TblRow key={item.id}
                                item={item}
                                selected={feedbackHandler.selected == item.id}
                                onClick={() => { feedbackHandler.setSelected(feedbackHandler.selected == item.id ? null : item.id); }}
                                onStateChanged={feedbackHandler.onChangeStateInSelected}
                                onDeleteClicked={() => feedbackHandler.onDeleteClicked(item.id)}
                                onResponseSend={e => feedbackHandler.sendResponse(item, e)}
                            />
                        ))}
                    </tbody>
                </table>
            </div>
        </div>
    )
}


const TblRow = ({ item, selected, onClick, onStateChanged, onDeleteClicked, onResponseSend }: { item: FeedbackType, selected: boolean, onClick: any, onStateChanged: any, onDeleteClicked: any, onResponseSend(e: string): void }) => {


    const users = useUsers();
    const plant = usePlant();
    const [deleteClicked, setDeleteClicked] = useState(false);
    const [responseText, setResponseText] = useState<string>(item?.response ?? "");

    const onResponseSendClicked = () => {
        onResponseSend(responseText);
        setResponseText("");
    }

    const plantName = plant.item(item.plantid)?.fields?.name;
    const plantSort = plant.item(item.plantid)?.fields?.specifictype;
    const plantFullName = plantName + (plantSort ? ", " + plantSort : "");

    return (
        <>
            <tr onClick={onClick} className='item'>
                <td>{item.screenname}</td>
                <td>{item.sectionname}</td>
                <td>{plantName}</td>
                <td>
                    <div className='truncate'>
                        {item.message}
                    </div>
                </td>
                <td>{item.state}</td>

                <td>{users.getUsersName(item?.senderuserid)}</td>
                <td>{users.getUsersEmail(item?.senderuserid)}</td>
                <td>{epochToString(item.sys_datecreated_utc_epoch)}</td>
            </tr>
            {selected && (
                <>
                    <tr>
                        <td colSpan={8} className='details-pane-row'>
                            <div className='details-pane'>
                                <div className='details-info'>

                                    <div className='screenname'>{item.screenname}</div>
                                    <div className='sectionname'>{item.sectionname}</div>
                                    <div className='plantid'>{!!item.plantid ? "Växt : " + plantFullName : "Ingen växt"}</div>
                                    <div className='state'>Status: {item.state}</div>
                                    <div className='ts'>{epochToString(item.sys_datecreated_utc_epoch)}</div>

                                </div>

                                <div className='details-message'>
                                    <div className='details-message-text'>
                                        {item.message}
                                    </div>
                                </div>

                                <div className='details-response'>
                                    <textarea value={responseText} onChange={e => setResponseText(e.target.value)}></textarea>
                                    {item?.state !== "processed" && (
                                        <button className='btn' onClick={onResponseSendClicked}>Svara</button>
                                    )}
                                </div>

                                <div className='details-buttons'>

                                    <button className='btn' disabled={item.state === "none"} onClick={() => {
                                        const n: FeedbackType = { ...item, state: "none" };
                                        onStateChanged(n);
                                    }}>none</button>

                                    <button className='btn' disabled={item.state === "inprogress"} onClick={() => {
                                        const n: FeedbackType = { ...item, state: "inprogress" };
                                        onStateChanged(n);
                                    }}>inProgress</button>

                                    <button className='btn' disabled={item.state === "processed"} onClick={() => {
                                        const n: FeedbackType = { ...item, state: "processed" };
                                        onStateChanged(n);
                                    }}>Processed</button>


                                    {item.state !== "hide" && (
                                        <button className='btn' onClick={() => {
                                            const n: FeedbackType = { ...item, state: "hide" };
                                            onStateChanged(n);
                                        }}>hide</button>
                                    )}

                                    {(item.state === "hide" && deleteClicked === false) && (
                                        <button className='btn' onClick={() => setDeleteClicked(!deleteClicked)}>Radera</button>
                                    )}
                                    {(item.state === "hide" && deleteClicked === true) && (
                                        <button className='btn' onClick={() => {
                                            onDeleteClicked();
                                            setDeleteClicked(false);
                                        }}>Tryck för att Radera</button>
                                    )}


                                </div>
                            </div>
                        </td>
                    </tr>
                    <tr></tr>
                </>
            )}
        </>
    )
}