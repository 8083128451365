import React, { Component } from 'react';
//import { Link } from 'react-router-dom';
import { FetchHandler } from "../../Handlers/FetchHandler";
import { fn } from "../../Tools/ArrayFunctions";
import { FormTextInput } from "../Shared/Formelements";
import { MessageBoxes } from "../../Handlers/MessageBoxes";
import { LoadingElement } from "../Shared/LoadingElement";
import { StoreFillerHandler } from "../../rdx/StoreFillerHandler";
import { useSelector } from 'react-redux';
import { Operations } from '../../rdx/Operations';
import { AdminIcons } from '../Shared/AdminIcon';
import { ScreenHeader } from '../UI/Elements';

interface IProps { }
interface IState {
  colors?: string[],
  types?: any[],
  families?: any[],
  id?: string,
  hexNumber?: string,
  name?: string
}

export class Colors extends Component<IProps, IState> {
  static displayName = Colors.name;

  constructor(props) {
    super(props);

    this.state = {
      colors: [],
      id: "",
      hexNumber: "",
      name: ""
    };
    this.onChange = this.onChange.bind(this);
    this.addClicked = this.addClicked.bind(this);
    this.loadList = this.loadList.bind(this);

  }
  onChange(e) {
    if (e.target.name === "id") {
      this.setState({ [e.target.name]: e.target.value.toUpperCase() })
    } else {
      this.setState({ [e.target.name]: e.target.value })
    }
  }
  addClicked() {

    if (this.state.id.length * this.state.hexNumber.length * this.state.name.length <= 0) {
      MessageBoxes.warning("Ange all fält!");
      return;
    }


    FetchHandler.postJson("/api/plantinfo/colors/add", {
      id: this.state.id,
      hexNumber: this.state.hexNumber,
      name: this.state.name
    })
      .then(data => {
        this.loadList();
        this.setState({
          id: "",
          hexNumber: "",
          name: ""
        });
        StoreFillerHandler.ensureCache(true);
      });
  }
  loadList() {
    FetchHandler.getJson("/api/plantinfo/colors").then(data => {
      this.setState({
        colors: data,
        types: fn.orderAsc(fn.groups(data, "type")),
        families: fn.orderAsc(fn.groups(data, "family"))
      });
    })
  }
  componentDidMount() {
    this.loadList();
  }


  render() {
    return (
      <div>
        <ScreenHeader text="Färger" icon={AdminIcons.palette} />
        <div>
          <FormTextInput title="Kod" invalid={false} name="id" value={this.state.id} onChange={this.onChange} />
          <FormTextInput title="hexNumber" invalid={false} name="hexNumber" value={this.state.hexNumber} onChange={this.onChange} />
          <FormTextInput title="Namn" invalid={false} name="name" value={this.state.name} onChange={this.onChange} />
          <button className="btn" onClick={this.addClicked}>Add</button>
        </div>
        <hr />
        {this.state.colors.length > 0 && (
          <div className='pt30'>
            <h3>Valbara</h3>
            <ColorTable selectable={true} />
            <h3>Ej valbara</h3>
            <ColorTable selectable={false} />
          </div>
        )}
        <div>
          <LoadingElement visible={this.state.colors.length === 0} />
        </div>
      </div>
    );
  }
}

const ColorTable = ({ selectable }) => {
  let colors = useSelector(state => state.data.colors.filter(obj => obj.selectable === selectable));
  colors.sort((a, b) => {
    if (a.hexNumber < b.hexNumber) { return 1 }
    if (a.hexNumber > b.hexNumber) { return -1 }
    return 0;
  })
  return (

    <table className="tbl" style={{ width: "50%" }}>
      <thead>
        <tr>
          <th colSpan={2}>hexNumber</th>
          <th>id</th>
          <th>Namn</th>
          <th>Valbar</th>
        </tr>
      </thead>
      <tbody>
        {colors && colors.map(c => (
          <tr key={c.id}>
            <td style={{ width: "25px" }}>
              <ColorCircle hexNumber={c.hexNumber} />
            </td>
            <td>{c.hexNumber}</td>
            <td>{c.id}</td>
            <td>{c.name}</td>
            <td>
              <SelectableButton colorId={c.id} />
            </td>
            {/* <td>{c.selectable ? "Valbar" : "Ej valbar"}</td> */}
          </tr>
        ))}
      </tbody>
    </table>
  )
}

const SelectableButton = ({ colorId }) => {
  const colors = useSelector(state => state.data.colors);
  const c = colors.filter(m => m.id === colorId)[0];

  const toggleSelectable = () => {
    Operations.toggleSelectableColor(colorId).then(success => {
    })
  }

  return (
    <button className="btn-link" onClick={toggleSelectable}>
      {c.selectable ? "Valbar" : "Ej Valbar"}
    </button>
  )
}

const ColorCircle = (props) => {
  return (
    <div className="color-circle" style={{ backgroundColor: props.hexNumber }}>

    </div>
  );
}
