import { useEffect, useState } from "react";
import { useSelector, useDispatch } from 'react-redux';
import { FetchHandler } from "../../Handlers/FetchHandler";
import { useDebounce } from "../../hooks/useDebounce";
import { RdxStore } from "../../rdx/ReduxTypes";
import { CHANGE_ELASTIC_SEARCH_PLANT } from "../../rdx/appReducer";

export const useElasticSearch = () => {

    const dispatch = useDispatch();

    const searchPlants = useSelector((state: RdxStore) => state.app.elasticSearchPlant);
    const setSearchPlants = (e: string) => {
        dispatch({ type: CHANGE_ELASTIC_SEARCH_PLANT, payload: e });
    }
    const [elasticcheck_filterout_checked, setElasticcheck_filterout_checked] = useState<boolean>(false);

    const [plants, setPlants] = useState<SearchResultPlants>(undefined);
    const [loadingPlants, setLoadingPlants] = useState<boolean>(false);

    const [users, setUsers] = useState<SearchResultUsers>(undefined);
    const [loadingUsers, setLoadingUsers] = useState<boolean>(false);

    const [blogs, setBlogs] = useState<SearchResultBlogs>(undefined);
    const [loadingBlogs, setLoadingBlogs] = useState<boolean>(false);

    const [pois, setPois] = useState<SearchResultPoi>(undefined);
    const [loadingPois, setLoadingPois] = useState<boolean>(false);

    const [posts, setPosts] = useState<SearchResultPosts>(undefined);
    const [loadingPosts, setLoadingPosts] = useState<boolean>(false);

    // Autocomplete
    const [poiSearch, setPoiSearch] = useState<string>("");
    const poiDebouncedQuery = useDebounce(poiSearch, 300);
    const [onSearchPPoisAutoComplete, setOnSearchPPoisAutoComplete] = useState<string[]>([]);
    useEffect(() => {
        const fetchResults = async () => {
            const data = await FetchHandler.getJson("/api/elastic/poi/autocomplete?q=" + encodeURIComponent(poiDebouncedQuery));
            setOnSearchPPoisAutoComplete(data)
        }
        fetchResults();
    }, [poiDebouncedQuery]);


    const onSearchPlants = async (search: string, options?: { pageSize?: number, filterOutSort?: boolean }) => {
        setLoadingPlants(true);
        var t = await FetchHandler.getJson("/api/elastic/plant/search?q=" + encodeURIComponent(search) + (!!options?.pageSize ? "&pz=" + options?.pageSize : "") + (!!options?.filterOutSort ? "&filtersort=true" : "&filtersort=false"))
        setLoadingPlants(false);
        setPlants(t);
    }

    useEffect(() => {
        if (!!searchPlants) {
            onSearchPlants(searchPlants);
        }
    }, [])



    return {
        plants: plants,
        loadingPlants: loadingPlants,
        onSearchPlants: onSearchPlants,
        searchPlants: searchPlants, setSearchPlants: setSearchPlants,
        elasticcheck_filterout_checked: elasticcheck_filterout_checked, setElasticcheck_filterout_checked: setElasticcheck_filterout_checked,

        users: users,
        loadingUsers: loadingUsers,
        onSearchUsers: async (search: string, pageSize?: number) => {
            setLoadingUsers(true);
            let t: SearchResultUsers = await FetchHandler.getJson("/api/elastic/users/search?q=" + encodeURIComponent(search) + (!!pageSize ? "&pz=" + pageSize : ""))
            let hits = [...t?.hits];
            if (hits) {
                hits.sort((a, b) => {
                    if (a.useractivityscore > b.useractivityscore) { return -1; }
                    else if (a.useractivityscore < b.useractivityscore) { return 1; }
                    else { return 0; }
                })
            }

            t.hits = hits;

            setLoadingUsers(false);
            setUsers(t);
        },

        blogs: blogs,
        loadingBlogs: loadingBlogs,
        onSearchBlogs: async (search: string, pageSize?: number) => {
            setLoadingBlogs(true);
            let t: SearchResultBlogs = await FetchHandler.getJson("/api/elastic/blog/search?q=" + encodeURIComponent(search) + (!!pageSize ? "&pz=" + pageSize : ""))
            let hits = [...t?.hits];
            if (hits) {
                hits.sort((a, b) => {
                    if (a.publisheddatetimeepoch > b.publisheddatetimeepoch) { return -1; }
                    else if (a.publisheddatetimeepoch < b.publisheddatetimeepoch) { return 1; }
                    else { return 0; }
                })
            }
            t.hits = hits;
            setLoadingBlogs(false);
            setBlogs(t);
        },



        posts: posts,
        loadingPosts: loadingPosts,
        onSearchPosts: async (search: string, pageSize?: number) => {
            setLoadingPosts(true);
            let t: SearchResultPosts = await FetchHandler.getJson("/api/elastic/posts/search?q=" + encodeURIComponent(search) + (!!pageSize ? "&pz=" + pageSize : ""))
            let hits = [...t?.hits];
            t.hits = hits;
            setLoadingPosts(false);
            setPosts(t);
        },


        pois: pois,
        loadingPois: loadingPois,
        onSearchPPoisChanged: async (e: string) => {
            setPoiSearch(e);
        },
        poisAutoComplete: onSearchPPoisAutoComplete,
        onSearchPPois: async (search: string, pageSize?: number) => {
            setLoadingPois(true);
            var t = await FetchHandler.getJson("/api/elastic/poi/search?q=" + encodeURIComponent(search) + (!!pageSize ? "&pz=" + pageSize : ""))
            setLoadingPois(false);
            setPois(t);
        },
        poiTypeName: (e: HitPoiType) => {
            if (e === "garden") {
                return "Trädgård"
            } else if (e === "store") {
                return "Butik"
            } else if (e === "municipality") {
                return "Kommun"
            } else {
                return "-";
            }
        }

    }
}


// Plants
export interface SearchResultPlants {
    hits: HitPlant[]
    total: number
    suggestions: string[],
    durationms: number
}
export interface HitPlant {
    Id: string
    Public: boolean
    Name: string
    name_lc: string
    specifictype: string
    specifictype_lc: string
    namelatin: string
    namelatin_lc: string
    nameenglish?: string
    nameenglish_lc?: string
    full_name_lc: string
    full_name_search_lc: string
    Type: string
    planttypeid: string
    flowercolor?: string[]
    sun_exposure: string[]
    florescence: string[]
    mature_height?: string[]
    seed_pre_sown_month?: string[]
    wateramount: string[]
    life_cycle: string[]
    soil: string[]
    properties: string[]
    pruning?: string[]
    seed_directly_sown_month?: string[]
    diseases_and_pests?: string[]
    plant_characteristics?: string[]
    toughness_a_to_d?: string[]
    nutrition?: string[]
    harvesting: any
    planting_month?: string[]
    color: any
    scent?: string[]
    rose_type?: string[]
    egenskaper_ros?: string[]
    foliagecolor?: string[]
    zone?: string
    mature_spread?: string
    planting_depth?: string
    plant_row_distance_cm?: string
    plant_distance_cm?: string
    plant_sowing_depth_cm?: string
    germination_time_days?: string
    development_days: any
    public_description?: string
    snippet_general_desription?: string
    snippet_grow_and_plant: string
    snippet_takle_care: string
    snippet_fertilize: any
    snippet_crop?: string
    snippet_advice?: string
    optimal_groning?: string
    weeks_before?: string
    sadd_till_skord?: string
    familyname: string
    sys_datecreated_utc_epoch: number
    imageid?: string
    short_id: string
    alternativekeywords?: string
    alternativenames?: string
}

// Users
export interface SearchResultUsers {
    hits: HitUser[]
    total: number
    suggestions: string[],
    durationms: number
}
export interface HitUser {
    id: string
    name: string
    name_lc: string
    gardenname: string
    gardenname_lc: string
    gardendescription: string
    privacyprofile: string
    zone: string
    useractivityscore: number
    profileimage: string
    gardenimageId: string
    municipality: string
    municipality_lc: string
    municipalityvisibility: string
    location?: Location
    sys_datemodified_utc_epoch: number
    sys_datecreated_utc_epoch: number
}

// Blog
export interface SearchResultBlogs {
    hits: HitBlog[]
    total: number
    durationms: number
}

export interface HitBlog {
    id: string
    idnumber: number
    image: string
    authorid: number
    authorimage: string
    author: string
    title: string
    title_lc: string
    titlehtmldecoded: string
    titleurlencoded: string
    html: string
    html_lc: string
    htmlexcerpt: string
    htmlexcerptdecoded: string
    permalink: string
    publisheddatetime: string
    publisheddatetimeepoch: number
    visibleonweb: boolean
}
// Posts
export interface SearchResultPosts {
    hits: HitPost[]
    total: number
    suggestions: string[],
    durationms: number
}
export interface HitPost {
    id: string
    userid: string
    text: string
    all_text_lc: string
    taggedplantids: string[]
    taggeduserids: string[]
    location: string
    latitude: number
    longitude: number
    locationname: string
    poststate: string
    numberofcomments: number
    numberoflikes: number
    alias: string
    alias_lc: string
    profileimage: string
    imageids: string[]
    hasimage: boolean
    epochposted: number
    gardenitemid: string
    gardenitemname: string
    gardenitemname_lc: string
    soil: string[]
    sunexposure: string[]
}
// POI
export interface SearchResultPoi {
    hits: HitPoi[]
    total: number
    suggestions: string[],
    durationms: number
}
export interface HitPoi {
    id: string
    location: Location
    title: string
    title_lc: string
    description: string
    description_lc: string
    addressline1: string
    addressline2: string
    url: string
    imageid: string
    type: HitPoiType[]
    deleted: boolean
    published: boolean
    sys_datecreated_utc_epoch: number
    hasimage: boolean
}
export type HitPoiType = "municipality" | "store" | "garden";
export interface Location {
    Latitude: number
    Longitude: number
}