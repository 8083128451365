
export type AdItemType = {
    id?: string,
    name?: string,
    clientId?: string,
    published?: boolean,
    type?: AdItemTypeType,

    link?: string,
    linkText?: string,

    title01?: string,
    title02?: string,
    title03?: string,
    title04?: string,
    title05?: string,

    imageUrl?: string,
    plantIds?: string,
}
export const AdItemTypeDefaultValue: AdItemType = {
    id: "",
    name: "",
    clientId: "",
    type: ""
}

// From 'OhGardenWeb.Lib.Models.BasicModels.AdModels.AdItemTypes'
export type AdItemTypeType = "" |
    // Plants
    "plantsearch" |
    "plantdetail_main" |
    "plantdetail_main_web" |

    // discovery
    "discovery_featured" |
    "discovery_featured_secondary" |
    "discovery_featured_plants" |

    "monthly_article" |
    // discovery
    "calendar_list";

export type AdClientType = {
    id: string,
    name: string,
}


export type AdItemStatisticsType = {
    num_views: number,
    num_press: number,
    num_users: number,
    num_users_view: number,
    num_users_press: number
}