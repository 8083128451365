import store from '../rdx/store';
import { CHANGE_SEARCH_SEARCHING, CHANGE_SEARCH_LIST } from '../rdx/reducers';
import { FetchHandler } from "./FetchHandler";
import { MessageBoxes } from './MessageBoxes';
import { Reloads } from '../rdx/StoreFillerHandler';

function plantHandler() {
    const self = this;

    self.publishPlants = async () => {
        let list = [...store.getState().search.searchList];

        let a = [];
        for (let i = 0; i < list.length; i++) {
            if (list[i].selected) {
                list[i].public = true;
                a.push(list[i].id);
            }
        }
        const postObj = {
            ids: a,
            publish: true
        };
        const success = await FetchHandler.postJson("/api/plant/publish", postObj);

        if (success) {
            store.dispatch({ type: CHANGE_SEARCH_SEARCHING, payload: false });
            store.dispatch({ type: CHANGE_SEARCH_LIST, payload: list });
            return true;
        } else {
            MessageBoxes.warning("update failed!");
            return false;
        }

    };
    self.unPublishPlants = async () => {
        let list = [...store.getState().search.searchList];

        let a = [];
        for (let i = 0; i < list.length; i++) {
            if (list[i].selected) {
                list[i].public = false;
                a.push(list[i].id);
            }
        }
        const postObj = {
            ids: a,
            publish: false
        };

        const success = FetchHandler.postJson("/api/plant/publish", postObj);

        if (success) {
            store.dispatch({ type: CHANGE_SEARCH_SEARCHING, payload: false });
            store.dispatch({ type: CHANGE_SEARCH_LIST, payload: list });
            return true;
        } else {
            MessageBoxes.warning("update failed!");
            return false;
        }
        ;
    };

    self.updatePlantsField = async (plantids, field, value, keepExistingValues) => {
        try {
            store.dispatch({ type: CHANGE_SEARCH_SEARCHING, payload: true });
            const postObj = {
                plantids: plantids,
                field: field,
                value: value,
                keepExistingValues: keepExistingValues
            };

            let list = [...store.getState().search.searchList];

            const result = await FetchHandler.postJson("/api/plants/updatefield", postObj);

            if (result.success) {
                list.filter(obj => plantids.indexOf(obj.id) >= 0).forEach(obj => {
                    if (keepExistingValues) {
                        let oldraw = obj.fields[field.toLowerCase()];
                        let old = oldraw ? JSON.parse(oldraw) : [];
                        value.forEach(v => {
                            if (old.indexOf(v) < 0) {
                                old.push(v);
                            }
                        })
                        obj.fields[field.toLowerCase()] = JSON.stringify(old);
                    } else {
                        obj.fields[field.toLowerCase()] = JSON.stringify(value);

                    }
                });
                store.dispatch({ type: CHANGE_SEARCH_SEARCHING, payload: false });
                store.dispatch({ type: CHANGE_SEARCH_LIST, payload: list });
            } else {
                MessageBoxes.warning(result.message, true);
            }

            return result.success;
        }
        catch (e) {
            MessageBoxes.warning("Fel:" + e, true);
            return false;
        }

    };


    self.updatePlantsType = async (plantids, type, typeText) => {
        try {
            store.dispatch({ type: CHANGE_SEARCH_SEARCHING, payload: true });
            const postObj = {
                plantids: plantids,
                planttypeId: type
            };
            let list = [...store.getState().search.searchList];

            const success = await FetchHandler.postJson("/api/plants/updatetype", postObj);

            if (success) {
                list.filter(obj => plantids.indexOf(obj.id) >= 0).forEach(obj => {
                    obj.plantTypeId = type;
                    obj.plantTypeName = typeText;
                    obj.fields.planttype = type;
                });
                store.dispatch({ type: CHANGE_SEARCH_SEARCHING, payload: false });
                store.dispatch({ type: CHANGE_SEARCH_LIST, payload: list });
            } else {
                MessageBoxes.warning("Update failed");
            }

            return success;
        }
        catch (e) {
            MessageBoxes.warning("Fel:" + e, true);
            return false;
        }

    };

    self.dropPlantsField = async (plantids, field) => {
        store.dispatch({ type: CHANGE_SEARCH_SEARCHING, payload: true });

        const success = await FetchHandler.postJson("/api/plants/dropfield", {
            plantids: plantids,
            field: field
        });

        let list = [...store.getState().search.searchList];

        if (success) {
            list.filter(obj => plantids.indexOf(obj.id) >= 0).forEach(obj => {
                delete obj.fields[field.toLowerCase()];
            });
            store.dispatch({ type: CHANGE_SEARCH_SEARCHING, payload: false });
            store.dispatch({ type: CHANGE_SEARCH_LIST, payload: list });
        }


        return success;

    };

    self.updateTwoFields = async (id, field1, field2, field1Value, field2Value) => {

        store.dispatch({ type: CHANGE_SEARCH_SEARCHING, payload: true });
        const success = await FetchHandler.postJson("/api/plants/updatetwofields", {
            id: id,
            field1: field1,
            field2: field2,
            field1Value: field1Value,
            field2Value: field2Value
        });

        let list = [...store.getState().search.searchList];

        if (success) {
            list.filter(obj => obj.id === id).forEach(obj => {
                obj.fields[field1.toLowerCase()] = field1Value;
                obj.fields[field2.toLowerCase()] = field2Value;
            });
            store.dispatch({ type: CHANGE_SEARCH_SEARCHING, payload: false });
            store.dispatch({ type: CHANGE_SEARCH_LIST, payload: list });
        }

        return success;

    };


    self.removeFieldOptions = async (selectedField, selectedOptions) => {
        const fieldId = selectedField.id;
        const optionKeys = Object.keys(selectedOptions);

        const postObj = {
            fieldId: fieldId,
            optionKeys: optionKeys
        }

        const response = await FetchHandler.postJson("/api/plantfields/removeoptions", postObj);

        Reloads.reloadPlantFields();

        return response;
    };

    self.consolidateFieldOptions = async (fieldId, fromKeys, targetOptionKey) => {

        const postObj = {
            fieldId: fieldId,
            fromKeys: fromKeys,
            targetKey: targetOptionKey
        }

        const response = await FetchHandler.postJson("/api/plantfields/consolidateoptions", postObj);

        Reloads.reloadPlantFields();

        return response;
    };

    self.getStatistics = async () => {
        // api/plants/stats
        const response = await FetchHandler.getJson("/api/plants/stats");
        return response;
    }

}


const PlantHandler = new plantHandler();

export { PlantHandler };