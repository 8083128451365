import React, { useEffect, useState } from "react"
import { FetchHandler } from "../../Handlers/FetchHandler";
import { ClipBoardDragContainer } from "../ClipBoard/ClipBoard";
import { EditCheckbox, EditStringInput, EditTextArea } from "../FormComponents/EditInput";
import { AdminIcon, AdminIcons } from "../Shared/AdminIcon";
import { ThreeColumnContainer } from "../Shared/Formelements";
import { ToolBar, ToolBarClean, ToolbarItemType } from "../ToolBar/ToolBar";
import { ScreenHeader, Tbl } from "../UI/Elements";
import { Overlay } from "../UI/Overlay";
import { BlogPostAuthorType, BlogPostType, useBlog } from "./useBlog";

export const BlogAuthors = () => {
    const ui = useBlog();

    const [refreshDisabled, setRefreshDisabled] = useState<boolean>(false);

    const tools: ToolbarItemType[] = [
        {
            id: "1", label: "Uppdatera listan", icon: refreshDisabled ? AdminIcons.spinner : AdminIcons.refresh, onClick: async () => {
                setRefreshDisabled(true);
                await ui.refreshDatabase()
                setRefreshDisabled(false);
            }, disabled: refreshDisabled
        },
        // { id: "2", label: "Gör 'Featured'", icon: AdminIcons.checkbox_checked, onClick: () => { ui.makeFeatured(ui.selectedItem?.idNumber) }, disabled: !!ui.selectedItem?.featured },
        // { id: "2", label: "Ta bort 'Featured'", icon: AdminIcons.checkbox_checked, onClick: () => { ui.removeFeatured(ui.selectedItem?.idNumber) }, disabled: !ui.selectedItem?.featured },
    ];

    const [selectedId, setSelectedId] = useState<string>(null);


    return (
        <div className="screen-blogauthors">
            <Overlay visible={ui.isWaiting} />
            <ScreenHeader text="Blogg skribenter" icon={AdminIcons.blog} />
            <div>
                <ToolBar tools={tools} />
            </div>
            <div>
                <table className="tbl">
                    <thead>
                        <tr>
                            <th>
                                Namn
                            </th>
                            <th>
                                Visa på blog
                            </th>
                            <th>
                                Visat namn
                            </th>
                            <th>
                                Visad rubrik
                            </th>
                        </tr>
                    </thead>
                    <tbody>
                        {ui.authors?.map(item => (
                            <AuthorRow
                                key={item?.id} item={item}
                                selected={selectedId === item?.id}
                                rowClicked={() => setSelectedId(selectedId === item?.id ? "" : item?.id)}
                                onSave={(e, type) => {
                                    if (type == "model") {
                                        ui.updateAuthors(item?.id, e);
                                    } else {
                                        ui.updateIsAuthor(item?.id, e?.isauthor);
                                    }
                                }}
                            />
                        ))}
                    </tbody>
                </table>
            </div>
        </div>
    )
}
type AuthorRowSaveType = "model" | "isauthor";
const AuthorRow = ({ item, selected, rowClicked, onSave }: { item: BlogPostAuthorType, selected: boolean, rowClicked(): void, onSave(e: BlogPostAuthorType, type: AuthorRowSaveType): void }) => {

    const [changed, setChanged] = useState<boolean>(false);
    const [open, setopen] = useState(selected);
    const [warningSaveVisible, setWarningSaveVisible] = useState<boolean>(false);

    const [change, setChange] = useState<BlogPostAuthorType>({});

    useEffect(() => {
        if (selected == true) {
            setopen(selected)
            setWarningSaveVisible(false);
        } else if (!changed && selected == false) {
            setopen(selected)
            setWarningSaveVisible(false);
        } else {
            setWarningSaveVisible(true);
        }
    }, [selected])


    const edit = (e: BlogPostAuthorType) => {
        setChanged(true);
        const c = { ...change, ...e };
        setChange(c)
        console.log(c)
    }

    const editIsAuthor = () => {
        onSave({ isauthor: item?.isauthor ? false : true }, "isauthor");
    }
    return (
        <>
            <tr key={item?.id} onClick={rowClicked} className={"author-row" + (item?.isauthor ? " author" : "")}>
                <Tbl.Td text={item?.name} />
                <Tbl.Td text={item?.isauthor ? "JA" : "NEJ"} />
                <Tbl.Td text={item?.publicname} />
                <Tbl.Td text={item?.publicheader} />
            </tr>
            {open && (
                <>
                    <tr />
                    <tr className={"author-row-edit"}>
                        <td colSpan={4}>
                            <div className={"author-row-edit-container" + (warningSaveVisible ? " warning-save-visible" : "")}>
                                {/* Info */}
                                <div>
                                    <EditStringInput title={"Publikt namn"} defaultValue={item?.publicname} onChange={e => edit({ publicname: e })} />
                                    <EditStringInput title={"Publik rubrik"} defaultValue={item?.publicheader} onChange={e => edit({ publicheader: e })} />
                                    <EditTextArea title={"Publik beskrivning"} defaultValue={item?.publicdescription} onChange={e => edit({ publicdescription: e })} />
                                </div>


                                {/* Contact */}
                                <div>
                                    <EditStringInput title={"Web"} defaultValue={item?.website} onChange={e => edit({ website: e })} placeholder="" />
                                    <EditStringInput title={"E-Post"} defaultValue={item?.email} onChange={e => edit({ email: e })} placeholder="" />
                                    <EditStringInput title={"Oh!Garden ID"} defaultValue={item?.ohgardenid} onChange={e => edit({ ohgardenid: e })} placeholder="" />
                                    <EditStringInput title={"Oh!Garden namn"} defaultValue={item?.ohgardennamn} onChange={e => edit({ ohgardennamn: e })} placeholder="" />
                                    <EditStringInput title={"Instagram"} defaultValue={item?.instagramhandle} onChange={e => edit({ instagramhandle: e })} placeholder="" />
                                    <EditStringInput title={"Facebook"} defaultValue={item?.facebookhandle} onChange={e => edit({ facebookhandle: e })} placeholder="" />
                                    <EditStringInput title={"Twitter"} defaultValue={item?.twitterhandle} onChange={e => edit({ twitterhandle: e })} placeholder="" />
                                    <EditStringInput title={"TikTok"} defaultValue={item?.tiktokhandle} onChange={e => edit({ tiktokhandle: e })} placeholder="" />

                                </div>

                                <div>
                                    <EditCheckbox title={"Visa på blog"} defaultValue={item?.isauthor} onChange={editIsAuthor} />
                                </div>

                                <div></div>

                                {/* Actions */}
                                <div>
                                    <ToolBarClean
                                        tools={[
                                            {
                                                id: "update", label: "Uppdatera", onClick: () => {
                                                    onSave({ ...change }, "model");
                                                    setChange({})
                                                    setChanged(false);
                                                }, icon: AdminIcons.save, disabled: !changed
                                            },
                                            {
                                                id: "cancel", label: "Ångra ändringar", onClick: () => {
                                                    setChanged(false);
                                                    setWarningSaveVisible(false);
                                                    setopen(false);
                                                }, icon: AdminIcons.trashcan, disabled: !warningSaveVisible
                                            },
                                        ]}
                                    />
                                </div>


                            </div>
                        </td>
                    </tr>
                </>
            )}
        </>
    )

}
