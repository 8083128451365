import React, { ReactNode } from "react";
import { Link } from 'react-router-dom';
import { AdminIcon, AdminIcons } from "../Shared/AdminIcon";



export type ToolbarItemType = {
    id: string,
    icon?: string,
    label: string,
    onClick?(): void,
    disabled?: boolean,
    hidden?: boolean,
    navigationLink?: string,
    isSpacer?: boolean
}

export const ToolBar = ({ tools, children }: { tools: ToolbarItemType[], children?: ReactNode }) => {


    return (
        <div className="toolbar">
            {tools?.filter(m => !(m?.hidden === true))?.map(t => (
                <Tool key={t?.id} item={t} />
            ))}
            {children}
        </div>
    )
}
export const ToolBarClean = ({ tools }: { tools: ToolbarItemType[] }) => {
    return (
        <div>
            {tools?.map(t => (
                <Tool key={t?.id} item={t} />
            ))}
        </div>
    )
}

export const Tool = ({ item }: { item: ToolbarItemType }) => {

    const disabled = item?.disabled || item.onClick == null;

    if (item?.isSpacer) {
        return (<div className="toolbar-item-spacer"></div>)
    }

    return (
        <div className={"toolbar-item " + (disabled === true ? "disabled" : "")} onClick={disabled === true ? null : item?.onClick}>
            <AdminIcon icon={item?.icon} />
            <div className="toolbar-item-label">
                {item?.label}
            </div>
        </div>
    )

}


export const CustomTool = ({ children, disabled, icon }: { children: ReactNode, disabled?: boolean, icon?: string }) => {


    return (
        <div className={"toolbar-item " + (disabled === true ? "disabled" : "")}>
            <AdminIcon icon={icon} />
            <div className="toolbar-item-label">
                {children}
            </div>
        </div>
    )

}


export const ActionToolsList = ({ tools }: { tools: ToolbarItemType[] }) => {
    return (
        <div className="action-tools-list">
            {tools?.map(t => {

                if (!!t.navigationLink) {
                    return (
                        <Link to={t.navigationLink}>
                            <div key={t.id} className="item">
                                <div className="icon">
                                    <AdminIcon icon={t?.icon} />
                                </div>
                                <div className="text">
                                    {t?.label}
                                </div>
                            </div>
                        </Link>
                    )

                }


                return (
                    <div key={t.id} className="item">
                        <div className="icon">
                            <AdminIcon icon={t?.icon} />
                        </div>
                        <div className="text">
                            {t?.label}
                        </div>
                    </div>
                )
            })}
        </div>
    )
}


export const TabSelector = ({ tabs, selectedId }: { tabs: ToolbarItemType[], selectedId: string }) => {

    return (
        <div className="tab-selector-container">
            {(tabs ?? []).map(m => (
                <div key={m?.id} className={"tab-selector-item" + (selectedId == m?.id ? " selected" : "")} onClick={m?.onClick}>
                    <div className="tab-selector-item-label">
                        {m?.label}
                    </div>
                </div>
            ))}
            <div className="tab-selector-item-filler"></div>
        </div>
    )

}