import React, { useEffect, useState } from "react"
import { Link } from 'react-router-dom';
import { useSelector, useDispatch } from 'react-redux';
import { FetchHandler } from "../../Handlers/FetchHandler"
import { AdminIcons } from "../Shared/AdminIcon"
import { ThreeColumnContainer } from "../Shared/Formelements"
import { ToolBar } from "../ToolBar/ToolBar"
import { ScreenHeader } from "../UI/Elements"
import { Overlay } from "../UI/Overlay"
import { RdxStore } from "../../rdx/ReduxTypes";
import { CHANGE_TOOLS_PLANTIMAGES } from "../../rdx/toolsreducer";

export const PlantImageOperations = () => {

    const ui = usePlantImageOperations();

    return (
        <div>
            <Overlay visible={ui.loading} />
            <ScreenHeader text="Växter med GAP som primärbild" icon={AdminIcons.images} />
            <div>
                <ToolBar tools={[
                    { id: "0", label: "Ladda om sidan", icon: AdminIcons.refresh, onClick: ui.load, },
                    { id: "1", label: "Föregående sida", icon: AdminIcons.arrowleft, onClick: ui.goPrev, disabled: ui.page == 0 },
                    { id: "2", label: "Nästa sida", icon: AdminIcons.arrowright, onClick: ui.goNext, },
                ]} />
            </div>
            <div>
                Sida: {ui.page + 1}
            </div>
            <div>
                <table className="tbl">
                    <thead>
                        <tr>
                            <th></th>
                            <th>GAP</th>
                            <th>Namn</th>
                            <th>Sort</th>
                            <th>Antal bilder</th>
                        </tr>
                    </thead>
                    <tbody>
                        {ui.data?.map((item, idx) => (
                            <tr key={item?.imageid + item?.plantid}>
                                <td>
                                    {item?.n + 1}
                                </td>
                                <td>
                                    {item?.plant?.imageid === item?.imageid && (
                                        <div>X</div>
                                    )}
                                </td>

                                <td>
                                    <Link to={"/plants/plantimages/" + item?.plantid}>
                                        {item?.plant?.name}
                                    </Link>
                                </td>
                                <td>
                                    {item?.plant?.specifictype}
                                </td>
                                <td>
                                    {item?.numberofimages}
                                </td>

                            </tr>
                        ))}
                    </tbody>


                </table>

            </div>
        </div>
    )
}

export interface PlantWithImage {
    imageid: string
    plantid: string
    plant: Plant
    image: Image,
    numberofimages: number
}

export interface Plant {
    id: string
    label: string
    publicimageid?: string
    imageid: string
    name: string
    specifictype: string
}

export interface Image {
    id: string
    label: string
    licensingkey: string
    name: string
}


const usePlantImageOperations = () => {

    const plantImages: PlantWithImage[] = useSelector((state: RdxStore) => state.tools.plantImages);
    const dispatch = useDispatch();

    const [loading, setLoading] = useState(false);

    const [page, setPage] = useState<number>(0);
    const [pageSize, setPageSize] = useState(50);


    const load = async () => {
        setLoading(true);
        let d: PlantWithImage[] = await FetchHandler.getJson("/api/plantops/getgapimages");
        d.sort((a, b) => {
            if (a?.numberofimages > b?.numberofimages) { return -1; }
            else if (a?.numberofimages < b?.numberofimages) { return 1; }
            else { return 0; }
        })
        setLoading(false);
        dispatch({ type: CHANGE_TOOLS_PLANTIMAGES, payload: d })
    }

    useEffect(() => {
        if (!plantImages || (plantImages?.length ?? 0) == 0) {
            load();
        }
    }, [])



    return {
        data: plantImages
            .filter(item => item?.plant?.imageid === item?.imageid)
            .map((m, i) => { return { ...m, n: i } })
            .filter((item, idx) => { return idx >= (page * pageSize) && idx < (page * pageSize + pageSize); }),
        page: page,
        goPrev: () => { setPage(page - 1) },
        goNext: () => { setPage(page + 1) },
        load: load,
        loading: loading
    }
}