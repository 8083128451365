import React, { useEffect, useState } from "react";
import { FetchHandler } from "../../Handlers/FetchHandler";
import { ForumPostCard } from "../Forum/ForumPostCard";
import { AdminIcons } from "../Shared/AdminIcon";
import { ToolBar } from "../ToolBar/ToolBar";
import { LoadingBar, ScreenHeader, ScreenMenu } from "../UI/Elements";
import { useElasticMenu } from "./useElasticMenu";
import { useElasticSearch } from "./useElasticSearch";

export const ElasticSearchUsers = () => {
    const ui = useElasticSearch();

    // useEffect(() => {
    //     ui.load();
    // }, []);

    const [search, setSearch] = useState<string>(undefined);
    const menu = useElasticMenu();
    return (
        <div className="screen-elastic">
            <ScreenHeader text="Appsök användare med Elastic Search" icon={AdminIcons.user} />
            <ScreenMenu items={menu?.items} />

            <LoadingBar loading={ui.loadingPlants} />
            <div>
                <input placeholder="Sök..." className="frm" type={"search"} value={search} onChange={e => setSearch(e.target.value)} onKeyUp={e => {
                    if (e.key === "Enter") {
                        ui.onSearchUsers(search);
                    }
                }} />
            </div>

            <div>
                <div>
                    Träffar: {ui?.users?.total ?? 0} - Svarstid: {ui.users?.durationms} ms.
                </div>
                <div>

                    {(ui.users?.suggestions?.length > 0) && (
                        <div className="suggestions-container">
                            <label>
                                Menade du?
                            </label>

                            <div className="suggestions">
                                {(ui.users?.suggestions?.length > 0) && ui.users?.suggestions?.map(s => (
                                    <div className="suggestion" key={s} onClick={() => {
                                        ui.onSearchUsers(s);
                                        setSearch(s);
                                    }} >
                                        {s}
                                    </div>
                                ))}
                            </div>
                        </div>
                    )}

                </div>
                <table className="tbl tbl-small">
                    <thead>
                        <tr>
                            <th>Namn</th>
                            <th>Kommun</th>
                            <th>Trädgård</th>
                            <th>Öppen/Stängd</th>
                            <th>Score</th>
                        </tr>
                    </thead>
                    <tbody>
                        {ui.users?.hits?.map(hit => (
                            <tr key={hit?.id}>
                                <td>{hit?.name}</td>
                                <td>{hit?.municipality}</td>
                                <td>{hit?.gardenname}</td>
                                <td>{hit?.privacyprofile}</td>
                                <td>{hit?.useractivityscore}</td>
                            </tr>
                        ))}
                    </tbody>
                </table>
            </div>
        </div>
    )
}
