import React from "react"

export const SvgProfile = ({ size = 24, color = "#CE9882", className }: { size?: number, color?: string, className?: string }) => {
    return (
        <svg width={size} height={size ? size : 24} viewBox="0 0 24 24" fill="none" className={className}>
            <path d="M19 20.5C19 19.5807 18.8189 18.6705 18.4672 17.8212C18.1154 16.9719 17.5998 16.2003 16.9497 15.5503C16.2997 14.9002 15.5281 14.3846 14.6788 14.0328C13.8295 13.6811 12.9193 13.5 12 13.5C11.0807 13.5 10.1705 13.6811 9.32122 14.0328C8.47194 14.3846 7.70026 14.9002 7.05025 15.5503C6.40024 16.2003 5.88463 16.9719 5.53284 17.8212C5.18106 18.6705 5 19.5807 5 20.5"
                stroke={color} stroke-width="1.4" stroke-linecap="round" />
            <path d="M6.75 5.52344L12.4375 6.94125L18.7128 8.16056"
                stroke={color} stroke-width="1.4" stroke-linecap="round" stroke-linejoin="round" />
            <path d="M15.8722 5.42662L16.5765 7.68599L16.1492 7.59181L8.88613 5.99069L10.4751 4.23686C10.957 3.70498 11.6869 3.47208 12.3878 3.62659L14.3934 4.0687C15.0943 4.22321 15.6586 4.74143 15.8722 5.42662Z"
                stroke={color}
                stroke-width="1.4" stroke-linecap="round" stroke-linejoin="round" />
            <path d="M8.50001 8.57171C8.58884 10.7445 10.4223 12.4339 12.5951 12.3451C14.7679 12.2562 16.375 10.4375 16.375 7.8125"
                stroke={color}
                stroke-width="1.4" stroke-linecap="round" stroke-linejoin="round" />
        </svg>
    )
}
