import React from 'react';
// import { Link } from 'react-router-dom';
// import { BuildInfo } from '../../BuildInfo';
// import { Routes /*, Views*/ } from "../../Handlers/ViewHandler";
// import store from './../../rdx/store';

export const Buttons = {
    Standard : ({label, onClick}) => {
        return (
            <button className="btn" onClick={onClick}>{label}</button>
        )
    },
    Link : ({label, onClick}) => {
        return (
            <button className="btn-link" onClick={onClick}>{label}</button>
        )
    },
}