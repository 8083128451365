import store from '../rdx/store';
import { FetchHandler } from "./FetchHandler";
import { MessageBoxes } from './MessageBoxes';
import { Reloads } from '../rdx/StoreFillerHandler';
import { CHANGE_PLANTTYPES_LIST, CHANGE_SNIPPETS } from '../rdx/dataReducer';

function snippetsHandler() {
    let self = this;


    self.updateSnippetsTags = async (id, tags) => {
        let data = store.getState().data.snippets;
        data[id].snippetTags = tags;
        store.dispatch({ type: CHANGE_SNIPPETS, payload: data});

        const response = await FetchHandler.postJson("/api/snippet/"+id+"/settags", { tags: tags});

        console.log("tags: ", tags)

    }

}

const SnippetsHandler = new snippetsHandler();

export { SnippetsHandler };