import { useSelector, useDispatch } from 'react-redux'; import { FetchHandler } from "../../Handlers/FetchHandler";
import { RdxStore } from "../../rdx/ReduxTypes";
import { useEffect, useState } from "react";
import { CHANGE_TOOLS_CLIPBOARD_DATA, CHANGE_TOOLS_CLIPBOARD_SHOW, CHANGE_TOOLS_CLIPBOARD_STATE, CHANGE_TOOLS_SOCIALIMAGESOPS, CHANGE_TOOLS_USERIMAGESOPS } from "../../rdx/toolsreducer";
import { PlantWithImage } from "../PlantImageOperations/PlantImageOperations";
import { ApiPostWithImages } from "../PlantImageOperations/useSocialImageOperations";
import { BlogPostType } from '../Blog/useBlog';
import { ForumPostType } from '../Forum/Forum';

export type ClipBoardDropTargetType = "plantimage" | "postimage" | "blog" | "userpostfeaturedcard";
export type ClipBoardStateType = "dropping" | "show";

export const ClipBoardDropTargets = {
    list: "list",
    plant: "plant",
    plantid: "plantid",
    blog: "blog",
    plantimage: "plantimage",
    postimage: "postimage",
    userpostfeaturedcard: "userpostfeaturedcard",
}

export type ClipBoardDataType = {
    plantimages?: PlantWithImage[],
    postimage?: ApiPostWithImages[],
    blogs?: BlogPostType[],
    plantids?: string[],
    featuredforumpost?: ForumPostType[]
}

export const useClipBoard = () => {
    const clipBoardData: ClipBoardDataType = useSelector((state: RdxStore) => state.tools.clipBoardData);

    const clipBoardShow: boolean = useSelector((state: RdxStore) => state.tools.clipBoardShow);
    const clipBoardState: ClipBoardStateType = useSelector((state: RdxStore) => state.tools.clipBoardState);


    const dispatch = useDispatch();

    useEffect(() => {
        if (clipBoardShow) {
            document.querySelector("clipboard-element")?.classList?.remove("hidden");
        } else {
            document.querySelector("clipboard-element")?.classList?.add("hidden");
        }
    }, [clipBoardShow])




    const totalCount = ((clipBoardData?.plantimages?.length) ?? 0) +
        ((clipBoardData?.postimage?.length) ?? 0) +
        ((clipBoardData?.blogs?.length) ?? 0) +
        ((clipBoardData?.plantids?.length) ?? 0) +
        ((clipBoardData?.featuredforumpost?.length) ?? 0);

    const [lastOpen, setLastOpen] = useState<boolean>(false);

    return {
        clipBoardShow: clipBoardShow,
        clipBoardState: (totalCount == 0 ? "dropping" : clipBoardState),
        setClipBoardState: (e: ClipBoardStateType) => {
            dispatch({ type: CHANGE_TOOLS_CLIPBOARD_STATE, payload: e });
        },
        clipBoardData: clipBoardData,
        showClipBoard: (e: ClipBoardStateType) => {
            setLastOpen(clipBoardShow);
            dispatch({ type: CHANGE_TOOLS_CLIPBOARD_SHOW, payload: true });
            if (e) {
                dispatch({ type: CHANGE_TOOLS_CLIPBOARD_STATE, payload: e });
            }
        },
        hideClipBoard: (e?: ClipBoardStateType, forceHide?: boolean) => {
            if (!lastOpen || forceHide === true) {
                dispatch({ type: CHANGE_TOOLS_CLIPBOARD_SHOW, payload: false });
            }
            if (e) {
                dispatch({ type: CHANGE_TOOLS_CLIPBOARD_STATE, payload: e });
            }
        },
        onDrop: () => {

        },
        selectedUserImagesPlants: clipBoardData?.plantimages,
        selectedUserImagesSocial: clipBoardData?.postimage,
        totalCount: totalCount,
        // Add
        addSelectedUserImagesPlants: (e: PlantWithImage) => {
            const c = [...(clipBoardData?.plantimages ?? []), e];
            dispatch({ type: CHANGE_TOOLS_CLIPBOARD_DATA, payload: { ...clipBoardData, plantimages: c } });
        },
        addSelectedUserImagesSocial: (e: ApiPostWithImages) => {
            const c = [...(clipBoardData?.postimage ?? []), e];
            dispatch({ type: CHANGE_TOOLS_CLIPBOARD_DATA, payload: { ...clipBoardData, postimage: c } });
        },
        addSelectedBlog: (e: BlogPostType) => {
            const c = [...(clipBoardData?.blogs ?? []), e];
            dispatch({ type: CHANGE_TOOLS_CLIPBOARD_DATA, payload: { ...clipBoardData, blogs: c } });
        },
        addSelectedFeaturedForumPost: (e: ForumPostType) => {
            const c = [...(clipBoardData?.featuredforumpost ?? []), e];
            dispatch({ type: CHANGE_TOOLS_CLIPBOARD_DATA, payload: { ...clipBoardData, featuredforumpost: c } });
        },
        addSelectedPlantids: (e: string[]) => {
            const c = [...(clipBoardData?.plantids ?? []), [...e]];
            dispatch({ type: CHANGE_TOOLS_CLIPBOARD_DATA, payload: { ...clipBoardData, plantids: c } });
        },
        // Remove
        removePostImage: (imageid: string) => {
            const c = [...(clipBoardData?.postimage?.filter(m => m?.imageid != imageid) ?? [])];
            dispatch({ type: CHANGE_TOOLS_CLIPBOARD_DATA, payload: { ...clipBoardData, postimage: c } });
        },
        removePlantImage: (imageid: string) => {
            const c = [...(clipBoardData?.plantimages?.filter(m => m?.imageid != imageid) ?? [])];
            dispatch({ type: CHANGE_TOOLS_CLIPBOARD_DATA, payload: { ...clipBoardData, plantimages: c } });
        },
        removeBlog: (blogId: string) => {
            const c = [...(clipBoardData?.blogs?.filter(m => m?.id != blogId) ?? [])];
            dispatch({ type: CHANGE_TOOLS_CLIPBOARD_DATA, payload: { ...clipBoardData, blogs: c } });
        },
        removeFeaturedForumPost: (postId: string) => {
            const c = [...(clipBoardData?.featuredforumpost?.filter(m => m?.id != postId) ?? [])];
            dispatch({ type: CHANGE_TOOLS_CLIPBOARD_DATA, payload: { ...clipBoardData, featuredforumpost: c } });
        },

        // clear
        clearPostImages: () => {
            dispatch({ type: CHANGE_TOOLS_CLIPBOARD_DATA, payload: { ...clipBoardData, postimage: [] } });
        },
        clearFeaturedForumPost: () => {
            dispatch({ type: CHANGE_TOOLS_CLIPBOARD_DATA, payload: { ...clipBoardData, featuredforumpost: [] } });
        },
        clearPlantImages: () => {
            dispatch({ type: CHANGE_TOOLS_CLIPBOARD_DATA, payload: { ...clipBoardData, plantimages: [] } });
        },
        clearBlogs: () => {
            dispatch({ type: CHANGE_TOOLS_CLIPBOARD_DATA, payload: { ...clipBoardData, blogs: [] } });
        },
        clearPlantIds: () => {
            dispatch({ type: CHANGE_TOOLS_CLIPBOARD_DATA, payload: { ...clipBoardData, plantids: [] } });
        },
    }
}