import React, { ReactNode } from 'react';
// props
//      disabled
//      onCancel
//      onOk
//      children
//      width
//      Height
export const Dialog = ({ title, visible, disabled, okDisabled, onCancel, okButtonText, cancelButtonText, onOk, children, width, height, top }: {
    title?: string,
    visible?: boolean, disabled?: boolean,
    onOk?: any, okButtonText?: string, okDisabled?: boolean,
    cancelButtonText?: string, onCancel?: any,
    children: ReactNode,
    width?: string, height?: string, top?: string
}) => {

    if (visible === false) {
        return (null)
    }


    let _okButtonText = "Ok";
    let _cancelButtonText = "Ångra";

    const _okDisabled = okDisabled !== null ? okDisabled : false;

    if (okButtonText) {
        _okButtonText = okButtonText;
    }
    if (cancelButtonText) {
        _cancelButtonText = cancelButtonText;
    }

    let style: React.CSSProperties = {
        paddingBottom: "70px"
    };
    if (disabled) {
        style.pointerEvents = "none";
        style.filter = "brightness(80%)";
    }
    if (width) {
        style.width = width;
        style.left = "calc(50vw - " + width + "/2)";
    }
    if (height) {
        style.minHeight = height;
    }
    if (top) {
        style.top = top;
    }
    if (top) {
        style.top = top;
    }


    return (
        <>
            <div className="dialog-outer"></div>
            <div className="dialog-inner" style={style}>
                {title && (
                    <h3 className="dialog-title">{title}</h3>
                )}
                <div></div>
                <div className="dialog-content">
                    {children}
                </div>
                <div className="buttons">
                    {onCancel && (
                        <button className="btn" onClick={disabled ? null : onCancel}>{_cancelButtonText}</button>
                    )}
                    {onOk && (
                        <button className="btn" onClick={disabled ? null : onOk} disabled={_okDisabled}>{_okButtonText}</button>
                    )}
                </div>
            </div>
        </>
    )
}