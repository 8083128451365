import { useEffect, useState } from "react";
import { FetchHandler } from "../../Handlers/FetchHandler";

type ScheduleTypeType =
    "none" |
    "singleperiod" |
    "singleperiodwithsnippet" |
    "singleperiodwithplanttype" |
    "zone";
export type PlantLinksTypes = "pruning" | "take_care" | "grow_and_plant";


export type zonePeriodType = { zone: number, period: string, }
export type GardenActivityType = {
    id?: string,
    name?: string,
    nameFormatter?: string,
    description?: string,
    scheduleType?: ScheduleTypeType,

    singleperiodSelection?: string,
    snippetId?: string,
    plantTypeId?: string,
    publicActivity?: boolean,

    zoneFieldOptions?: { fieldId: string, value: string },
    zonePeriods?: zonePeriodType[],
    plantLink?: PlantLinksTypes,
    isSingleActivity?: boolean,
    cardColor?: string
}
export const useGardenActivities = (loadOnMount: boolean) => {


    const [data, setData] = useState<GardenActivityType[]>([]);
    const [addDialogVisible, setAddDialogVisible] = useState<boolean>(false);
    const [addDialogDisabled, setAddDialogDisabled] = useState(false);
    const [addModel, setAddModel] = useState<GardenActivityType>(null);
    const [selectedActivityId, setSelectedActivityId] = useState<string>(null);


    useEffect(() => {
        if (loadOnMount === true) {
            loadData();
        }
    }, []);

    const getFirstPeriodFromActivity = (a: GardenActivityType) => {
        return a?.singleperiodSelection ? a?.singleperiodSelection : a?.zonePeriods?.[0]?.period;
    }

    const loadData = async () => {
        let data: GardenActivityType[] = await FetchHandler.getJson("/api/activities");
        data.sort((a, b) => {
            const asort = getFirstPeriodFromActivity(a) + a?.name;
            const bsort = getFirstPeriodFromActivity(b) + b?.name;
            if (asort > bsort) { return 1; }
            else if (asort < bsort) { return -1; }
            else { return 0 }
        })
        setData(data);
    }

    const addActivity = async () => {
        const url = "/api/activities/add";
        const newId = await FetchHandler.postJson(url, addModel);
        if (newId) {
            loadData();
            setSelectedActivityId(newId);
        }
        return !!newId;
    }
    const deleteActivity = async (id: string) => {
        const url = "/api/activities/" + id + "/delete";
        const success = await FetchHandler.postJson(url, {});
        if (success) {
            loadData();
        }
        return success;
    }
    const updateActivity = (e: GardenActivityType) => {
        const url = "/api/activities/edit";
        FetchHandler.postJson(url, e).then(success => {
            if (success) {
                loadData();
            }
        });
    }

    const scheduleTypes: { value: ScheduleTypeType, label: string }[] = [
        { value: null, label: "Välj..." },
        { value: "singleperiod", label: "En period" },
        { value: "singleperiodwithsnippet", label: "En period och snippet" },
        { value: "singleperiodwithplanttype", label: "En period och växttyp" },
        { value: "zone", label: "Zone" }
    ];

    const plantLinkOptions: { value: PlantLinksTypes, label: string }[] = [
        { value: null, label: "Ingen" },
        { value: "take_care", label: "Sköta" },
        { value: "pruning", label: "Beskära" },
        { value: "grow_and_plant", label: "Odla & Plantera" },
    ];

    return {
        data: data,
        scheduleTypes: scheduleTypes,
        plantLinkOptions: plantLinkOptions,
        updateActivity: updateActivity,
        getFirstPeriodFromActivity: getFirstPeriodFromActivity,
        // Add
        addClicked: () => { setAddDialogVisible(true) },
        addDialogVisible: addDialogVisible,
        addDialogDisabled: addDialogDisabled,
        addOnOk: async () => {
            setAddDialogDisabled(true);
            const success = await addActivity();
            setAddDialogDisabled(false);
            setAddDialogVisible(false);
            setAddModel(null);
        },
        addOnCancel: () => { setAddDialogVisible(false) },
        addModel: addModel,
        setAddModel: ((e: GardenActivityType) => { setAddModel({ ...addModel, ...e }); }),
        addIsValid: () => { return addModel?.name?.length > 2 && addModel?.description?.length > 2 && addModel?.scheduleType?.length > 2 },
        // selectedActivityId
        selectedActivityId: selectedActivityId,
        onRowClicked: (id: string) => { setSelectedActivityId(selectedActivityId == id ? null : id) },
        onRowDeleteClicked: async (id: string) => {
            const d = await deleteActivity(id);
            const l = await loadData();
        },

    }

}